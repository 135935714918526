export enum EventsEnum {
    PAGE_READY = 'PAGE_READY',
    PAGE_ERROR = 'PAGE_ERROR',
    PAGE_DATA_RESOLVED = 'PAGE_DATA_RESOLVED',

    MARK_MODE_ENABLE = 'MARK_MODE_ENABLE',
    MARK_MODE_DISABLE = 'MARK_MODE_DISABLE',

    USER_LOGGED_IN = 'USER_LOGGED_IN',
    USER_LOGGED_OUT = 'USER_LOGGED_OUT',

    TRACK_ACTION = 'TRACK_ACTION',

    GROUP_DELETED = 'GROUP_DELETED',
    INVALID_SESSION = 'INVALID_SESSION',
}
