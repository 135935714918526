import { ComponentRef, ViewContainerRef } from '@angular/core';
import { compact } from 'lodash';

import { RESTRICTION_ACTION } from '@shared/enums';
import { CHANNEL, DEVICE } from '@main/enums';
import { Country, Period } from '@shared/models';
import { CommonHelpers } from '@shared/helpers/common.helpers';
import { WidgetInfo, WidgetSpecialProps } from '@shared/widgets/core/widgets.types';
import { ReportConfigComparisonSettings } from '@shared/interfaces/ReportConfig';
import { Channel, Device, EntityGroup, EntityItem } from '@main/models';
import { IEntityItemGroupId, IEntityItemId, IEntityItemInstanceId, RouteIdsItem } from '@main/types/types';
import { WIDGET_DIMENSION } from '@main/widgets/shared/widgets-dimensions.types';
import { ICountry, IPeriod } from '@shared/interfaces';
import { IChannel } from '../interfaces';
import { WidgetBase } from '../widgets/shared/widget-base';

interface BuildDatasetsParams<T> {
    allItems?: T[];
    currentItems: T[];
    noPermissionAction: RESTRICTION_ACTION;
    noDataAction: RESTRICTION_ACTION;
}

interface BuildPeriodsDatasetsParams<T> extends BuildDatasetsParams<T> {
    maxMonthsLimitAction: RESTRICTION_ACTION;
    maxMonthLimit?: number;
}

const defaultDimensions = [WIDGET_DIMENSION.SPEND, WIDGET_DIMENSION.IMPRESSIONS, WIDGET_DIMENSION.CREATIVES];

export class ReportHelpers {
    static buildCountriesList(params: BuildDatasetsParams<ICountry>): Country[] {
        const { allItems, currentItems, noPermissionAction, noDataAction } = params;

        const availIdentifiers = currentItems && currentItems.length ? currentItems.map((a) => a.id) : undefined;

        return allItems
            .map((item) => {
                const dataExist = availIdentifiers && availIdentifiers.includes(item.id);
                const permissionExist = !item.error;

                const country = new Country({
                    id: item.id,
                    code: item.code,
                    title: item.title,
                    disabled: false,
                    restricted: false,
                });

                if (!dataExist) {
                    if (noDataAction === RESTRICTION_ACTION.DISABLE) {
                        country.disabled = true;
                    }

                    if (noDataAction === RESTRICTION_ACTION.RESTRICT) {
                        country.restricted = true;
                    }

                    if (noDataAction === RESTRICTION_ACTION.REMOVE) {
                        return;
                    }
                }

                if (!permissionExist) {
                    if (noPermissionAction === RESTRICTION_ACTION.DISABLE) {
                        country.disabled = true;
                    }

                    if (noPermissionAction === RESTRICTION_ACTION.RESTRICT) {
                        country.restricted = true;
                    }

                    if (noPermissionAction === RESTRICTION_ACTION.REMOVE) {
                        return;
                    }
                }

                return country;
            })
            .filter((v) => !!v);
    }

    static buildChannelsList(params: BuildDatasetsParams<IChannel>): Channel[] {
        const { currentItems, noPermissionAction, noDataAction } = params;

        let channels = currentItems
            .map((item) => {
                const dataExist = true;
                const permissionExist = !item.error;

                const channel = new Channel({
                    id: item.id,
                    title: item.title,
                    type: item.type as CHANNEL,
                    devices: [],
                    disabled: false,
                    restricted: false,
                });

                if (!dataExist) {
                    if (noDataAction === RESTRICTION_ACTION.DISABLE) {
                        channel.disabled = true;
                    }

                    if (noDataAction === RESTRICTION_ACTION.RESTRICT) {
                        channel.restricted = true;
                    }

                    if (noDataAction === RESTRICTION_ACTION.REMOVE) {
                        return;
                    }
                }

                if (!permissionExist) {
                    if (noPermissionAction === RESTRICTION_ACTION.DISABLE) {
                        channel.disabled = true;
                    }

                    if (noPermissionAction === RESTRICTION_ACTION.RESTRICT) {
                        channel.restricted = true;
                    }

                    if (noPermissionAction === RESTRICTION_ACTION.REMOVE) {
                        return;
                    }
                }

                if (item.devices?.elements) {
                    const devices = item.devices.elements.map(
                        (v) =>
                            new Device({
                                code: v.entity.code as DEVICE,
                                title: v.entity.title,
                                disabled: false,
                                restricted: false,
                            }),
                    );

                    if (devices.length > 1 && !devices.find((device) => device.code === DEVICE.ALL)) {
                        devices.unshift({ code: DEVICE.ALL, title: 'All devices', restricted: false, disabled: false });
                    }

                    channel.devices = devices;
                }

                return channel;
            })
            .filter((v) => !!v);

        if (channels.length === 2) {
            channels = channels.filter((v) => v.type !== CHANNEL.ALL);
        }

        return channels;
    }

    static buildPeriodsList(params: BuildPeriodsDatasetsParams<IPeriod>): Period[] {
        const { currentItems, noPermissionAction, noDataAction, maxMonthsLimitAction, maxMonthLimit } = params;

        return currentItems
            .map((item) => {
                const dataExist = !item.warning;
                const permissionExist = !item.error;

                const period = new Period({
                    id: item.id,
                    code: item.code,
                    type: item.type,
                    title: item.title,
                    start: item.start,
                    end: item.end,
                    supportedIntervals: item.supportedIntervals,
                    defaultInterval: item.defaultInterval,
                    disabled: false,
                    restricted: false,
                });

                if (!dataExist) {
                    if (noDataAction === RESTRICTION_ACTION.DISABLE) {
                        period.disabled = true;
                    }

                    if (noDataAction === RESTRICTION_ACTION.RESTRICT) {
                        period.restricted = true;
                    }

                    if (noDataAction === RESTRICTION_ACTION.REMOVE) {
                        return;
                    }
                }

                if (maxMonthLimit && CommonHelpers.getPeriodMonthsCount(period) > maxMonthLimit) {
                    if (maxMonthsLimitAction === RESTRICTION_ACTION.DISABLE) {
                        period.disabled = true;
                    }

                    if (maxMonthsLimitAction === RESTRICTION_ACTION.RESTRICT) {
                        period.restricted = true;
                    }

                    if (maxMonthsLimitAction === RESTRICTION_ACTION.REMOVE) {
                        return;
                    }
                }

                if (!permissionExist) {
                    if (noPermissionAction === RESTRICTION_ACTION.DISABLE) {
                        period.disabled = true;
                    }

                    if (noPermissionAction === RESTRICTION_ACTION.RESTRICT) {
                        period.restricted = true;
                    }

                    if (noPermissionAction === RESTRICTION_ACTION.REMOVE) {
                        return;
                    }
                }

                return period;
            })
            .filter((v) => !!v);
    }

    static plainRouteIds(ids: RouteIdsItem[]): (IEntityItemInstanceId | IEntityItemGroupId)[] {
        return ids.reduce(
            (a, v) => (Array.isArray(v) ? a.concat(...this.plainRouteIds(v)) : a.concat(v)),
            [] as Array<any>,
        );
    }

    static getDefaultComparisonSettings(dimensions?: WIDGET_DIMENSION[]): ReportConfigComparisonSettings {
        dimensions = dimensions ? [...defaultDimensions, ...dimensions] : defaultDimensions;

        return {
            dimensions,
        };
    }

    static addWidgetToContainer(
        containerRef: ViewContainerRef,
        widget: WidgetInfo,
        specialProps: WidgetSpecialProps,
    ): ComponentRef<WidgetBase> {
        const ref = containerRef.createComponent<WidgetBase>(widget.component);

        const { instance } = ref;

        instance.widgetInfo = widget;

        if (widget.props) {
            Object.entries(widget.props).forEach(([key, value]) => {
                if (typeof value === 'string' && value[0] === '$') {
                    instance[key] = specialProps[value.slice(1)];
                } else {
                    instance[key] = value;
                }
            });
        }

        return ref;
    }

    static serializeExportEntitiesFileNamePart(
        rootEntity: EntityGroup,
        entities: EntityItem[],
        categoryBenchmarkId: IEntityItemId,
    ): string {
        const entitiesTitles = rootEntity.isVirtual()
            ? entities.map((item) => (item.id === categoryBenchmarkId ? '' : item.title))
            : [rootEntity.title];

        return compact(entitiesTitles).join('_');
    }
}
