import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FullscreenViewService } from '@main/shared/widget-full-screen-view/services/fullscreen-view.service';

@Directive({ selector: '[showForFullScreenMode]' })
export class ShowForFullScreenModeDirective implements OnInit {
    @Input() showForFullScreenMode = true;
    @Input('showForFullScreenModeExceptions') exceptions: unknown[];

    constructor(
        private viewContainer: ViewContainerRef,
        private template: TemplateRef<HTMLElement>,
        private fullScreenViewService: FullscreenViewService,
    ) {}

    ngOnInit() {
        this.updateView();
    }

    private updateView(): void {
        if (this.showForFullScreenMode === this.fullScreenViewService.isFullscreenView) {
            this.viewContainer.createEmbeddedView(this.template);
        } else {
            if (!this.exceptions) return;

            const hasExceptions = this.exceptions.some(Boolean);

            if (hasExceptions) this.viewContainer.createEmbeddedView(this.template);
        }
    }
}
