import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IUser, IUserFeatures, IUserRole } from '@shared/interfaces';
import { FEATURE_NAME_ENUM, PRODUCT_TYPE } from '@shared/enums';
import { AuthHelpers } from './auth/auth.helpers';
import { EventsService } from './events/events.service';
import { EventsEnum } from './events/events.enum';
import { AuthService } from './auth/auth.service';

const userStorageKey = 'currentUser';

@Injectable()
export class AccountService {
    $currentUser = new Observable<IUser>();
    $features = new Observable<IUserFeatures>();

    private $currentUserSubject = new BehaviorSubject<IUser>(null);
    private $featuresSubject = new BehaviorSubject<IUserFeatures>(null);

    private currentUser: IUser;

    constructor(private authService: AuthService, private eventsService: EventsService) {
        this.$currentUser = this.$currentUserSubject.asObservable().pipe(filter((value) => value !== null));

        this.$features = this.$featuresSubject.asObservable().pipe(filter((value) => value !== null));

        this.eventsService.on(
            EventsEnum.USER_LOGGED_IN,
            () => {
                this.setFromAuthData();
            },
            'AccountService',
        );

        this.eventsService.on(
            EventsEnum.USER_LOGGED_OUT,
            () => {
                this.invalidate();
            },
            'AccountService',
        );

        this.authService.isAuthenticated().subscribe((isAuthenticated) => {
            if (isAuthenticated) {
                this.setFromAuthData();
            }
        });
    }

    get features(): IUserFeatures {
        return this.currentUser?.features;
    }

    get roles(): IUserRole[] {
        return this.currentUser?.roles ? this.currentUser.roles : [];
    }

    setFromAuthData(): void {
        this.authService.getAuthData().subscribe((authData) => {
            const accessTokenData: Record<string, any> = authData
                ? AuthHelpers.decodeToken(authData.accessToken)
                : undefined;

            if (!accessTokenData || !accessTokenData.actor) {
                return;
            }

            const userData = accessTokenData.actor;

            const roles = accessTokenData.actor?.roles || [];
            const features = accessTokenData.package?.features;

            //          features['SHOW_EXPORT_LIMITATION'] = {};

            this.currentUser = {
                id: userData.id,
                customer: userData.customer,
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                avatarUrl: userData.avatarUrl,
                roles,
                features,
            };

            this.clearLocalCurrentUser();

            this.emitCurrentState();
        });
    }

    invalidate(): void {
        delete this.currentUser;
    }

    hasPermission(permission: FEATURE_NAME_ENUM | FEATURE_NAME_ENUM[]): boolean {
        const permissionsAr = Array.isArray(permission) ? permission : [permission];
        let hasPermission = false;

        for (const checkPermission of permissionsAr) {
            if (!this.features || !!this.features[checkPermission]) {
                hasPermission = true;
                break;
            }
        }

        return hasPermission;
    }

    getAvailableProducts(): PRODUCT_TYPE[] {
        const items: PRODUCT_TYPE[] = [];

        //        if (this.hasPermission(FEATURE_NAME_ENUM.PRODUCT_DIGITAL_AD_INTELLIGENCE)) {
        items.push(PRODUCT_TYPE.DIGITAL_AD_INTELLIGENCE);
        //        }

        if (this.hasPermission(FEATURE_NAME_ENUM.PRODUCT_BRAND_REPUTATION)) {
            items.push(PRODUCT_TYPE.BRAND_REPUTATION);
        }

        return items;
    }

    private clearLocalCurrentUser(): void {
        try {
            localStorage.removeItem(userStorageKey);
        } catch (err) {
            console.error(`Can't acess localStorage`);
        }
    }

    private emitCurrentState(): void {
        this.$currentUserSubject.next(this.currentUser);

        this.$featuresSubject.next(this.currentUser.features);
    }
}
