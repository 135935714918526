export class ExportHelpers {
    static setSvgIconsIds(originContainer: HTMLElement): void {
        const randomId = () => 'id_icon_' + Math.floor(Math.random() * 1000000);

        Array.from(originContainer.querySelectorAll('svg')).forEach((element) => {
            if (!element.id) element.id = randomId();
        });
    }

    static fixSvgIcons(originContainer: HTMLElement, clonedContainer: HTMLElement): void {
        // setting icons exact size istead of 100%

        const originEls = originContainer.querySelectorAll('svg');

        Array.from(originEls).forEach((element) => {
            const isMatIcon = element.parentNode.nodeName.toLowerCase() === 'mat-icon';
            const isCheckbox = element.classList.contains('mdc-checkbox__checkmark');

            if (isMatIcon || isCheckbox) {
                const clonedEl = element.id && (clonedContainer.querySelector(`#${element.id}`) as HTMLElement);

                if (clonedEl) {
                    const color = window.getComputedStyle(element).getPropertyValue('color');

                    clonedEl.setAttribute('width', element.clientWidth.toString());
                    clonedEl.setAttribute('height', element.clientHeight.toString());
                    clonedEl.setAttribute('color', color);

                    if (isCheckbox) {
                        clonedEl.querySelector('path').setAttribute('stroke', '#fff');
                        clonedEl.querySelector('path').setAttribute('stroke-width', '3');
                    }
                }
            }
        });
    }

    static uncacheImages(container: HTMLElement): void {
        const corsImages = container.querySelectorAll('[export-cors-image]');
        const uncacheImages = container.querySelectorAll('[export-uncache-image]');
        const rnd = Math.floor(Math.random() * 1000000000);

        Array.from(corsImages).forEach((img: HTMLImageElement) => {
            img.setAttribute('crossorigin', '');
        });

        Array.from(uncacheImages).forEach((img: HTMLImageElement) => {
            if (img.src?.indexOf('?nc') === -1) {
                img.src += '?nc' + rnd;
            }
        });
    }

    static unsetScrollables(container: HTMLElement): void {
        const cts = container.querySelectorAll('[perfectscrollbar]');

        Array.from(cts).forEach((element: HTMLElement) => {
            element.removeAttribute('perfectscrollbar');
            element.classList.remove('scrollable-container');
        });
    }
}
