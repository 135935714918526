import { CommonHelpers } from '@shared/helpers/common.helpers';
import { axisTitleColor, labelsColor, lineColor } from './charts-defaults';

export const options = {
    generic: {
        chart: {
            type: 'spline',
            marginRight: 0,
        },
        xAxis: {
            visible: true,
            lineColor,
            tickWidth: 0,
            title: {
                text: '',
            },
            labels: {
                style: {
                    color: labelsColor,
                    fontSize: '12px',
                },
            },
        },
        yAxis: {
            labels: {
                style: {
                    color: labelsColor,
                },
                formatter(): string {
                    return CommonHelpers.formatShortNumber(this.value, 1, true);
                },
            },
            title: {
                style: {
                    color: axisTitleColor,
                },
                margin: 0,
                x: -15,
            },
        },
        legend: {
            itemStyle: {
                fontSize: '12px',
                fontWeight: 'normal',
            },
            enabled: true,
            itemMarginTop: 5,
        },
        title: {
            text: '',
        },
        credits: { enabled: false },
        subtitle: { text: '' },
        plotOptions: {
            series: {
                stickyTracking: true,
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 3,
                },
            },
        },
        tooltip: {
            useHTML: true,
            headerFormat: `
                <b>{point.key}</b>
                <br/>
                <table>
            `,
            pointFormatter(): string {
                const value = CommonHelpers.formatShortNumber(this.y);

                return `
                    <tr>
                        <td style="color: ${this.color}">${this.series.name}:&nbsp;</td>
                        <td style="text-align: right"><b>${value}</b></td>
                    </tr>
                `;
            },
            footerFormat: '</table>',
            xDateFormat: '%d, %b',
            shared: true,
        },
    },

    small: {
        chart: {
            height: 320,
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%b %e',
                week: '%b %e',
                month: '%b %e, %Y',
                year: '%b',
                minTickInterval: 24 * 3600 * 1000,
                millisecond: '%e %b',
            },
        },
        legend: {
            y: 15,
            x: 20,
            itemDistance: 30,
            align: 'left',
        },
    },

    big: {
        chart: {
            height: 320,
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%b %e',
                week: '%b %e',
                month: '%b %e, %Y',
                year: '%b',
                minTickInterval: 24 * 3600 * 1000,
                millisecond: '%e %b ',
            },
        },
        legend: {
            y: 15,
            x: 20,
            itemDistance: 30,
            align: 'left',
        },
    },

    customTooltip: {
        tooltip: {
            formatter: () => {},
            className: 'adc-charts-custom-tooltip',
            padding: 0,
            borderWidth: 0,
            shadow: false,
        },
    },

    xAxisDate: {},
};
