<button
    [ngClass]="isStroked ? 'menu-btn-stroked' : 'menu-btn'"
    [matMenuTriggerFor]="menu"
    (click)="$event.stopPropagation()"
>
    <mat-icon class="menu-icon" svgIcon="threeDots"></mat-icon>
</button>

<mat-menu #menu="matMenu" class="dashboards-custom-menu">
    <div *ngFor="let item of menuSchema" [matTooltip]="item.tooltip">
        <button mat-menu-item (click)="onEvent.emit(item)" [disabled]="item.disabled">
            <mat-icon [svgIcon]="item.icon"></mat-icon>
            <span>{{ item.name }}</span>
        </button>
    </div>
</mat-menu>
