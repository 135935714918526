<ng-container [ngSwitch]="item.entityType">
    <category-entity-card
        *ngSwitchCase="BUSINESS_ENTITY.Category"
        [item]="item"
        [addable]="addable"
        [removable]="removable"
        [animate]="animate"
        [stripTitle]="stripTitle"
        [addTooltip]="addTooltip"
        [removeTooltip]="removeTooltip"
        (add)="add.emit($event)"
        (remove)="remove.emit($event)"
        (click)="click.emit($event)"
        class="padded"
    >
    </category-entity-card>

    <default-entity-card
        *ngSwitchDefault
        [item]="item"
        [addable]="addable"
        [removable]="removable"
        [animate]="animate"
        [stripTitle]="stripTitle"
        [addTooltip]="addTooltip"
        [removeTooltip]="removeTooltip"
        (add)="add.emit($event)"
        (remove)="remove.emit($event)"
        (click)="click.emit($event)"
        class="padded"
    >
    </default-entity-card>
</ng-container>
