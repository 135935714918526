import { Directive, Input } from '@angular/core';

import { UXArea } from '../../models';

@Directive()
export abstract class AnalyticsAreaBase {
    abstract type: UXArea;

    @Input() analyticsName?: string;
}
