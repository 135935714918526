import { Class } from '@shared/core/class';
import { DEVICE } from '@main/enums';

export class Device extends Class {
    code: DEVICE;
    title: string;
    restricted: boolean;
    disabled: boolean;

    constructor(data: Partial<Device>) {
        super();

        this.code = data.code;
        this.title = data.title;
        this.restricted = data.restricted;
        this.disabled = data.disabled;
    }
}
