import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minimp',
})
export class MinImpressionsPipe implements PipeTransform {
    min = '<10K';

    transform(value: string): string {
        // if (isNaN(parseFloat(value)) || !isFinite(value)) return '';
        if (parseFloat(value) < 10000) {
            return this.min;
        }

        return value ?? 'N/A';
    }
}
