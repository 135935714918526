import { svgIconsConfig } from '@app/app.icons-config';
import { CHANNEL, DEVICE } from '@main/enums';
import { Observable, Observer } from 'rxjs';

export class IconsHelpers {
    static getDeviceIconNameByCode(deviceCode: DEVICE): string {
        let icon: string;

        switch (deviceCode) {
            case DEVICE.ALL:
                icon = 'allDevices';
                break;

            case DEVICE.DESKTOP:
                icon = 'channelDesktopDisplay';
                break;

            case DEVICE.MOBILE:
                icon = 'channelMobileWebDisplay';
                break;

            default:
                icon = 'channelAll';
                break;
        }

        return icon;
    }

    static getChannelIconNameByType(channelType: CHANNEL): string {
        let icon: string;

        switch (channelType) {
            case CHANNEL.ALL:
                icon = 'channelAll';
                break;

            case CHANNEL.DISPLAY:
                icon = 'channelDisplay';
                break;

            case CHANNEL.VIDEO:
                icon = 'channelVideo';
                break;

            case CHANNEL.SOCIAL:
                icon = 'channelSocial';
                break;

            default:
                icon = 'channelAll';
                break;
        }

        return icon;
    }

    static getEntityIconImagePath(icon?: string): string {
        const defaultIconsPath = '/assets/icons/';
        const defaultIcon = ':siteDefault';

        const path = icon || defaultIcon;

        if (path[0] === ':') {
            return defaultIconsPath + svgIconsConfig[path.slice(1)];
        } else {
            return path;
        }
    }

    static getImageOriginalSize(imgUrl: string): Observable<{ width; height }> {
        return new Observable((observer: Observer<{ width; height }>) => {
            const image = new Image();
            image.src = imgUrl;

            image.onload = function () {
                const height = image.height;
                const width = image.width;
                observer.next({ width, height });
            };

            image.onerror = function (error) {
                observer.error(error);
            };
        });
    }
}
