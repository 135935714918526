import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ParseErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((event: HttpErrorResponse) => {
                if (request.responseType !== 'text') return throwError(event);

                const parsedError = JSON.parse(event.error);
                const response: HttpErrorResponse = { ...event, error: parsedError };

                return throwError(response);
            }),
        );
    }
}
