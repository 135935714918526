import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestService } from '@shared/services/request/request.service';
import { DatasetRequestParams } from '@shared/services/request/request.types';
import { ReportConfig } from '@shared/interfaces/ReportConfig';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { FiltersValue } from '@shared/services/filters/filters.types';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { BUSINESS_ENTITY, PUBLISHER_TYPE, WIDGET } from '../enums';
import { EntityItem } from '../models';

export interface GetSpecialPublishersResponse {
    elements: SpecialPublisherItem[];
    impressions: {
        total: number;
    };
    count: {
        total: number;
    };
}

export interface SpecialPublisherItem {
    id: number;
    name: string;
    homePageUrl: string;
    favIconUrl: string;
    type: {
        code: string;
        title: string;
    };
    impressions: {
        total: number;
    };
}

@Injectable()
export class PublishersService {
    private getPublisherTypeData = {
        result: undefined,
        params: undefined,
    };

    constructor(private requestService: RequestService, @Inject(REPORT_CONFIG) protected reportConfig: ReportConfig) {}

    getSpecialPublishers(
        entities: EntityItem[],
        filters: FiltersValue,
        type?: PUBLISHER_TYPE,
    ): Observable<SpecialPublisherItem[]> {
        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(entities, {
            globalParentheses: true,
            extractGroupsIds: true,
            plain: true,
        });

        const customParams = EntityHelpers.serializeFiltersParams(filters);

        const datasetParams: DatasetRequestParams = {
            datasetName: 'advertiser-publishers',
            entitiesIdsPairs,
            customParams,
            useViewToken: true,
            labelEntityType: BUSINESS_ENTITY.Advertiser,
            labelArea: WIDGET.TOP_ADS,
        };

        if (type) {
            datasetParams.customParams.type = {
                operation: 'in',
                items: [type],
            };
        }

        const cachedParams = JSON.stringify(datasetParams);

        if (this.getPublisherTypeData.result && cachedParams === this.getPublisherTypeData.params) {
            return of(JSON.parse(this.getPublisherTypeData.result));
        }

        return this.requestService
            .dataset<GetSpecialPublishersResponse>(datasetParams)
            .pipe(map((res) => res?.elements));
    }
}
