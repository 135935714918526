import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { AuthService } from '@shared/services';

@Injectable({ providedIn: 'root' })
export class JwtLoginGuard {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const jwtToken: string = route.queryParams.jwt;

        if (route.outlet !== 'primary' || !jwtToken) {
            return of(true);
        }

        return this.authService.loginByAnyToken(jwtToken).pipe(
            mapTo(false),
            catchError(() => of(this.router.createUrlTree(['401']))),
        );
    }
}
