import { Component, OnInit, inject } from '@angular/core';

import { startPagePath } from '@app/config';
import { IntegrationsService } from '@app/integrations/services/integrations.service';
import { WinmoService } from '../../services/winmo.service';

@Component({
    selector: 'winmo-not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss'],
})
export class WinmoNotFoundPageComponent implements OnInit {
    private readonly integrationsService = inject(IntegrationsService);

    message: Record<string, string>;

    ngOnInit() {
        this.message = {
            headerImage: 'assets/images/winmo/not-found.svg',
            title: 'Search results unavailable',
            subscript:
                'There may be an issue with data synchronization between platforms. For additional information and insight, we recommend searching in the app.',
        };
    }

    onToAppClick() {
        this.integrationsService.getService<WinmoService>().openApp(startPagePath);
    }
}
