<div class="container pt-24">
    <div *ngIf="dataUnavailable$ | async" class="unavailable-box">
        <ng-container *ngIf="noChannelAvailable$ | async">
            <ng-container *ngIf="isIntegration">
                <ng-container *ngIf="restrictedChannels$ | async as restrictedChannels">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="s-18 mr-4 icon-denied">clear</mat-icon>
                        <span>
                            Interested in <b>{{ entityTitle }}</b
                            >? The data is available in
                            <b
                                >{{ restrictedChannels.join(', ') }} channel{{
                                    restrictedChannels.length > 1 ? 's' : ''
                                }}</b
                            >.
                        </span>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="s-18 mr-4 icon-ok">done</mat-icon>
                        <span>
                            <a (click)="onBtnUpgradeClick()">Upgrade now</a>
                            to get instant access to {{ restrictedChannels.join(' & ') }} channel{{
                                restrictedChannels.length > 1 ? 's' : ''
                            }}
                            !
                        </span>
                    </div>

                    <div>or</div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!isIntegration">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-18 mr-4 icon-denied">clear</mat-icon>
                    <span
                        >Interested in <b>{{ entityTitle }}</b
                        >? The data is not available in your plan.</span
                    >
                </div>

                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-18 mr-4 icon-ok">done</mat-icon>
                    <span>Contact your account manager to upgrade it.</span>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="noPeriodAvailable$ | async">
            <ng-container *ngIf="isIntegration">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-18 mr-4 icon-denied">clear</mat-icon>
                    <span
                        >Interested in <b>{{ entityTitle }}</b
                        >? The data is not available in your plan.</span
                    >
                </div>
            </ng-container>

            <ng-container *ngIf="!isIntegration">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-18 mr-4 icon-denied">clear</mat-icon>
                    <span>The data is not available for the period.</span>
                </div>
            </ng-container>
        </ng-container>

        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-18 mr-4">arrow_back</mat-icon>
            <span>
                Go back to
                <a (click)="onBtnHomeClick()">home page</a>
            </span>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between start">
        <div class="widget mr-16" fxFlex="100" fxFlex.gt-sm="33">
            <!--  Widget 1 -->
            <img src="/assets/images/widget_1.jpg" width="100%" />
        </div>
        <div class="widget mx-16" fxFlex="100" fxFlex.gt-sm="33">
            <!--  Widget 2 -->
            <img src="/assets/images/widget_2.jpg" width="100%" />
        </div>
        <div class="widget ml-16" fxFlex="100" fxFlex.gt-sm="33">
            <!--  Widget 3 -->
            <img src="/assets/images/widget_3.jpg" width="100%" />
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start">
        <!--  Widget 4 -->
        <div class="widget mt-32" fxFlex="100">
            <img src="/assets/images/widget_4.jpg" width="100%" />
        </div>
        <!-- / Widget 4 -->
    </div>
    <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start">
        <!--  Widget 5 -->
        <div class="widget mt-32" fxFlex="100">
            <img src="/assets/images/widget_5.jpg" width="100%" />
        </div>
        <!-- / Widget 5 -->
    </div>
</div>
