import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Channel } from '@main/models';
import { IconsHelpers } from '@main/helpers/icons.helpers';

@Component({
    selector: 'channel-icon',
    templateUrl: './channel-icon.component.html',
    styleUrls: ['./channel-icon.component.scss'],
})
export class ChannelIconComponent implements OnInit, OnChanges {
    @Input() channel: Channel;
    @Input() customIcon: string;
    @Input() size = 16;

    icon: string;
    tooltipText: string;

    ngOnInit(): void {
        this.update();
    }

    ngOnChanges(): void {
        this.update();
    }

    private update(): void {
        if (this.channel) {
            this.icon = this.customIcon || IconsHelpers.getChannelIconNameByType(this.channel.type);
            this.tooltipText = this.channel.title;
        }
    }
}
