import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SharedTopNotificationBarService } from './top-notification-bar.service';
import { Notification } from './top-notification-bar.types';

@UntilDestroy()
@Component({
    selector: 'top-notification-bar',
    templateUrl: './top-notification-bar.component.html',
    styleUrls: ['./top-notification-bar.component.scss'],
})
export class TopNotificationBarComponent {
    hasNotifications: boolean;
    items: Notification[] = [];

    constructor(private service: SharedTopNotificationBarService) {
        this.items = this.service.items.slice();
        this.hasNotifications = this.service.hasNotifications();

        this.service.onAdd.pipe(untilDestroyed(this)).subscribe((item) => {
            this.onAdd(item);
        });

        this.service.onRemove.pipe(untilDestroyed(this)).subscribe((item) => {
            this.onRemove(item);
        });

        this.service.onClear.pipe(untilDestroyed(this)).subscribe(() => {
            this.hasNotifications = false;
            this.items = [];
        });
    }

    onClose(item: Notification): void {
        this.service.remove(item.id, true);
    }

    private onAdd(item: Notification): void {
        this.items.unshift(item);

        setTimeout(() => {
            this.hasNotifications = true;
        });

        item.state.hidden = true;

        setTimeout(() => {
            item.state.hidden = false;
        }, 200);
    }

    private onRemove(item: Notification): void {
        item.state.hidden = true;

        setTimeout(() => {
            this.items.splice(this.items.indexOf(item), 1);
            this.hasNotifications = !!this.items.length;
        }, 200);
    }
}
