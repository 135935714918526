export enum SETTINGS {
    ROOT_CHANNEL = 'CHANNEL_ID',
    ROOT_DEVICE = 'DEVICE_ID',
    ROOT_PERIOD = 'PERIOD_ID',
    ROOT_COUNTRY = 'COUNTRY_ID',
    ROOT_CURRENCY = 'CURRENCY',
    ROOT_TAXONOMY_ID = 'TAXONOMY_ID',

    BRAND_PERIOD = 'BRAND_PERIOD',
    BRAND_COUNTRY = 'BRAND_COUNTRY',
    BRAND_RECENT_ID = 'BRAND_RECENT_ID',

    BRAND_WIDGET_MEDIAGALLERY_SORTBY = 'BRAND_WIDGET_MEDIAGALLERY_SORTBY',

    WIDGET_TOP_ADS_SORTBY = 'WIDGET_TOPADS_SORTBY',
    WIDGET_TOP_ADVPUB_TYPEFILTER = 'WIDGET_TOP_ADVPUB_TYPEFILTER',
    WIDGET_EXPENDITURE_BREAKDOWN_TREND_VIEW = 'WIDGET_EXPENDITURE_BREAKDOWN_TREND_VIEW',
    WIDGET_EXPENDITURE_BREAKDOWN_TREND_INTERVAL = 'WIDGET_EXPENDITURE_BREAKDOWN_TREND_INTERVAL',
    WIDGET_METRIC = 'WIDGET_METRIC',

    EXCEEDED_EXPORT_CLICKS = 'EXCEEDED_EXPORT_CLICKS',
}
