export enum UXElementType {
    Button = 'Button',
    Link = 'Link',
    Image = 'Image',
    List = 'List',
    TextControl = 'TextControl',
    Checkbox = 'Checkbox',
    Radio = 'Radio',
    Element = 'Element', // Generic element
}

export enum UXActionType {
    Click = 'Click',
    Keypress = 'Keypress',
    Hover = 'Hover',
    Check = 'Check',
}

export type UXArea = 'top-toolbar' | 'popup' | 'widget' | 'widget-popup';

export type UXBaseActionPayload = {
    actionType: UXActionType;
    elementType: UXElementType;
    elementName?: string;
    areaType?: UXArea;
    areaName?: string;
};
// Can be extended or removed in the future
export type UXButtonActionPayload = UXBaseActionPayload;
export type UXLinkActionPayload = UXBaseActionPayload;
export type UXImageActionPayload = UXBaseActionPayload;
export type UXListActionPayload = UXBaseActionPayload;
export type UXTextControlActionPayload = UXBaseActionPayload;

export type UXRadioActionPayload = UXBaseActionPayload &
    Readonly<{
        checked: boolean;
    }>;

export type UXCheckboxActionPayload = UXBaseActionPayload &
    Readonly<{
        checked: boolean;
    }>;

export type UXElementWithTextActionPayload = UXButtonActionPayload | UXLinkActionPayload | UXListActionPayload;

export type UXActionPayload =
    | UXButtonActionPayload
    | UXLinkActionPayload
    | UXImageActionPayload
    | UXListActionPayload
    | UXRadioActionPayload
    | UXCheckboxActionPayload
    | UXTextControlActionPayload;

export type AnalyticsWidgetComponent = Readonly<{ analyticsWidgetName: string }>;
