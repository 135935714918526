/* 'name' field is used for matching with the BE special publishers list */

import { SOCIAL_PUBLISHERS } from '@main/enums/social_publishers';

export const socialPublishersDefinition = [
    {
        name: SOCIAL_PUBLISHERS.FACEBOOK,
        tooltipTitle: 'Facebook',
        icon: 'publisherFilterFacebook',
    },
    {
        name: SOCIAL_PUBLISHERS.TWITTER,
        tooltipTitle: 'Twitter',
        icon: 'publisherFilterTwitter',
    },
    {
        name: SOCIAL_PUBLISHERS.INSTAGRAM,
        tooltipTitle: 'Instagram',
        icon: 'publisherFilterInstagram',
    },
    {
        name: SOCIAL_PUBLISHERS.TIKTOK,
        tooltipTitle: 'Tiktok',
        icon: 'publisherFilterTiktok',
    },
    {
        name: SOCIAL_PUBLISHERS.PINTEREST,
        tooltipTitle: 'Pinterest',
        icon: 'publisherFilterPinterest',
    },
    {
        name: SOCIAL_PUBLISHERS.LINKEDIN,
        tooltipTitle: 'LinkedIn',
        icon: 'publisherFilterLinkedin',
    },
];
