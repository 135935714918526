import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { BUSINESS_ENTITY } from '../enums';
import { IEntityItemGroupId, IEntityItemId } from '../types';
import { IGroupDto, IGroupType } from '@main/interfaces';
import { ResponseGetGroup } from './groups.service.types';
import { EntityGroup } from '../models';
import { SerializedFiltersParams } from '@main/helpers/entity.helpers.types';
import { IAccessLogUnsavedGroup } from '@main/services/recents.service.types';
import { GroupsApiService } from '@main/services/groups-api.service';
import { EntityCommonService } from '@main/services/entity-common.service';
import { GroupsHelper } from '@main/services/groups.helper';

@Injectable()
export class GroupsService {
    private groupsCache: EntityGroup[] = [];

    constructor(private groupsApiService: GroupsApiService, private entityCommonService: EntityCommonService) {}

    getGroup(id: IEntityItemId): Observable<EntityGroup> {
        const cachedItem = this.groupsCache.find((v) => v.id === id);

        if (cachedItem) {
            return of(cachedItem);
        }

        return this.groupsApiService.fetchGroup<IGroupDto>(id).pipe(
            map((response) => {
                const groupEntity = GroupsHelper.deserializeGroup(response);
                this.cache(groupEntity);

                return groupEntity;
            }),
        );
    }

    getGroupsList(
        entityType: BUSINESS_ENTITY | BUSINESS_ENTITY[],
        opts: Partial<{ search: string; limit: number; type: IGroupType; strict: boolean }> = {},
    ): Observable<EntityGroup[]> {
        const params: Record<string, any> = {
            size: opts.limit || 100,
        };

        if (entityType) {
            params.entityTypes = Array.isArray(entityType) ? entityType.join(',') : entityType;
        }

        if (opts.search) {
            params.q = opts.search;
        }

        if (opts.type) {
            params.types = opts.type;
        }

        if (opts.strict) {
            params.strict = true;
        }

        return this.groupsApiService.fetchGroupsList<ResponseGetGroup>(params).pipe(
            switchMap((response) => {
                return GroupsHelper.resolveUnsavedGroups(
                    response,
                    Array.isArray(entityType) ? entityType[0] : entityType,
                    this.entityCommonService.resolveSingleEntitiesById.bind(this.entityCommonService),
                );
            }),
            map((response) => {
                const items = response?.elements || [];

                const result = items.map((item) => {
                    const rootEntity = GroupsHelper.deserializeGroup(item);
                    this.cache(rootEntity);

                    return rootEntity;
                });

                result[Symbol.for('total')] = response?.total || 0;

                return result;
            }),
        );
    }

    createGroup(
        entityType: BUSINESS_ENTITY,
        name: string,
        ids: IEntityItemId[],
        unsavedGroups: IAccessLogUnsavedGroup[],
        categoryBenchmarkId: IEntityItemId,
        comparison = false,
        params: SerializedFiltersParams,
    ): Observable<IGroupDto> {
        const data = GroupsHelper.serializeGroupUpdateRequest(
            entityType,
            null,
            name,
            ids,
            unsavedGroups,
            categoryBenchmarkId,
            comparison,
            params,
        );

        return this.groupsApiService.createGroup(data);
    }

    updateGroup(
        entityType: BUSINESS_ENTITY,
        id: IEntityItemGroupId,
        name: string,
        ids: IEntityItemId[],
        unsavedGroups: IAccessLogUnsavedGroup[],
        comparison: boolean,
        categoryBenchmarkId: IEntityItemId,
        params: SerializedFiltersParams,
    ): Observable<IGroupDto> {
        const data: IGroupDto = GroupsHelper.serializeGroupUpdateRequest(
            entityType,
            id,
            name,
            ids,
            unsavedGroups,
            categoryBenchmarkId,
            comparison,
            params,
        );

        return this.groupsApiService.updateGroup(data);
    }

    deleteGroup(id: IEntityItemId): Observable<void> {
        return this.groupsApiService.deleteGroup(id).pipe(
            tap(() => {
                this.groupsCache = this.groupsCache.filter((group) => group.id !== id);
            }),
        );
    }

    resetCache(): void {
        this.groupsCache = [];
    }

    private cache(item: EntityGroup): void {
        if (!this.groupsCache.find((v) => v.id === item.id)) {
            this.groupsCache.push(item);
        }
    }
}
