import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { DownloadParams, IDownloadItem, IDownloadsStatus, RequestService } from '@shared/services';

@Injectable()
export class DownloadApiService {
    constructor(private requestService: RequestService) {}

    fetchDownloads(): Observable<IDownloadsStatus> {
        return this.requestService.get<IDownloadsStatus>('downloads');
    }

    fetchDownload(id: string): Observable<IDownloadItem> {
        return this.requestService.get<IDownloadItem>(`downloads/${id}`);
    }

    download(params: DownloadParams): Observable<IDownloadItem> {
        return this.requestService.post<IDownloadItem>('downloads/', params);
    }
}
