<div *ngIf="chartOptions" class="row">
    <highcharts-chart
        [Highcharts]="Highcharts"
        [callbackFunction]="onCreateFn"
        [(update)]="updateFlag"
        constructorType="chart"
        [options]="chartOptions"
    ></highcharts-chart>

    <adc-chart-legend *ngIf="options.customLegend" [adcChart]="adcChart" class="custom-legend"></adc-chart-legend>
</div>
