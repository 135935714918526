import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureToggleService } from '@main/shared/feature-toggle';

import { Feature } from '../feature-toggle.model';

@Directive({
    selector: '[displayForFeature]',
})
export class DisplayForFeatureDirective implements OnInit {
    @Input('displayForFeature') feature!: Feature;

    constructor(
        private template: TemplateRef<void>,
        private viewContainer: ViewContainerRef,
        private featureToggleService: FeatureToggleService,
    ) {}

    ngOnInit() {
        if (this.featureToggleService.isEnabled(this.feature)) {
            this.viewContainer.createEmbeddedView(this.template);
        }
    }
}
