import { Injectable } from '@angular/core';

import { AnalyticsToolService } from '../../models';

@Injectable()
export class AmplitudeService implements AnalyticsToolService {
    disabled: boolean;

    track(_action: string, _payload: object) {}
}
