import { Injectable } from '@angular/core';

import { StorageService } from '@app/core';

import { SETTINGS } from './persisted-settings.service.types';

@Injectable({ providedIn: 'root' })
export class PersistedSettingsService {
    constructor(private storageService: StorageService) {}

    get<T = unknown>(entity: SETTINGS): T {
        return this.storageService.get<T>(entity);
    }

    set(entity: SETTINGS, value: unknown): void {
        this.storageService.set(entity, value);
    }
}
