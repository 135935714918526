import { BUSINESS_ENTITY } from '@main/enums';
import { IEntityInfo } from '@main/interfaces';

export const entityInfoConfig: Record<BUSINESS_ENTITY, IEntityInfo> = {
    [BUSINESS_ENTITY.Advertiser]: {
        id: BUSINESS_ENTITY.Advertiser,
        nameKey: 'domain',
        dataset: 'advertisers',
        recentDataset: 'recent-advertisers',
        singleKey: 'advertiser',
        comparable: true,
        groupingMaxCount: 10,
        action: {
            getOne: 'getAdvertiser',
            getList: 'getAdvertiserList',
        },
        meta: {
            list: 'advertiserList',
        },
        text: {
            article: 'an',
            one: 'advertiser',
            few: 'advertisers',
            oneUpper: 'Advertiser',
            fewUpper: 'Advertisers',
        },
        url: {
            common: 'advertiser',
            compare: 'advertiser-compare',
            empty: 'advertiser-empty',
        },
        icon: '/assets/icons/redesign/dark/advertisers.svg',
    },
    [BUSINESS_ENTITY.Publisher]: {
        id: BUSINESS_ENTITY.Publisher,
        nameKey: 'name',
        dataset: 'publishers',
        recentDataset: 'recent-publishers',
        singleKey: 'publisher',
        comparable: true,
        groupingMaxCount: 10,
        action: {
            getOne: 'getPublisher',
            getList: 'getPublisherList',
        },
        meta: {
            list: 'publisherList',
        },
        text: {
            article: 'a',
            one: 'publisher',
            few: 'publishers',
            oneUpper: 'Publisher',
            fewUpper: 'Publishers',
        },
        url: {
            common: 'publisher',
            compare: 'publisher-compare',
            empty: 'publisher-empty',
        },
        icon: '/assets/icons/redesign/dark/publishers.svg',
    },
    [BUSINESS_ENTITY.Campaign]: {
        id: BUSINESS_ENTITY.Campaign,
        dataset: 'campaigns',
        recentDataset: 'recent-campaigns',
        singleKey: 'campaign',
        text: {
            article: 'a',
            one: 'campaign',
            few: 'campaigns',
            oneUpper: 'Campaign',
            fewUpper: 'Campaigns',
        },
        url: {
            common: 'campaign',
            empty: 'campaign-empty',
        },
        icon: '/assets/icons/redesign/dark/campaigns.svg',
    },
    [BUSINESS_ENTITY.Keyword]: {
        id: BUSINESS_ENTITY.Keyword,
        singleKey: 'campaign',
        groupingMaxCount: 10,
        text: {
            article: 'a',
            one: 'keyword',
            few: 'keywords',
            oneUpper: 'Keyword',
            fewUpper: 'Keywords',
        },
        url: {
            common: 'keywords',
            empty: 'keyword-empty',
        },
        icon: '/assets/icons/redesign/dark/keywords.svg',
    },
    [BUSINESS_ENTITY.Category]: {
        id: BUSINESS_ENTITY.Category,
        nameKey: 'domain',
        dataset: 'categories',
        recentDataset: 'recent-categories',
        singleKey: 'category',
        comparable: true,
        groupingMaxCount: 4,
        action: {
            getOne: 'getCategory',
            getList: 'getCategoryList',
        },
        meta: {
            list: 'categoryList',
        },
        text: {
            article: 'a',
            one: 'category',
            few: 'categories',
            oneUpper: 'Category',
            fewUpper: 'Categories',
        },
        url: {
            common: 'category',
            compare: 'category-compare',
            empty: 'category-empty',
        },
        beta: true,
        icon: '/assets/icons/redesign/dark/categories.svg',
    },
    [BUSINESS_ENTITY.Brand]: {
        id: BUSINESS_ENTITY.Brand,
        nameKey: 'domain',
        dataset: 'brands',
        recentDataset: 'recent-brands',
        singleKey: 'brand',
        comparable: true,
        groupingMaxCount: 10,
        meta: {
            list: 'brandsList',
        },
        text: {
            article: 'a',
            one: 'brand',
            few: 'brands',
            oneUpper: 'Brand',
            fewUpper: 'Brands',
        },
        url: {
            common: 'brand',
            compare: 'brand-compare',
            empty: 'brand-empty',
        },
        beta: true,
        icon: '/assets/icons/redesign/dark/brands.svg',
    },
    [BUSINESS_ENTITY.Benchmark]: null,
};
