import { Component, inject, Input } from '@angular/core';
import { WIDGET } from '@app/app-main/enums';
import { FullscreenViewService } from '@app/app-main/shared/widget-full-screen-view';
import { WIDGET_STATUS } from '@shared/widgets/core/widgets.types';

@Component({
    selector: 'widget-status',
    templateUrl: './widget-status.component.html',
    styleUrls: ['./widget-status.component.scss'],
})
export class WidgetStatusComponent {
    private readonly fullscreenViewService = inject(FullscreenViewService);

    @Input() title: string;
    @Input() hasData: boolean;
    @Input() status: WIDGET_STATUS;
    @Input() type: WIDGET;

    get isLoading(): boolean {
        return this.status === WIDGET_STATUS.LOADING;
    }

    get isLoadingSuccess(): boolean {
        return this.status === WIDGET_STATUS.SUCCESS;
    }

    get isLoadingError(): boolean {
        return this.status === WIDGET_STATUS.ERROR;
    }

    get isFullscreenView(): boolean {
        return this.fullscreenViewService.isFullscreenView;
    }
}
