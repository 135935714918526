import { isNil } from 'lodash';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';

import { AppConfig } from '@app/app.config';
import { AuthService } from '@shared/services';

const nonAuthPaths = [
    '^/api/rest/v2/security/tokens/(identity|authentication|access|verification)',
    '^/favicons/category/',
];

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    private isRefreshingToken = false;
    private tokenSubject = new BehaviorSubject<string>(null);

    private nonAuthPathsRegexp = new RegExp(nonAuthPaths.join('|'));

    constructor(private authService: AuthService, private config: AppConfig) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const accessToken = this.authService.accessToken;

        if (!isNil(accessToken) && this.needAddAuthToken(request.url)) {
            request = this.addToken(request, accessToken);
        }

        return next.handle(request).pipe(
            catchError((error: Error, _caught: Observable<HttpEvent<any>>) => {
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 401:
                            if (this.authService.authToken) {
                                return this.handle401Error(request, next);
                            }
                            break;

                        case 403:
                            return this.handle403Error(error);

                        default:
                            return this.handleGenericError(error);
                    }
                }

                return throwError(error);
            }),
        );
    }

    private handleGenericError(error: HttpErrorResponse): Observable<any> {
        return throwError(error);
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.refreshAccessToken().pipe(
                catchError((err) => this.handleInvalidSession(err)),
                finalize(() => {
                    this.isRefreshingToken = false;
                }),
                switchMap((newToken: string) => {
                    this.tokenSubject.next(newToken);

                    return next.handle(this.addToken(request, newToken));
                }),
            );
        } else {
            return this.tokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => next.handle(this.addToken(request, token))),
            );
        }
    }

    private handle403Error(error: HttpErrorResponse): Observable<any> {
        return this.handleGenericError(error);

        //      return this.logoutUser(error);
    }

    private needAddAuthToken(url: string): boolean {
        try {
            return !this.nonAuthPathsRegexp.test(new URL(url).pathname);
        } catch (_error) {
            return false;
        }
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    private logoutUser(error: Error): Observable<any> {
        this.authService.logout(false);
        return throwError(error);
    }

    private handleInvalidSession(error: Error): Observable<any> {
        this.showSessionExpiredModal();

        return throwError(error);
    }

    private showSessionExpiredModal(): void {
        const canRedirectToLogin = !this.config.appInfo.integration;
        const message = canRedirectToLogin ? 'Please relogin' : 'Please reload the page';

        Swal.fire({
            html: `Current session is expired. ${message}`,
            confirmButtonText: 'Ok',
            showConfirmButton: canRedirectToLogin,
            customClass: {
                container: 'dialog-invalid-session-handler-component',
            },
        }).then(() => {
            this.authService.logout(false);
        });
    }
}
