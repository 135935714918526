import { Directive, Input } from '@angular/core';

import { UXImageActionPayload, UXElementType } from '../../models';
import { ANALYTICS_ELEMENT } from '../../data';

import { AnalyticsElementBaseDirective } from './analytics-element.base';

@Directive({
    selector: '[analyticsImage]',
    providers: [
        {
            provide: ANALYTICS_ELEMENT,
            useExisting: AnalyticsImageDirective,
        },
    ],
})
export class AnalyticsImageDirective extends AnalyticsElementBaseDirective<UXImageActionPayload, HTMLImageElement> {
    elementType = UXElementType.Image;

    @Input() analyticsImageName = this.getDefaultName();

    protected getSpecificPayload(): Partial<UXImageActionPayload> {
        return { elementName: this.analyticsImageName };
    }

    private getDefaultName(): string {
        return this.host.alt;
    }
}
