import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'dialog-adblock-enabled-popup',
    templateUrl: './dialog-adblock-enabled-popup.component.html',
    styleUrls: ['./dialog-adblock-enabled-popup.component.scss'],
})
export class DialogAbBlockEnabledPopupComponent {
    constructor(public matDialogRef: MatDialogRef<DialogAbBlockEnabledPopupComponent>) {}
}
