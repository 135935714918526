<top-notification-bar class="export-hide"></top-notification-bar>
<div class="page-container">
    <div class="message-container" fxLayout="column" fxLayoutAlign="start center">
        <div class="limit-message" fxLayout="column" fxLayoutAlign="start center">
            <div class="header-illustration">
                <img [src]="message.headerImage" />
            </div>
            <div class="title">
                {{ message.title }}
            </div>

            <div class="subscript">
                {{ message.subscript }}
            </div>

            <div class="actions-container"></div>
        </div>
    </div>
</div>
