import { BadRequestPageComponent } from './bad-request/bad-request-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { ServerErrorPageComponent } from './server-error-page/server-error-page.component';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';

export * from './error-page/error-page.component';
export * from './not-found-page/not-found-page.component';
export * from './server-error-page/server-error-page.component';
export * from './unauthorized-page/unauthorized-page.component';
export * from './bad-request/bad-request-page.component';

export const errorPages = [
    ErrorPageComponent,
    NotFoundPageComponent,
    ServerErrorPageComponent,
    UnauthorizedPageComponent,
    BadRequestPageComponent,
];
