import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestService } from '@shared/services/request/request.service';
import { IPeriod, ICountry, ITaxonomyTopic } from '@shared/interfaces';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { IChannel, IElementsResponse } from '@main/interfaces';
import { Currency } from '@app/shared/models';
import { FiltersValue } from '@app/shared/services/filters/filters.types';

@Injectable()
export class ReportApiService {
    constructor(private requestService: RequestService) {}

    getChannels(entitiesIdsPairs?: Record<string, string>): Observable<IChannel[]> {
        return this.requestService
            .dataset<IElementsResponse<IChannel>>({
                datasetName: 'channels',
                entitiesIdsPairs,
                labelArea: 'base_service',
            })
            .pipe(map((result) => result.elements));
    }

    getPeriods(entitiesIdsPairs?: Record<string, string>): Observable<IPeriod[]> {
        return this.requestService
            .dataset<IElementsResponse<IPeriod>>({
                datasetName: 'periods',
                entitiesIdsPairs,
                labelArea: 'base_service',
            })
            .pipe(map((result) => result.elements));
    }

    getCountries(entitiesIdsPairs?: Record<string, string>): Observable<ICountry[]> {
        return this.requestService
            .dataset<IElementsResponse<ICountry>>({
                datasetName: 'countries',
                entitiesIdsPairs,
                labelArea: 'base_service',
            })
            .pipe(map((result) => result.elements.filter((v) => Boolean(v.id))));
    }

    getTaxonomyTopics(
        entitiesIdsPairs: Record<string, string>,
        filters: FiltersValue,
        currency: Currency,
    ): Observable<ITaxonomyTopic[]> {
        return this.requestService
            .dataset<{ taxonomies: IElementsResponse<ITaxonomyTopic> }>({
                datasetName: 'taxonomies',
                entitiesIdsPairs,
                customParams: EntityHelpers.serializeFiltersParams(filters, currency),
                labelArea: 'base_service',
            })
            .pipe(map((result) => result.taxonomies.elements));
    }
}
