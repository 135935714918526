import { Injectable, inject } from '@angular/core';
import { AppConfig } from '@app/app.config';

import { SemrushService } from '../semrush/services/semrush.service';
import { IntegrationServiceBase } from './integration.service.base';
import { WinmoService } from '../winmo/services/winmo.service';
import { INTEGRATION } from '../integrations.types';

@Injectable({ providedIn: 'root' })
export class IntegrationsService {
    private service: IntegrationServiceBase;

    constructor(private config: AppConfig) {
        if (this.isIntegration(INTEGRATION.SEMRUSH)) {
            this.service = inject(SemrushService);
        }

        if (this.isIntegration(INTEGRATION.WINMO)) {
            this.service = inject(WinmoService);
        }

        if (this.service) {
            this.service.loadSdk();
        }
    }

    getService<T = IntegrationServiceBase>(): T {
        return <T>this.service;
    }

    isIntegration(id?: INTEGRATION): boolean {
        /*
        if (window.self === window.top) {
            return false;
        }
*/
        return id ? this.config.appInfo.integrationId === id : this.config.appInfo.integration;
    }
}
