import { merge, of, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { PersistedSettingsService, SETTINGS } from '@shared/services/persisted-settings';
import { WidgetMetric } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class WidgetMetricsService {
    private initialMetric$ = of(this.getInitialMetric());
    private saveMetric$ = new Subject<WidgetMetric>();

    metric$ = merge(this.initialMetric$, this.saveMetric$).pipe(shareReplay(1));

    constructor(private persistedSettingsService: PersistedSettingsService) {
        this.saveMetric$.subscribe((metric) => this.persistMetric(metric));
    }

    saveMetric(metric: WidgetMetric): void {
        this.saveMetric$.next(metric);
    }

    private getPersistedMetric(): WidgetMetric {
        return this.persistedSettingsService.get<WidgetMetric>(SETTINGS.WIDGET_METRIC);
    }

    private persistMetric(metric: WidgetMetric): void {
        this.persistedSettingsService.set(SETTINGS.WIDGET_METRIC, metric);
    }

    getInitialMetric(): WidgetMetric {
        const persistedMetric = this.getPersistedMetric();

        return persistedMetric || WidgetMetric.Both;
    }
}
