import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { FeatureToggleService } from '@main/shared/feature-toggle';

@Injectable()
export class FeaturesConfig {
    readonly featurePath = 'assets/config/features.json';
    private httpClient: HttpClient;
    constructor(handler: HttpBackend, private featureToggleService: FeatureToggleService) {
        this.httpClient = new HttpClient(handler);
    }
    resolve(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.httpClient.get<Record<string, Record<string, boolean>>>(this.featurePath).subscribe(
                (data) => {
                    this.featureToggleService.setExternalFeatureFlags(data?.features);
                    resolve();
                },
                (error) => {
                    resolve(error);
                },
            );
        });
    }
}
