import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DatasetRequestParams, RequestService } from '@shared/services';
import { RequestParams } from '@shared/models/request-params.model';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import {
    CampaignIdsPayload,
    GetCampaignsByKeywordsResponse,
} from '@main/reports/keywords/services/keywords.service.types';
import { BUSINESS_ENTITY } from '@main/enums';

@Injectable()
export class KeywordsApiService {
    constructor(private requestService: RequestService) {}

    fetchCampaignsIds({ filters, entities, pageNumber, pageSize }: CampaignIdsPayload): Observable<number[]> {
        const keywords = entities.map((v) => v.id).join(' ');

        const requestParams = new RequestParams();

        requestParams.setPage(pageNumber, pageSize);

        const datasetParams: DatasetRequestParams = {
            datasetName: 'campaigns-by-keywords',
            useViewToken: true,
            customParams: {
                ...EntityHelpers.serializeFiltersParams(filters),
                q: keywords,
            },
            requestParams,
            labelEntityType: BUSINESS_ENTITY.Keyword,
            labelArea: 'widget_keywords_service',
        };

        return this.requestService.dataset<GetCampaignsByKeywordsResponse>(datasetParams).pipe(
            map(({ elements: campaigns, count }) => {
                const result = campaigns.map(({ id }) => id);
                result[Symbol.for('total')] = count?.total || 0;
                return result;
            }),
        );
    }
}
