import { Directive, Input } from '@angular/core';

import { UXArea } from '../../models';
import { ANALYTICS_AREA } from '../../data';

import { AnalyticsAreaBase } from './analytics-area.base';

@Directive({
    selector: '[analyticsArea]',
    providers: [
        {
            provide: ANALYTICS_AREA,
            useExisting: AnalyticsAreaDirective,
        },
    ],
})
export class AnalyticsAreaDirective extends AnalyticsAreaBase {
    @Input('analyticsArea') type: UXArea;
    @Input() analyticsName: string;
}
