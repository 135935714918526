import { Observable } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { REPORT_CONFIG } from '@shared/const/report-config';
import { PeriodsService } from '@shared/services/periods/periods.service';
import { FiltersService } from '@shared/services/filters/filters.service';
import { MainExportService } from '@main/services/export.service';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { ReportService } from '@main/core/report.service';
import { GlobalExportExcelService } from '@main/services/global-export-excel.service';

@Injectable()
export class ReportGlobalExportExcelService extends GlobalExportExcelService {
    private readonly exportService = inject(MainExportService);
    private readonly periodsService = inject(PeriodsService);
    private readonly filtersService = inject(FiltersService);
    private readonly reportService = inject(ReportService);
    private readonly reportConfig = inject(REPORT_CONFIG);

    export(): Observable<string> {
        const { comparison, entityType } = this.reportConfig;
        const { entities } = this.reportService;

        const fileNameTpl = this.reportService.getGlobalExportFileNameTpl();

        const downloadType = this.reportConfig.globalExportDownloadType;

        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(entities, {
            extractGroupsIds: true,
            globalParentheses: !comparison,
        });

        const reportLink = this.getReportLink();

        const criteria: Record<string, unknown> = { ...entitiesIdsPairs, reportLink };

        return this.periodsService.periodInterval$.pipe(
            switchMap(({ code }) => {
                criteria.interval = code;

                return this.exportService.export({
                    downloadType,
                    setReportType: true,
                    entityType,
                    fileNameTpl,
                    filters: this.filtersService.value(),
                    criteria,
                });
            }),
        );
    }
}
