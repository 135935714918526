<ng-container *hasPermission="permissions$ | async; then exportButton; else noExportButton"></ng-container>

<ng-template #exportButton>
    <ng-container *ngTemplateOutlet="rootButtonTpl; context: { disabled: disabled }"></ng-container>
</ng-template>

<ng-template #noExportButton>
    <div *ngIf="!isHiddenByRestriction" [matTooltip]="showDisabledExportButtonTooltip ? exportTooltipText : null">
        <ng-container *ngTemplateOutlet="rootButtonTpl; context: { disabled: isDisabledByRestriction }"></ng-container>
    </div>
</ng-template>

<mat-menu #exportMenu="matMenu" overlapTrigger="false" class="advanced-export-popup">
    <ng-container *ngIf="hasUnlimitedExportItems$ | async">
        <div class="header" (click)="$event.stopPropagation()" fxLayout="row" fxLayoutAlign="space-between center">
            UNLIMITED
        </div>

        <ng-container *ngFor="let item of unlimitExportItems$ | async">
            <div class="item-box" [matTooltip]="item._tooltip">
                <ng-container *ngTemplateOutlet="itemTpl; context: { $implicit: item }"></ng-container>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="hasLimitedExportItems$ | async">
        <div *ngFor="let item of limitedExportItems$ | async" class="item">
            <ng-container *ngIf="item.showHeader">
                <div class="header" (click)="$event.stopPropagation()" fxLayout="row" fxLayoutAlign="start center">
                    <div>LIMITED</div>

                    <div fxFlex></div>

                    <ng-container *ngIf="showLimitationLabel">
                        <div
                            *ngIf="item.limitsLabel"
                            class="header-limit-text"
                            [innerHTML]="item.limitsLabel | safeHtml"
                        ></div>
                        <div *ngIf="item.limitsTooltip" class="header-limit-text-tooltip">
                            <mat-icon class="s-16" svgIcon="info" [matTooltip]="item.limitsTooltip"></mat-icon>
                        </div>
                    </ng-container>
                </div>

                <div
                    *ngIf="item.noticeText"
                    class="label-limit-text"
                    fxLayout="row"
                    [innerHTML]="item.noticeText | safeHtml"
                ></div>
            </ng-container>

            <div class="item-box" [matTooltip]="item._tooltip">
                <ng-container *ngTemplateOutlet="itemTpl; context: { $implicit: item }"></ng-container>
            </div>
        </div>
    </ng-container>
</mat-menu>

<ng-template #itemTpl let-item>
    <button
        mat-menu-item
        [disabled]="item._disabled"
        [ngClass]="{ disabled: item._disabled, busy: item.busy, 'not-ready': !item.ready }"
        (click)="onExportClick(item, $event)"
    >
        <div fxLayout="row" fxLayoutAlign="start center">
            <div *ngIf="item.busy" class="column-icon-progress mr-16">
                <div class="loader-v2 theme-blue s-32"></div>
                <mat-icon [svgIcon]="item.option.icon" class="s-12"></mat-icon>
            </div>

            <div *ngIf="!item.busy" class="column-icon">
                <mat-icon [svgIcon]="item.option.icon" class="s-32"></mat-icon>
            </div>

            <div>
                <span class="text-title">{{ item.option.title }}</span>
            </div>
        </div>
    </button>
</ng-template>

<ng-template #rootButtonTpl let-disabled="disabled">
    <button
        #menuTrigger="matMenuTrigger"
        mat-button
        [color]="buttonColor"
        [matTooltip]="tooltip"
        [disabled]="disabled"
        [matMenuTriggerFor]="exportMenu"
        [class.active]="menuTrigger.menuOpen"
        [ngClass]="buttonClass"
        (click)="onRootButtonClick($event)"
        class="u-button h-32 export-button {{ 'type-' + buttonType }}"
        (keydown.esc)="menuTrigger.closeMenu()"
    >
        <ng-container *ngIf="isButtonTypeText">
            <mat-icon class="s-12 mr-8" svgIcon="export2"></mat-icon>
            <span>Export</span>
        </ng-container>

        <ng-container *ngIf="isButtonTypeIcon">
            <mat-icon class="s-12" svgIcon="export2"></mat-icon>
        </ng-container>
    </button>
</ng-template>
