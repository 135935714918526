export enum HttpStatus {
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
}

export enum ADC_ERROR_CODE_ENUM {
    AUTH_WRONG_CREDENTIALS = 'AUTH_WRONG_CREDENTIALS',
    AUTH_UNKNOWN_TOKEN_TYPE = 'AUTH_UNKNOWN_TOKEN_TYPE',
    AUTH_INVALID_TOKEN = 'AUTH_INVALID_TOKEN',
    AUTH_ERROR_TOKEN_FETCH = 'AUTH_ERROR_TOKEN_FETCH',
    AUTH_WRONG_ORIGIN = 'AUTH_WRONG_ORIGIN',
    AUTH_ACCESS_DENIED = 'AUTH_ACCESS_DENIED',
    GENERIC_ERROR = 'GENERIC_ERROR',

    CONTEXT_RESOLVE = 'CONTEXT_RESOLVE',
    CONTEXT_ENTITY_NOT_SPECIFIED = 'CONTEXT_ENTITY_NOT_SPECIFIED',
    CONTEXT_ENTITY_UNAVAILABLE = 'CONTEXT_ENTITY_UNAVAILABLE',
    CONTEXT_DATA_UNAVAILABLE = 'CONTEXT_DATA_UNAVAILABLE',
}
