import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    MarkRawEntitiesRequest,
    MarkRawStatusesResponse,
    MarkStatus,
    MarkStatuses,
} from '@main/components/mark-entity-button/services/mark.service.types';
import { HttpHeaders } from '@angular/common/http';

import { RequestService } from '@shared/services/request/request.service';
import { MARK_ENTITY_ID, MARK_ENTITY_TYPE } from '@main/components/mark-entity-button/mark.types';

@Injectable()
export class MarkService {
    constructor(private requestService: RequestService) {}

    mark(entityId: MARK_ENTITY_ID, entityType: MARK_ENTITY_TYPE): Observable<void> {
        const payload: MarkRawEntitiesRequest = [{ entityId, entityType }];
        return this.requestService.post(`db/skiplists`, payload, undefined, 'v1');
    }

    unMark(entityId: MARK_ENTITY_ID, entityType: MARK_ENTITY_TYPE): Observable<void> {
        const payload: MarkRawEntitiesRequest = [{ entityId, entityType }];
        return this.requestService.delete(
            `db/skiplists`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify(payload),
            },
            'v1',
        );
    }

    getMarkStatuses(entityType: MARK_ENTITY_TYPE, ids: MARK_ENTITY_ID[]): Observable<MarkStatuses> {
        const endpoint = `db/skiplists?type=${entityType}&ids=${ids.join(',')}`;
        const items: MarkStatus[] = ids.map((v) => ({ id: v, status: false, entityType }));

        return this.requestService.get(endpoint, undefined, 'v1').pipe(
            map((res: MarkRawStatusesResponse) => {
                res.forEach((status) => {
                    const item = items.find((v) => v.id === status.entityId);
                    if (item) {
                        item.status = true;
                    }
                });
                return { items };
            }),
        );
    }
}
