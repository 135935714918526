import { CommonHelpers } from '@shared/helpers/common.helpers';
import { EntityBase } from './EntityBase';
import { EntityAdvertiser } from './EntityAdvertiser';
import { ICampaignDto } from '../interfaces';
import { EntityHelpers } from '../helpers/entity.helpers';
import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '../enums';

export class EntityCampaign extends EntityBase<ICampaignDto> {
    advertiser: EntityAdvertiser;
    homePageUrl: string;
    firstSeen: string;
    lastSeen: string;
    duration: string;
    impressionsCount: number;

    constructor(dto?: ICampaignDto) {
        super({
            type: ENTITY_ITEM_TYPE.SINGLE,
            entityType: BUSINESS_ENTITY.Campaign,
            dto,
        });

        if (dto) {
            this.id = dto.id;
            this.icon = dto.creative?.imageUrl;

            this.advertiser =
                dto.advertiser &&
                EntityHelpers.mapSingleEntity<EntityAdvertiser>(BUSINESS_ENTITY.Advertiser, dto.advertiser);
            this.homePageUrl = dto.landingPageUrl;
            this.firstSeen = dto.detectionRange?.first;
            this.lastSeen = dto.detectionRange?.last;
            this.duration = dto.detectionRange?.duration;
            this.impressionsCount = dto.impressions?.total;

            this.setTitle(dto.title);
        }
    }

    copy(): EntityCampaign {
        return CommonHelpers.copyProps(this, new EntityCampaign());
    }
}
