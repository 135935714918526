import { AmplitudeService, MixpanelService } from '@app/modules/analytics';

export enum AnalyticsActionType {
    // "business"/high-level actions
    PAGE_VIEW = 'PAGE_VIEW',

    SELECT_RECENT = 'SELECT_RECENT',
    SELECT_SAVED = 'SELECT_SAVED',
    EXPORT = 'EXPORT',
    SELECT_FILTER = 'SELECT_FILTER',
}

export interface AnalyticsToolService {
    disabled: boolean;
    track(action: string, payload: object): void;
}

export type AnalyticsTool = 'mixpanel' | 'amplitude'; // New tools can be added here

export type AnalyticsModuleOptions = Readonly<{
    tool: AnalyticsTool;
}>;

export type AnalyticsServiceClass = typeof MixpanelService | typeof AmplitudeService; // New analytics tool services can be added here
