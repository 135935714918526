import { Injector } from '@angular/core';
/**
 * Allows for retrieving singletons using `AppInjector.get(MyService)` (whereas
 * `ReflectiveInjector.resolveAndCreate(MyService)` would create a new instance
 * of the service).
 */
export let AppInjector: Injector;
/**
 * Helper to set the exported {@link AppInjector}, needed as ES6 modules export
 */
export const setAppInjector = (injector: Injector): void => {
    if (AppInjector) {
        // Should not happen
        console.error('Programming error: AppInjector was already set');
    } else {
        AppInjector = injector;
    }
};
