<top-notification-bar class="export-hide"></top-notification-bar>
<div class="content content-container" fxLayout="column" fxLayoutAlign="center center">
    <div class="logo">
        <img [src]="appInfo.logoSmallBw" width="100%" [alt]="appInfo.alt" />
    </div>

    <div class="h-divider"></div>

    <div class="icon" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon svgIcon="warning" class="s-38"></mat-icon>
    </div>

    <div class="title">{{ title }}</div>

    <div class="message">
        <ng-content></ng-content>
    </div>

    <button
        *ngIf="showActionButton"
        mat-raised-button
        type="button"
        color="accent"
        class="action-button u-button h-38"
        aria-label="Page 404"
        (click)="onAction.emit()"
    >
        {{ actionText }}
    </button>
</div>
