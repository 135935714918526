<top-notification-bar class="export-hide"></top-notification-bar>

<div class="page-container">
    <div class="message-container" fxLayout="column" fxLayoutAlign="start center">
        <div class="limit-message" fxLayout="column" fxLayoutAlign="start center">
            <div class="header-illustration">
                <img [src]="message.headerImage" />
            </div>
            <div class="title">
                {{ message.title }}
            </div>

            <div class="subscript">
                {{ message.subscript }}
            </div>

            <div class="actions-container">
                <button
                    mat-raised-button
                    type="button"
                    color="accent"
                    class="action-button u-button h-40 w-240"
                    fxLayoutAlign="center center"
                    (click)="onToAppClick()"
                >
                    Go to the app
                </button>
            </div>
        </div>
    </div>
</div>
