import { Options } from 'highcharts';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

import { AdcChartOptions, PresetsConfig } from '../chart.types';
import { chartColors } from '@shared/colors/';

export class GenericChart {
    chartInstance: Highcharts.Chart;
    pointHover$ = new Subject<[number, boolean]>();
    legendHover$ = new Subject<[number, boolean]>();

    protected presetsConfig: PresetsConfig;
    protected options: AdcChartOptions;

    setChartInstance(chart: Highcharts.Chart): void {
        this.chartInstance = chart;
    }

    buildHighchartOptions(options: AdcChartOptions): Options {
        this.options = options;

        let result: Highcharts.Options = {};

        if (options.presets) {
            result = this.applyPresets(result, options.presets);
        }

        if (options.customOptions) {
            result = this.applyCustomOptions(result, options.customOptions);
        }

        if (options.data) {
            result = this.applyData(result, options.data);
        }

        result = this.applyCustomHandlers(result, options.presets);

        result.series = this.applyValuesColors(result.series);

        return result;
    }

    /* for single serie data */
    applyData(options: Highcharts.Options, data: Highcharts.Point[]): Highcharts.Options {
        options.series = [{ type: undefined, data }];

        return options;
    }

    applyCustomHandlers(options: Highcharts.Options, _presets: string[]): Highcharts.Options {
        return options;
    }

    applyValuesColors(seriesData: Highcharts.SeriesOptionsType[]): Highcharts.SeriesOptionsType[] {
        return seriesData.map((series, i) => ({ ...series, color: chartColors[i] }));
    }

    destroy(): void {
        if (this.chartInstance) {
            try {
                this.chartInstance.destroy();
            } catch (err) {}
        }
    }

    private applyPresets(options: Highcharts.Options, presets: string[]): Highcharts.Options {
        let result = options;
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        if (this.presetsConfig['generic']) {
            /* eslint-disable-next-line @typescript-eslint/dot-notation */
            result = _.merge(result, this.presetsConfig['generic']);
        }

        presets.forEach((presetName: string) => {
            result = _.merge(result, this.presetsConfig[presetName]);
        });

        return result;
    }

    private applyCustomOptions(options: Highcharts.Options, customOptions: Highcharts.Options): Highcharts.Options {
        return _.merge(options, customOptions);
    }
}
