import { FactoryProvider, InjectionToken, ValueProvider } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('window');
export const HISTORY = new InjectionToken<History>('history');

const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: () => window,
};

const historyProvider: ValueProvider = {
    provide: HISTORY,
    useValue: History,
};

export const WINDOW_PROVIDERS = [windowProvider, historyProvider];
