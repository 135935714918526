import { PRODUCT_TYPE } from '@shared/enums';
import { ContextClass } from '@shared/core/context';
import { BUSINESS_ENTITY } from '@main/enums';
import { EntityGroup, ReportType } from '@main/models';
import { IContext, IContextDatasets, IContextFilters, IContextIntegrations, IContextSettings } from '@main/interfaces';
import { EntityCommonService } from '../services/entity-common.service';
import { NavigationService } from '../services/navigation.service';

export class Context extends ContextClass<IContext> {
    productType: PRODUCT_TYPE;
    entityType: BUSINESS_ENTITY;

    rootEntity: EntityGroup;

    datasets: IContextDatasets;
    filters: IContextFilters;
    settings: IContextSettings;
    integrations: IContextIntegrations;

    filtersReady: boolean;

    // XXX: hack to pass entity services to non-report area (top-toolbar -> bookmarks)
    services: {
        entityCommonService: EntityCommonService;
        navigationService: NavigationService;
    };

    constructor() {
        super();

        this.datasets = {} as IContextDatasets;
        this.filters = {} as IContextFilters;
        this.settings = {
            globalExportEnabled: true,
            comparison: {
                dimensions: undefined,
            },
            currency: undefined,
        } as IContextSettings;
        this.integrations = {} as IContextIntegrations;

        this.filtersReady = false;
    }

    get analyticsReportType(): ReportType {
        if (this.rootEntity.items.length === 1) return ReportType.SINGLE;

        return this.rootEntity.isComparison() ? ReportType.COMPARISON : ReportType.GROUP;
    }
}
