import * as moment from 'moment';

export const maxExportFileNameLength = 100;
export const pageSizeOptions: number[] = [5, 10, 20, 50, 100];

export const youTubePublisherId = 204389;
export const responseDateFormat = 'YYYY-MM-DD';

export const copyrightYear = moment.utc().year().toString();

export const productAdIntelligencePath = 'ad-intelligence';
export const productBrandPath = 'editorial';
export const startPagePath = `/${productAdIntelligencePath}/start`;
export const dashboardsPath = `/${productAdIntelligencePath}/dashboards`;

export const groupNameValidationPattern = `^[\\p{L}\\s0-9-_]{1,64}$`;
export const maxSubscriptionsCount = 10;
export const keywordAllowedPattern = '[\\p{L}a-z-_]+[\\p{L}a-z0-9-_]*';

export const viewReportTokenName = 'X-Report-Id';

export const brandsAllowedCountries = ['us'];
export const brandsAllowedMoreCountries = ['ca', 'de', 'es', 'fr', 'uk', 'us'];
