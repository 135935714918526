import { Observable, of } from 'rxjs';
import { AdBlockCheckMethod } from './adblock-check.class';

export class AdblockCheckMethod2 implements AdBlockCheckMethod {
    init(): Observable<void> {
        return of(undefined);
    }

    check(): Observable<boolean> {
        const blockAdBlock = new (window as any).BlockAdBlock({
            checkOnLoad: false,
            resetOnEnd: true,
        });

        return new Observable((observer) => {
            blockAdBlock.onDetected(() => {
                observer.next(true);
                observer.complete();
            });

            blockAdBlock.onNotDetected(() => {
                observer.next(false);
                observer.complete();
            });

            blockAdBlock.check(true);
        });
    }
}
