<div class="u-paginator">
    <span class="mr-16">Page {{ index + 1 }} of {{ totalPages }}</span>

    <button mat-icon-button [disabled]="index === 0" (click)="previousPage()" class="btn-previous">
        <mat-icon class="s-18">chevron_left</mat-icon>
    </button>

    <button mat-icon-button [disabled]="index === totalPages - 1" (click)="nextPage()" class="btn-next">
        <mat-icon class="s-18">chevron_right</mat-icon>
    </button>

    <ng-container *ngIf="showPageSizeControl">
        <span class="ml-24 mr-16">Items per page:</span>
        <mat-form-field appearance="outline" class="u-select">
            <mat-select disableOptionCentering [value]="size" (selectionChange)="onItemsPerPageChange($event.value)">
                <mat-option class="u-select-option--small" *ngFor="let size of pageSizeOptions" [value]="size">{{
                    size
                }}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</div>
