import { Pipe, PipeTransform } from '@angular/core';

import { CommonHelpers } from '@shared/helpers/common.helpers';

@Pipe({
    name: 'short',
})
export class ShortPipe implements PipeTransform {
    transform(value: string | number, precision?: number, roundSmall?: boolean, naStr?: string): string {
        return CommonHelpers.formatShortNumber(value, precision, roundSmall, naStr);
    }
}
