import { SystemColor } from '../../models';
import { getColors, getColorsPalette } from '../../utils';

export const chartColorNames = [
    SystemColor.Darkviolet,
    SystemColor.Turquoise,
    SystemColor.Mustard,
    SystemColor.Hotpink,
    SystemColor.Graphite,
    SystemColor.LightGreen,
    SystemColor.Coral,
    SystemColor.Olive,
    SystemColor.Deepskyblue,
    SystemColor.Crab,
];

export const chartColors = getColors(chartColorNames);
export const chartPalette = getColorsPalette(chartColorNames);
