import { Routes } from '@angular/router';
import { LoggedOutAccessGuard } from '@core/guards';

import {
    ForgotPasswordPageComponent,
    LoginPageComponent,
    PasswordChangedPageComponent,
    RecoverPasswordPageComponent,
    ResetPasswordPageComponent,
} from './auth';

import {
    BadRequestPageComponent,
    NotFoundPageComponent,
    ServerErrorPageComponent,
    UnauthorizedPageComponent,
} from './error';

export const commonPagesRoutes: Routes = [
    {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [LoggedOutAccessGuard],
    },
    {
        path: 'password/:token',
        component: ResetPasswordPageComponent,
        canActivate: [LoggedOutAccessGuard],
    },
    {
        path: 'activate/:token',
        component: ResetPasswordPageComponent,
        canActivate: [LoggedOutAccessGuard],
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordPageComponent,
        canActivate: [LoggedOutAccessGuard],
    },
    {
        path: 'recover-password',
        component: RecoverPasswordPageComponent,
        canActivate: [LoggedOutAccessGuard],
    },
    {
        path: 'password-changed',
        component: PasswordChangedPageComponent,
        canActivate: [LoggedOutAccessGuard],
    },
    {
        path: '500',
        component: ServerErrorPageComponent,
    },
    {
        path: '401',
        component: UnauthorizedPageComponent,
    },
    {
        path: 'not-found',
        component: NotFoundPageComponent,
    },
    {
        path: 'bad-request',
        component: BadRequestPageComponent,
    },
    {
        path: '**',
        redirectTo: '/not-found',
    },
];
