import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * Represents a component that allows inline editing of text.
 */
@Component({
    selector: 'inline-edit',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
    ],
    templateUrl: './inline-edit.component.html',
    styleUrls: ['./inline-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineEditComponent implements OnInit {
    @Input() text = '';
    @Input() maxLength = 64;
    @Input() validationPattern = '';
    @Input() validationErrorMessage = '';
    @Input() actionsVisible = true;

    @Output() textChange = new EventEmitter<string>();
    @Output() isEditingChange = new EventEmitter<boolean>();

    @ViewChild('textInput') textInput: ElementRef<HTMLInputElement>;

    isEditing = false;
    form: FormGroup;
    textControl: FormControl<string>;

    get validationError(): string {
        return this.textControl.invalid ? this.validationErrorMessage : '';
    }

    ngOnInit(): void {
        this.initForm();
    }

    beginEdit(): void {
        this.isEditing = true;
        this.isEditingChange.emit(true);

        setTimeout(() => {
            this.textInput.nativeElement?.focus();
            this.textControl.markAsTouched();
        });
    }

    editDone(): void {
        if (this.textControl.invalid) return;

        this.isEditing = false;
        this.isEditingChange.emit(false);

        const newText = this.textControl.value.trim();

        if (newText === this.text) {
            this.textControl.setValue(this.text);
            return;
        }

        this.text = newText;
        this.textChange.emit(newText);
    }

    cancel(): void {
        this.isEditing = false;
        this.isEditingChange.emit(false);
        this.textControl.setValue(this.text);
    }

    private initForm(): void {
        this.textControl = new FormControl(this.text, [
            Validators.required,
            Validators.maxLength(this.maxLength),
            Validators.pattern(new RegExp(this.validationPattern, 'ui')),
        ]);

        this.form = new FormGroup({ text: this.textControl });
    }
}
