import { isFunction } from 'lodash';

import { validationMessages } from '../data';

export const getValidationMessage = (
    fieldName: string,
    key: string,
    dynamicValues?: Record<string, unknown>,
): string => {
    const result = validationMessages[fieldName][key];

    return isFunction(result) ? result(dynamicValues) : result;
};
