import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { WinmoNotFoundPageComponent } from '@app/integrations/winmo/pages/not-found/not-found-page.component';
import { SharedTopNotificationBarModule } from '@app/modules/top-notification/top-notification-bar.module';
import { WinmoUnsubscribedPageComponent } from '@app/integrations/winmo/pages/unsubscribed/unsubscribed.component';
import { SharedAdBlockCheckModule } from '@shared/features/adblock-check/adblock-check.module';
import { SubscriptionsService } from '@main/services/subscriptions.service';
import { ReportApiService } from '@main/core/report.api-service';
import { IntegrationUserGuidingModule } from '../userguiding/userguiding.module';
import { WinmoService } from './services/winmo.service';
import { PageWinmoPreviewComponent } from './preview/preview.component';
import { ViewportService } from './services/viewport.service';

@NgModule({
    imports: [
        IntegrationUserGuidingModule,
        SharedTopNotificationBarModule,
        SharedAdBlockCheckModule,
        RouterModule.forChild([]),
        CommonModule,
        MatButtonModule,
    ],
    declarations: [PageWinmoPreviewComponent, WinmoNotFoundPageComponent, WinmoUnsubscribedPageComponent],
    providers: [WinmoService, SubscriptionsService, ReportApiService, ViewportService],
})
export class IntegrationWinmoModule {}
