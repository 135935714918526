<p class="btn-showmore" [ngClass]="{ expanded: !toggled }" (click)="onToggle()">
    <mat-icon class="s-12 mr-8" svgIcon="dropdown"></mat-icon>

    <span>
        <ng-container *ngIf="!toggled">
            {{ showMoreEntitiesLeft }} more {{ showMoreEntitiesLeft === 1 ? entityInfo.text.one : entityInfo.text.few }}
        </ng-container>

        <ng-container *ngIf="toggled">Show less</ng-container>
    </span>
</p>
