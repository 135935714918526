<mat-button-toggle-group name="button-group" aria-label="" multiple [(value)]="_selected">
    <mat-button-toggle
        *ngFor="let f of items"
        class="py-0"
        [ngClass]="{ hidden: !f.display }"
        [value]="f.id"
        [matTooltip]="f.tooltip"
        [disabled]="disabled || f.disabled"
        (click)="click(f)"
    >
        <mat-icon class="close-filter s-12" svgIcon="adFilterClose"></mat-icon>
        <mat-icon class="item-icon s-{{ iconSize }}" [svgIcon]="f.icon" [ngStyle]="styleConf"></mat-icon>
    </mat-button-toggle>
</mat-button-toggle-group>
