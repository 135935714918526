/*
    SINGLE: single instance
    GROUP: single group OR few instances
    COMPARISON:
        a) few instances
        b) few groups
        c) combination a & b
        d) single comparison group
*/

export enum RECENT_TYPE {
    SINGLE = 'SINGLE',
    GROUP = 'GROUP',
    COMPARISON = 'COMPARISON',
}
