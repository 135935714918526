import { isNil } from 'lodash';
import { Injectable } from '@angular/core';

export enum LOCAL_STORAGE_KEY {
    UNAUTH_SAVED_URL = 'UNAUTH_SAVED_URL',
    PRICING_PAGE_AB_TYPE = 'PRICING_PAGE_AB_TYPE',
    DASHBOARDS_DEMO_ID = 'DASHBOARDS_DEMO_ID',
}

@Injectable()
export class StorageService {
    get<T>(key: LOCAL_STORAGE_KEY | string, defaultValue: T = null): T {
        let rawValue: string;

        try {
            rawValue = localStorage.getItem(key);
        } catch (err) {
            console.error(`Can't access localStorage`);
        }

        if (isNil(rawValue)) return defaultValue;

        let parsedValue;

        try {
            parsedValue = JSON.parse(rawValue);
        } catch (err) {
            parsedValue = null;
        }

        return parsedValue ?? defaultValue;
    }

    set(key: LOCAL_STORAGE_KEY | string, value: unknown): void {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            console.error(`Can't access localStorage`);
        }
    }

    remove(key: LOCAL_STORAGE_KEY | string): void {
        try {
            localStorage.removeItem(key);
        } catch (err) {
            console.error(`Can't access localStorage`);
        }
    }
}
