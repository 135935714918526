import { defaultFormatRule, formatRules } from '../configs/currencies-formatting-rules';
import { Currency } from '../models';

export class CurrenciesHelpers {
    static formatValue(value: number | string, currency: Currency, shortIfCan = false, naStr?: string): string {
        if (!currency) {
            return '$' + value;
        }

        if (value === undefined && naStr !== undefined) {
            return naStr;
        }

        if (!value) {
            value = 0;
        }

        const fullFormat = formatRules[currency.code]?.full;
        const shortFormat = formatRules[currency.code]?.short;

        const template = (shortIfCan && shortFormat) || fullFormat || defaultFormatRule;

        return template
            .replace('${code}', currency.code)
            .replace('${symbol}', currency.symbol)
            .replace('${value}', value);
    }

    static formatHeadline(currency: Currency): string {
        const headline = formatRules[currency.code]?.headline;

        return headline ? headline.replace('${code}', currency.code).replace('${symbol}', currency.symbol) : '';
    }
}
