import { AD_TYPE } from '@main/enums/ad_type';
import { CHANNEL } from '@main/enums';

// all possible ad type UI filters list
export enum UI_AD_TYPE_FILTER {
    // top level channels filters
    ALL_CHANNELS_BANNER = 'ALL_CHANNELS_BANNER',
    ALL_CHANNELS_VIDEO = 'ALL_CHANNELS_VIDEO',
    ALL_CHANNELS_SOCIAL = 'ALL_CHANNELS_SOCIAL',

    // desktop channel filters
    DESKTOP_CHANNEL_BANNER = 'DESKTOP_CHANNEL_BANNER',
    DESKTOP_CHANNEL_ANIMATED_BANNER = 'DESKTOP_CHANNEL_ANIMATED_BANNER',

    // social channel filters
    SOCIAL_CHANNEL_IMAGE = 'SOCIAL_CHANNEL_IMAGE',
    SOCIAL_CHANNEL_VIDEO = 'SOCIAL_CHANNEL_VIDEO',
    SOCIAL_CHANNEL_CAROUSEL = 'SOCIAL_CHANNEL_CAROUSEL',
    SOCIAL_CHANNEL_PROMOTED_ACCOUNT = 'SOCIAL_CHANNEL_PROMOTED_ACCOUNT',
}

export const CHANNELS_AD_TYPE_FILTER = {
    [CHANNEL.ALL]: [
        UI_AD_TYPE_FILTER.ALL_CHANNELS_BANNER,
        UI_AD_TYPE_FILTER.ALL_CHANNELS_VIDEO,
        UI_AD_TYPE_FILTER.ALL_CHANNELS_SOCIAL,
    ],

    [CHANNEL.DISPLAY]: [UI_AD_TYPE_FILTER.DESKTOP_CHANNEL_BANNER, UI_AD_TYPE_FILTER.DESKTOP_CHANNEL_ANIMATED_BANNER],

    [CHANNEL.VIDEO]: [UI_AD_TYPE_FILTER.ALL_CHANNELS_VIDEO],

    [CHANNEL.SOCIAL]: [
        UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_IMAGE,
        UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_VIDEO,
        UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_CAROUSEL,
        UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_PROMOTED_ACCOUNT,
    ],

    [CHANNEL.INAPP]: [UI_AD_TYPE_FILTER.ALL_CHANNELS_BANNER, UI_AD_TYPE_FILTER.ALL_CHANNELS_VIDEO],
};

// ui-filter => sending value(s)
export const UI_TO_BE_AD_TYPE_MAPPING = {
    // top level channels
    ALL_CHANNELS_BANNER: [AD_TYPE.BANNER, AD_TYPE.ANIMATED_BANNER],
    ALL_CHANNELS_VIDEO: [AD_TYPE.VIDEO],
    ALL_CHANNELS_SOCIAL: [
        AD_TYPE.SOCIAL_IMAGE,
        AD_TYPE.SOCIAL_VIDEO,
        AD_TYPE.SOCIAL_CAROUSEL,
        AD_TYPE.SOCIAL_PROMOTED_ACCOUNT,
    ],

    DESKTOP_CHANNEL_BANNER: AD_TYPE.BANNER,
    DESKTOP_CHANNEL_ANIMATED_BANNER: AD_TYPE.ANIMATED_BANNER,

    SOCIAL_CHANNEL_IMAGE: AD_TYPE.SOCIAL_IMAGE,
    SOCIAL_CHANNEL_VIDEO: AD_TYPE.SOCIAL_VIDEO,
    SOCIAL_CHANNEL_CAROUSEL: AD_TYPE.SOCIAL_CAROUSEL,
    SOCIAL_CHANNEL_PROMOTED_ACCOUNT: AD_TYPE.SOCIAL_PROMOTED_ACCOUNT,
};

export const BE_TO_UI_AD_TYPE_FILTER_MAPPING = {
    [AD_TYPE.BANNER]: [UI_AD_TYPE_FILTER.ALL_CHANNELS_BANNER, UI_AD_TYPE_FILTER.DESKTOP_CHANNEL_BANNER],

    [AD_TYPE.ANIMATED_BANNER]: [UI_AD_TYPE_FILTER.DESKTOP_CHANNEL_ANIMATED_BANNER],

    [AD_TYPE.VIDEO]: [UI_AD_TYPE_FILTER.ALL_CHANNELS_VIDEO],

    [AD_TYPE.SOCIAL]: [
        UI_AD_TYPE_FILTER.ALL_CHANNELS_SOCIAL,
        UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_IMAGE,
        UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_VIDEO,
        UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_CAROUSEL,
        UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_PROMOTED_ACCOUNT,
    ],

    [AD_TYPE.SOCIAL_IMAGE]: [UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_IMAGE],
    [AD_TYPE.SOCIAL_VIDEO]: [UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_VIDEO],
    [AD_TYPE.SOCIAL_CAROUSEL]: [UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_CAROUSEL],
    [AD_TYPE.SOCIAL_PROMOTED_ACCOUNT]: [UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_PROMOTED_ACCOUNT],
};

// ui-filter => svg icon name
export enum AD_TYPE_FILTER_ICON {
    ALL_CHANNELS_BANNER = 'adFilterImage',
    ALL_CHANNELS_VIDEO = 'adFilterVideo',
    ALL_CHANNELS_SOCIAL = 'adFilterSocial',

    DESKTOP_CHANNEL_BANNER = 'adFilterImage',
    DESKTOP_CHANNEL_ANIMATED_BANNER = 'adFilterAnimatedBanner',

    SOCIAL_CHANNEL_IMAGE = 'adFilterImage',
    SOCIAL_CHANNEL_VIDEO = 'adFilterVideo',
    SOCIAL_CHANNEL_CAROUSEL = 'adFilterSocialCarousel',
    SOCIAL_CHANNEL_PROMOTED_ACCOUNT = 'adFilterSocialPromoted',
}

export const AD_TYPE_FILTER_TITLE = {
    [UI_AD_TYPE_FILTER.ALL_CHANNELS_BANNER]: 'Banner',
    [UI_AD_TYPE_FILTER.ALL_CHANNELS_SOCIAL]: 'Social',
    [UI_AD_TYPE_FILTER.ALL_CHANNELS_VIDEO]: 'Video',
    [UI_AD_TYPE_FILTER.DESKTOP_CHANNEL_BANNER]: 'Banner',
    [UI_AD_TYPE_FILTER.DESKTOP_CHANNEL_ANIMATED_BANNER]: 'Animated banner',
    [UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_IMAGE]: 'Image',
    [UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_VIDEO]: 'Video',
    [UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_CAROUSEL]: 'Carousel',
    [UI_AD_TYPE_FILTER.SOCIAL_CHANNEL_PROMOTED_ACCOUNT]: 'Promoted',
};

// be-filter => svg icon name
export enum AD_TYPE_ICON {
    BANNER = 'image',
    ANIMATED_BANNER = 'animBanner',
    VIDEO = 'play',
    SOCIAL = 'award',
    SOCIAL_IMAGE = 'image',
    SOCIAL_VIDEO = 'play',
    SOCIAL_CAROUSEL = 'carousel',
    SOCIAL_PROMOTED_ACCOUNT = 'award',
}
