/* eslint-disable max-lines */
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE_KEY, StorageService } from '@core/services';

import { IntegrationsService } from '@app/integrations/services/integrations.service';
import { AppConfig } from 'app/app.config';
import { AdcError } from '@shared/models/error.model';
import { ADC_ERROR_CODE_ENUM, HttpStatus } from '@shared/enums';

import {
    AuthCredentials,
    AuthTokenLoginOptions,
    AuthTokens,
    CUSTOMER_TYPE,
    CustomerContactsDto,
    LoginOptions,
} from './auth.types';
import { RequestService } from '../request';
import { EventsEnum, EventsService } from '../events';
import { AuthHelpers } from './auth.helpers';

const genericErrorMessage = 'We encountered a problem, please try again later';

@Injectable({ providedIn: 'root' })
export class AuthService {
    authToken: string;
    accessToken: string;

    private getAuthData$: Observable<AuthTokens>;

    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
        private router: Router,
        private eventsService: EventsService,
        private config: AppConfig,
        private integrationsService: IntegrationsService,
    ) {}

    get isTrialCustomer(): boolean {
        const decodedToken = AuthHelpers.decodeToken(this.authToken);
        return Boolean(decodedToken?.actor?.customer?.type === CUSTOMER_TYPE.TRIAL);
    }

    get redirectAfterLoginUrl(): string {
        return this.storageService.get<string>(LOCAL_STORAGE_KEY.UNAUTH_SAVED_URL) || null;
    }

    set redirectAfterLoginUrl(url: string) {
        this.storageService.set(LOCAL_STORAGE_KEY.UNAUTH_SAVED_URL, url);
    }

    get userEmail(): string {
        const decodedToken = AuthHelpers.decodeToken(this.authToken);
        return decodedToken?.actor?.email || '';
    }

    get accountManagerMail(): Observable<string> {
        const decodedToken = AuthHelpers.decodeToken(this.authToken);
        const customerId = decodedToken?.actor?.customer?.id;

        return this.requestService.get<CustomerContactsDto>(`/customers/${customerId}/contacts`).pipe(
            shareReplay(),
            map((contacts) => {
                return contacts?.customerManager?.email || '';
            }),
            catchError(() => {
                return '';
            }),
        );
    }

    isAuthenticated(): Observable<boolean> {
        return this.getAuthData().pipe(
            switchMap((authData) => {
                const authed = Boolean(authData?.accessToken);

                if (!authed && this.config.appInfo.integration) {
                    return this.tryToRestoreIntegrationSession();
                }

                return of(authed);
            }),
        );
    }

    getAuthData(): Observable<AuthTokens> {
        if (this.accessToken) {
            return of({
                authToken: this.authToken,
                accessToken: this.accessToken,
            });
        }

        if (this.getAuthData$) {
            return this.getAuthData$;
        }

        this.getAuthData$ = this.obtainAuthData().pipe(
            tap((data: AuthTokens) => {
                if (data) {
                    this.authToken = data.authToken;
                    this.accessToken = data.accessToken;
                }
            }),
        );

        return this.getAuthData$;
    }

    loginByCredentials(credentials: AuthCredentials): Observable<void> {
        return this.fetchAuthTokenByCridentials(credentials).pipe(
            switchMap((authToken) => {
                if (!AuthHelpers.checkOriginClientAppAddress(authToken)) {
                    this.redirectToOriginDomain(authToken);

                    throw new AdcError(ADC_ERROR_CODE_ENUM.AUTH_WRONG_ORIGIN);
                }

                return this.loginByAuthToken(authToken, {
                    rememberMe: credentials.rememberMe,
                    redirectToPage$: of(this.redirectAfterLoginUrl),
                });
            }),
            catchError((error) => {
                if (error instanceof AdcError) throw error;

                let errorCode: ADC_ERROR_CODE_ENUM;
                let errorMessage: string;

                switch (error.status) {
                    case HttpStatus.UNAUTHORIZED:
                        errorCode = ADC_ERROR_CODE_ENUM.AUTH_WRONG_CREDENTIALS;
                        errorMessage = 'Username or password is incorrect';
                        break;
                    case HttpStatus.FORBIDDEN:
                        errorCode = ADC_ERROR_CODE_ENUM.AUTH_ACCESS_DENIED;
                        errorMessage = 'Access denied';
                        break;
                    default:
                        errorCode = ADC_ERROR_CODE_ENUM.GENERIC_ERROR;
                        errorMessage = genericErrorMessage;
                }

                throw new AdcError(errorCode, errorMessage);
            }),
        );
    }

    loginByAnyToken(token: string): Observable<void> {
        const tokenHeader = AuthHelpers.decodeTokenHeader(token);

        this.clearAuthData();

        if (!tokenHeader) {
            return throwError(new AdcError(ADC_ERROR_CODE_ENUM.AUTH_INVALID_TOKEN, 'Invalid token format'));
        }

        if (tokenHeader.stt === 'AUTHENTICATION') {
            return this.loginByAuthToken(token, { storeAuthToken: true });
        }

        if (tokenHeader.stt === 'ACCESS') {
            return this.loginByAccessToken(token);
        }

        return this.loginBy3rdPartyToken(token);
    }

    loginByAuthToken(
        authToken: string,
        options: Partial<AuthTokenLoginOptions> = {} as AuthTokenLoginOptions,
    ): Observable<void> {
        this.authToken = authToken;

        const putAuthTokenStep = options.storeAuthToken ? this.putAuthTokenToCookie(authToken) : of(undefined);

        return putAuthTokenStep.pipe(
            catchError((error) => {
                if (error instanceof HttpResponseBase) {
                    if (error.status === 401) {
                        throw new AdcError(ADC_ERROR_CODE_ENUM.AUTH_INVALID_TOKEN, 'Invalid auth token');
                    }
                }

                throw new AdcError(ADC_ERROR_CODE_ENUM.GENERIC_ERROR, genericErrorMessage);
            }),
            switchMap(() =>
                this.fetchAccessToken(authToken).pipe(
                    catchError((error) => {
                        if (error instanceof AdcError) {
                            throw error;
                        }

                        if (error instanceof HttpResponseBase) {
                            if (error.status === 401) {
                                throw new AdcError(ADC_ERROR_CODE_ENUM.AUTH_INVALID_TOKEN, 'Invalid auth token');
                            }
                        }

                        throw new AdcError(ADC_ERROR_CODE_ENUM.GENERIC_ERROR, genericErrorMessage);
                    }),
                    switchMap((accessToken) => this.loginByAccessToken(accessToken, options)),
                ),
            ),
        );
    }

    private clearAuthData(): void {
        this.getAuthData$ = null;
        this.authToken = null;
        this.accessToken = null;
    }

    clearSession(): Observable<void> {
        return this.clearAuthToken();
    }

    logout(clearSession = true, saveOriginUrl = true): void {
        this.eventsService.sendMessage(EventsEnum.USER_LOGGED_OUT);

        if (saveOriginUrl) this.redirectAfterLoginUrl = this.router.url;

        this.clearAuthData();

        if (clearSession) {
            this.clearSession().subscribe(
                () => {
                    this.redirectToLoginPage();
                },
                () => {
                    this.redirectToLoginPage();
                },
            );
        } else {
            this.redirectToLoginPage();
        }
    }

    remindPassword(email: string): Observable<void> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'text/plain',
            }),
        };

        return this.requestService
            .post<boolean>('security/tokens/verification', email, httpOptions)
            .pipe(map(() => undefined));
    }

    setNewPassword(token: string, password: string): Observable<string> {
        return this.fetchIdentity(token, password);
    }

    checkTokenForValidity(token: string): Observable<void | never> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        });

        return this.requestService.head('security/tokens/verification', { headers }).pipe(
            catchError((error) => {
                if (error instanceof HttpResponseBase) {
                    if (error.status === 401) {
                        throw new AdcError(ADC_ERROR_CODE_ENUM.AUTH_INVALID_TOKEN, 'Invalid token');
                    }
                }

                throw new AdcError(ADC_ERROR_CODE_ENUM.GENERIC_ERROR, genericErrorMessage);
            }),
        );
    }

    refreshAccessToken(): Observable<string> {
        if (this.authToken) {
            return this.fetchAccessToken(this.authToken).pipe(
                tap((accessToken) => {
                    this.accessToken = accessToken;
                }),
            );
        } else {
            return throwError('no auth token');
        }
    }

    getAdminPanelUrl(): string {
        const decoded: Record<string, any> = this.authToken && AuthHelpers.decodeToken(this.authToken);

        if (!decoded || !decoded.clientApps) {
            return;
        }

        if (decoded.clientApps.admin) {
            const { scheme, domain, port } = decoded.clientApps.admin.address;
            const uri = scheme + '://' + domain + (port ? ':' + port : '');

            return `${uri}/login?authToken=${this.authToken}`;
        }

        return;
    }

    private loginBy3rdPartyToken(token: string, options: Partial<LoginOptions> = {} as LoginOptions): Observable<void> {
        return this.fetchAuthTokenBy3rdPartyToken(token, false).pipe(
            catchError((error) => {
                if (error instanceof AdcError) {
                    throw error;
                }

                throw new AdcError(ADC_ERROR_CODE_ENUM.GENERIC_ERROR, genericErrorMessage);
            }),
            switchMap((authToken) =>
                this.loginByAuthToken(authToken, {
                    rememberMe: options.rememberMe,
                    redirectToPage$:
                        options.redirectToPage$ || this.integrationsService.getService().getIntegrationRedirectPage(),
                }),
            ),
        );
    }

    private loginByAccessToken(
        accessToken: string,
        options: Partial<LoginOptions> = {} as LoginOptions,
    ): Observable<void> {
        this.accessToken = accessToken;

        this.onLoginSuccess(options);

        return of(null);
    }

    private onLoginSuccess(options: Partial<LoginOptions> = {} as LoginOptions): void {
        this.eventsService.sendMessage(EventsEnum.USER_LOGGED_IN);
        this.redirectAfterLoginUrl = null;

        const redirectToPage$ = options.redirectToPage$ || of('/');

        redirectToPage$.pipe(first()).subscribe((url) => {
            let redirectUrl: string;

            try {
                redirectUrl = decodeURI(new URL(url).pathname);
            } catch (err) {
                redirectUrl = url;
            }

            if (!redirectUrl) {
                redirectUrl = '/';
            }

            const [path, query] = redirectUrl.split('?');
            const queryParams = query && Object.fromEntries(new URLSearchParams(query));

            this.router.navigate([path], { queryParams });
        });
    }

    private fetchAccessToken(authToken: string): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: `text/plain, */*`,
                Authorization: 'Bearer ' + authToken,
            }),
            responseType: 'text' as 'json',
        };

        return this.requestService.post<string>('security/tokens/access', undefined, httpOptions).pipe(
            map((accessToken) => {
                if (!accessToken) {
                    throw new AdcError(ADC_ERROR_CODE_ENUM.AUTH_ERROR_TOKEN_FETCH, 'Error retrieving access token');
                }

                return accessToken;
            }),
            shareReplay(1),
        );
    }

    private fetchAuthTokenByCridentials({ username, password, rememberMe }: AuthCredentials): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Basic ' + AuthHelpers.encodeBasicString(username, password),
            }),
            responseType: 'text',
        };

        const url = `security/tokens/authentication?forever=${rememberMe.toString()}`;

        return this.requestService.post<string>(url, undefined, httpOptions);
    }

    private fetchAuthTokenBy3rdPartyToken(token: string, rememberMe: boolean): Observable<string> {
        if (this.config.appInfo.integration) {
            this.integrationsService.getService().setToken(token);
        }

        const httpOptions = {
            headers: new HttpHeaders({
                Accept: `text/plain, */*`,
                Authorization: 'Bearer ' + token,
            }),
            responseType: 'text' as 'json',
        };

        const url = `security/tokens/authentication?forever=${rememberMe.toString()}`;

        return this.requestService.post<string>(url, null, httpOptions);
    }

    private clearAuthToken(): Observable<void> {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: `text/plain, */*`,
            }),
            responseType: 'text' as 'json',
        };

        return this.requestService.delete('security/tokens/authentication', httpOptions);
    }

    private fetchIdentity(token: string, password: string): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: `text/plain, */*`,
                Authorization: 'Bearer ' + token,
            }),
            responseType: 'text' as 'json',
        };

        return this.requestService.post<string>('security/tokens/identity', password, httpOptions);
    }

    private putAuthTokenToCookie(authToken: string): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: `text/plain, */*`,
                Authorization: `Bearer ${authToken}`,
            }),
            responseType: 'text' as 'json',
        };

        return this.requestService.get<string>('security/tokens/authentication', httpOptions);
    }

    private getAuthTokenFromCookie(): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: `text/plain, */*`,
            }),
            responseType: 'text' as 'json',
        };

        return this.requestService.get<string>('security/tokens/authentication', httpOptions);
    }

    private obtainAuthData(): Observable<AuthTokens> {
        return this.getAuthTokenFromCookie().pipe(
            switchMap((authToken) =>
                this.fetchAccessToken(authToken).pipe(map((accessToken) => ({ authToken, accessToken }))),
            ),
            catchError(() => of(null)),
            shareReplay(1),
        );
    }

    private redirectToOriginDomain(authToken: string): void {
        const decodedToken = AuthHelpers.decodeToken(authToken);

        this.redirectToUrl(AuthHelpers.composeWhiteLabelRedirectUrl(decodedToken.clientApps.pro.address, authToken));
    }

    private redirectToLoginPage(): void {
        void this.router.navigate(['login']);
    }

    private redirectToUrl(url: string): void {
        window.location.href = url;
    }

    private tryToRestoreIntegrationSession(): Observable<boolean> {
        return this.integrationsService
            .getService()
            .getToken()
            .pipe(
                mergeMap((token) => {
                    if (!token) {
                        return of(false);
                    }

                    return this.loginBy3rdPartyToken(token).pipe(
                        map(() => true),
                        catchError(() => {
                            this.router.navigate([401]);
                            return of(false);
                        }),
                    );
                }),
                catchError(() => {
                    void this.router.navigate(['bad-request']);
                    return of(false);
                }),
            );
    }
}
