import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Country, Period } from '@shared/models';
import { PeriodHelpers } from '@shared/helpers/period.helpers';
import { PersistedSettingsService, SETTINGS } from '@shared/services';
import { IEntityItemId, RouteIdsItem } from '@main/types/types';
import { Device, EntityItem } from '@main/models';
import { EntityService } from '@main/services/entity.service';
import { EntityCommonService } from '@main/services/entity-common.service';
import { BUSINESS_ENTITY, DEVICE } from '@main/enums';
import { EntityBase } from '@main/models/EntityBase';

@Injectable()
export class CampaignEntityService extends EntityService {
    protected readonly entityCommonService = inject(EntityCommonService);
    protected readonly persistedSettingsService = inject(PersistedSettingsService);

    parseRouteIds(str: string): RouteIdsItem[] {
        const parsed = str.match(/(\d+)/g);

        return [Number(parsed[0])];
    }

    resolveEntitiesByIds<T extends EntityBase>(ids: RouteIdsItem[]): Observable<T[]> {
        return this.entityCommonService.resolveSingleEntitiesById(
            BUSINESS_ENTITY.Campaign,
            ids as IEntityItemId[],
        ) as Observable<any>;
    }

    serializeEntitiesIds(items: EntityItem[]): string {
        return items.map((v) => v.id).join(',');
    }

    getDefaultDevice(devices: Device[]): Device {
        const { channel: queryValue } = this.queryParams;
        const [, deviceCode = null] = queryValue?.split('-') || [];
        const persistedId = this.persistedSettingsService.get<string>(SETTINGS.ROOT_DEVICE);

        const getAvailableDevice = (code: DEVICE) =>
            devices.find((v) => v.code === code && !v.disabled && !v.restricted);

        let device: Device;

        if (deviceCode) {
            device = getAvailableDevice(deviceCode);
        }

        if (!device) {
            device = getAvailableDevice(persistedId as DEVICE);
        }

        if (!device) {
            device = devices && devices[0];
        }

        if (devices?.length === 1) {
            device.disabled = true;
        }

        return device;
    }

    getDefaultCountry(countries: Country[]): Country {
        const { country: queryValue } = this.queryParams;

        let bestCountry;

        if (queryValue) {
            bestCountry = countries.find((v) => v.code === queryValue || v.id.toString() === queryValue);
            this.navigationService.removeQueryParam('country', true);
        }

        if (!bestCountry) {
            const persistedId = this.persistedSettingsService.get<number>(SETTINGS.ROOT_COUNTRY);

            bestCountry = countries.find((v) => v.id === persistedId && !v.disabled && !v.restricted);
        }

        if (!bestCountry) {
            const allAvaliableCountries = countries.filter((v) => !v.restricted && !v.disabled);

            bestCountry = allAvaliableCountries[0];
        }

        return bestCountry;
    }

    getDefaultPeriod(periods: Period[]): Period {
        const { period: queryValue } = this.queryParams;

        const availablePeriods = periods.filter((v) => !v.restricted && !v.disabled);

        let period: Period;

        if (queryValue) {
            period = periods.find((v) => v.id.toString() === queryValue);
            this.navigationService.removeQueryParam('period', true);
        }

        if (!period) {
            const persistedId = this.persistedSettingsService.get<string>(SETTINGS.ROOT_PERIOD);

            if (persistedId) {
                period = PeriodHelpers.getPeriodByPersistedValue(availablePeriods, persistedId);
            }
        }

        if (!period) {
            period = PeriodHelpers.calculateWholeRangePeriod(availablePeriods);
        }

        if (!period) {
            period =
                availablePeriods.find((v) => v.code === 'LAST_30_DAYS') ||
                availablePeriods.find((v) => v.code === 'LAST_7_DAYS');
        }

        if (!period) {
            period = availablePeriods[0];
        }

        return period;
    }
}
