import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { HighchartsChartModule } from 'highcharts-angular';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { NouisliderModule } from 'ng2-nouislider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModulesModule } from './features/modules.module';
import { AccountService, PeriodsApiService, PeriodsService } from './services';
import { FormControlsModule } from './form-controls/form-controls.module';
import { AnalyticsModule } from '@app/modules/analytics/analytics.module';

import { components } from './components';
import { directives } from './directives';
import { pipeProviders, pipes } from './pipes';
import { WidgetFullScreenViewModule } from '@main/shared/widget-full-screen-view/widget-full-screen-view.module';
import { MessageBannerComponent } from './components/message-banner/message-banner.component';
import { MatCardModule } from '@angular/material/card';
import { PixelatedShadeComponent } from '@main/components/pixelated-shade/pixelated-shade.component';
import { MenuButtonComponent } from '@main/dashboards/components/menu-button/menu-button.component';
import { InlineEditComponent } from './components/inline-edit/inline-edit.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { WidgetSkeletonComponent } from './components/widget-skeleton/widget-skeleton.component';
import { MultiCasePipe } from './pipes/multi-case/multi-case.pipe';

@NgModule({
    imports: [
        HighchartsChartModule,
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatToolbarModule,
        MatTableModule,
        MatTreeModule,
        MatSortModule,
        MatTooltipModule,
        NgOptimizedImage,
        ScrollingModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        NouisliderModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'once' }),
        RouterModule,
        InfiniteScrollModule, // load more scroll
        NgxPopperjsModule,
        FlexLayoutModule,
        MatSlideToggleModule,
        SharedModulesModule,
        FormControlsModule,
        AnalyticsModule,
        WidgetFullScreenViewModule,
        MatCardModule,
        PixelatedShadeComponent,
        MenuButtonComponent,
        InlineEditComponent,
        WidgetSkeletonComponent,
        MultiCasePipe,
    ],
    declarations: [...components, ...directives, ...pipes, MessageBannerComponent],
    exports: [
        HighchartsChartModule,
        CommonModule,
        FormsModule,
        InfiniteScrollModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        NgOptimizedImage,
        NouisliderModule,
        ReactiveFormsModule,
        ScrollingModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        NgxPopperjsModule,

        SharedModulesModule,
        FormControlsModule,
        AnalyticsModule,
        FlexLayoutModule,
        WidgetFullScreenViewModule,

        ...components,
        ...directives,
        ...pipes,
        MessageBannerComponent,
        InlineEditComponent,
        WidgetSkeletonComponent,
        MultiCasePipe,
    ],
    // XXX: check if we need provide ReportService here
    providers: [...pipeProviders, PeriodsService, PeriodsApiService, AccountService],
})
export class SharedModule {}
