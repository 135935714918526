import { INTERVAL_TYPE } from '@main/enums';

export enum EXPORT_FORMAT {
    WIDGET_TSV = 'WIDGET_TSV',
    WIDGET_EXCEL = 'WIDGET_EXCEL',
    WIDGET_IMAGE = 'WIDGET_IMAGE',
    GLOBAL_PDF = 'GLOBAL_PDF',
    GLOBAL_EXCEL = 'GLOBAL_EXCEL',
}

export interface ExportStatus {
    inProgress: boolean;
    currentExportId: string;
    downloadsCount: Record<INTERVAL_TYPE, number>;
    limits: {
        downloadsCount: Record<INTERVAL_TYPE, number>;
        periodMonthsCount: number;
    };
}
