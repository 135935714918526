import { Directive, Input } from '@angular/core';

import { UXTextControlActionPayload, UXElementType } from '../../models';
import { ANALYTICS_ELEMENT } from '../../data';

import { AnalyticsElementBaseDirective } from './analytics-element.base';

@Directive({
    selector: '[analyticsTextControl]',
    providers: [
        {
            provide: ANALYTICS_ELEMENT,
            useExisting: AnalyticsTextControlDirective,
        },
    ],
})
export class AnalyticsTextControlDirective extends AnalyticsElementBaseDirective<
    UXTextControlActionPayload,
    HTMLInputElement
> {
    elementType = UXElementType.TextControl;

    @Input() name = this.getDefaultName();

    protected getSpecificPayload(): Partial<UXTextControlActionPayload> {
        return { elementName: this.name };
    }

    private getDefaultName(): string {
        return this.host.name;
    }
}
