<common-export-button
    [options]="exportItems"
    [updateState$]="exportUpdateState$"
    [exportTypes]="exportTypes"
    [disabled]="disabled"
    [matTooltip]="disabled && disabledTooltip"
    buttonType="TEXT"
    buttonColor="primary"
    tooltip="Full page export"
    [handler]="exportHandler"
    restrictionActionConfigPath="toolbar.export"
    [analyticsData]="{ 'Export Type': 'Full-page', 'Export Name': 'Entire report' }"
>
</common-export-button>
