import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnalyticsWidgetComponent } from '@app/modules/analytics';
import { ANALYTICS_WIDGET } from '@app/modules/analytics/data';
import { TooltipData } from './widget-help-tooltip.types';
import { formatTooltip } from './widget-help-tooltip.helpers';
import { WidgetInfo } from '@app/shared/widgets/core/widgets.types';

@Component({
    selector: 'widget-help-tooltip',
    templateUrl: './widget-help-tooltip.component.html',
    styleUrls: ['./widget-help-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetHelpTooltipComponent implements OnChanges {
    @Input() widgetInfo: WidgetInfo<any>;
    @Input() data: TooltipData;
    @Input() text: string;

    tooltip$: Observable<string>;

    data$ = new BehaviorSubject<TooltipData>(null);
    text$ = new BehaviorSubject<string>(null);

    constructor(@Optional() @Inject(ANALYTICS_WIDGET) public analyticsWidget: AnalyticsWidgetComponent) {
        this.tooltip$ = combineLatest([this.data$, this.text$]).pipe(
            map(([data, text]) => text || (data && formatTooltip(this.widgetInfo, data))),
        );
    }

    ngOnChanges({ data, text }: SimpleChanges): void {
        if (data) {
            this.data$.next(data.currentValue);
        }

        if (text) {
            this.text$.next(text.currentValue);
        }
    }
}
