import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Country, Period } from '@shared/models';
import { RESTRICTION_ACTION } from '@shared/enums';
import { ICountry, IPeriod } from '@shared/interfaces';
import { ReportHelpers } from '@main/core/report.helpers';
import { Channel, Device } from '@main/models';
import { IChannel } from '@main/interfaces';
import { AppConfig } from '@app/app.config';
import { FiltersValue } from './filters.types';
import { PersistedSettingsService, SETTINGS } from '@shared/services';
import { TaxonomyTopic } from '@app/app-main/models/taxonomy';

@Injectable()
export class FiltersService {
    channels$ = new BehaviorSubject<Channel[]>(null);
    devices$ = new BehaviorSubject<Device[]>(null);
    countries$ = new BehaviorSubject<Country[]>(null);
    periods$ = new BehaviorSubject<Period[]>(null);
    taxonomyTopics$ = new BehaviorSubject<TaxonomyTopic[]>(null);

    channel$ = new BehaviorSubject<Channel>(null);
    device$ = new BehaviorSubject<Device>(null);
    country$ = new BehaviorSubject<Country>(null);
    period$ = new BehaviorSubject<Period>(null);
    taxonomyTopic$ = new BehaviorSubject<TaxonomyTopic>(null);

    countries = toSignal(this.countries$);
    periods = toSignal(this.periods$);

    value$: Observable<FiltersValue>;
    ready$: Observable<boolean>;

    value: Signal<FiltersValue>;

    private state$ = combineLatest([
        this.channel$,
        this.device$,
        this.country$,
        this.period$,
        this.taxonomyTopic$.pipe(distinctUntilChanged((previous, current) => previous?.id === current?.id)),
    ]);

    constructor(private config: AppConfig, private persistedSettingsService: PersistedSettingsService) {
        this.value$ = this.state$.pipe(
            filter(
                ([channel, device, country, period]) =>
                    Boolean(channel) && Boolean(device) && Boolean(country) && Boolean(period),
            ),
            map(([channel, device, country, period, taxonomyTopic]) => ({
                channel,
                device,
                country,
                period,
                taxonomyTopic,
            })),
        );

        this.ready$ = combineLatest([this.state$, this.taxonomyTopics$]).pipe(
            filter(([state, taxonomyTopics]) => Boolean(state) && Boolean(taxonomyTopics)),
            map(
                ([[channel, device, country, period], taxonomyTopics]) =>
                    Boolean(channel) &&
                    Boolean(device) &&
                    Boolean(country) &&
                    Boolean(period) &&
                    Boolean(taxonomyTopics),
            ),
        );

        this.value = toSignal(this.value$);
    }

    setChannelsList(items: IChannel[]): void {
        const restrictionConfig = this.config.appInfo.override.controlsRestrictionActions;
        const restrictionActions = restrictionConfig && restrictionConfig['toolbar.channels'];

        const channels = ReportHelpers.buildChannelsList({
            currentItems: items,
            noPermissionAction: restrictionActions?.noPermissionAction || RESTRICTION_ACTION.RESTRICT,
            noDataAction: restrictionActions?.noDataAction || RESTRICTION_ACTION.DISABLE,
        });

        this.channels$.next(channels);
    }

    setCountriesList(allItems: ICountry[], entityItems: ICountry[]): void {
        const restrictionConfig = this.config.appInfo.override.controlsRestrictionActions;
        const restrictionActions = restrictionConfig && restrictionConfig['toolbar.countries'];

        const countries = ReportHelpers.buildCountriesList({
            allItems,
            currentItems: entityItems,
            noPermissionAction: restrictionActions?.noPermissionAction || RESTRICTION_ACTION.RESTRICT,
            noDataAction: restrictionActions?.noDataAction || RESTRICTION_ACTION.DISABLE,
        });

        this.countries$.next(countries);
    }

    setPeriodsList(items: IPeriod[], maxMonthLimit?: number): void {
        const restrictionConfig = this.config.appInfo.override.controlsRestrictionActions;
        const restrictionActions = restrictionConfig && restrictionConfig['toolbar.periods'];

        const periods = ReportHelpers.buildPeriodsList({
            currentItems: items,
            noPermissionAction: restrictionActions?.noPermissionAction || RESTRICTION_ACTION.NO_ACTION,
            noDataAction: restrictionActions?.noDataAction || RESTRICTION_ACTION.DISABLE,
            maxMonthsLimitAction: RESTRICTION_ACTION.DISABLE,
            maxMonthLimit,
        });

        this.periods$.next(periods);
    }

    setDevicesList(items: Device[]): void {
        this.devices$.next(items);
    }

    setTaxonomyTopicsList(topics: TaxonomyTopic[]): void {
        this.taxonomyTopics$.next(topics);
    }

    setChannel(item: Channel, persist = true): void {
        this.channel$.next(item);

        if (persist) {
            this.persistedSettingsService.set(SETTINGS.ROOT_CHANNEL, item?.id);
        }
    }

    setDevice(item: Device, persist = true): void {
        this.device$.next(item);

        if (persist) {
            this.persistedSettingsService.set(SETTINGS.ROOT_DEVICE, item?.code);
        }
    }

    setCountry(item: Country, persist = true): void {
        this.country$.next(item);

        if (persist) {
            this.persistedSettingsService.set(SETTINGS.ROOT_COUNTRY, item?.id);
        }
    }

    setPeriod(item: Period, persist = true): void {
        this.period$.next(item);

        if (persist) {
            this.persistedSettingsService.set(SETTINGS.ROOT_PERIOD, item?.id);
        }
    }

    setTaxonomyTopic(item: TaxonomyTopic, persist = true): void {
        this.taxonomyTopic$.next(item);

        if (persist) {
            this.persistedSettingsService.set(SETTINGS.ROOT_TAXONOMY_ID, item?.id);
        }
    }
}
