import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import Highcharts from 'highcharts';

import { AdcChartOptions } from './chart.types';
import { ColumnChart } from './libs/column-chart';
import { GenericChart } from './libs/generic-chart';
import { LineChart } from './libs/line-chart';
import { PieChart } from './libs/pie-chart';

@Component({
    selector: 'adc-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnDestroy, OnChanges {
    @Input() options: AdcChartOptions = {} as AdcChartOptions;
    @Input() updateFlag: boolean;
    @Input() resetOnUpdate = false;

    @Output() ready = new EventEmitter<Highcharts.Chart>();

    constructor(private cdr: ChangeDetectorRef) {}

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    onCreateFn = this.onCreate.bind(this);

    adcChart: GenericChart;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && changes.options.currentValue) {
            this.initChart();

            this.updateFlag = true;
        }

        if (!this.adcChart) {
            return;
        }

        if ((changes.options || changes.updateFlag) && this.resetOnUpdate) {
            this.chartOptions = null;
        }

        setTimeout(() => {
            this.chartOptions = this.adcChart.buildHighchartOptions(this.options);

            this.updateFlag = true;

            this.cdr.detectChanges();
        });
    }

    ngOnDestroy(): void {
        if (this.adcChart) {
            this.adcChart.destroy();
        }
    }

    private onCreate(instance: Highcharts.Chart): void {
        this.adcChart.setChartInstance(instance);

        this.ready.emit(instance);
    }

    private initChart(): void {
        switch (this.options.type) {
            case 'line-chart':
                this.adcChart = new LineChart();
                break;

            case 'pie-chart':
                this.adcChart = new PieChart();
                break;

            case 'column-chart':
                this.adcChart = new ColumnChart();
                break;

            default:
                throw new Error('Unknown chart type');
        }

        this.chartOptions = this.adcChart.buildHighchartOptions(this.options);
    }
}
