import { CommonHelpers } from '@shared/helpers/common.helpers';
import { ExportStatus } from '@main/services/export.service.types';
import { ExportItem } from './common-export-button.types';
import { Period } from '@app/shared/models';
import { ICountLimit } from '@shared/interfaces/IUserFeatures';
import { INTERVAL_TYPE } from '@main/enums';

export class ExportHelpers {
    static applyExportItemStatusLimits(
        item: ExportItem,
        { downloadsCount, limits }: ExportStatus,
        period: Period,
    ): void {
        const downloadsCountLimit = limits.downloadsCount;
        const downloadsCountCurrent = downloadsCount;
        const downloadsCountLeft = ExportHelpers.getDownloadLimitsDiff(downloadsCountLimit, downloadsCountCurrent);

        const monthsCountLimit = limits.periodMonthsCount;
        const monthsCountCurrent = CommonHelpers.getPeriodMonthsCount(period);

        const downloadsCountLimitExceeded = Object.values(downloadsCountLeft).some((val) => val <= 0);
        const monthsCountLimitExceeded = monthsCountCurrent > monthsCountLimit;

        if (downloadsCountLimit) {
            const counters: string[] = [];

            if (downloadsCountLimit.DAILY) {
                counters.push(`${downloadsCountLeft.DAILY}/${downloadsCountLimit.DAILY} daily`);
            }

            if (downloadsCountLimit.MONTHLY) {
                counters.push(`${downloadsCountLeft.MONTHLY}/${downloadsCountLimit.MONTHLY} monthly`);
                item.limitsTooltip =
                    'Export pool refills gradually. An attempt replenishes automatically 30 days after you execute an export.';
            }

            item.limitsLabel = counters.join(' | ');
        }

        if (monthsCountLimit) {
            const isSingular = monthsCountLimit % 10 === 1;
            const message = `Export period is limited to ${monthsCountLimit} ${isSingular ? 'month' : 'months'}`;

            item.noticeText = monthsCountLimitExceeded ? `<span></span><p>${message}</p>` : message;
        }

        item.limited = Boolean(downloadsCountLimit) || Boolean(monthsCountLimit);
        item.limitExceeded = monthsCountLimitExceeded || downloadsCountLimitExceeded;
    }

    static getTooltip(item: ExportItem): string {
        if (item.notAvailable) {
            return `Not available due to exceeding view limit`;
        }

        if (!item.hasPermission) {
            return `Contact us to open "${item.option.title}" export ability`;
        }

        if (item.limitExceeded) {
            return 'Exceeded limit';
        }

        if (!item.ready) {
            return 'Page is loading';
        }

        if (item.inProgress) {
            return 'Export loading';
        }

        return item.tooltip;
    }

    static getDownloadLimitsDiff(total: ICountLimit = <ICountLimit>{}, current: ICountLimit): ICountLimit {
        return Object.keys(total).reduce(
            (acc: ICountLimit, interval: INTERVAL_TYPE) => ({
                ...acc,
                [interval]: Math.max(0, total[interval] - current[interval]),
            }),
            <ICountLimit>{},
        );
    }

    static formatRemoteDownloadError(response: any): string {
        const tokenErrors = {
            'concurrent.processing.error': 'Another export is being generated',
        };
        const token = response.error?.token;

        return tokenErrors[token] || response.error?.message;
    }
}
