import { Directive } from '@angular/core';

import { UXElementType } from '../../models';
import { ANALYTICS_ELEMENT } from '../../data';

import { AnalyticsElementWithTextBaseDirective } from '@app/modules/analytics/directives/elements/analytics-element-with-text.base';

@Directive({
    selector: '[analyticsButton]',
    providers: [
        {
            provide: ANALYTICS_ELEMENT,
            useExisting: AnalyticsButtonDirective,
        },
    ],
})
export class AnalyticsButtonDirective extends AnalyticsElementWithTextBaseDirective {
    elementType = UXElementType.Button;
}
