import { Component } from '@angular/core';

import { AppConfig } from 'app/app.config';
import { copyrightYear } from '@app/config';

interface FooterConfig {
    copyright: string;
    logo: string;
}

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class PageMainFooterComponent {
    footerConfig: FooterConfig;
    copyrightText: string;

    copyrightYear = copyrightYear;

    get showFooter(): boolean {
        return !!this.footerConfig && this.config.appInfo.showFooter;
    }

    constructor(private config: AppConfig) {
        this.footerConfig = this.config?.appInfo.footer;

        if (this.footerConfig) {
            this.copyrightText =
                this.footerConfig.copyright && this.footerConfig.copyright.replace('{year}', this.copyrightYear);
        }
    }
}
