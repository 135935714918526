<div class="content-container">
    <router-outlet></router-outlet>

    <div class="container px-40">
        <div class="divider"></div>

        <div class="footer py-16">
            <div></div>

            <button
                *ngIf="showFullReportBtn"
                mat-button
                type="button"
                color="accent"
                class="u-button h-32 font-12"
                (click)="onViewReportBtnClick()"
            >
                View full report
            </button>
        </div>
    </div>
</div>

<adblock-check></adblock-check>
<integration-userguiding></integration-userguiding>
