import { Directive, HostListener } from '@angular/core';

import { UXActionType } from '../../models';

import { AnalyticsActionBaseDirective } from './analytics-action.base.directive';

@Directive({
    selector: '[analyticsClick]',
})
export class AnalyticsClickDirective extends AnalyticsActionBaseDirective {
    @HostListener('click')
    protected handleEvent() {
        super.track(UXActionType.Click);
    }
}
