import { Directive, ElementRef, Inject, Input, Optional } from '@angular/core';

import { ANALYTICS_AREA } from '../../data';
import { UXActionPayload, UXActionType, UXArea, UXBaseActionPayload, UXElementType } from '../../models';
import { AnalyticsService } from '../../services';

import { AnalyticsAreaBase } from '../areas';
import { AnalyticsBusinessDirective } from '../analytics-business.directive';

@Directive()
export abstract class AnalyticsElementBaseDirective<T extends UXActionPayload, E extends HTMLElement = HTMLElement> {
    protected abstract elementType: UXElementType;

    @Input() areaType: UXArea = this.area?.type;
    @Input() areaName: string;

    protected host = this.elementElementRef.nativeElement;

    constructor(
        private elementElementRef: ElementRef<E>,
        private analyticsService: AnalyticsService,
        @Optional() @Inject(ANALYTICS_AREA) private area?: AnalyticsAreaBase,
        @Optional() private businessAnalyticsDirective?: AnalyticsBusinessDirective,
    ) {}

    track(actionType: UXActionType): void {
        const payload = this.getPayload(actionType);

        if (this.businessAnalyticsDirective) {
            this.businessAnalyticsDirective.track(payload);
            return;
        }

        this.analyticsService.trackUIAction(payload);
    }

    protected abstract getSpecificPayload(): Partial<T>;

    private getBasePayload(actionType: UXActionType): UXBaseActionPayload {
        const { elementType, areaType } = this;

        const areaName = this.areaName || this.area?.analyticsName;

        return {
            actionType,
            elementType,
            areaType,
            areaName,
        };
    }

    protected getPayload(actionType: UXActionType): T {
        return <T>{
            ...this.getBasePayload(actionType),
            ...this.getSpecificPayload(),
        };
    }
}
