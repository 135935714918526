import { Routes } from '@angular/router';

import { canActivateBrand } from '@app/core/guards/features/can-activate-brand.guard';
import { LoggedInAccessGuard } from '@app/core/guards/auth/logged-in-access.guard';
import { WinmoUnsubscribedPageComponent } from '@app/integrations/winmo/pages/unsubscribed/unsubscribed.component';
import { WIDGET } from '@main/enums';
import { WinmoNotFoundPageComponent } from './pages/not-found/not-found-page.component';
import { PageWinmoPreviewComponent } from './preview/preview.component';
import { ReportDataParams } from '@main/core/report.types';

const winmoReportParams: ReportDataParams = {
    showToolbars: false,
    showReportHeader: false,
    showFooter: false,
    persistFiltersValue: false,
    widgets: [
        WIDGET.BRAND_HEADER,
        WIDGET.ESTIMATED_EXPENDITURE,
        WIDGET.AD_PLACEMENT,
        WIDGET.AD_TYPES_DISTRIBUTION,
        WIDGET.TRANSACTION_METHODS,
        WIDGET.EXPENDITURE_TREND,
    ],
};

export const winmoPagesRoutes: Routes = [
    {
        path: 'winmo-not-found',
        component: WinmoNotFoundPageComponent,
    },
    {
        path: 'winmo-unsubscribed',
        component: WinmoUnsubscribedPageComponent,
    },
    {
        path: 'winmo-preview',
        component: PageWinmoPreviewComponent,
        canActivateChild: [LoggedInAccessGuard],
        children: [
            {
                path: 'advertiser/:id',
                loadChildren: () =>
                    import('@main/reports/advertisers/advertisers.module').then((m) => m.AdvertisersModule),
                data: winmoReportParams,
            },
            {
                path: 'brand/:id',
                loadChildren: () => import('@main/reports/brands/brands.module').then((m) => m.BrandsModule),
                canActivate: [canActivateBrand],
                data: winmoReportParams,
            },
        ],
    },
];
