<div class="box" fxLayout="row" fxLayoutAlign="center center">
    <img
        *ngIf="imageSrc"
        style="width: {{ matIconSize }}px; height: {{ matIconSize }}px;"
        alt="fav"
        [src]="imageSrc"
        (error)="onImageLoadError()"
    />

    <mat-icon
        *ngIf="svgIcon"
        [svgIcon]="svgIcon"
        [ngStyle]="matIconColor && { color: matIconColor }"
        class="s-{{ matIconSize }}"
    ></mat-icon>
</div>

<div *ngIf="removable" class="btn-remove" (click)="remove.emit(id)">
    <mat-icon class="s-10 secondary-text">close</mat-icon>
</div>
