import { chunk, isInteger } from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bigNumber' })
export class BigNumberPipe implements PipeTransform {
    private readonly THOUSAND_DIGITS_COUNT = 3;

    transform(value: number): string {
        if (value <= 0 || !isInteger(value)) return null;

        const reversedNumbers = value.toString().split('').reverse();

        return chunk(reversedNumbers, this.THOUSAND_DIGITS_COUNT)
            .map((digits) => digits.reverse().join(''))
            .reverse()
            .join(',');
    }
}
