import { disabledDuplicateWidgetTooltip } from '@main/dashboards/configs/tooltips';
import { MenuSchema } from '@main/dashboards/interfaces';

export class Helpers {
    static generateItemMenuSchema(canDelete = true, canDuplicate = true): MenuSchema[] {
        return [
            {
                name: 'Edit',
                icon: 'edit',
                disabled: false,
                action: 'EDIT',
            },
            {
                name: 'Duplicate',
                icon: 'duplicate',
                disabled: !canDuplicate,
                action: 'DUPLICATE',
                tooltip: canDuplicate ? 'Duplicate' : disabledDuplicateWidgetTooltip,
            },
            {
                name: 'Delete',
                icon: 'trash',
                disabled: !canDelete,
                action: 'DELETE',
            },
        ];
    }
}
