import { TemplateRef, ViewContainerRef } from '@angular/core';

export class FullscreenViewExportService {
    private container: ViewContainerRef;

    setContainer(container: ViewContainerRef): void {
        this.container = container;
    }

    showWidgetExport(template: TemplateRef<unknown>): void {
        if (this.container) {
            this.container.clear();
            this.container.createEmbeddedView(template);
        }
    }
}
