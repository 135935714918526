import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { DialogAbBlockEnabledPopupComponent } from '../dialog/dialog-adblock-enabled-popup.component';
import { AdblockCheckHelpers } from '../helpers/adblock-check.helpers';
import { AdblockCheckService } from '../services/adblock-check.service';

@Component({
    selector: 'adblock-check',
    template: '',
})
export class AdBlockCheckComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject<void>();

    constructor(private matDialog: MatDialog, private service: AdblockCheckService) {}

    ngOnInit(): void {
        if (AdblockCheckHelpers.popupWasShown()) {
            return;
        }

        this.service
            .isAdBlockDetected()
            .pipe(delay(1000), takeUntil(this._unsubscribeAll))
            .subscribe((detected) => {
                if (detected) {
                    this.showDialog();
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    showDialog(): void {
        const dialogRef = this.matDialog.open(DialogAbBlockEnabledPopupComponent, {
            panelClass: 'dialog-adblock-enabled-popup',
            width: '357px',
            position: {
                top: '90px',
                right: '90px',
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            AdblockCheckHelpers.popupSetShown();
        });
    }
}
