import { WidgetInfo } from '@shared/widgets/core/widgets.types';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { TooltipData } from './widget-help-tooltip.types';
import { BUSINESS_ENTITY } from '@app/app-main/enums';

export function formatTooltip(widgetInfo: WidgetInfo<any>, data: TooltipData): string {
    const {
        entityType,
        filters: { channel },
        comparison,
    } = widgetInfo.data;
    const contrEntityType: BUSINESS_ENTITY = widgetInfo.config?.contrEntityType;

    const reportTypeTooltips =
        data && ((comparison && data.COMPARISON && data.COMPARISON[entityType]) || data[entityType] || data.DEFAULT);

    if (!reportTypeTooltips) {
        return '';
    }

    let str: string = reportTypeTooltips[channel.type] || reportTypeTooltips.CHANNEL_DEFAULT || reportTypeTooltips;

    if (!str) {
        return '';
    }

    const entityInfo = EntityHelpers.getEntityInfo(entityType);
    const contrEntityInfo = EntityHelpers.getEntityInfo(contrEntityType);

    const fillValues: Record<string, string> = {
        channelName: channel.title.toLowerCase(),
        channelNameUpper: channel.title,
        commonTpl: data.commonTpl,
    };

    if (entityInfo) {
        fillValues.entityName = entityInfo.text.one;
        fillValues.entityNameUpper = entityInfo.text.oneUpper;
        fillValues.entityNames = entityInfo.text.few;
        fillValues.entityNamesUpper = entityInfo.text.fewUpper;

        fillValues.contrEntityName = contrEntityInfo?.text.one;
        fillValues.contrEntityNameUpper = contrEntityInfo?.text.oneUpper;
        fillValues.contrEntityNames = contrEntityInfo?.text.few;
        fillValues.contrEntityNamesUpper = contrEntityInfo?.text.fewUpper;
    }

    Object.entries(fillValues).forEach(([key, value]) => {
        const r = new RegExp('{' + key + '}', 'g');

        str = typeof str === 'string' && str.replace(r, value);
    });

    return str;
}
