import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Params, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { filter, pairwise } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { clone, isNil, omitBy } from 'lodash';

import { productAdIntelligencePath } from '@app/config';
import { WINDOW } from '@shared/services';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { BUSINESS_ENTITY } from '../enums';
import { IEntityItemId } from '../types';
import { RouteIdsItem } from '../types/types';
import { EntityGroup, EntityItem } from '../models';
import { FullscreenNavigationParams } from '@main/shared/widget-full-screen-view';

export type NavigationOptions = Partial<{
    compare: boolean;
    forceReload: boolean;
    saveParams: boolean;
    queryParams: Params;
    fullPath: boolean;
    baseUrl: string;
    extractGroups: boolean;
}>;

export type NavigationParams = Partial<{
    path: string;
    queryParams: Params;
}>;

@Injectable()
export class NavigationService {
    prevRoute = new BehaviorSubject<NavigationEnd>(null);

    constructor(@Inject(WINDOW) private window: Window, private router: Router) {
        this.initPrevRouteCheck();
    }

    navigateToEntities(entities: EntityItem[], navigationOptions: NavigationOptions = {}): void {
        const { saveParams } = navigationOptions;

        const { path, queryParams } = this.getEntitiesNavigationParams(entities, navigationOptions);

        this.routeToPath(path, queryParams, saveParams);
    }

    navigateToEntityHomePage(entityType: BUSINESS_ENTITY): void {
        const entityInfo = EntityHelpers.getEntityInfo(entityType);
        const entityPath = entityInfo.url.common;

        const link = [productAdIntelligencePath, entityPath].filter(Boolean).join('/');

        this.routeToPath(link, {}, false);
    }

    navigateToEntityEmptyPage(entityType: BUSINESS_ENTITY): void {
        const entityInfo = EntityHelpers.getEntityInfo(entityType);
        const entityPath = entityInfo.url.empty;

        const link = [productAdIntelligencePath, entityPath].filter(Boolean).join('/');

        this.routeToPath(link, {}, false);
    }

    navigateToEntitiesIds(
        entityType: BUSINESS_ENTITY,
        ids: IEntityItemId[] | RouteIdsItem[],
        navigationOptions: NavigationOptions = {},
    ): void {
        const { saveParams } = navigationOptions;

        const { path, queryParams } = this.getEntitiesIdsNavigationParams(entityType, ids, navigationOptions);

        this.routeToPath(path, queryParams, saveParams);
    }

    getQueryParam<T extends keyof FullscreenNavigationParams>(param: T): FullscreenNavigationParams[T] {
        const rawValue = this.router.parseUrl(this.router.routerState.snapshot.url)?.queryParams[param];

        if (!rawValue) return null;

        try {
            return JSON.parse(rawValue);
        } catch (error) {
            return rawValue;
        }
    }

    goBack(n = 1) {
        this.window.history.go(-n);
    }

    getEntitiesNavigationParams(entities: EntityItem[], navigationOptions: NavigationOptions = {}): NavigationParams {
        let { compare, extractGroups } = navigationOptions;

        let entityItems = entities;
        let entitiesType = entities[0].entityType;
        let categoryBenchmark = false;
        let categoryBenchmarkId = null;

        if (EntityHelpers.isItemsSingleGroup(entityItems)) {
            const group = entities[0] as EntityGroup;

            compare = group.isComparison();
            categoryBenchmark = group.isCategoryBenchmarkComparison();

            if (group.isVirtual()) {
                extractGroups = true;
            }

            if (extractGroups) {
                entityItems = EntityHelpers.unwrapSingleGroup(entityItems);
            }

            if (categoryBenchmark && extractGroups) {
                categoryBenchmarkId = EntityHelpers.filterEntitiesByEntityType(group.items, BUSINESS_ENTITY.Category)[0]
                    .id;
            }
        }

        if (entityItems.length > 1 && EntityHelpers.entitiesHasGroup(entityItems)) {
            compare = true;
        }

        const advertisersEntities = EntityHelpers.filterEntitiesByEntityType(entities, BUSINESS_ENTITY.Advertiser);
        const categoriesEntities = EntityHelpers.filterEntitiesByEntityType(entities, BUSINESS_ENTITY.Category);

        if (advertisersEntities.length && categoriesEntities.length && compare) {
            categoryBenchmarkId = categoriesEntities[0].id;
            categoryBenchmark = true;
        }

        if (categoryBenchmark) {
            entitiesType = BUSINESS_ENTITY.Advertiser;
        }

        const ids = this.serializeEntitiesRoutingIds(entityItems, { extractGroups, categoryBenchmark });

        const params = this.getEntitiesIdsNavigationParams(entitiesType, [ids], { ...navigationOptions, compare });

        params.queryParams.category = categoryBenchmark ? categoryBenchmarkId : null;

        return params;
    }

    removeQueryParam(paramName: string, replaceState = false): void {
        const url = new URL(window.location.href);
        const queryParams = new URLSearchParams(url.search);

        queryParams.delete(paramName);

        const newParams = queryParams.toString();
        const newUrl = url.pathname + (newParams ? `?${newParams}` : '');

        if (replaceState) {
            this.window.history.replaceState({}, '', newUrl);
        } else {
            this.window.history.pushState({}, '', newUrl);
        }
    }

    addQueryParams(params: Record<string, string | number>, replaceState = false): void {
        const url = new URL(window.location.href);
        const queryParams = new URLSearchParams(url.search);

        Object.entries(params).forEach(([k, v]) => queryParams.set(k, `${v}`));

        const newParams = queryParams.toString();
        const newUrl = url.pathname + (newParams ? `?${newParams}` : '');

        if (replaceState) {
            this.window.history.replaceState({}, '', newUrl);
        } else {
            this.window.history.pushState({}, '', newUrl);
        }
    }

    static isFullScreenUrl(url: string) {
        return /widget=/.test(url);
    }

    getEntitiesIdsNavigationParams(
        entityType: BUSINESS_ENTITY,
        ids: IEntityItemId[] | RouteIdsItem[],
        { compare, forceReload, saveParams, queryParams, fullPath, baseUrl }: NavigationOptions = {},
    ): NavigationParams {
        const navQueryParams = queryParams ? clone(queryParams) : {};
        const entityInfo = EntityHelpers.getEntityInfo(entityType);
        const entityPath = compare ? entityInfo.url.compare : entityInfo.url.common;
        const baseUrlPath = (fullPath && new URL(window.location.href).origin) || baseUrl || null;

        const link = [baseUrlPath, productAdIntelligencePath, entityPath, ids].filter(Boolean).join('/');

        const currentUrl = new URL(window.location.href);
        const isSamePath = currentUrl.pathname.slice(1) === link;
        const hashParams = saveParams ? currentUrl.hash : '';

        // force reload options
        navQueryParams.refresh = isSamePath && forceReload ? new Date().getMilliseconds() : null;

        return {
            path: link + hashParams,
            queryParams: navQueryParams,
        };
    }

    serializeNavigationParamsToUrl({ path, queryParams }: NavigationParams, omitNullQueryParams = true): string {
        const params = omitNullQueryParams ? omitBy(queryParams, isNil) : queryParams;
        const httpParams = new HttpParams({ fromObject: params }).toString();

        return path + (httpParams ? '?' + httpParams : '');
    }

    serializeEntitiesRoutingIds(
        entities: EntityItem[],
        { extractGroups = false, virtualGroupParentheses = false, categoryBenchmark = false } = {},
    ): string {
        return entities
            .filter((item) => !categoryBenchmark || item.entityType !== BUSINESS_ENTITY.Category)
            .map((item) => {
                if (item.isGroup()) {
                    if ((item as EntityGroup).isVirtual() || extractGroups) {
                        const nested = this.serializeEntitiesRoutingIds((item as EntityGroup).items, {
                            extractGroups,
                            categoryBenchmark,
                            virtualGroupParentheses: true,
                        });

                        const title = item[Symbol.for('isRecent')] ? null : item.title;
                        const formatted = (title ? title + ':' : '') + nested;

                        return virtualGroupParentheses || entities.length > 1 ? `(${formatted})` : formatted;
                    }

                    return item.id;
                }

                return item.id;
            })
            .join(',');
    }

    private routeToPath(path: string, queryParams: Params, saveParams: boolean): void {
        const navExtras: NavigationExtras = {
            queryParamsHandling: saveParams ? 'merge' : '',
            queryParams,
        };

        this.router.navigate([path], navExtras);
    }

    private initPrevRouteCheck(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                pairwise(),
            )
            .subscribe(([prev, _]: [NavigationEnd, NavigationEnd]) => {
                this.prevRoute.next(prev);
            });
    }
}
