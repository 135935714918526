export enum FEATURE_NAME_ENUM {
    EXPORT_WIDGET_TSV = 'WIDGET_TSV_EXPORT',
    EXPORT_WIDGET_EXCEL = 'WIDGET_EXCEL_EXPORT',
    EXPORT_WIDGET_IMAGE = 'WIDGET_IMAGE_EXPORT',
    EXPORT_CREATIVES = 'CREATIVES_EXPORT',
    EXPORT_REPORT_PDF = 'REPORT_PDF_EXPORT',
    EXPORT_REPORT_EXCEL = 'REPORT_EXCEL_EXPORT',
    EXPORT_SHOW_LIMITS = 'SHOW_EXPORT_LIMITATION',

    PRODUCT_DIGITAL_AD_INTELLIGENCE = 'PRODUCT_DIGITAL_AD_INTELLIGENCE',
    PRODUCT_BRAND_REPUTATION = 'EDITORIAL_DASHBOARD',
}
