import { isNil } from 'lodash';
import { LOCAL_STORAGE_KEY } from '@app/core';

export class SessionStorageHelper {
    static get<T>(key: LOCAL_STORAGE_KEY | string, defaultValue: T = null): T {
        let rawValue: string;

        try {
            rawValue = sessionStorage.getItem(key);
        } catch (err) {
            console.error(`Can't access localStorage`);
        }

        if (isNil(rawValue)) return defaultValue;

        let parsedValue;

        try {
            parsedValue = JSON.parse(rawValue);
        } catch (err) {
            parsedValue = null;
        }

        return parsedValue ?? defaultValue;
    }

    static set(key: LOCAL_STORAGE_KEY | string, value: unknown): void {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            console.error(`Can't access sessionStorage`);
        }
    }

    static remove(key: LOCAL_STORAGE_KEY | string): void {
        try {
            sessionStorage.removeItem(key);
        } catch (err) {
            console.error(`Can't access sessionStorage`);
        }
    }
}
