import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { BUSINESS_ENTITY } from '@main/enums';
import { RouteIdsItem } from '../../../types/types';
import { EntityService } from '../../../services/entity.service';
import { EntityCommonService } from '../../../services/entity-common.service';

@Injectable()
export class AdvertisersPublishersEntityService extends EntityService {
    protected readonly entityCommonService = inject(EntityCommonService);

    resolveEntitiesByIds<T>(ids: RouteIdsItem[], entityType: BUSINESS_ENTITY): Observable<T[]> {
        return this.entityCommonService.resolveEntitiesByIds(ids, entityType);
    }
}
