export class ScrollToHelpers {
    static scrollToElement(targetEl: string | HTMLElement, boxEl: string | HTMLElement, duration = 1): void {
        const targetCt = typeof targetEl === 'object' ? targetEl : (document.querySelector(targetEl) as HTMLElement);

        if (!boxEl) {
            targetCt.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        const boxCt = typeof boxEl === 'object' ? boxEl : document.querySelector(boxEl);
        const posY = this.getRelativeOffsetY(boxCt, targetCt);

        this.scrollTo(boxCt, posY, duration);
    }

    static getRelativeOffsetY(boxCt, targetCt): number {
        const boxPos = boxCt.getBoundingClientRect();
        const targetPos = targetCt.getBoundingClientRect();

        return targetPos.top - boxPos.top + targetCt.parentNode.scrollTop;
    }

    private static scrollTo(boxCt, targetY, duration): void {
        const self = this;
        const start = boxCt.scrollTop;
        const change = targetY - start;
        const startTime = performance.now();

        const animateScroll = (): void => {
            const now = performance.now();
            const elapsed = (now - startTime) / 1000;
            const t = elapsed / duration;

            boxCt.scrollTop = start + change * self.easeInOutQuad(t);

            if (t < 1) {
                window.requestAnimationFrame(animateScroll);
            }
        };

        animateScroll();
    }

    private static easeInOutQuad(t): number {
        return t < 0.5 ? 2 * t * t : (4 - 2 * t) * t - 1;
    }
}
