import { Constants } from 'app/util/constants';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe extends DatePipe implements PipeTransform {
    transform(value: string, format?: string): any {
        return super.transform(value, format || Constants.DATE_FMT);
    }
}
