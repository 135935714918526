import { ModuleWithProviders, NgModule } from '@angular/core';

import { DisplayForFeatureDirective } from './directives';
import { FeatureToggleService } from './services';
import { FeatureToggleModuleOptions } from './feature-toggle.model';
import { ENABLED_BY_DEFAULT_FEATURES } from './feature-toggle.providers';

@NgModule({
    declarations: [DisplayForFeatureDirective],
    exports: [DisplayForFeatureDirective],
})
export class FeatureToggleModule {
    static forRoot(options?: FeatureToggleModuleOptions): ModuleWithProviders<FeatureToggleModule> {
        return {
            ngModule: FeatureToggleModule,
            providers: [
                {
                    provide: ENABLED_BY_DEFAULT_FEATURES,
                    useValue: options?.enabledByDefaultFeatures || [],
                },
                FeatureToggleService,
            ],
        };
    }

    static forChild(): ModuleWithProviders<FeatureToggleModule> {
        return { ngModule: FeatureToggleModule };
    }
}
