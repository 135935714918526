<app-base-page title="Recover password" icon="mail">
    <p class="mb-15">
        A recovery email was sent to <strong>{{ email }}</strong>
    </p>

    <p class="mb-30">Check your inbox and click on the “Reset your password” link to set your new password.</p>

    <div class="actions">
        <button
            routerLink="/login"
            mat-raised-button
            type="submit"
            color="accent"
            class="submit-button u-button h-38 block"
        >
            Back to login page
        </button>
    </div>
</app-base-page>
