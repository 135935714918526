const shownStorageKey = 'ADBLOCK_POPUP_SHOWN';

export class AdblockCheckHelpers {
    static popupWasShown(): boolean {
        try {
            return localStorage.getItem(shownStorageKey) === '1';
        } catch (err) {
            console.error(`Can't acess localStorage`);
            return false;
        }
    }

    static popupSetShown(): void {
        try {
            localStorage.setItem(shownStorageKey, '1');
        } catch (err) {
            console.error(`Can't acess localStorage`);
        }
    }
}
