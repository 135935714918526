import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IReportBrandsSettings, IReportComparisonSettings } from '@shared/interfaces/IReportSettings';
import { ReportHelpers } from '@main/core/report.helpers';
import { WIDGET_DIMENSION } from '@main/widgets/shared/widgets-dimensions.types';
import { REPORT_CONFIG } from '../const/report-config';
import { ReportConfig, ReportConfigComparisonSettings } from '../interfaces/ReportConfig';

// ***********************************************************************************************
// * Comparison controls are deprecated at redesign stage, similar functionality may be used in  *
// * the future.                                                                                 *
// * Using default settings                                                                      *
// ***********************************************************************************************

@Injectable()
export class ReportSettingsService {
    comparison = {
        dimensions$: new BehaviorSubject<WIDGET_DIMENSION[]>([]),
        showTop5$: new BehaviorSubject<boolean>(false),
    };

    brands = {
        showSubBrands$: new BehaviorSubject<boolean>(null),
    };

    comparison$: Observable<IReportComparisonSettings> = combineLatest([
        this.comparison.dimensions$,
        this.comparison.showTop5$,
    ]).pipe(map(([dimensions, showTop5]) => ({ dimensions, showTop5 })));

    constructor(@Inject(REPORT_CONFIG) private reportConfig: ReportConfig) {}

    setDefaults(dimensions?: WIDGET_DIMENSION[]): void {
        const comparisonDimensions = dimensions || this.reportConfig.settings?.comparison.dimensions;
        this.setComparisonSettings(ReportHelpers.getDefaultComparisonSettings(comparisonDimensions));
    }

    setBrandsSettings(settings: IReportBrandsSettings): void {
        this.brands.showSubBrands$.next(settings.showSubBrands);
    }

    private setComparisonSettings(settings: ReportConfigComparisonSettings): void {
        this.comparison.dimensions$.next(settings.dimensions);
    }
}
