type ValidationMessage = string | ((errors: Record<string, unknown>) => string);

export const validationMessages: Record<string, Record<string, ValidationMessage>> = {
    password: {
        required: 'Password is required',
        strong: 'Minimum 8 characters, at least 1 lower-case letter, upper-case letter and number',
    },
    email: {
        email: 'Incorrect email format',
        required: 'Email is required',
    },
    confirmPassword: {
        required: 'Confirm password is required',
        match: 'New password and confirmed password do not match',
    },
};
