import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from '../../directives/perfect-scrollbar.directive';
import { Country } from '@shared/models';

@Component({
    selector: 'country-selector',
    templateUrl: './country-selector.component.html',
    styleUrls: ['./country-selector.component.scss'],
})
export class CountrySelectorComponent {
    @ViewChild(PerfectScrollbarDirective, { static: false }) scrollBar: PerfectScrollbarDirective;

    @Input() value: Country;
    @Input() countries: Country[];
    @Input() disabled = false;
    @Input() disabledTooltip: string;

    @Output() select = new EventEmitter<Country>();

    searchValue = '';

    shownCountries: Country[] = [];

    get isDisabled(): boolean {
        return this.value?.disabled || this.disabled;
    }

    onOpen(): void {
        this.shownCountries = this.countries;

        this.searchValue = ' ';

        setTimeout(() => {
            this.searchValue = '';
        });
    }

    formatValue(item: Country): string {
        return item.code === 'WORLDWIDE' ? item.title : item.code.toUpperCase();
    }

    onSearchChange(value: string): void {
        this.filterSearchCountries(value);
        this.resetScroll();
    }

    _select(event, country): void {
        if (this.isCountrySelectable(country)) {
            this.value = country;
            this.select.emit(country);
        } else {
            event.stopPropagation();
        }
    }

    flagCodeByCountry(country: Country): string {
        return country.code.toLowerCase();
    }

    private filterSearchCountries(str: string): void {
        if (str.length) {
            this.shownCountries = this.countries.filter((item) => item.title.toLowerCase().includes(str.toLowerCase()));
        } else {
            this.shownCountries = this.countries;
        }
    }

    private isCountrySelectable(item): boolean {
        return !item.disabled && !item.restricted;
    }

    private resetScroll(): void {
        setTimeout(() => {
            this.scrollBar.update();
        });
    }
}
