import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is designed to be used in conjunction with `*ngSwitchCase` to check
 * if the value is included in the cases array.
 */
@Pipe({ name: 'multiCase', standalone: true })
export class MultiCasePipe implements PipeTransform {
    transform(value: any, cases: any[]): boolean {
        if (!value || !cases || !cases.length) {
            return false;
        }

        return cases.includes(value);
    }
}
