import { Subscription } from 'rxjs';

import { OptionItem } from '@shared/components/common-select-control/common-select-control.types';
import { EntityItem } from '@main/models';
import { BUSINESS_ENTITY } from '@main/enums';
import { CreativesItem } from './creatives.model';

export interface WidgetConfig extends Record<string, unknown> {
    showAdvertiser: boolean;
    showAdvertiserIfFewEntities: boolean;
    showPublisherTypeFilter: boolean;
    showEntitiesFilter: boolean;
    showViewModeSwitch: boolean;

    singleCreativeCampaignLinkShow: boolean;
    singleCreativeTopItemsEntityType: BUSINESS_ENTITY;
    singleCreativeTopItemsShow: boolean;

    pageSize: number;
    lazyLoadingMode: boolean;
}

export enum SORT_BY_TYPE {
    NEWEST = 'NEWEST',
    IMPRESSIONS = 'IMPRESSIONS',
    EXPENSES = 'EXPENSES',
    DURATION = 'DURATION',
}

export type SortByItem = OptionItem;

export enum VIEW_MODE {
    ALL = 'ALL',
    SPLITTED = 'SPLITTED',
}

export interface SplittedCreativesItem {
    request: Subscription;
    entity: EntityItem;
    items: CreativesItem[];
    total: number;
    loading: boolean;
    showSeparatorAfter: boolean;
}

export enum EXPORT_TYPES {
    WIDGET_ZIP_TSV = 'WIDGET_ZIP_TSV',
    WIDGET_ZIP_EXCEL = 'WIDGET_ZIP_EXCEL',
    WIDGET_EXCEL = 'WIDGET_EXCEL',
    WIDGET_IMAGE = 'WIDGET_IMAGE',
}
