import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { AdcError } from '@shared/models/error.model';
import { AuthService } from '@shared/services';

@UntilDestroy()
@Component({
    selector: 'login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['../../pages.scss'],
})
export class LoginPageComponent {
    form = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        password: [null, Validators.required],
        rememberMe: [true],
    });

    loading: boolean;
    loginError: AdcError;

    constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService) {}

    login(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        const { email: username, password, rememberMe } = this.form.value;

        this.authService
            .loginByCredentials({ username, password, rememberMe })
            .pipe(
                untilDestroyed(this),
                finalize(() => (this.loading = false)),
            )
            .subscribe(
                () => (this.loginError = null),
                (error: AdcError) => (this.loginError = error),
            );
    }
}
