import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '../enums';
import { EntityItem } from '../models';
import { IEntityItemGroupId, IEntityItemInstanceId } from '../types';

export interface ISubscription {
    id: string;
    type: ENTITY_ITEM_TYPE;
    dataPoint: BUSINESS_ENTITY;
    countryId: number;
    instances?: IEntityItemInstanceId[];
    favoriteId?: IEntityItemGroupId;
}

export interface ISubscriptionsSettings {
    frequencies: SUBSCRIPTION_PERIOD[];
}

export enum SUBSCRIPTION_PERIOD {
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
}

export interface Subscription {
    id: string;
    countryId: number;
    entity: EntityItem;
}

export interface NotificationPeriodItem {
    id: SUBSCRIPTION_PERIOD;
    title: string;
}
