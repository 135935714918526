import { NgModule } from '@angular/core';

import { IntegrationUserGuidingComponent } from './userguding.component';
import { UserGuidingService } from './userguiding.service';

@NgModule({
    declarations: [IntegrationUserGuidingComponent],
    exports: [IntegrationUserGuidingComponent],
    providers: [UserGuidingService],
})
export class IntegrationUserGuidingModule {}
