<top-notification-bar class="export-hide"></top-notification-bar>
<div class="container">
    <div class="left">
        <div class="content">
            <div *ngIf="icon" class="icon">
                <mat-icon>{{ icon }}</mat-icon>
            </div>

            <h2 class="title">{{ title }}</h2>

            <ng-content></ng-content>
        </div>
    </div>
    <div class="right">
        <img [width]="240" [src]="logoIconUrl" alt="Logo" />
    </div>
</div>
