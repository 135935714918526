<ng-container *ngIf="addable">
    <div class="add-button" [matTooltip]="addTooltip" (click)="add.emit(item)">
        <mat-icon class="add-icon s-6" svgIcon="plusShape"></mat-icon>
    </div>
</ng-container>

<ng-container *ngIf="!isGroup">
    <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="box"
        [ngClass]="{ clickable: clickable, 'animate-border': isAnimating }"
        [matTooltip]="categoryTooltip"
        (click)="onClick($event)"
    >
        <fav-icon [src]="iconUrl" [ngClass]="iconCls" [useAsSvg]="true" [matIconColor]="iconColor" class="icon16-box30">
        </fav-icon>
        <span [innerHtml]="title" class="title"></span>
    </div>
</ng-container>

<ng-container *ngIf="isGroup">
    <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="box"
        [ngClass]="{ clickable: clickable, 'animate-border': isAnimating }"
        [matTooltip]="tooltip"
        (click)="onClick($event)"
    >
        <span [innerHtml]="title" class="title"></span>
        <span class="mr-8">({{ item.items.length }})</span>

        <fav-icon
            *ngFor="let item of shownGroupItems"
            [src]="item.icon"
            [ngClass]="iconCls"
            [matIconColor]="iconColor"
            [useAsSvg]="true"
            [matTooltip]="getGroupItemTooltip(item)"
            matTooltipClass="tooltip-multiline"
            class="icon16-box26 in-group"
        ></fav-icon>

        <span
            *ngIf="otherGroupItems.length > 0"
            class="group-other-item-counter"
            [matTooltip]="otherGroupItemsTooltip"
            matTooltipClass="tooltip-multiline"
        >
            +{{ otherGroupItems.length }}
        </span>
    </div>
</ng-container>

<div *ngIf="removable" class="btn-remove" [matTooltip]="removeTooltip" (click)="remove.emit(item)">
    <mat-icon class="s-6" svgIcon="close"></mat-icon>
</div>
