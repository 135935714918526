<div fxLayout="row" fxLayoutAlign="start" class="box">
    <div
        *ngFor="let item of items"
        [ngClass]="{ selected: item.id === selected?.id }"
        class="item"
        (click)="onSelect(item)"
    >
        <span *ngIf="item.isCurrency">{{ item.title | adcCurrencyHeadline : (currency$ | async) }}</span>
        <span *ngIf="!item.isCurrency">{{ item.title }}</span>
    </div>
</div>
