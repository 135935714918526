import { EntityAdvertiser, EntityItem } from '@main/models';
import { AD_TYPE } from '@main/enums/ad_type';
import { CreativesResponseBaseElement } from './creatives.service.types';

export class CreativesItem {
    id: number;
    size: {
        height: number;
        width: number;
    };
    imageUrl: string;
    videoUrl: string;
    duration: number;

    type: {
        code: AD_TYPE;
        title: string;
    };
    placement: {
        code: string;
        title: string;
    };

    detectionRange: {
        first: string;
        last: string;
        duration: string;
    };

    totalImpressions: number;
    totalExpenses: number;

    advertiser: EntityItem;

    campaign: {
        id: number;
        title: string;
        landingPageUrl: string;
    };

    constructor(element: CreativesResponseBaseElement) {
        if (element) {
            const data = this.deserialize(element);

            Object.assign(this, data);
        }
    }

    isVideo(): boolean {
        return (
            [AD_TYPE.ANIMATED_BANNER, AD_TYPE.VIDEO, AD_TYPE.SOCIAL_VIDEO].includes(this.type.code) ||
            (this.type.code === AD_TYPE.SOCIAL_CAROUSEL && !!this.videoUrl)
        );
    }

    isImage(): boolean {
        return (
            [AD_TYPE.BANNER, AD_TYPE.SOCIAL_IMAGE, AD_TYPE.SOCIAL_PROMOTED_ACCOUNT].includes(this.type.code) ||
            (this.type.code === AD_TYPE.SOCIAL_CAROUSEL && !this.videoUrl)
        );
    }

    deserialize(item: CreativesResponseBaseElement): Partial<CreativesItem> {
        return {
            id: item.id,
            size: {
                height: item.height,
                width: item.width,
            },
            imageUrl: item.imageUrl,
            videoUrl: item.videoUrl,
            duration: item.duration,
            totalImpressions: item.impressions.total,
            type: {
                code: item.type.code,
                title: item.type.title,
            },
            detectionRange: {
                first: item.detectionRange.first,
                last: item.detectionRange.last,
                duration: item.detectionRange.duration,
            },
            totalExpenses: item.expenses.total,
        };
    }

    applyAdvertiser(item: EntityAdvertiser): void {
        this.advertiser = item;
    }

    applyCampaign(data): void {
        this.campaign = {
            id: data.id,
            title: data.title,
            landingPageUrl: data.landingPageUrl,
        };
    }

    applyPlacement(data): void {
        this.placement = data;
    }
}
