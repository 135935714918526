import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { AccountService } from '@shared/services/account.service';
import { FEATURE_NAME_ENUM } from '@shared/enums';

@Injectable({ providedIn: 'root' })
export class ProductGuard {
    constructor(private accountService: AccountService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.accountService.$features.pipe(
            map(() => {
                const { product } = route.data;

                if (!product) return false;

                const isIntelligenceAd = product === FEATURE_NAME_ENUM.PRODUCT_DIGITAL_AD_INTELLIGENCE;

                return isIntelligenceAd || this.accountService.hasPermission(product);
            }),
        );
    }
}
