import { Component, OnInit } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { copyrightYear } from '@app/config';
import { IAppInfo } from '@shared/interfaces';

@Component({
    selector: 'widget-export-as-image-footer',
    templateUrl: './widget-export-as-image-footer.component.html',
    styleUrls: ['./widget-export-as-image-footer.component.scss'],
})
export class WidgetExportAsImageFooterComponent implements OnInit {
    appInfo: IAppInfo;

    copyrightYear = copyrightYear;

    constructor(private config: AppConfig) {}

    ngOnInit(): void {
        this.appInfo = this.config.appInfo;
    }
}
