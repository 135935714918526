export enum PAGE_TYPE {
    ADVERTISERS = 'ADVERTISERS',
    PUBLISHERS = 'PUBLISHERS',
    ADVERTISERS_COMPARE = 'ADVERTISERS_COMPARE',
    PUBLISHERS_COMPARE = 'PUBLISHERS_COMPARE',
    CAMPAIGN = 'CAMPAIGN',
    START = 'START',
    KEYWORDS = 'KEYWORDS',
    CATEGORIES = 'CATEGORIES',
    CATEGORIES_COMPARE = 'CATEGORIES_COMPARE',
    BRANDS = 'BRANDS',
    BRANDS_COMPARE = 'BRANDS_COMPARE',
    DASHBOARDS = 'DASHBOARDS',
    SETTINGS_GENERAL = 'SETTINGS_GENERAL',
    SETTINGS_EMAIL_NOTIFICATIONS = 'SETTINGS_EMAIL_NOTIFICATIONS',
}
