import { BUSINESS_ENTITY } from '@main/enums';
import { SerializedFiltersParams } from '@main/helpers/entity.helpers.types';

type PredefinedEntities = {
    [key in BUSINESS_ENTITY]?: [string | number | string[], SerializedFiltersParams][];
};

export const defaultPredefinedFilter = compPref(90, 'ALL-ALL', 'us');

export const predefinedEntities: PredefinedEntities = {
    [BUSINESS_ENTITY.Category]: [
        [4031, compPref(90, 'ALL-ALL', 'uk')],
        [14626, compPref(90, 'SOCIAL-ALL', 'ca')],
        [13959, compPref(90, 'ALL-MOBILE', 'us')],
    ],
    [BUSINESS_ENTITY.Brand]: [
        [5, compPref(90, 'ALL-ALL', 'us')],
        [4096, compPref(360, 'DISPLAY-DESKTOP', 'us')],
        [525, compPref(30, 'SOCIAL-ALL', 'us')],
    ],
    [BUSINESS_ENTITY.Advertiser]: [
        [19428820, compPref(360, 'ALL-ALL', 'us')],
        [19431871, compPref(360, 'ALL-ALL', 'us')],
        [19435875, compPref(360, 'ALL-ALL', 'de')],
    ],
    [BUSINESS_ENTITY.Publisher]: [
        [2508680, compPref(360, 'SOCIAL-ALL', 'il')],
        [2945191, compPref(360, 'SOCIAL-ALL', 'us')],
        [204389, compPref(360, 'ALL-ALL', 'au')],
    ],
    [BUSINESS_ENTITY.Campaign]: [
        [1721937807, compPref('20220301-20221031', 'ALL-ALL', 'us')],
        [1641012046, compPref(90, 'ALL-ALL', 'us')],
        [103457411, compPref(30, 'SOCIAL-ALL', 'fr')],
    ],
    [BUSINESS_ENTITY.Keyword]: [
        [['iphone', 'case', 'airpods'], compPref(360, 'ALL-ALL', 'us')],
        [['music', 'spotify'], compPref(360, 'ALL-ALL', 'fr')],
        [['tshirt', 'elegant'], compPref(360, 'ALL-ALL', 'fr')],
    ],
};

/**
 * Compile filter preferences
 */
function compPref(period: string | number, channel: string, country: string): SerializedFiltersParams {
    return {
        period,
        channel,
        country,
    };
}
