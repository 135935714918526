import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'recover-password-page',
    templateUrl: './recover-password-page.component.html',
    styleUrls: ['../../pages.scss', './recover-password-page.component.scss'],
})
export class RecoverPasswordPageComponent implements OnInit {
    email: string;

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        const { email } = this.route.snapshot.queryParams;

        if (email) this.email = email;
        else this.redirectToLoginPage();
    }

    private redirectToLoginPage(): void {
        this.router.navigate(['login']);
    }
}
