import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '@main/enums';
import { EntityGroup, EntityItem } from '@main/models';
import { FormatHelpers } from '@main/helpers/format.helpers';

@Component({
    selector: 'default-entity-card',
    templateUrl: './default-entity-card.component.html',
    styleUrls: ['../entity-card.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultEntityCardComponent implements OnChanges, OnInit {
    @Input() item: EntityItem;
    @Input() addable = false;
    @Input() removable: boolean;
    @Input() showIcon = true;
    @Input() iconCls = '';
    @Input() clickable = false;
    @Input() animate = false;
    @Input() stripTitle = false;
    @Input() stripTitleCount = 30;
    @Input() addTooltip = '';
    @Input() removeTooltip = '';

    @Output() add = new EventEmitter<EntityItem>();
    @Output() click = new EventEmitter<EntityItem>();
    @Output() remove = new EventEmitter<EntityItem>();

    @HostBinding('class.addable') addableStyles: boolean;
    get isGroup(): boolean {
        return this.item.isGroup();
    }

    get isCategory(): boolean {
        return this.item.entityType === BUSINESS_ENTITY.Category;
    }

    get otherGroupItemsTooltip(): string {
        return this.otherGroupItems.map((v) => v.fullTitle).join('\n');
    }

    shownGroupItems: EntityItem[] = [];
    otherGroupItems: EntityItem[] = [];

    isAnimating = false;

    title = '';
    tooltip = '';

    ngOnInit() {
        this.addableStyles = this.addable;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.item) {
            if (this.isGroup) {
                this.cutGroupItemsList();
            }

            this.setTitle();
            this.setTooltips();
        }

        if (changes.animate) {
            this.doAnimate();
        }
    }

    onClick(event: Event): void {
        if (this.clickable) {
            event.stopPropagation();

            this.click.emit(this.item);
        }
    }

    getGroupItemTooltip(item: EntityItem) {
        if (item.type === ENTITY_ITEM_TYPE.GROUP) {
            return `Group: ${item.title}` + '\n\n' + (item as EntityGroup).getItemsTitles().join('\n');
        }

        return item.title;
    }

    private cutGroupItemsList(): void {
        const groupItems = (this.item as EntityGroup).items;
        const sortedItems = [].concat(
            groupItems.filter((v) => !!v.icon),
            groupItems.filter((v) => !v.icon),
        );

        this.shownGroupItems = sortedItems.slice(0, 3);
        this.otherGroupItems = sortedItems.slice(3);
    }

    private doAnimate(): void {
        this.isAnimating = true;
    }

    private setTitle(): void {
        const textTitle = FormatHelpers.formatFullTitle(this.item, false, false, true);
        const htmlTitle = FormatHelpers.formatFullTitle(this.item, true, false, true);

        this.title =
            this.stripTitle && textTitle.length > this.stripTitleCount
                ? textTitle.slice(0, this.stripTitleCount) + '...'
                : htmlTitle;
    }

    private setTooltips(): void {
        if (!this.isGroup) {
            this.tooltip = this.stripTitle && this.item.title.length <= this.stripTitleCount ? null : this.item.title;
        }

        if (!this.removeTooltip) {
            this.removeTooltip = `Remove ${this.item.title}`;
        }

        if (!this.addTooltip) {
            this.addTooltip = `Add ${this.item.title}`;
        }
    }
}
