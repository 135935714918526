import { Directive } from '@angular/core';

import { UXElementType } from '../../models';
import { ANALYTICS_ELEMENT } from '../../data';

import { AnalyticsElementWithTextBaseDirective } from './analytics-element-with-text.base';

@Directive({
    selector: '[analyticsLink]',
    providers: [
        {
            provide: ANALYTICS_ELEMENT,
            useExisting: AnalyticsLinkDirective,
        },
    ],
})
export class AnalyticsLinkDirective extends AnalyticsElementWithTextBaseDirective<HTMLLinkElement> {
    elementType = UXElementType.Link;
}
