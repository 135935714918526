import { partition } from 'lodash';

import { chartColorNames } from '@shared/colors';
import { CheckboxOption } from '@shared/components/checkbox-group/checkbox-group.types';

import { SystemColor } from '../models';
import { systemColors } from '../data/palette/colors.data';

const convertHexToRGBA = (hex: string, opacity = 100) => {
    const tempHex = hex.replace('#', '');

    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
};

export const getPalette = (color: SystemColor, shadesCount = 5): string[] => {
    const hexValue = systemColors[color];
    const opacityStep = Math.round(100 / shadesCount);

    const palette = Array.from({ length: shadesCount }).map((_, index) => {
        const opacity = 100 - index * opacityStep;

        return convertHexToRGBA(hexValue, opacity);
    });

    return palette;
};

export const getColors = (colors: SystemColor[]) => colors.map((color) => systemColors[color]);
export const getColorsPalette = (colors: SystemColor[], shadesCount = 5) =>
    colors.map((color) => getPalette(color, shadesCount));

export const updateLegendColors = (options: CheckboxOption[], checkedOptions: CheckboxOption[]) => {
    const [checkedLegendOptions, uncheckedLegendOptions] = partition(options, (option) =>
        checkedOptions.find(({ id }) => id === option.id),
    );

    checkedLegendOptions.forEach((option, i) => (option.color = chartColorNames[i]));
    uncheckedLegendOptions.forEach((option) => (option.color = null));
};
