import { isPlainObject } from 'lodash';
import { ReplaySubject, Subject } from 'rxjs';

import { CONTEXT_STATE } from './context.types';
import { Class } from './class';
import { AdcError } from '../models/error.model';

export class ContextClass<I> extends Class {
    onChange = new Subject<this>();
    onStateChange = new ReplaySubject<CONTEXT_STATE>();

    private state: CONTEXT_STATE;
    private stateError: AdcError;

    private lastChangedFields: string[] = [];

    get(): I {
        return null;
    }

    wasChanged(field: string | string[]): boolean {
        const fields = Array.isArray(field) ? field : [field];

        return !!fields.find((v) => this.lastChangedFields.includes(v));
    }

    update(data: Partial<I>, emitEvent = true): void {
        this.lastChangedFields = [];

        const processObject = (obj: Record<string, any>, scope: object, parentPaths = []): void => {
            Object.entries(obj).forEach(([key, value]) => {
                const paths = parentPaths.concat(key);
                const namespace = paths.join('.');

                this.lastChangedFields.push(namespace);

                if (isPlainObject(value) && !(value instanceof Class)) {
                    if (!scope[key]) {
                        scope[key] = {};
                    }

                    processObject(value, scope[key], paths);
                } else {
                    scope[key] = value;
                }
            });
        };

        processObject(data, this);

        if (emitEvent) {
            this.onChange.next(this);
        }
    }

    isResolved(): boolean {
        return this.state === CONTEXT_STATE.RESOLVED;
    }

    isResolveError(): boolean {
        return this.state === CONTEXT_STATE.RESOLVE_ERROR;
    }

    getResolveError(): AdcError {
        return this.stateError;
    }

    setState(state: CONTEXT_STATE, error?: AdcError): void {
        this.state = state;

        if (state === CONTEXT_STATE.RESOLVE_ERROR) {
            this.stateError = error;
        }

        this.onStateChange.next(state);

        if (state === CONTEXT_STATE.RESOLVED) {
            this.onChange.next(this);
        }
    }
}
