import { SystemColor } from '../../models';
import { getPalette } from '../../utils';

export const singleInfoColor = SystemColor.Royalblue;

export const dataChangeColors: Record<string, SystemColor> = {
    increase: SystemColor.Turquoise,
    noChange: SystemColor.Mustard,
    decrease: SystemColor.Crab,
};

export const singleInfoPalette = getPalette(singleInfoColor);
