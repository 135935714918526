import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { pageSizeOptions } from 'app/config';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'adc-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
    @Input() index: number;
    @Input() size: number;
    @Input() total: number;
    @Input() options: number[];
    @Input() showPageSizeControl = true;

    @Output() indexChange = new EventEmitter<number>();
    @Output() sizeChange = new EventEmitter<number>();
    @Output() change = new EventEmitter<PageEvent>();

    pageSizeOptions: number[];

    get totalPages(): number {
        return Math.ceil(this.total / this.size);
    }

    ngOnInit(): void {
        this.pageSizeOptions = this.options || pageSizeOptions;
    }

    previousPage() {
        this.setPage(this.index - 1);
    }

    nextPage() {
        this.setPage(this.index + 1);
    }

    firstPage() {
        this.setPage(0);
    }

    onItemsPerPageChange(value: number) {
        if (this.size !== value) {
            this.setPage(0, false);
        }

        this.size = value;
        this.sizeChange.emit(value);
        this.emitChange();
    }

    private setPage(page: number, emit = true) {
        this.index = page;

        this.indexChange.emit(page);

        if (emit) {
            this.emitChange();
        }
    }

    private emitChange(): void {
        this.change.emit({
            pageIndex: this.index,
            pageSize: this.size,
            length: this.total,
        });
    }
}
