import { Injectable } from '@angular/core';
import { RequestService } from '@shared/services';
import { IEntityItemId } from '@main/types';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IGroupDto } from '@main/interfaces';

@Injectable()
export class GroupsApiService {
    constructor(private requestService: RequestService) {}

    fetchGroup<T>(id: IEntityItemId): Observable<T> {
        const url = `favorites/datapoints/${id}`;

        return this.requestService.get<T>(url).pipe(catchError(() => of(null)));
    }

    fetchGroupsList<T>(params: Record<string, any>): Observable<T> {
        const url = 'favorites/datapoints';

        return this.requestService.get<T>(url, { params }).pipe(
            catchError(() => {
                console.error('Error fetching groups list');

                return of(<T>[]);
            }),
        );
    }

    createGroup(data: IGroupDto): Observable<IGroupDto> {
        const url = `favorites/datapoints/`;

        return this.requestService.post<IGroupDto>(url, data);
    }

    updateGroup(data: IGroupDto): Observable<IGroupDto> {
        const url = `favorites/datapoints/${data.id}`;

        return this.requestService.put<IGroupDto>(url, data);
    }

    deleteGroup(id: IEntityItemId): Observable<void> {
        const url = `favorites/datapoints/${id}`;

        return this.requestService.delete(url);
    }
}
