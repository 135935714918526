import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { EntityHelpers } from '@main/helpers/entity.helpers';

@Component({
    selector: 'labeled-icon',
    templateUrl: './labeled-icon.component.html',
    styleUrls: ['./labeled-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledIconComponent implements OnInit, OnChanges {
    @HostBinding('class') classList = '';

    @Input() isLink = false;
    @Input() clickable = false;
    @Input() removable = false;
    @Input() useAsSvg = false;

    @Input() iconUrl: string;
    @Input() showIcon = true;
    @Input() matIconSize: number;
    @Input() matIconColor: string;
    @Input() defaultIcon: string;
    @Input() hideEmptyIcon: boolean;
    @Input() linkUrl: string;
    @Input() linkNewPage = true;
    @Input() title: string;
    @Input() disabled = false;
    @Input() tooltip: string;
    @Input() cls: string;
    @Input() iconCls: string;

    @Output() click = new EventEmitter<number>();
    @Output() remove = new EventEmitter<void>();

    _tooltip: string;

    private linkValid: boolean;

    get navigatable(): boolean {
        return this.isLink && this.linkValid;
    }

    get needShowIcon(): boolean {
        return !this.hideEmptyIcon && this.showIcon;
    }

    ngOnInit(): void {
        this.linkValid = EntityHelpers.isValidLink(this.linkUrl);

        this.applyClss();
    }

    ngOnChanges({ title, tooltip, disabled }: SimpleChanges): void {
        if (title || tooltip || disabled) {
            this._tooltip = this.disabled ? null : this.tooltip || this.title;
        }
    }

    onClick(): void {
        if (this.clickable) {
            this.click.emit();
        }
    }

    private applyClss(): void {
        let clsList = [];

        if (this.cls) {
            clsList = this.cls.split(' ');
        }

        if (this.clickable || this.navigatable) {
            clsList.push('clickable');
        }

        this.classList = clsList.join(' ');
    }
}
