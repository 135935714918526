import { CommonHelpers } from '@shared/helpers/common.helpers';
import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE, PUBLISHER_TYPE } from '../enums';
import { IPublisherDto } from '../interfaces';
import { EntityBase } from './EntityBase';

export class EntityPublisher extends EntityBase<IPublisherDto> {
    publisherTypeCode: PUBLISHER_TYPE;
    impressionsCount: number;

    constructor(dto?: Partial<IPublisherDto>) {
        super({
            type: ENTITY_ITEM_TYPE.SINGLE,
            entityType: BUSINESS_ENTITY.Publisher,
            dto,
        });

        if (dto) {
            this.id = dto.id;
            this.icon = dto.favIconUrl;
            this.publisherTypeCode = dto.type?.code as PUBLISHER_TYPE;
            this.impressionsCount = dto.impressions?.total;

            this.setTitle(dto.name);
            this.updateTitleSuffix();
        }
    }

    copy(): EntityPublisher {
        return CommonHelpers.copyProps(this, new EntityPublisher());
    }

    private updateTitleSuffix(): void {
        const suffixes = {
            [PUBLISHER_TYPE.YOUTUBE_CHANNEL]: 'YT channel',
            [PUBLISHER_TYPE.ANDROID_APP]: 'Android',
            [PUBLISHER_TYPE.IOS_APP]: 'iOS',
        };

        const suffix = suffixes[this.publisherTypeCode];

        if (suffix) {
            this.setTitleSuffix(` (${suffix})`, `<span class="italic"> (${suffix})</span>`);
        }
    }
}
