import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '@shared/models';
import { CurrenciesHelpers } from '../helpers/currencies.helpers';
import { ShortPipe } from './short.pipe';

@Pipe({ name: 'adcCurrency' })
export class AdcCurrencyPipe implements PipeTransform {
    constructor(private shortPipe: ShortPipe) {}

    transform(value: any, currency?: Currency, shortValuePrecision = 1, shortFormat = false, naStr?: string): string {
        const formattedValue =
            shortValuePrecision !== undefined
                ? this.shortPipe.transform(value, shortValuePrecision, false, naStr)
                : value;

        return CurrenciesHelpers.formatValue(formattedValue, currency, shortFormat, naStr);
    }
}
