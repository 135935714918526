import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@app/app.config';
import { WINDOW } from '@shared/services/window.providers';

@Injectable()
export class GlobalExportExcelService {
    private readonly appConfig = inject(AppConfig);
    private readonly window = inject(WINDOW);

    export(): Observable<string> {
        throw new Error('Method not implemented.');
    }

    protected getReportLink(): string {
        const shareLink = this.appConfig.settings.shareLinkUrl;
        const currentUrl = new URL(this.window.location.href);

        const url = shareLink ? shareLink + currentUrl.pathname : this.window.location.href;

        return url;
    }
}
