import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, ElementRef } from '@angular/core';

@Component({
    selector: 'app-password-visibility',
    templateUrl: './password-visibility.component.html',
    styleUrls: ['./password-visibility.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordVisibilityComponent implements AfterContentInit {
    @ContentChild('control') private control: ElementRef<HTMLInputElement>;

    visible = false;

    ngAfterContentInit() {
        if (!this.control) throw new Error('Form control should be passed with "control" reference variable');
    }

    get tooltip(): string {
        return this.visible ? 'Hide password' : 'Show password';
    }

    toggle(): void {
        this.visible = !this.visible;

        const controlType = this.visible ? 'text' : 'password';

        this.control.nativeElement.type = controlType;
    }
}
