import { DOWNLOAD_TYPE } from '@shared/services/download/download.service.types';
import { FEATURE_NAME_ENUM } from '@shared/enums';
import { EXPORT_FORMAT } from '@main/services/export.service.types';
import { ExportOption } from './common-export-button.types';

enum EXPORT_TYPES {
    WIDGET_TSV = 'WIDGET_TSV',
    WIDGET_EXCEL = 'WIDGET_EXCEL',
    WIDGET_IMAGE = 'WIDGET_IMAGE',
}

export const defaultExportOptions: ExportOption[] = [
    {
        id: EXPORT_TYPES.WIDGET_TSV,
        title: 'TSV',
        permission: FEATURE_NAME_ENUM.EXPORT_WIDGET_TSV,
        icon: 'filetypeTsv',
        format: EXPORT_FORMAT.WIDGET_TSV,
        downloadType: [DOWNLOAD_TYPE.SINGLE_DATASET_WIDGET, DOWNLOAD_TYPE.COMPARISON_SINGLE_DATASET_WIDGET],
        featureName: FEATURE_NAME_ENUM.EXPORT_WIDGET_TSV,
        groupId: 1,
    },
    {
        id: EXPORT_TYPES.WIDGET_EXCEL,
        title: 'Excel',
        permission: FEATURE_NAME_ENUM.EXPORT_WIDGET_EXCEL,
        icon: 'filetypeXls',
        format: EXPORT_FORMAT.WIDGET_EXCEL,
        downloadType: [DOWNLOAD_TYPE.SINGLE_DATASET_WIDGET, DOWNLOAD_TYPE.COMPARISON_SINGLE_DATASET_WIDGET],
        featureName: FEATURE_NAME_ENUM.EXPORT_WIDGET_EXCEL,
        groupId: 1,
    },
    {
        id: EXPORT_FORMAT.WIDGET_IMAGE,
        title: 'JPG',
        permission: FEATURE_NAME_ENUM.EXPORT_WIDGET_IMAGE,
        icon: 'filetypeJpg',
        format: EXPORT_FORMAT.WIDGET_IMAGE,
    },
];
