import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, NgForOf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { MenuSchema } from '../../interfaces';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'menu-button',
    standalone: true,
    imports: [CommonModule, MatIconModule, MatMenuModule, NgForOf, MatTooltipModule],
    templateUrl: './menu-button.component.html',
    styleUrls: ['./menu-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonComponent {
    @Input() menuSchema: MenuSchema[];
    @Input() isStroked: boolean;
    @Output() onEvent = new EventEmitter<MenuSchema>();
}
