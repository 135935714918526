import { Injectable } from '@angular/core';
import { distinctUntilKeyChanged, map, shareReplay, switchMap } from 'rxjs/operators';

import { Period } from '@shared/models';
import { PeriodInterval } from '@shared/models/period-interval';
import { FiltersService } from '@shared/services/filters/filters.service';
import { PersistedSettingsService, SETTINGS } from '../persisted-settings';
import { PeriodsApiService } from './periods.api.service';

@Injectable()
export class PeriodsService {
    periodIntervals$ = this.filtersService.period$.pipe(
        distinctUntilKeyChanged<Period>('id'),
        switchMap((period) => this.apiService.fetchPeriodIntervals(period)),
        shareReplay(1),
    );

    periodInterval$ = this.periodIntervals$.pipe(map((intervals) => this.getPeriodInterval(intervals)));

    constructor(
        private apiService: PeriodsApiService,
        private persistedSettingsService: PersistedSettingsService,
        private filtersService: FiltersService,
    ) {}

    private getPeriodInterval(intervals: PeriodInterval[]): PeriodInterval {
        let interval;

        const persistedValue = this.persistedSettingsService.get(SETTINGS.WIDGET_EXPENDITURE_BREAKDOWN_TREND_INTERVAL);

        if (persistedValue) {
            interval = intervals.find((v) => v.code === persistedValue);
        }

        if (!interval) {
            interval = intervals.find((v) => v.default);
        }

        return interval;
    }
}
