import { Component } from '@angular/core';
import { UserGuidingService } from './userguiding.service';

// TODO: move it from integrations to features

@Component({
    selector: 'integration-userguiding',
    template: '',
})
export class IntegrationUserGuidingComponent {
    constructor(private userGuidingService: UserGuidingService) {}
}
