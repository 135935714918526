import { NgModule } from '@angular/core';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from '@shared/shared.module';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { EntityService } from '@main/services/entity.service';
import { TopToolbarComponent } from './top-toolbar.component';
import { SelectBookmarkComponent } from '../../../shared/components/select-bookmark/select-bookmark.component';
import { SelectAnalysisPageComponent } from './components/select-analysis-page/select-analysis-page.component';

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        CommonModule,
        RouterModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTooltipModule,
        MatToolbarModule,
        NgxPopperjsModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'once' }),
    ],
    declarations: [TopToolbarComponent, SelectBookmarkComponent, SelectAnalysisPageComponent],
    exports: [TopToolbarComponent, SelectBookmarkComponent],
    providers: [
        {
            provide: REPORT_CONFIG,
            useValue: {},
        },
        {
            provide: EntityService,
            useClass: null,
        },
    ],
})
export class TopToolbarModule {}
