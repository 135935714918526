import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { RouteIdsItem } from '@main/types/types';
import { EntityService } from '@main/services/entity.service';
import { BUSINESS_ENTITY } from '@main/enums';
import { EntityCommonService } from '@main/services/entity-common.service';

@Injectable()
export class CategoriesEntityService extends EntityService {
    protected readonly entityCommonService = inject(EntityCommonService);

    resolveEntitiesByIds<T>(ids: RouteIdsItem[]): Observable<T[]> {
        return this.entityCommonService.resolveEntitiesByIds(ids, BUSINESS_ENTITY.Category);
    }
}
