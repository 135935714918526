import { MatDialogRef } from '@angular/material/dialog';

export class LayoutHelpers {
    static getElementHeight(el: HTMLElement | Element): number {
        return Math.max(el.scrollHeight, el.clientHeight);
    }

    static calcAppHeight(): number {
        const containersList = ['top-notification-bar', 'header', '.content-container'];

        const totalHeight = containersList.reduce((a, v) => {
            const ct = document.querySelector(v);
            return a + (ct ? this.getElementHeight(ct) : 0);
        }, 0);

        return totalHeight;
    }

    static addCss(css: string): void {
        const head = document.getElementsByTagName('head')[0];
        const s = document.createElement('style');
        s.setAttribute('type', 'text/css');
        s.appendChild(document.createTextNode(css));
        head.appendChild(s);
    }

    static isElementInViewport(el: HTMLElement, viewportCt?: HTMLElement): boolean {
        const viewport = viewportCt || document.getElementById('viewport-container');
        const rect = el.getBoundingClientRect();
        const bottom = viewport.clientHeight;
        const right = viewport.clientWidth;

        return rect.top >= 0 && rect.left >= 0 && rect.bottom <= bottom && rect.right <= right;
    }

    // workaround for FF
    static fixTopScrollForMatMenuButton(buttonEvent): void {
        const buttonEl = buttonEvent.target as HTMLElement;

        if (buttonEvent.offsetY < 0) {
            buttonEl.scrollIntoView();
        }
    }

    static getComputedStyle(element: HTMLElement): CSSStyleDeclaration {
        const style = window.getComputedStyle(element);
        const elementFont = style.getPropertyValue('font');
        const result: CSSStyleDeclaration = Object.assign({}, style);

        if (!elementFont) {
            const fontStyle = style.getPropertyValue('font-style');
            const fontVariant = style.getPropertyValue('font-variant');
            const fontWeight = style.getPropertyValue('font-weight');
            const fontSize = style.getPropertyValue('font-size');
            const fontFamily = style.getPropertyValue('font-family');

            result.font = (fontStyle + ' ' + fontVariant + ' ' + fontWeight + ' ' + fontSize + ' ' + fontFamily)
                .replace(/ +/g, ' ')
                .trim();
        }

        return result;
    }

    static maximizeDialog(dialogRef: MatDialogRef<any>, options: Record<string, any> = {}): void {
        const dialogCt = document.getElementById(dialogRef.id);
        const overlayCt = dialogCt?.parentNode as HTMLElement;
        const wrapperCt = overlayCt?.parentNode as HTMLElement;

        if (!wrapperCt) {
            return;
        }

        wrapperCt.style.alignItems = 'unset';

        overlayCt.classList.add('full-size');
        overlayCt.style.display = 'block';

        if (options.below) {
            const ct = document.querySelector(options.below);

            if (ct) {
                const rect = ct.getBoundingClientRect();
                overlayCt.style.marginTop = rect.top + 'px';
                overlayCt.style.height = `calc(100% - ${rect.top}px)`;
            }
        }
    }
}
