import { Directive, Input } from '@angular/core';

import { UXCheckboxActionPayload, UXElementType } from '../../models';
import { ANALYTICS_ELEMENT } from '../../data';

import { AnalyticsElementBaseDirective } from './analytics-element.base';

@Directive({
    selector: '[analyticsTextCheckbox]',
    providers: [
        {
            provide: ANALYTICS_ELEMENT,
            useExisting: AnalyticsCheckboxDirective,
        },
    ],
})
export class AnalyticsCheckboxDirective extends AnalyticsElementBaseDirective<UXCheckboxActionPayload> {
    elementType = UXElementType.Checkbox;

    @Input() label: string;

    protected getSpecificPayload(): Partial<UXCheckboxActionPayload> {
        return { elementName: this.label };
    }
}
