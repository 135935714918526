import { Injectable } from '@angular/core';
import { AppInjector } from '@app/util/injector';
import { EventsService } from '@shared/services/events/events.service';
import { EventsEnum } from '@shared/services/events/events.enum';

@Injectable()
export class MarkStateService {
    private readonly key = 'MARK_MODE_STATE';
    private state = false;
    private eventsService: EventsService;

    constructor() {
        try {
            this.state = !!window.localStorage.getItem(this.key);
        } catch (err) {
            console.error(`Can't acess localStorage`);
        }
        this.eventsService = AppInjector.get(EventsService);

        this.eventsService.on(EventsEnum.USER_LOGGED_OUT, this.disable.bind(this), 'markStateService');
    }

    enable(): void {
        this.state = true;

        try {
            window.localStorage.setItem(this.key, 'true');
        } catch (err) {
            console.error(`Can't acess localStorage`);
        }
    }

    disable(): void {
        this.state = false;
        try {
            window.localStorage.setItem(this.key, '');
        } catch (err) {
            console.error(`Can't acess localStorage`);
        }
    }

    isEnabled(): boolean {
        return this.state;
    }

    isDisabled(): boolean {
        return !this.isEnabled();
    }
}
