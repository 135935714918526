import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchHighlight' })
export class SearchHighlightPipe implements PipeTransform {
    transform(value: string, search: string): string {
        if (!search) return value;

        const regexp = new RegExp(`${search}`, 'gi');
        const highlightedText = value.replaceAll(regexp, (match) => `<mark>${match}</mark>`);

        return highlightedText;
    }
}
