import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LayoutService } from '@shared/services';
import { LayoutHelpers } from '@shared/helpers/layout.helpers';
import { GetPageInfoResult } from './viewport.service.types';

@UntilDestroy()
@Injectable()
export class ViewportServiceBase {
    minAppHeight: number;

    protected layoutService = inject(LayoutService);

    private prevAppHeight: number;

    private scrollTo$ = new Subject<HTMLElement>();

    constructor() {
        this.scrollTo$.pipe(untilDestroyed(this), debounceTime(200)).subscribe((element) => {
            this.layoutService.scrollToElement(element);
        });
    }

    setSDK(_SDK: unknown): void {
        throw new Error('Method not implemented.');
    }

    init(): void {
        this.scrollTo(0);

        this.setContainersCustomHeight();

        this.layoutService.setScrollToElementHandler((ctName) => {
            this.scrollToElementHandler(ctName);
        });

        setInterval(() => {
            const appHeight = this.getAppHeight();
            const isLayoutHeightChanged = appHeight !== this.prevAppHeight;

            this.prevAppHeight = appHeight;

            if (isLayoutHeightChanged) {
                this.resizeWindow(appHeight);
            }

            this.checkDialogsPositions(isLayoutHeightChanged);
        }, 1000);
    }

    scrollTo(_top: number): void {
        throw new Error('Method not implemented.');
    }

    resizeWindow(_height: number, _width?: number): void {
        throw new Error('Method not implemented.');
    }

    getPageInfo(): Promise<GetPageInfoResult> {
        throw new Error('Method not implemented.');
    }

    private setContainersCustomHeight(): void {
        this.getPageInfo().then((info) => {
            if (info) {
                const { clientHeight } = info;

                this.layoutService.setContainersCustomHeight(clientHeight - 100, this.minAppHeight);
            }
        });
    }

    private scrollToElementHandler(targetEl: string | HTMLElement): void {
        const targetCt = typeof targetEl === 'object' ? targetEl : (document.querySelector(targetEl) as HTMLElement);
        const posY = Math.floor(targetCt.getBoundingClientRect().top) + 50;

        this.scrollTo(posY);
    }

    private checkDialogsPositions(isLayoutHeightChanged: boolean): void {
        const angularDdialogWrapperCts = document.getElementsByClassName('cdk-global-overlay-wrapper');

        if (angularDdialogWrapperCts) {
            Array.from(angularDdialogWrapperCts).forEach((container: HTMLElement) => {
                this.updateAngularDialogPosition(container, isLayoutHeightChanged);
            });
        }

        const swalDialogWrapperCts = document.getElementsByClassName('swal2-container');

        if (swalDialogWrapperCts) {
            Array.from(swalDialogWrapperCts).forEach((container: HTMLElement) => {
                this.updateSwalDialogPosition(container);
            });
        }
    }

    private updateAngularDialogPosition(wrapperCt: HTMLElement, isLayoutHeightChanged: boolean): void {
        const dialogContentCt = wrapperCt.getElementsByClassName('cdk-overlay-pane')[0] as HTMLElement;

        if (!dialogContentCt) {
            return;
        }

        if (
            !dialogContentCt.classList.contains('adc-dialog') &&
            !dialogContentCt.classList.contains('dialog-popup-v2')
        ) {
            return;
        }

        const isContentHeightChanged =
            dialogContentCt.clientHeight !== Number(dialogContentCt.getAttribute('prev-height'));

        dialogContentCt.setAttribute('prev-height', dialogContentCt.clientHeight.toString());

        if (isLayoutHeightChanged || isContentHeightChanged) {
            this.scrollTo$.next(dialogContentCt);
        }
    }

    private updateSwalDialogPosition(dialogCt: HTMLElement): void {
        dialogCt.classList.add('reset-positioning');

        const contentCt = dialogCt.getElementsByClassName('swal2-popup')[0] as HTMLElement;

        this.getPageInfo().then((info) => {
            if (!info) {
                return;
            }

            const { clientHeight, offsetTop, scrollTop } = info;
            const top = Math.round((clientHeight - contentCt.offsetHeight) / 2) + scrollTop - offsetTop;

            contentCt.style.transition = 'top 0.5s ease';
            contentCt.style.top = top + 'px';
        });
    }

    private getAppHeight(): number {
        const appHeight = LayoutHelpers.calcAppHeight();

        return appHeight > this.minAppHeight ? appHeight : this.minAppHeight;
    }
}
