export enum SystemColor {
    Darkviolet = 'Darkviolet',
    Turquoise = 'Turquoise',
    Mustard = 'Mustard',
    Hotpink = 'Hotpink',
    Graphite = 'Graphite',
    LightGreen = 'LightGreen',
    Coral = 'Coral',
    Olive = 'Olive',
    Deepskyblue = 'Deepskyblue',
    Crab = 'Crab',
    Royalblue = 'Royalblue',
}
