import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';

import { RequestService } from '@shared/services/request/request.service';
import { DatasetRequestParams } from '@shared/services/request/request.types';
import { FiltersService } from '@shared/services/filters/filters.service';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { EntityItem } from '../models';
import { BUSINESS_ENTITY, WIDGET } from '../enums';
import { ChannelDataByEntity, ChannelsResponse, RestResponseByEntities } from '@main/models/channel';
import { ReportService } from '../core/report.service';

@Injectable()
export class ChannelsService {
    constructor(
        private requestService: RequestService,
        private filtersService: FiltersService,
        private reportService: ReportService,
    ) {}

    sortEntities(
        entities: EntityItem[],
        limit: number,
        labelArea: WIDGET,
        sortBy: 'expenses' | 'impressions' = 'expenses',
    ): Observable<EntityItem[]> {
        const { categoryBenchmark } = this.reportService;

        const entityTypes = <BUSINESS_ENTITY[]>EntityHelpers.uniqIds(entities.map((v) => v.entityType));

        const requests$ = entityTypes.map((entityType) => {
            const filteredEntities = entities.filter((v) => v.entityType === entityType);

            return this.fetchEntitiesChannelsData(filteredEntities, labelArea);
        });

        return forkJoin(requests$).pipe(
            map((results) => results.flat()),
            map((items) =>
                items.sort((v1, v2) => (v2[sortBy]?.total || 0) - (v1[sortBy]?.total || 0)).map((v) => v.entity),
            ),
            map((items) => {
                if (categoryBenchmark && limit) {
                    const benchmarkEntity = items.find((v) => v.entityType === BUSINESS_ENTITY.Category);

                    if (benchmarkEntity) {
                        items = [benchmarkEntity, ...items.filter((v) => v.entityType !== BUSINESS_ENTITY.Category)];
                    }
                }

                return limit ? items.slice(0, limit) : items;
            }),
        );
    }

    private fetchEntitiesChannelsData(entities: EntityItem[], labelArea: string): Observable<ChannelDataByEntity[]> {
        const { entityType } = entities[0];

        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(entities);

        return this.filtersService.value$.pipe(
            switchMap((filters) => {
                const datasetParams: DatasetRequestParams = {
                    datasetName: 'channels',
                    entitiesIdsPairs,
                    useViewToken: true,
                    customParams: {
                        ...EntityHelpers.serializeFiltersParams(filters),
                        shareMod: 'GLOBAL_ENTITIES_CHANNEL_SHARE',
                    },
                    labelEntityType: entityType,
                    labelArea,
                };

                return this.requestService.dataset<RestResponseByEntities | ChannelsResponse>(datasetParams).pipe(
                    map((result) => {
                        const elements = (<RestResponseByEntities>result).elements || ([result] as ChannelsResponse[]);

                        return (
                            elements?.map((element) => {
                                const entity = entities.find((v) =>
                                    EntityHelpers.matchCommonResponseItemAndEntity<ChannelsResponse>(v, element),
                                );

                                const { impressions, expenses } =
                                    element.channels?.elements?.find((v) => v.type === filters.channel.type) || {};

                                return { entity, impressions, expenses };
                            }) || []
                        );
                    }),
                );
            }),
            first(),
            shareReplay(),
        );
    }
}
