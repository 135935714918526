export const disabledAddDashboardTooltip =
    'You have reached the maximum number of saved dashboards. Remove one to add another.';

export const disabledDuplicateDashboardTooltip =
    'You have reached the maximum number of dashboards. Remove one to duplicate another.';

export const disabledDeleteDashboardTooltip = 'You must have at least one dashboard.';

export const disabledAddWidgetTooltip =
    'You have reached the maximum number of widgets per dashboard. Remove one to add another';

export const disabledDuplicateWidgetTooltip =
    'You have reached the maximum number of widgets per dashboard. Remove one to add another';
