import { ITaxonomyTopic } from '@app/shared/interfaces';

export class TaxonomyTopic {
    id: string;
    name: string;
    expenses?: number;
    impressions?: number;
    children?: TaxonomyTopic[];
    parent?: { id: string; name: string };
    error?: string;

    constructor(data: Partial<TaxonomyTopic>) {
        this.id = data.id;
        this.name = data.name;
        this.expenses = data.expenses;
        this.impressions = data.impressions;
        this.children = data.children;
        this.parent = data.parent;
        this.error = data.error;
    }

    static fromData(data: ITaxonomyTopic): TaxonomyTopic {
        return new TaxonomyTopic({
            id: data.id.toString(),
            name: data.name,
            expenses: data.expenses?.total,
            impressions: data.impressions?.total,
            children: data.taxonomies?.elements?.map((child) => TaxonomyTopic.fromData(child)),
            parent: data.parent ? { id: data.parent.id, name: data.parent.name } : undefined,
            error: data.error,
        });
    }
}

export enum TaxonomyTopicSort {
    BySpend = 'bySpend',
    ByImpression = 'byImpression',
}
