import { concat, EMPTY, from, merge, Observable, of, Subject, throwError } from 'rxjs';
import { delay, expand, filter, first, map, mergeAll, shareReplay, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { RequestService } from '../request';
import { DownloadApiService } from './download.api.service';
import { DOWNLOAD_STATUS, DownloadParams, IDownloadItem } from '@shared/services';

@Injectable()
export class DownloadService {
    private readonly STATUS_POLL_TIMEOUT = 3000;

    private refreshDownloadsStatusAction$ = new Subject<void>();

    private initialDownloadsStatus$ = this.apiService.fetchDownloads().pipe(shareReplay(1));

    private refreshDownloadsStatus$ = this.refreshDownloadsStatusAction$.pipe(
        switchMap(() => this.apiService.fetchDownloads()),
    );

    downloadsStatus$ = merge(this.initialDownloadsStatus$, this.refreshDownloadsStatus$).pipe(shareReplay(1));

    startedDownloads$ = this.initialDownloadsStatus$.pipe(
        map(({ processing }) => processing || []),
        switchMap((processingDownloads) => from(processingDownloads)),
    );

    completedDownloads$ = this.startedDownloads$.pipe(
        map(({ downloadId }) => this.getDownLoad(downloadId)),
        mergeAll(),
    );

    constructor(private requestService: RequestService, private apiService: DownloadApiService) {
        this.completedDownloads$.subscribe((download) => this.generateLinkAndPrepareDownload(download));
    }

    download(params: DownloadParams): Observable<IDownloadItem> {
        const download$ = this.apiService.download(params).pipe(
            switchMap(({ id }) => this.getDownLoad(id)),
            shareReplay(1),
        );

        download$.subscribe((download) => {
            this.refreshDownloadsStatus();
            this.generateLinkAndPrepareDownload(download);
        });

        return download$;
    }

    private getDownLoad(identifier: string): Observable<IDownloadItem> {
        const initialDownload$ = this.apiService.fetchDownload(identifier);

        const downloadPolling$ = of({ id: identifier, status: DOWNLOAD_STATUS.PROGRESS }).pipe(
            expand(({ id, status }) => {
                if (this.isDownLoadCompleted(status)) return EMPTY;

                return this.apiService.fetchDownload(id).pipe(delay(this.STATUS_POLL_TIMEOUT));
            }),
        );

        return concat(initialDownload$, downloadPolling$).pipe(
            filter(({ status }) => this.isDownLoadCompleted(status)),
            first(),
            switchMap((download) =>
                download.status === DOWNLOAD_STATUS.FAILED ? throwError('Download status: failed') : of(download),
            ),
        );
    }

    private isDownLoadCompleted(status: DOWNLOAD_STATUS): boolean {
        return status === DOWNLOAD_STATUS.READY || status === DOWNLOAD_STATUS.FAILED;
    }

    private generateLinkAndPrepareDownload({ id, signature }: IDownloadItem): void {
        const url = `downloads/${id}`;
        const baseLink = this.requestService.link(url);
        const link = `${baseLink}/content?signature=${signature}`;

        const linkElem = document.createElement('a');

        linkElem.style.display = 'none';
        linkElem.setAttribute('download', '');
        linkElem.href = link;

        document.body.appendChild(linkElem);

        linkElem.click();
        linkElem.remove();
    }

    private refreshDownloadsStatus(): void {
        this.refreshDownloadsStatusAction$.next();
    }
}
