import { Component, Inject, Input } from '@angular/core';

import { ReportConfig } from '@shared/interfaces/ReportConfig';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { FiltersService } from '@shared/services/filters/filters.service';
import { WidgetInfo } from '@shared/widgets/core/widgets.types';
import { EntityItem } from '@main/models';
import { PAGE_TYPE } from '@main/enums';

export interface ExportAsImageHeaderOptions {
    afterPeriodLabel?: string;
    afterTitleLabel?: string;
}

@Component({
    selector: 'widget-export-as-image-header',
    templateUrl: './widget-export-as-image-header.component.html',
    styleUrls: ['./widget-export-as-image-header.component.scss'],
})
export class WidgetExportAsImageHeaderComponent {
    @Input() widgetInfo: WidgetInfo<unknown>;
    @Input() title: string;
    @Input() options: ExportAsImageHeaderOptions;

    filters$ = this.filtersService.value$;

    constructor(private filtersService: FiltersService, @Inject(REPORT_CONFIG) protected reportConfig: ReportConfig) {}

    get showEntitiesIcons(): boolean {
        return this.reportConfig.pageType !== PAGE_TYPE.BRANDS;
    }

    get items(): EntityItem[] {
        return this.showEntitiesIcons ? this.widgetInfo.data.entities : [];
    }
}
