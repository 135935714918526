import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WIDGET } from '@app/app-main/enums';
import { MultiCasePipe } from '@app/shared/pipes/multi-case/multi-case.pipe';

/**
 * A component that displays a placeholder that appears during loading data for
 * the specified widget type.
 */
@Component({
    selector: 'widget-skeleton',
    standalone: true,
    imports: [CommonModule, MultiCasePipe],
    templateUrl: './widget-skeleton.component.html',
    styleUrls: ['./widget-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetSkeletonComponent {
    @Input({ required: true }) type: WIDGET;
    @Input() isFullscreenView = false;

    protected WIDGET = WIDGET;
}
