import { Observable, of } from 'rxjs';
import { AdBlockCheckMethod } from './adblock-check.class';

export class AdblockCheckMethod1 implements AdBlockCheckMethod {
    init(): Observable<void> {
        const injectScript = document.createElement('script');
        injectScript.setAttribute('src', '/assets/js/prebid-ads.js');

        return new Observable((observer) => {
            injectScript.onload = injectScript.onerror = () => {
                observer.next(undefined);
                observer.complete();
            };

            document.head.appendChild(injectScript);
        });
    }

    check(): Observable<boolean> {
        const adCtName = 'ctAdTestCt';

        return of(!document.getElementById(adCtName));
    }
}
