import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IEntityInfo } from '@main/interfaces';
import { BUSINESS_ENTITY } from '@main/enums';
import { EntityHelpers } from '@main/helpers/entity.helpers';

@Component({
    selector: 'widget-show-more-button',
    templateUrl: './widget-show-more-button.component.html',
    styleUrls: ['./widget-show-more-button.component.scss'],
})
export class WidgetShowMoreButtonComponent implements OnInit {
    @Input() toggled: boolean;
    @Input() entityType: BUSINESS_ENTITY;
    @Input() totalCount: number;
    @Input() collapsedCount = 5;

    @Output() toggledChange = new EventEmitter<boolean>();
    @Output() change = new EventEmitter<boolean>();

    get showMoreEntitiesLeft(): number {
        return this.totalCount - this.collapsedCount;
    }

    entityInfo: IEntityInfo;

    ngOnInit(): void {
        this.entityInfo = EntityHelpers.getEntityInfo(this.entityType);
    }

    onToggle(): void {
        this.toggled = !this.toggled;

        this.toggledChange.emit(this.toggled);
        this.change.emit(this.toggled);
    }
}
