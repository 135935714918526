import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { AdBlockCheckComponent } from './component/adblock-check.component';
import { AdblockCheckService } from './services/adblock-check.service';
import { DialogAbBlockEnabledPopupComponent } from './dialog/dialog-adblock-enabled-popup.component';

@NgModule({
    imports: [MatIconModule, MatDialogModule, MatButtonModule],
    declarations: [AdBlockCheckComponent, DialogAbBlockEnabledPopupComponent],
    exports: [AdBlockCheckComponent, DialogAbBlockEnabledPopupComponent],
    providers: [AdblockCheckService],
})
export class SharedAdBlockCheckModule {}
