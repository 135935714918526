import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TopNotificationBarComponent } from './top-notification-bar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [CommonModule, SharedModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule],
    declarations: [TopNotificationBarComponent],
    exports: [TopNotificationBarComponent],
})
export class SharedTopNotificationBarModule {}
