import { FEATURE_NAME_ENUM } from '@shared/enums';
import { DOWNLOAD_TYPE } from '@shared/services/download/download.service.types';
import { EXPORT_FORMAT } from '@main/services/export.service.types';

export type ExportOptionId = any;

export interface ExportOption {
    id: ExportOptionId;
    title: string;
    icon: string;

    /* user level permission */
    permission?: FEATURE_NAME_ENUM;

    /* downloads status feature key */
    featureName?: FEATURE_NAME_ENUM;

    /* download parameter */
    downloadType?: DOWNLOAD_TYPE | DOWNLOAD_TYPE[];

    format?: EXPORT_FORMAT;
    custom?: Record<string, any>;

    /* group to share download status & limits */
    groupId?: unknown;

    tooltip?: string;
    disabled?: boolean;
    inProgress?: boolean;
    ready?: boolean;
}

export interface ExportItem {
    id: ExportOptionId;
    option: ExportOption;
    limited: boolean;
    limitExceeded: boolean;
    notAvailable: boolean;
    showHeader: boolean;
    limitsLabel: string;
    limitsTooltip: string;
    noticeText: string;
    hasPermission: boolean;
    inProgress: boolean;
    ready: boolean;
    limitsStatusReady: boolean;
    disabled: boolean;
    busy: boolean;
    tooltip: string;

    _disabled: boolean;
    _tooltip: string;
}

export interface UpdateStateParams {
    id?: ExportOptionId;
    inProgress?: boolean;
    ready?: boolean;
    disabled?: boolean;
    notAvailable?: boolean;
}

export enum ROOT_BUTTON_TYPE {
    ICON = 'ICON',
    TEXT = 'TEXT',
}

export interface ExportErrorResponse {
    error: {
        token: string;
        message: string;
    };
}

export interface AnalyticsData {
    exportType: string;
    exportName: string;
}
