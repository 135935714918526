import { ValidatorFn } from '@angular/forms';

export const passwordStrongValidator: ValidatorFn = ({ value }) => {
    if (!value) return null;

    const hasNumber = /\d/.test(value);
    const hasUpper = /[A-Z]/.test(value);
    const hasLower = /[a-z]/.test(value);
    const isLong = /^.{8,}$/.test(value);

    const isValid = hasNumber && hasLower && hasUpper && isLong;

    return isValid ? null : { strong: { valid: false } };
};

export const passwordMatchValidator: ValidatorFn = (control) => {
    if (!control.parent || !control.value) return null;

    const passwordValue = control.parent.get('password').value;
    const isMatch = passwordValue === control.value;

    return isMatch ? null : { match: { valid: false } };
};
