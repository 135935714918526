import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'adcPercent' })
export class AdcPercentPipe implements PipeTransform {
    transform(value: number, precision = 0, smallPrecision = 0, naStr?: string): string {
        if (value === undefined && naStr !== undefined) {
            return naStr;
        }

        if (!value) {
            return '0%';
        }

        const n = value.toFixed(value > 1 ? precision : smallPrecision);

        return Number(n).toString() + '%';
    }
}
