export enum WIDGET_VIEW_MODE {
    REGULAR = 'REGULAR',
    DASHBOARD = 'DASHBOARD',
}

export enum WIDGET_SUBVIEW_MODE {
    GRAPH = 'GRAPH',
    HEATMAP = 'HEATMAP',
    TABLE = 'TABLE',
}

export type WidgetViewModeOption = Readonly<{
    id: WIDGET_SUBVIEW_MODE;
    title: string;
    tooltip: string;
}>;
