import { INTERVAL_TYPE, WIDGET } from '@main/enums';

export enum DOWNLOAD_TYPE {
    SINGLE_MEDIA = 'SINGLE_MEDIA',
    SINGLE_ARTICLE = 'SINGLE_ARTICLE',
    SINGLE_CREATIVE = 'SINGLE_CREATIVE',
    MULTIPLE_MEDIA = 'MULTIPLE_MEDIA',
    MULTIPLE_CREATIVES = 'MULTIPLE_CREATIVES',
    SINGLE_DATASET_WIDGET = 'SINGLE_DATASET_WIDGET',
    COMPARISON_SINGLE_DATASET_WIDGET = 'COMPARISON_SINGLE_DATASET_WIDGET',
    GLOBAL_EXCEL_GROUP = 'GLOBAL_EXCEL_REPORT',
    GLOBAL_EXCEL_COMPARISON = 'COMPARISON_GLOBAL_EXCEL_REPORT',
    CATEGORY_GLOBAL_EXCEL_GROUP = 'CATEGORY_EXCEL_REPORT',
    DASHBOARD_GLOBAL_EXCEL = 'DASHBOARD_GLOBAL_EXCEL_REPORT',
}

export enum DOWNLOAD_REPORT_TYPE {
    ADVERTISER_REPORT = 'ADVERTISER_REPORT',
    PUBLISHER_REPORT = 'PUBLISHER_REPORT',
    CAMPAIGN_REPORT = 'CAMPAIGN_REPORT',
    CATEGORY_REPORT = 'CATEGORY_REPORT',
    BENCHMARK_REPORT = 'BENCHMARK_REPORT',
    BRAND_REPORT = 'BRAND_REPORT',
}

export enum DOWNLOAD_FORMAT {
    XLSX = 'XLSX',
    TSV = 'TSV',
}

export enum DOWNLOAD_STATUS {
    REGISTERED = 'REGISTERED',
    READY = 'READY',
    FAILED = 'FAILED',
    PROGRESS = 'IN_PROGRESS',
}

export interface DownloadParams {
    type: DOWNLOAD_TYPE;
    reportType?: DOWNLOAD_REPORT_TYPE;
    tableReportOnly?: boolean;
    widgetType?: WIDGET;
    format?: DOWNLOAD_FORMAT;
    name: string;
    criteria: Record<string, any>;
}

export interface IDownloadItem {
    id: string;
    fileName: string;
    type: DOWNLOAD_TYPE;
    status: DOWNLOAD_STATUS;
    signature: string;
    expirationDate: number;
    progress: number;
}

export interface IDownloadsStatus {
    processing?: IDownloadsProgressItem[];
    downloads: IDownload[];
}

interface IDownload {
    type: DOWNLOAD_TYPE;
    counters: IDownloadsStatusCounters[];
}

export interface IDownloadsStatusCounters {
    status: DOWNLOAD_STATUS;
    intervals: IInterval[];
}

export interface IInterval {
    interval: INTERVAL_TYPE;
    count: number;
}
export interface IDownloadsProgressItem {
    downloadId: string;
    type: DOWNLOAD_TYPE;
    status: DOWNLOAD_STATUS;
}
