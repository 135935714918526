import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { PasswordVisibilityComponent, ValidationMessagesComponent } from './components';
import { MatTooltipModule } from '@angular/material/tooltip';

const components = [ValidationMessagesComponent, PasswordVisibilityComponent];

@NgModule({
    declarations: [...components],
    imports: [CommonModule, MatIconModule, MatTooltipModule],
    exports: [...components],
})
export class FormControlsModule {}
