import { Injectable, inject } from '@angular/core';

import { FeatureToggleService } from '../shared/feature-toggle/services/feature-toggle.service';
import { AnalysisPageConfig } from './analysis-page-config.types';
import { analysisReportsPagesConfig } from './analysis-page-config.data';

@Injectable()
export class AnalysisPageConfigService {
    private readonly featureToggleService = inject(FeatureToggleService);

    getAvailableReportsList(): AnalysisPageConfig[] {
        return analysisReportsPagesConfig.filter(
            (config) => !config.featureFlag || this.featureToggleService.isEnabled(config.featureFlag),
        );
    }
}
