import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ParseErrorInterceptor } from './parse-error.interceptor';
import { AuthTokenInterceptor } from './auth-token.interceptor';
import { ViewReportTokenInterceptor } from './view-report-token.interceptor';

export const interceptors: Provider[] = [
    {
        provide: HTTP_INTERCEPTORS,
        multi: true,
        useClass: ParseErrorInterceptor,
    },
    {
        provide: HTTP_INTERCEPTORS,
        multi: true,
        useClass: AuthTokenInterceptor,
    },
    {
        provide: HTTP_INTERCEPTORS,
        multi: true,
        useClass: ViewReportTokenInterceptor,
    },
];
