import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { deepFreeze, elfHooks, enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

const useSmartLook = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = './assets/js/smartlook.js';
    document.head.appendChild(script);
};

if (environment.production) {
    enableProdMode();
    enableElfProdMode();
    useSmartLook();
} else {
    devTools();
    // during the development, preventing bugs associated with mutability
    elfHooks.registerPreStateInit((initialState) => deepFreeze(initialState));
    elfHooks.registerPreStoreUpdate((_currentState, nextState) => deepFreeze(nextState));
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

/*
if ( environment.hmr ) {
    if ( module['hot'] )
    {
        hmrBootstrap(module, bootstrap);
    }
    else
    {

    }
}
else
*/
{
    bootstrap();
}
