import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '@shared/models';
import { CurrenciesHelpers } from '../helpers/currencies.helpers';

@Pipe({ name: 'adcCurrencyHeadline' })
export class CurrencyHeadlinePipe implements PipeTransform {
    transform(title: string, currency: Currency): string {
        const headline = CurrenciesHelpers.formatHeadline(currency);

        return headline ? title + ' (' + headline + ')' : title;
    }
}
