import { ReplaySubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { EventsEnum } from '@shared/services/events/events.enum';
import { Event, EventSubscription, Listener } from './events.types';

@Injectable({ providedIn: 'root' })
export class EventsService extends Subject<Event> {
    bus$ = new ReplaySubject<Event>(10);

    private listeners: Listener[] = [];

    private eventsToHold = [EventsEnum.PAGE_READY];

    private holdedEvents: Event[] = [];

    constructor() {
        super();

        this.bus$.subscribe((event) => {
            this.listeners
                .filter((item) => item.id === event.id)
                .forEach((listener) => {
                    this.fireEvent(event, listener);
                });
        });
    }

    sendMessage(eventId: EventsEnum, data?: unknown): void {
        const event: Event = {
            id: eventId,
            payload: data,
        };

        if (this.eventsToHold.includes(eventId)) {
            this.holdedEvents = this.holdedEvents.filter((v) => v.id !== eventId);

            this.holdedEvents.push(event);
        }

        this.bus$.next(event);
    }

    on(eventId: EventsEnum, callback: (any) => void, eventLabel?: string): EventSubscription {
        const listener: Listener = {
            id: eventId,
            callback,
            eventLabel,
        };

        this.listeners.push(listener);

        const holdedEvent = this.holdedEvents.find((event) => event.id === eventId);

        if (holdedEvent) {
            this.fireEvent(holdedEvent, listener);
        }

        return {
            unsubscribe: () => {
                this.off(listener);
            },
        };
    }

    reset(): void {
        this.listeners = [];
    }

    resetHoldedEvents(): void {
        this.holdedEvents = [];
    }

    private off(listener: Listener): void {
        const ind = this.listeners.findIndex((v) => v === listener);

        if (ind !== -1) {
            this.listeners.splice(ind, 1);
        }
    }

    private fireEvent(event: Event, listener: Listener): void {
        setTimeout(() => {
            listener.callback(event.payload);
        });
    }
}
