import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { RequestService } from '@shared/services/request/request.service';
import { BUSINESS_ENTITY, GROUP_TYPE, RECENT_TYPE } from '@main/enums';
import { IEntityItemId } from '@main/types';
import { FeatureToggleService } from '@main/shared/feature-toggle';
import { ChannelFilter } from '@main/services/entity-common.service.types';
import { EntityCommonService } from './entity-common.service';
import { EntityGroup, EntityItem, EntitySingle } from '../models';
import { EntityHelpers } from '../helpers/entity.helpers';
import { EntityKeyword } from '../models/EntityKeyword';
import { GetAccessLogsResponse, IAccessLog } from './recents.service.types';
import { KeywordsEntityService } from '../reports/keywords/services/keywords.entity.service';
import { IGroupDto } from '@main/interfaces';
import { GlobalConnectionService } from '@main/services/global-connection.service';

@Injectable()
export class RecentsService {
    categoryEnabled = this.featureToggleService.isEnabled('category_report');
    brandsEnabled = this.featureToggleService.isEnabled('brand_report');

    private readonly recentUrl = `accesslogs/datapoints`;
    private readonly recentToFavoriteUrl = `accesslogs/datapoints/save2favorites`;

    constructor(
        private requestService: RequestService,
        private entityCommonService: EntityCommonService,
        private keywordsEntityService: KeywordsEntityService,
        private featureToggleService: FeatureToggleService,
        private globalConnectionService: GlobalConnectionService,
    ) {}

    getLast(): Observable<EntityItem> {
        return this.getList(1, null, null, null, false).pipe(map((res: EntityItem[]) => res.length > 0 && res[0]));
    }

    saveRecentToFavorites(id: string, name: string): Observable<IGroupDto> {
        return this.requestService
            .put<IGroupDto>(`${this.recentToFavoriteUrl}?accessLogId=${id}&favouriteName=${name}`)
            .pipe(tap(() => this.globalConnectionService.favoritesUpdated$.next()));
    }

    getList(
        size = 10,
        entityTypes?: BUSINESS_ENTITY | BUSINESS_ENTITY[],
        recentType?: RECENT_TYPE,
        channel: ChannelFilter = null,
        includeDashboardEntities = true,
        populateSubgroups = false,
    ): Observable<EntityItem[]> {
        const params: Record<string, any> = {
            includeDashboardEntities,
            size,
        };

        if (entityTypes) {
            params.entityTypes = Array.isArray(entityTypes) ? entityTypes.join(',') : entityTypes;
        }

        if (recentType) {
            params.types = recentType;
        }

        return this.requestService.get<GetAccessLogsResponse>(this.recentUrl, { params }).pipe(
            map((response) => response?.elements),
            switchMap((result) => this.resolveItems(result, channel, populateSubgroups)),
            catchError(() => of([])),
        );
    }

    private resolveItems(
        recents: IAccessLog[],
        channel: ChannelFilter,
        populateSubgroups: boolean,
    ): Observable<EntityItem[]> {
        const idsByEntityType: Partial<Record<BUSINESS_ENTITY, IEntityItemId[]>> = {};

        Object.entries(BUSINESS_ENTITY).forEach(([_, value]) => {
            idsByEntityType[value] = [];
        });

        recents.forEach((recent) => {
            const { entityType: recentEntityType, instances = [], categoryId, unsavedGroups } = recent.details;

            const entityType =
                recentEntityType === BUSINESS_ENTITY.Benchmark ? BUSINESS_ENTITY.Advertiser : recentEntityType;

            const unsavedGroupsInstances = unsavedGroups?.map((group) => group.instances).flat() || [];

            idsByEntityType[entityType].push(...instances, ...unsavedGroupsInstances);

            if (recentEntityType === BUSINESS_ENTITY.Benchmark && categoryId) {
                idsByEntityType[BUSINESS_ENTITY.Category].push(categoryId);
            }
        });

        const resolvers = Object.entries(idsByEntityType)
            .map(([entityType, ids]) => {
                if (!ids.length) {
                    return;
                }

                if (entityType === BUSINESS_ENTITY.Category && !this.categoryEnabled) {
                    return;
                }

                const uniqIds = EntityHelpers.uniqIds(ids);

                if (entityType === BUSINESS_ENTITY.Keyword) {
                    return this.keywordsEntityService
                        .resolveEntitiesByIds<EntityKeyword>(uniqIds)
                        .pipe(catchError(() => of([])));
                }

                return this.entityCommonService
                    .resolveEntitiesByIds<EntitySingle>(uniqIds, entityType as BUSINESS_ENTITY, channel)
                    .pipe(catchError(() => of([])));
            })
            .filter(Boolean);

        const groupsIds = recents.map(({ details: { favoriteIds } }) => favoriteIds).flat();

        if (groupsIds.length) {
            resolvers.push(
                this.entityCommonService.resolveGroupsByIds(groupsIds).pipe(
                    map((groups) => groups.filter(Boolean)),
                    switchMap((groups) =>
                        populateSubgroups ? this.entityCommonService.resolveGroupsItems(groups) : of(groups),
                    ),
                ),
            );
        }

        if (!resolvers.length) {
            return of([]);
        }

        return forkJoin(resolvers).pipe(
            map((items) =>
                recents.map((v, i) => this.mapRecentEntity(v, items.flat(), i, populateSubgroups)).filter(Boolean),
            ),
        );
    }

    private isBookmarked(item: IAccessLog): boolean {
        return item.favorites.length === 1 && !item.details.instances.length && !!item.favorites[0].details?.params;
    }

    private mapRecentEntity(
        recent: IAccessLog,
        resolvedEntities: EntityItem[],
        recentIndex: number,
        preserveOriginalTitle = false,
    ): EntityItem {
        const isFavorite = this.isBookmarked(recent);
        const {
            details: {
                type,
                entityType: recentEntityType,
                instances = [],
                favoriteIds = [],
                unsavedGroups = [],
                categoryId,
            },
        } = isFavorite ? recent.favorites[0] : recent;

        if (categoryId && !this.categoryEnabled) return;

        const isComparison = type === RECENT_TYPE.COMPARISON;
        const isCategoryBenchmarkComparison = recentEntityType === BUSINESS_ENTITY.Benchmark;
        const isKeyword = recentEntityType === BUSINESS_ENTITY.Keyword;
        const entityType = isCategoryBenchmarkComparison ? BUSINESS_ENTITY.Advertiser : recentEntityType;

        const recentCombinedIds = [...instances, ...favoriteIds, categoryId].filter(Boolean);

        const unsavedGroupsEntities = unsavedGroups.map((item, n) => {
            const groupInstances = item.instances.map((id) => resolvedEntities.find((v) => v.id === id));
            return EntityHelpers.createVirtualGroup(
                n,
                item.name,
                groupInstances || [],
                GROUP_TYPE.REGULAR,
                entityType,
                recent,
                recent.id,
            );
        });

        const entities = recentCombinedIds
            .map((id) => resolvedEntities.find((v) => v.id === id && v.entityType === entityType))
            .filter(Boolean)
            .concat(unsavedGroupsEntities);

        if (!entities.length) return;

        if (categoryId) {
            entities.unshift(
                resolvedEntities.find((v) => v.entityType === BUSINESS_ENTITY.Category && v.id === categoryId),
            );
        }

        if (isComparison && entities.length < 2 && !EntityHelpers.entitiesHasGroup(entities)) return;

        let recentEntity: EntityItem;

        /* wrapping in virtual group for few items  */

        if (entities.length === 1) {
            recentEntity = entities[0];
        } else {
            const divider = isComparison ? 'vs' : isKeyword ? '' : '&';
            const title =
                preserveOriginalTitle && recent?.favorites?.[0]?.name
                    ? recent?.favorites?.[0]?.name
                    : entities
                          .map((v) =>
                              isCategoryBenchmarkComparison && v.entityType === BUSINESS_ENTITY.Category
                                  ? v.title
                                  : v.fullTitleHtml,
                          )
                          .join(` <span class="gray">${divider}</span> `);
            const groupType = isComparison
                ? isCategoryBenchmarkComparison
                    ? GROUP_TYPE.COMPARISON_CATEGORY_BENCHMARK
                    : GROUP_TYPE.COMPARISON
                : GROUP_TYPE.REGULAR;
            const recentFavorite = (this.isBookmarked(recent) && recent?.favorites?.[0]) || null;

            if (preserveOriginalTitle && recentFavorite) {
                recentEntity = new EntityGroup({
                    id:
                        preserveOriginalTitle && recentFavorite.id
                            ? recentFavorite.id
                            : recent.details?.id || EntityHelpers.getVirtualGroupId(`recent_group_${recentIndex}`),
                    entityType,
                    groupType,
                    title,
                    titleGenerated: title !== 'virtualGroupName',
                    icon: EntityHelpers.getIcon(entityType, groupType),
                    items: entities,
                    dto: recent,
                    resolved: true,
                    virtual: true,
                    accessLogId: recent.id,
                });
            } else {
                recentEntity = EntityHelpers.createVirtualGroup(
                    `recent_${recentIndex}`,
                    title,
                    entities,
                    isComparison
                        ? isCategoryBenchmarkComparison
                            ? GROUP_TYPE.COMPARISON_CATEGORY_BENCHMARK
                            : GROUP_TYPE.COMPARISON
                        : GROUP_TYPE.REGULAR,
                    entityType,
                    recent,
                    recent.id,
                );
            }

            (recentEntity as EntityGroup).titleGenerated = true;
        }

        recentEntity.setPreferredFilters(recent.details.params);
        recentEntity.setDateTime(recent.time);

        recentEntity[Symbol.for('isRecent')] = true;
        recentEntity[Symbol.for('bookmarked')] = this.isBookmarked(recent);

        return recentEntity;
    }
}
