import { INTERVAL_TYPE } from '@main/enums';
import { Class } from '../core/class';
import { IPeriodInterval } from '../interfaces';

export class PeriodInterval extends Class {
    code: INTERVAL_TYPE;
    title: string;
    default: boolean;

    constructor(data: Partial<IPeriodInterval>, isDefault = false) {
        super();

        this.code = data.code;
        this.title = data.title;
        this.default = isDefault;
    }
}
