import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { WINDOW } from '@shared/services';

@Component({
    selector: 'page-server-error-page',
    templateUrl: './server-error-page.component.html',
})
export class ServerErrorPageComponent {
    constructor(private router: Router, private route: ActivatedRoute, @Inject(WINDOW) private window: Window) {}

    refreshPage(): void {
        const { refreshUrl } = this.route.snapshot.queryParams;

        if (refreshUrl) {
            this.router.navigate([refreshUrl]);
        } else {
            this.window.location.reload();
        }
    }
}
