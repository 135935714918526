import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'pixelated-shade',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './pixelated-shade.component.html',
    styleUrls: ['./pixelated-shade.component.scss'],
})
export class PixelatedShadeComponent {
    @Input() lockIcon = true;
}
