import { ADC_ERROR_CODE_ENUM } from '@shared/enums';

export class AdcError {
    error?: Error;
    message: string;
    httpStatus?: number;
    code?: ADC_ERROR_CODE_ENUM;
    formErrors?: string[];

    constructor(code: ADC_ERROR_CODE_ENUM, message?: string) {
        this.code = code;
        this.message = message;
    }
}
