import { EntityKeyword } from '../../models/EntityKeyword';
import { RouteIdsItem } from '../../types/types';

export class KeywordsHelpers {
    static createKeywordsEntities(keywords: RouteIdsItem[]): EntityKeyword[] {
        return keywords.map((v) => this.createKeywordEntity(v));
    }

    static createKeywordEntity(keyword: RouteIdsItem): EntityKeyword {
        return new EntityKeyword(keyword as string);
    }

    static serializeCampaignsIds(ids: number[]): string {
        if (!ids.length) {
            return;
        }

        return ids.length === 1 ? ids[0].toString() : `(${ids.join(',')})`;
    }
}
