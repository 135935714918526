<div class="adc-card auto-width px-24 py-20">
    <widget-export-as-image-header
        [widgetInfo]="widgetInfo"
        [title]="title"
        [options]="extraExportImageOptions"
    ></widget-export-as-image-header>

    <div class="header-wrapper">
        <div class="w-100-p">
            <ng-content *ngIf="showHeader" select="[card-header]"></ng-content>
        </div>
        <menu-button
            *ngIf="showMenu"
            [isStroked]="true"
            [menuSchema]="menuSchema"
            (onEvent)="onMenuEvent($event)"
        ></menu-button>
    </div>

    <ng-container *ngIf="showAdvancedInfo">
        <div class="sub-header">
            <div class="entityType">{{ entityTypeName }}</div>

            <div class="circle mx-12"></div>
            <div fxLayout fxLayoutAlign="start center">
                <channel-icon [channel]="widgetInfo.data.filters.channel"></channel-icon>
                <div>{{ widgetInfo.data.filters.channel.title }}</div>
            </div>
            <div class="circle mx-12"></div>
            <div fxLayout fxLayoutAlign="start center">
                <device-icon [device]="widgetInfo.data.filters.device"></device-icon>
                <div>{{ widgetInfo.data.filters.device.title }}</div>
            </div>
        </div>
        <div *ngIf="widgetInfo.data?.entities" class="entities-wrapper mt-24" #wrapper>
            <multiline-entities-list
                [entities]="widgetInfo.data.entities"
                [isComparison]="widgetInfo.data.comparison"
                [parent]="wrapper"
            ></multiline-entities-list>
        </div>
    </ng-container>

    <div class="content-area w-100-p" [ngClass]="contentCls">
        <widget-status [status]="status" [title]="title" [hasData]="hasData" [type]="widgetInfo.type"></widget-status>

        <ng-content [ngIf]="hasData" select="[card-content]"></ng-content>
    </div>

    <ng-content *ngIf="footer" select="[card-footer]"></ng-content>

    <widget-export-as-image-footer></widget-export-as-image-footer>
</div>
<div *ngIf="canDrag" class="drag-wrapper">
    <mat-icon class="s-16" svgIcon="dragIndicator"></mat-icon>
</div>
