import { Directive, HostListener, Input } from '@angular/core';

enum NAVIGATION_KEY {
    ENTER = 'Enter',
    DOWN = 'ArrowDown',
    UP = 'ArrowUp',
}

const dataSelectedAttrName = 'data-adc-key-navigator-selected';

@Directive({
    selector: '[adcKeyNavigator]',
})
export class AdcKeyNavigatorDirective {
    @Input() adcKeyNavigator: string;
    @Input() adcKeyNavigatorScrollElementsOffset = 2;

    @HostListener('keydown', ['$event'])
    keyDown(event: KeyboardEvent) {
        const navigationKeys = [NAVIGATION_KEY.ENTER, NAVIGATION_KEY.DOWN, NAVIGATION_KEY.UP];
        const key = event.key as NAVIGATION_KEY;

        if (navigationKeys.includes(key)) {
            this.onNavigation(key, event);
        }
    }

    onNavigation(key: NAVIGATION_KEY, event: KeyboardEvent): void {
        const elements = Array.from(
            document.querySelectorAll(`[adcKeyNavigatorItem="${this.adcKeyNavigator}"]`),
        ) as HTMLElement[];

        if (!elements.length) {
            return;
        }

        const selectedElement = document.querySelectorAll(`[${dataSelectedAttrName}]`)[0] as HTMLElement;
        const selectedElementIndex = elements.indexOf(selectedElement);

        if (key === NAVIGATION_KEY.ENTER) {
            if (selectedElement) {
                event.cancelBubble = true;

                selectedElement.removeAttribute(dataSelectedAttrName);
                selectedElement.click();
            }

            return;
        }

        //      event.stopPropagation();

        if (selectedElement) {
            selectedElement.removeAttribute(dataSelectedAttrName);
        }

        let nextSelectedElement = elements[selectedElementIndex];

        if (key === NAVIGATION_KEY.DOWN && elements[selectedElementIndex + 1]) {
            nextSelectedElement = elements[selectedElementIndex + 1];

            this.tryScrollToElement(elements[selectedElementIndex - this.adcKeyNavigatorScrollElementsOffset]);
        }

        if (key === NAVIGATION_KEY.UP && elements[selectedElementIndex - 1]) {
            nextSelectedElement = elements[selectedElementIndex - 1];

            this.tryScrollToElement(elements[selectedElementIndex - this.adcKeyNavigatorScrollElementsOffset - 1]);
        }

        nextSelectedElement.setAttribute(dataSelectedAttrName, '');
    }

    private tryScrollToElement(element: HTMLElement): void {
        if (element) {
            element.scrollIntoView();
        }
    }
}
