import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, share, shareReplay } from 'rxjs/operators';

import { RequestService } from '@shared/services/request';
import { ICountry } from '@shared/interfaces';
import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '../enums';
import {
    ISubscription,
    ISubscriptionsSettings,
    NotificationPeriodItem,
    Subscription,
    SUBSCRIPTION_PERIOD,
} from './subscriptions.service.types';
import { EntityItem } from '../models';
import { IElementsResponse } from '../interfaces';
import { EntityCommonService } from './entity-common.service';

@Injectable()
export class SubscriptionsService {
    subscriptions$: Observable<Subscription[]>;
    notificationPeriods$: Observable<SUBSCRIPTION_PERIOD[]>;
    countries$: Observable<ICountry[]>;

    subscriptionsLoading$: Observable<boolean>;
    notificationPeriodsLoading$: Observable<boolean>;
    countriesLoading$: Observable<boolean>;

    private refresh$ = new BehaviorSubject<void>(null);

    constructor(private requestService: RequestService, private entityCommonService: EntityCommonService) {
        this.subscriptions$ = this.refresh$.pipe(
            mergeMap(() => this.fetchSubscriptionsList()),
            shareReplay(),
        );

        this.countries$ = this.fetchCountriesList().pipe(shareReplay());

        this.subscriptionsLoading$ = merge(
            this.refresh$.pipe(mapTo(true)),
            this.subscriptions$.pipe(
                mapTo(false),
                catchError(() => of(false)),
            ),
        );

        this.notificationPeriods$ = this.refresh$.pipe(
            mergeMap(() => this.fetchNotificationPeriods()),
            shareReplay(),
        );

        this.notificationPeriodsLoading$ = merge(
            of(true),
            this.notificationPeriods$.pipe(
                mapTo(false),
                catchError(() => of(false)),
            ),
        );

        this.countriesLoading$ = merge(
            of(true),
            this.countries$.pipe(
                mapTo(false),
                catchError(() => of(false)),
            ),
        );
    }

    getAllNotificationPeriods(): NotificationPeriodItem[] {
        return [
            { id: SUBSCRIPTION_PERIOD.WEEKLY, title: 'Weekly' },
            { id: SUBSCRIPTION_PERIOD.MONTHLY, title: 'Monthly' },
        ];
    }

    setCurrentNotificationPeriods(periods: SUBSCRIPTION_PERIOD[]): Observable<void> {
        const data = {
            frequencies: periods,
        };

        return this.requestService.post<void>('subscriptions', data);
    }

    getSubscriptionsCount(entityType: BUSINESS_ENTITY): Observable<number> {
        return this.subscriptions$.pipe(map((items) => items.filter((v) => v.entity.entityType === entityType).length));
    }

    getSubscriptionStateByEntity(entity: EntityItem, countryId: number): Observable<string> {
        return this.subscriptions$.pipe(
            filter((items) => !!items),
            map((items) => {
                const item = this.findSubscriptionByEntity(items, entity, countryId);
                return item?.id;
            }),
        );
    }

    addSubscription(entity: EntityItem, countryId: number): Observable<void> {
        const newItem = {
            id: undefined,
            countryId,
            entity,
        };

        const stream$ = this.addSubscriptions([newItem]).pipe(share(), mapTo(null));

        stream$.subscribe(() => this.refresh());

        return stream$;
    }

    removeSubscription(ids: string[]): Observable<void> {
        const stream$ = this.requestService.delete('subscriptions/entries', { body: ids }).pipe(share(), mapTo(null));

        stream$.subscribe(() => this.refresh());

        return stream$;
    }

    fetchCountriesList(): Observable<ICountry[]> {
        return this.requestService
            .dataset({
                datasetName: 'countries',
                labelArea: 'base_service',
            })
            .pipe(map((result: IElementsResponse<ICountry>) => result.elements));
    }

    refresh(): void {
        this.refresh$.next(null);
    }

    private findSubscriptionByEntity(
        subscriptions: Subscription[],
        entity: EntityItem,
        countryId: number,
    ): Subscription {
        return (
            entity &&
            subscriptions.find(
                (v) =>
                    v.entity.type === entity.type &&
                    v.entity.entityType === entity.entityType &&
                    v.entity.id === entity.id &&
                    v.countryId === countryId,
            )
        );
    }

    private fetchNotificationPeriods(): Observable<SUBSCRIPTION_PERIOD[]> {
        return this.requestService
            .get<ISubscriptionsSettings>('subscriptions')
            .pipe(map((response) => response.frequencies));
    }

    private fetchSubscriptionsList(): Observable<Subscription[]> {
        return this.requestService.get<ISubscription[]>('subscriptions/entries').pipe(
            mergeMap((items) =>
                forkJoin([
                    of(items),
                    this.resolveEntities(BUSINESS_ENTITY.Advertiser, items),
                    this.resolveEntities(BUSINESS_ENTITY.Publisher, items),
                ]),
            ),
            map(([items, advertiserEntities, publisherEntities]) =>
                this.deserializeItems(items, advertiserEntities, publisherEntities),
            ),
        );
    }

    private addSubscriptions(items: Subscription[]): Observable<void> {
        const data = this.serializeItems(items);

        return this.requestService.post<void>('subscriptions/entries', data);
    }

    private serializeItems(items: Subscription[]): ISubscription[] {
        return items.map((v) => ({
            id: v.id,
            type: v.entity.type,
            dataPoint: v.entity.entityType,
            countryId: v.countryId,
            instances: v.entity.type === ENTITY_ITEM_TYPE.SINGLE ? ([v.entity.id] as number[]) : undefined,
            favoriteId: v.entity.type === ENTITY_ITEM_TYPE.GROUP ? (v.entity.id as string) : undefined,
            name: v.entity.type === ENTITY_ITEM_TYPE.GROUP ? v.entity.title : undefined,
        }));
    }

    private deserializeItems(
        elements: ISubscription[],
        advertiserEntities: EntityItem[],
        publisherEntities: EntityItem[],
    ): Subscription[] {
        const searchInEntities = (entities: EntityItem[], subscription: ISubscription): EntityItem =>
            entities.find(
                (entity) =>
                    (subscription.type === ENTITY_ITEM_TYPE.SINGLE &&
                        subscription.instances?.length &&
                        subscription.instances[0] === entity.id) ||
                    (subscription.type === ENTITY_ITEM_TYPE.GROUP && subscription.favoriteId === entity.id),
            );

        return elements.map((v) => {
            let entity: EntityItem;

            if (v.dataPoint === BUSINESS_ENTITY.Advertiser) {
                entity = searchInEntities(advertiserEntities, v);
            }

            if (v.dataPoint === BUSINESS_ENTITY.Publisher) {
                entity = searchInEntities(publisherEntities, v);
            }

            return {
                id: v.id,
                countryId: v.countryId,
                entity,
            };
        });
    }

    private resolveEntities(entityType: BUSINESS_ENTITY, subscriptionsList: ISubscription[]): Observable<EntityItem[]> {
        const entitiesIds = subscriptionsList
            .filter((v) => v.dataPoint === entityType)
            .map(
                (v) =>
                    (v.type === ENTITY_ITEM_TYPE.GROUP && v.favoriteId) ||
                    (v.type === ENTITY_ITEM_TYPE.SINGLE && v.instances),
            )
            .flat()
            .filter((v) => !!v);

        return entitiesIds.length ? this.entityCommonService.resolveEntitiesByIds(entitiesIds, entityType) : of([]);
    }
}
