import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { RequestService } from '@shared/services/request/request.service';
import { FiltersValue } from '@shared/services/filters/filters.types';
import { Currency } from '@shared/models';
import { DatasetRequestParams } from '@shared/services/request/request.types';
import { GetDeviationDataRestResponse } from '@main/widgets/estimated-expenditures/estimated-expenditures.types';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { BUSINESS_ENTITY, WIDGET } from '@main/enums';
import { EntityItem } from '@main/models';

@Injectable()
export class ReportDataAggregationService {
    private readonly requestService = inject(RequestService);

    fetchDataDeviation(
        entities: EntityItem[],
        filters: FiltersValue,
        currency: Currency,
        labelEntityType: BUSINESS_ENTITY,
        labelArea: WIDGET,
    ): Observable<GetDeviationDataRestResponse> {
        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(entities, {
            globalParentheses: true,
            extractGroupsIds: true,
            plain: true,
        });

        const datasetParams: DatasetRequestParams = {
            datasetName: 'month-over-month-change',
            entitiesIdsPairs,
            useViewToken: true,
            customParams: EntityHelpers.serializeFiltersParams(filters, currency),
            labelEntityType,
            labelArea,
        };

        return this.requestService.dataset<GetDeviationDataRestResponse>(datasetParams);
    }
}
