import { FiltersValue } from '@shared/services/filters/filters.types';
import { BUSINESS_ENTITY, RECENT_TYPE } from '@main/enums';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { IAccessLogDetails, IAccessLogUnsavedGroup } from '@main/services/recents.service.types';
import { EntityGroup, EntityItem } from '@main/models';
import { IEntityItemId } from '@main/types';

export class Helpers {
    static composeRequestPayload(
        entities: EntityItem[],
        filters: FiltersValue,
        entityType: BUSINESS_ENTITY,
        categoryBenchmarkId: IEntityItemId,
        compare = false,
    ) {
        const isSingleGroup = EntityHelpers.isItemsSingleGroup(entities);

        let instances = [];
        let groupsIds = [];
        let unsavedGroups: IAccessLogUnsavedGroup[] = [];
        let type: RECENT_TYPE;
        let recentEntityType = entityType;

        switch (entityType) {
            case BUSINESS_ENTITY.Advertiser:
            case BUSINESS_ENTITY.Publisher:
            case BUSINESS_ENTITY.Category:
            case BUSINESS_ENTITY.Brand: {
                const filteredEntities = entities.filter((v) => v.entityType === entityType);

                const virtualGroupMembers = this.getVirtualGroups(filteredEntities);

                unsavedGroups = virtualGroupMembers.map((v) => ({
                    name: v.title,
                    instances: v.items.map((item) => item.id) as number[],
                }));

                instances = filteredEntities.filter((v) => v.isInstance()).map((v) => v.id);

                groupsIds = filteredEntities
                    .filter((v) => v.isGroup() && !(<EntityGroup>v).isVirtual())
                    .map((v) => v.id);

                type = this.serializeRecentType(filteredEntities, compare, Boolean(categoryBenchmarkId));

                if (categoryBenchmarkId) {
                    recentEntityType = BUSINESS_ENTITY.Benchmark;
                }
                break;
            }

            case BUSINESS_ENTITY.Campaign:
                instances = entities.map((v) => v.id);
                type = RECENT_TYPE.SINGLE;
                break;

            case BUSINESS_ENTITY.Keyword:
                instances = entities.map((v) => v.id);
                groupsIds = entities.filter((v) => v.isGroup()).map((v) => v.id);
                type = instances.length > 1 ? RECENT_TYPE.GROUP : RECENT_TYPE.SINGLE;
                break;
        }

        const payload: IAccessLogDetails = {
            type,
            entityType: recentEntityType,
            instances,
            favoriteIds: groupsIds,
            unsavedGroups,
            params: EntityHelpers.serializeFiltersParams(filters),
        };

        if (categoryBenchmarkId && !isSingleGroup) {
            payload.categoryId = categoryBenchmarkId;
        }

        return payload;
    }

    private static serializeRecentType(
        entities: EntityItem[],
        compare: boolean,
        categoryBenchmark: boolean,
    ): RECENT_TYPE {
        const instancesCount = entities.filter((v) => v.isInstance()).length;
        const groupsCount = entities.filter((v) => v.isGroup()).length;

        if (compare) {
            if (instancesCount > 1 || groupsCount > 0 || categoryBenchmark) {
                return RECENT_TYPE.COMPARISON;
            }

            throw new Error('Error serializing comparison recent');
        }

        if (instancesCount === 1 && groupsCount === 0) {
            return RECENT_TYPE.SINGLE;
        }

        if (instancesCount > 1 || groupsCount === 1) {
            return RECENT_TYPE.GROUP;
        }

        throw new Error('Error serializing comparison recent');
    }

    static getVirtualGroups(entities: EntityItem[]): EntityGroup[] {
        return entities.filter((entity) => entity.isGroup() && (entity as EntityGroup)?.isVirtual()) as EntityGroup[];
    }
}
