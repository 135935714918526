<div class="inline-edit">
    <div *ngIf="!isEditing" class="inline-edit__text" [matTooltip]="text">
        {{ text }}
    </div>
    <form *ngIf="isEditing" [formGroup]="form">
        <mat-form-field
            class="inline-edit__input"
            [ngStyle]="{ 'width.px': textInput.value.length * 7, 'min-width.px': 60, 'max-width.px': 200 }"
        >
            <input
                data-unit="inline-edit-input"
                #textInput
                matInput
                type="text"
                [formControl]="textControl"
                [matTooltip]="validationError"
                [matTooltipDisabled]="!validationError.length"
                (blur)="editDone()"
                (keyup.enter)="editDone()"
                (keyup.escape)="cancel()"
            />
        </mat-form-field>
    </form>
    <button
        *ngIf="actionsVisible && !isEditing"
        data-unit="inline-edit-button"
        class="inline-edit__button"
        mat-icon-button
        aria-label="Edit button"
        matTooltip="Edit"
        (click)="beginEdit()"
    >
        <mat-icon class="inline-edit__button-icon" svgIcon="edit"></mat-icon>
    </button>
</div>
