import { Class } from '../core/class';

export class Country extends Class {
    id: number;
    code: string;
    title: string;
    error?: string;
    restricted: boolean;
    disabled: boolean;

    constructor(data: Partial<Country>) {
        super();

        this.id = data.id;
        this.code = data.code;
        this.title = data.title;
        this.restricted = data.restricted;
        this.disabled = data.disabled;
    }
}
