import { CommonHelpers } from '@shared/helpers/common.helpers';
import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '../enums';
import { IEntityItemId } from '../types';
import { EntityBase } from './EntityBase';

export class EntityKeyword extends EntityBase {
    constructor(keyword: string) {
        super({
            id: keyword as IEntityItemId,
            type: ENTITY_ITEM_TYPE.SINGLE,
            entityType: BUSINESS_ENTITY.Keyword,
            title: keyword,
            icon: ':hashtagBlue',
            dto: {},
        });
    }

    copy(): EntityKeyword {
        return CommonHelpers.copyProps(this, new EntityKeyword(''));
    }
}
