import { Injectable, inject } from '@angular/core';
import { forkJoin, merge, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, mapTo, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { keywordAllowedPattern } from '@app/config';
import { Country, Period } from '@shared/models';
import { PeriodHelpers } from '@shared/helpers/period.helpers';
import { PersistedSettingsService, SETTINGS } from '@shared/services';
import { FiltersService } from '@shared/services/filters/filters.service';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { RouteIdsItem } from '@main/types/types';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { Device, EntityGroup, EntityItem } from '@main/models';
import { EntityService } from '@main/services/entity.service';
import { ReportApiService } from '@main/core/report.api-service';
import { DatasetsData } from '@main/core/report.types';
import { ReportService } from '@main/core/report.service';
import { EntityCommonService } from '@main/services/entity-common.service';
import { CampaignIdsPayload } from './keywords.service.types';
import { KeywordsApiService } from './keywords.api-service';
import { KeywordsHelpers } from '../keywords.helpers';
import { SerializedFiltersParams } from '@main/helpers/entity.helpers.types';

@UntilDestroy()
@Injectable()
export class KeywordsEntityService extends EntityService {
    protected readonly entityCommonService = inject(EntityCommonService);
    protected readonly reportService = inject(ReportService);
    protected readonly reportConfig = inject(REPORT_CONFIG);
    protected readonly persistedSettingsService = inject(PersistedSettingsService);
    protected readonly reportApiService = inject(ReportApiService);
    protected readonly filtersService = inject(FiltersService);

    private readonly apiService = inject(KeywordsApiService);

    private getCampaignsIdsByKeywords$ = new ReplaySubject<CampaignIdsPayload>(1);

    private campaignsIds: number[] = [];

    campaignIds$ = merge(
        this.reportService.reportTokenUpdateTrigger$.pipe(map(() => null)),

        this.reportService.reportTokenUpdateTrigger$.pipe(
            withLatestFrom(this.filtersService.value$, this.reportService.entities$),
            map(([, ...rest]) => rest),
            switchMap(([filters, entities]) => this.cachedFetchCampaignsIds({ filters, entities })),
        ),
    );

    campaignsCount$ = this.campaignIds$.pipe(
        filter(Boolean),
        map((res) => res[Symbol.for('total')] || 0),
        shareReplay(1),
    );

    loading$ = merge(
        this.getCampaignsIdsByKeywords$.pipe(mapTo(true)),
        this.campaignIds$.pipe(
            mapTo(false),
            catchError(() => of(false)),
        ),
    );

    loadingCount$ = merge(
        this.filtersService.value$.pipe(mapTo(true)),
        this.campaignsCount$.pipe(
            mapTo(false),
            catchError(() => of(false)),
        ),
    );

    constructor() {
        super();

        this.reportService.reportUpdateTrigger$.subscribe(() => {
            this.campaignsIds = [];
        });
    }

    getDatasetsData(): Observable<DatasetsData> {
        return forkJoin([
            this.reportApiService.getChannels(),
            this.reportApiService.getPeriods(),
            this.reportApiService.getCountries(),
        ]).pipe(map(([channels, periods, countries]) => ({ channels, periods, countries })));
    }

    parseRouteIds(str: string): RouteIdsItem[] {
        const r = new RegExp('/(.+?)|([^,]+?:)|(' + keywordAllowedPattern + ')|([a-z0-9-]+)', 'gui');
        const parsed = str.match(r);
        let result: RouteIdsItem[] = [];

        for (const v of parsed) {
            /* virtual group name */
            if (v.slice(-1) === ':') {
                result = this.parseRouteIds(parsed.slice(1).join(','));
                result[Symbol.for('name')] = v.slice(0, -1);

                break;
            }

            result.push(v);
        }

        return result;
    }

    resolveEntitiesByIds<T>(ids: RouteIdsItem[]): Observable<T[]> {
        const groupsIds = ids.filter((id) => EntityHelpers.isGroupId(id)) as string[];

        return this.entityCommonService
            .resolveGroupsByIds(groupsIds)
            .pipe(map((groupsEntities) => this.buildEntitiesTree(ids, groupsEntities))) as unknown as Observable<T[]>;
    }

    getDefaultDevice(devices: Device[], _: unknown, preferredFilters: Partial<SerializedFiltersParams>): Device {
        const { channel: queryValue } = this.queryParams;
        const [, deviceCode = null] = queryValue?.split('-') || [];
        const [, preferredDeviceCode] = preferredFilters?.channel.split('-') || [];
        const persistedId = this.persistedSettingsService.get<string>(SETTINGS.ROOT_DEVICE);
        const availableDevices = devices.filter((v) => !v.disabled && !v.restricted);

        let device: Device;

        if (deviceCode) {
            device = availableDevices.find((v) => v.code === deviceCode);
            this.navigationService.removeQueryParam('device', true);
        }

        if (!device && preferredDeviceCode) {
            device = availableDevices.find((v) => v.code === persistedId);
        }

        if (!device) {
            device = devices && devices[0];
        }

        if (devices?.length === 1) {
            device.disabled = true;
        }

        return device;
    }

    getDefaultCountry(countries: Country[], _: unknown, preferredFilters: Partial<SerializedFiltersParams>): Country {
        const { country: queryValue } = this.queryParams;
        const preferredCountry = preferredFilters?.country || null;
        const persistedId = this.persistedSettingsService.get<number>(SETTINGS.ROOT_COUNTRY);
        const availableCountries = countries.filter((v) => !v.disabled && !v.restricted);

        let bestCountry: Country;

        if (queryValue) {
            bestCountry = availableCountries.find(({ id, code }) => `${id}` === queryValue || code === queryValue);
            this.navigationService.removeQueryParam('country', true);
        }

        if (!bestCountry && preferredCountry) {
            bestCountry = availableCountries.find(
                ({ id, code }) => `${id}` === preferredCountry || code === preferredCountry,
            );
        }

        if (!bestCountry) {
            bestCountry = availableCountries.find((v) => v.id === persistedId);
        }

        if (!bestCountry) {
            const allAvailableCountries = countries.filter((v) => !v.restricted && !v.disabled);

            bestCountry = allAvailableCountries[0];
        }

        return bestCountry;
    }

    getDefaultPeriod(periods: Period[], _: unknown, preferredFilters: Partial<SerializedFiltersParams>): Period {
        const { period: queryValue } = this.queryParams;
        const preferredPeriod = preferredFilters?.period || null;
        const persistedId = this.persistedSettingsService.get<string>(SETTINGS.ROOT_PERIOD);

        const availablePeriods = periods.filter((v) => !v.restricted);

        let period: Period;

        if (queryValue) {
            period = periods.find((v) => v.id.toString() === queryValue);
            this.navigationService.removeQueryParam('period', true);
        }

        if (!period && preferredPeriod) {
            period = availablePeriods.find((v) => v.id === preferredPeriod);
        }

        if (!period && persistedId) {
            period = PeriodHelpers.getPeriodByPersistedValue(periods, persistedId);
        }

        if (!period) {
            period = PeriodHelpers.calculateWholeRangePeriod(periods);
        }

        if (!period) {
            period =
                availablePeriods.find((v) => v.code === 'LAST_30_DAYS') ||
                availablePeriods.find((v) => v.code === 'LAST_7_DAYS');
        }

        if (!period) {
            period = availablePeriods[0];
        }

        return period;
    }

    buildEntitiesTree(ids: RouteIdsItem[], groupsEntities: EntityGroup[]): EntityItem[] {
        return ids
            .map((id) => {
                const isGroup = EntityHelpers.isGroupId(id);

                if (isGroup) {
                    const group = groupsEntities.find((v) => v.id === id);

                    if (!group) {
                        return;
                    }

                    group.items = KeywordsHelpers.createKeywordsEntities(group._dto.details.instances);
                    group.setResolved();

                    return group;
                }

                return KeywordsHelpers.createKeywordEntity(id);
            })
            .filter(Boolean);
    }

    cachedFetchCampaignsIds({
        filters,
        entities,
        pageNumber = 0,
        pageSize = 100,
    }: CampaignIdsPayload): Observable<number[]> {
        const values = this.campaignsIds.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);

        if (values.length === pageSize) {
            return of(values);
        }

        const campaignIds$ = this.apiService
            .fetchCampaignsIds({ filters, entities, pageNumber, pageSize })
            .pipe(shareReplay(1));

        campaignIds$
            .pipe(untilDestroyed(this))
            .subscribe((ids) => ids.forEach((id, index) => (this.campaignsIds[pageNumber * pageSize + index] = id)));

        return campaignIds$;
    }
}
