<ng-content></ng-content>

<div class="toggle-visibility" (click)="toggle()" [matTooltip]="tooltip" matTooltipShowDelay="250">
    <mat-icon *ngIf="visible" class="icon">visibility_off</mat-icon>
    <mat-icon *ngIf="!visible" class="icon">visibility</mat-icon>

    <!--  TODO:  replace Material icons with the svg icons when "Visibility off" icon will be available-->
    <!--    <mat-icon *ngIf="visible" svgIcon="eyeOff" class="icon"></mat-icon>-->
    <!--    <mat-icon *ngIf="!visible" svgIcon="eye" class="icon"></mat-icon>-->
</div>
