import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ICurrency } from '@shared/interfaces';
import { PersistedSettingsService, SETTINGS } from '@shared/services';
import { CurrencyApiService } from './currency-api.service';
import { Currency } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class CurrencyService {
    currencies$ = this.apiService.fetchCurrencies().pipe(shareReplay(1));

    private defaultCurrency$ = this.currencies$.pipe(map((currencies) => this.getDefaultCurrency(currencies)));

    private saveCurrency$ = new Subject<ICurrency>();

    currency$ = new BehaviorSubject<Currency>(null);

    constructor(private apiService: CurrencyApiService, private persistedSettingsService: PersistedSettingsService) {
        merge(this.defaultCurrency$, this.saveCurrency$).subscribe((v) => this.currency$.next(v));
        this.saveCurrency$.subscribe((currency) => this.persistCurrency(currency));
    }

    saveCurrency(currency: ICurrency): void {
        this.saveCurrency$.next(currency);
    }

    private persistCurrency(currency): void {
        this.persistedSettingsService.set(SETTINGS.ROOT_CURRENCY, currency.code);
    }

    private getDefaultCurrency(currencies: ICurrency[]): ICurrency {
        const defaultCurrencyCode = 'USD';
        const persistedCode = this.persistedSettingsService.get(SETTINGS.ROOT_CURRENCY);

        let currency: ICurrency;

        if (persistedCode) {
            currency = currencies.find((v) => v.code === persistedCode);
        }

        if (!currency) {
            currency = currencies.find((v) => v.code === defaultCurrencyCode);
        }

        return currency;
    }
}
