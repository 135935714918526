<form [formGroup]="searchForm" autocomplete="off" class="adc-mat-form" (click)="onFormClick($event)">
    <mat-form-field class="u-input search-field" [ngClass]="controlCls" [class]="size">
        <mat-icon matPrefix svgIcon="search" class="s-{{ searchIconSize }} icon-search"></mat-icon>

        <input
            matInput
            formControlName="value"
            autocomplete="off"
            [placeholder]="placeholderText"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (click)="onInputClick($event)"
            class="adc-mat-search-input"
        />

        <mat-icon
            *ngIf="canClose"
            matSuffix
            svgIcon="close"
            class="s-{{ closeIconSize }} icon-close"
            (click)="onCloseClick()"
        ></mat-icon>
    </mat-form-field>
</form>
