import { groupBy } from 'lodash';

import { options as configOptions } from './line-chart.options';
import { GenericChart } from './generic-chart';
import { chartColors } from '@shared/colors';

export class LineChart extends GenericChart {
    presetsConfig = configOptions;

    applyValuesColors(seriesData: Highcharts.SeriesOptionsType[]): Highcharts.SeriesOptionsType[] {
        const groupedSeries = Object.values(groupBy(seriesData, 'name'));

        return groupedSeries.flatMap((group, i) =>
            group.map((series) => ({ ...series, color: series.color || chartColors[i] })),
        );
    }
}
