import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DefaultEntityCardComponent } from '@shared/components/entity-card';
import { EntityCategory } from '@main/models/EntityCategory';

@Component({
    selector: 'category-entity-card',
    templateUrl: './category-entity-card.component.html',
    styleUrls: ['../entity-card.scss', './category-entity-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryEntityCardComponent extends DefaultEntityCardComponent {
    @Input() item: EntityCategory;

    readonly iconColor = '#4d7cfe';

    get parent(): EntityCategory {
        return this.item.parent || null;
    }

    get categoryTooltip(): string {
        return this.parent?.title || this.item.title;
    }

    get iconUrl(): string {
        return this.parent?.icon || this.item.icon;
    }
}
