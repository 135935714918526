import { merge, Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { catchError, first, map, mapTo, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@shared/services';

import { passwordMatchValidator, passwordStrongValidator } from '../../validators';

interface ErrorResponse {
    message: string;
    token: string;
    fieldErrors: {
        fieldKey: string;
        messages: string[];
    }[];
    error: ErrorResponse;
}

@UntilDestroy()
@Component({
    selector: 'reset-password-page',
    templateUrl: './reset-password-page.component.html',
    styleUrls: ['../../pages.scss', './reset-password-page.scss'],
})
export class ResetPasswordPageComponent implements OnInit {
    errorMessage: string;

    form = this.formBuilder.group({
        password: [null, [Validators.required, passwordStrongValidator]],
        confirmPassword: [null, [Validators.required, passwordMatchValidator]],
    });

    token$ = this.route.params.pipe(
        first(),
        map(({ token }) => token),
    );
    isTokenValid$ = this.token$.pipe(
        switchMap((token) => (token ? this.checkIfTokenValid(token) : of(false))),
        shareReplay(1),
    );
    checkingTokenValidity$ = this.isTokenValid$.pipe(mapTo(false), startWith(true));

    private submitForm$ = new Subject<void>();

    private sendNewPassword$ = this.submitForm$.pipe(
        withLatestFrom(this.token$),
        switchMap(([, token]) => this.authService.setNewPassword(token, this.form.value.password)),
        shareReplay(1),
    );

    submissionProcessing$ = merge(
        this.submitForm$.pipe(mapTo(true)),
        this.sendNewPassword$.pipe(
            mapTo(false),
            catchError(() => of(false)),
        ),
    );

    passwordErrorMessage$ = this.sendNewPassword$.pipe(
        mapTo(null),
        catchError((error) => this.getErrorMessage(error)),
    );

    constructor(
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.form.controls.password.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.form.controls.confirmPassword.updateValueAndValidity());

        this.sendNewPassword$.pipe(untilDestroyed(this)).subscribe(() => this.router.navigate(['password-changed']));
    }

    onSubmit(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.submitForm$.next();
    }

    private checkIfTokenValid(token: string): Observable<boolean> {
        return this.authService.checkTokenForValidity(token).pipe(
            mapTo(true),
            catchError(() => of(false)),
        );
    }

    private getErrorMessage(error: ErrorResponse): string {
        // TODO: clarify response error shape

        const defaultMessage = 'We encountered a problem, please try again later';
        const message: string = error.message || error.error?.message || defaultMessage;
        const tokenErrorType: string = error.token || error.error.token;

        if (tokenErrorType !== 'error.validation') return message;

        const fieldErrors = error.fieldErrors || error.error?.fieldErrors;

        if (!fieldErrors) return message;

        const targetError = fieldErrors.find(({ fieldKey }) => fieldKey === 'password');

        if (!targetError) return message;

        return targetError?.messages[0] || message;
    }
}
