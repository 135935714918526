import { CHANNEL } from '@main/enums';
import { IUserFeatures, IUserRole } from '@shared/interfaces';
import { HttpStatus } from '@shared/enums';
import { AppHttpErrorResponse } from '@shared/models';
import { Observable } from 'rxjs';

export type AuthCredentials = Readonly<{
    username: string;
    password: string;
    rememberMe: boolean;
}>;

export interface AuthTokens {
    authToken: string;
    accessToken: string;
}

export interface LoginOptions {
    rememberMe: boolean;
    redirectToPage$: Observable<string>;
}

export interface AuthTokenLoginOptions extends LoginOptions {
    storeAuthToken: boolean;
}

export type AuthTokenAddress = Readonly<{
    scheme: string;
    domain: string;
    port: string;
}>;

export enum CUSTOMER_TYPE {
    TRIAL = 'TRIAL',
    /* ... */
}

export type AuthTokenClientApp = Readonly<{
    address: AuthTokenAddress;
    addressAlias: AuthTokenAddress;
}>;

export type AuthTokenData = SemrushTokenData &
    Readonly<{
        clientApps?: {
            admin?: AuthTokenClientApp;
            pro?: AuthTokenClientApp;
        };
        url?: string;
        package?: {
            dataPoints?: {
                CHANNEL: CHANNEL;
            };
            features?: IUserFeatures;
        };
        actor?: {
            roles?: IUserRole[];
            customer?: {
                id?: string;
                mail?: string; // good to have
                type: CUSTOMER_TYPE;
            };
            email?: string;
        };
    }>;

export type SemrushTokenData = Readonly<{
    /** Unique app ID. */
    aud: string;

    /** Lifetime period of a valid token (in this case, 5 minutes). */
    exp: number;

    /** App user ID. If this is a corporate account, all its sub users have the same value. */
    viewer_id: string;

    /**
     * The value is false if the app hasn't been purchased or the app is free of charge.
     * Once the user purchases the app or gets a trial, the value becomes true.
     */
    is_main_product_active: boolean;

    /**
     * The value is true if the user gets the app for free.
     *
     * Applicable only for {@link https://www.semrush.com/apps/docs/monetization-options|free and demo apps}.
     */
    is_app_taken_for_free: boolean;

    /**
     * The value is true if the user can activate a trial for the app's main product.
     *
     * For example, it will be true, if the user has never bought the app
     * or activated a trial for its main product before.
     */
    is_main_product_trial_available: boolean;

    /**
     * This array contains all the IDs of the app's products for which the user can activate a trial.
     *
     * For example, if the user has never bought or activated a trial for the in-app purchase with the given id before,
     * that id will be in this array.
     */
    product_trials_available: string[];

    /** List of the user's purchased {@link https://www.semrush.com/apps/docs/in-app-overview|in-app products}. */
    active_products: SemrushProduct[];
}>;

export type SemrushProduct = Readonly<{
    /** Product ID. */
    id: string;
    /** Number of purchased in-app products. */
    value: number;
}>;

export type AuthHttpError = Readonly<{
    message: string;
    method: string;
    status: HttpStatus;
    token: string;
    uri: string;
}>;

export type AuthHttpErrorResponse = AppHttpErrorResponse<AuthHttpError>;

export type CustomerContactsDto = Readonly<{
    customerManager: {
        email: string;
    };
}>;
