import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ANALYTICS_TOOL, getAnalyticsToolService } from './data';
import { directives } from './directives';
import { AnalyticsModuleOptions } from './models';
import { AnalyticsService } from './services';
import { moduleServices } from '@main/reports/shared/module-services';

@NgModule({
    declarations: [...directives],
    imports: [CommonModule],
    exports: [...directives],
    providers: [AnalyticsService, ...moduleServices],
})
export class AnalyticsModule {
    static forRoot(options: AnalyticsModuleOptions): ModuleWithProviders<AnalyticsModule> {
        return {
            ngModule: AnalyticsModule,
            providers: [
                {
                    provide: ANALYTICS_TOOL,
                    useClass: getAnalyticsToolService(options.tool),
                },
            ],
        };
    }
}
