import { Constants } from '@app/util/constants';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateAgo' })
export class DateAgoPipe extends DatePipe implements PipeTransform {
    transform(value: string | number, regularFormat?: string, pastFormat?: string): any {
        const pluralize = (amount: number, singular: string, plural: string) =>
            amount === 1 ? `${amount} ${singular}` : `${amount} ${plural}`;
        const diffInSeconds = Math.floor((+new Date() - +new Date(value)) / 1000);
        const nowYear = new Date().getFullYear();

        if (diffInSeconds < 1) {
            return 'Created Just now';
        }

        if (diffInSeconds < 60) {
            return `Created ${pluralize(diffInSeconds, 'second', 'seconds')} ago`;
        }

        if (diffInSeconds < 3600) {
            return `Created ${pluralize(Math.floor(diffInSeconds / 60), 'minute', 'minutes')} ago`;
        }

        if (diffInSeconds < 86400) {
            return `Created ${pluralize(Math.floor(diffInSeconds / 3600), 'hour', 'hours')} ago`;
        }

        if (diffInSeconds < 172800) {
            const time = super.transform(value, pastFormat || 'h:mm aaa');

            return `Created Yesterday at ${time}`;
        }

        if (nowYear === new Date(value).getFullYear()) {
            return super.transform(value, regularFormat || Constants.DATE_FMT);
        }

        return super.transform(value, Constants.DATE_FMT);
    }
}
