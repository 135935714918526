export enum AD_TYPE {
    BANNER = 'BANNER',
    ANIMATED_BANNER = 'ANIMATED_BANNER',
    VIDEO = 'VIDEO',
    SOCIAL = 'SOCIAL',
    SOCIAL_IMAGE = 'SOCIAL_IMAGE',
    SOCIAL_VIDEO = 'SOCIAL_VIDEO',
    SOCIAL_CAROUSEL = 'SOCIAL_CAROUSEL',
    SOCIAL_PROMOTED_ACCOUNT = 'SOCIAL_PROMOTED_ACCOUNT',
}
