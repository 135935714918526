import jwt_decode from 'jwt-decode';

import { AuthTokenData } from '@shared/services';

export class AuthHelpers {
    static decodeToken<T = AuthTokenData>(token: string): T {
        try {
            return jwt_decode<T>(token);
        } catch (e) {
            return null;
        }
    }

    static decodeTokenHeader<T extends Record<string, any> = Record<string, any>>(token: string): T {
        try {
            return jwt_decode<any>(token, { header: true });
        } catch (e) {
            return null;
        }
    }

    static encodeBasicString(username: string, password: string): string {
        return btoa(unescape(encodeURIComponent(username + ':' + password)));
    }

    static checkOriginClientAppAddress(authToken: string): boolean {
        const decodedToken = this.decodeToken(authToken);
        const clientApps = decodedToken.clientApps;
        const { hostname, port, protocol } = window.location;

        const checkAddress = (address: Record<string, any>): boolean =>
            !!address &&
            `${address.scheme}:` === protocol &&
            address.domain === hostname &&
            (!address.port || address.port.toString() === port);

        const checkApp = (appName: string): boolean =>
            clientApps[appName] &&
            (checkAddress(clientApps[appName].address) || checkAddress(clientApps[appName].addressAlias));

        return checkApp('admin') || checkApp('pro');
    }

    static composeWhiteLabelRedirectUrl(address: Record<string, any>, authToken: string): string {
        return (
            address.scheme +
            '://' +
            address.domain +
            (address.port ? ':' + address.port : '') +
            '/login?jwt=' +
            authToken
        );
    }
}
