import { PAGE_TYPE } from '../enums';
import { AnalysisPageConfig } from './analysis-page-config.types';

export const analysisReportsPagesConfig: AnalysisPageConfig[] = [
    {
        title: 'Categories',
        icon: '/assets/icons/redesign/categories.svg',
        link: '/ad-intelligence/category',
        matchPage: [PAGE_TYPE.CATEGORIES, PAGE_TYPE.CATEGORIES_COMPARE],
        featureFlag: 'category_report',
    },
    {
        title: 'Brands',
        icon: '/assets/icons/redesign/brands.svg',
        link: '/ad-intelligence/brand',
        matchPage: [PAGE_TYPE.BRANDS, PAGE_TYPE.BRANDS_COMPARE],
        featureFlag: 'brand_report',
    },
    {
        title: 'Advertisers',
        icon: '/assets/icons/redesign/advertisers.svg',
        link: '/ad-intelligence/advertiser',
        matchPage: [PAGE_TYPE.ADVERTISERS, PAGE_TYPE.ADVERTISERS_COMPARE],
    },
    {
        title: 'Publishers',
        icon: '/assets/icons/redesign/publishers.svg',
        link: '/ad-intelligence/publisher',
        matchPage: [PAGE_TYPE.PUBLISHERS, PAGE_TYPE.PUBLISHERS_COMPARE],
    },
    {
        title: 'Campaigns',
        icon: '/assets/icons/redesign/campaigns.svg',
        link: '/ad-intelligence/campaign',
        matchPage: [PAGE_TYPE.CAMPAIGN],
    },
    {
        title: 'Keywords',
        icon: '/assets/icons/redesign/keywords.svg',
        link: '/ad-intelligence/keywords',
        matchPage: [PAGE_TYPE.KEYWORDS],
    },
];
