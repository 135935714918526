import mixpanel, { Dict as MixPanelActionPayload } from 'mixpanel-browser';

import { partition } from 'rxjs';
import { first } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';

import { AppConfig } from '@app/app.config';

import { IUser } from '@shared/interfaces';
import { AccountService, WINDOW } from '@shared/services';

import { environment } from '../../../../../environments/environment';
import { AnalyticsActionType, AnalyticsToolService } from '../../models';
import { AnalyticsToolBaseService } from './analytics-tool.base.service';

@Injectable()
export class MixpanelService extends AnalyticsToolBaseService implements AnalyticsToolService {
    disabled: boolean;
    user$ = this.accountService.$currentUser;

    constructor(
        protected accountService: AccountService,
        @Inject(WINDOW) private window: Window,
        private config: AppConfig,
    ) {
        super();

        this.initSubscriptions();
    }

    track(action: AnalyticsActionType, payload: MixPanelActionPayload): void {
        mixpanel.track(action, payload);
    }

    private initSubscriptions(): void {
        const [testUser$, nonTestUser$] = partition(this.user$, ({ id }) => this.isTestUser(id));

        nonTestUser$.pipe(first()).subscribe(() => this.init());
        nonTestUser$.subscribe((user) => this.setIdentity(user));
        nonTestUser$.subscribe(() => (this.disabled = false));
        testUser$.subscribe(() => (this.disabled = true));
    }

    private init() {
        const { mixpanelAnalyticsId } = environment;

        mixpanel.init(mixpanelAnalyticsId, { debug: environment.mixpanelDebug });
    }

    private setIdentity(user: IUser): void {
        const domain = new URL(this.window.location.href).hostname;
        const userName = `${user.firstName} ${user.lastName}`;

        mixpanel.identify(user.id);

        mixpanel.people.set({
            /* can use set_once to persist */ $first_name: user.firstName,
            $last_name: user.lastName,
            $name: userName,
        });

        mixpanel.register({
            domain,
            whiteLabel: this.config.appInfo.id,
            customer: user.customer.name,
        });
    }
}
