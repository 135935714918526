import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { AuthService } from '@shared/services';

import { productAdIntelligencePath } from '@app/config';

@Injectable({ providedIn: 'root' })
export class LoggedOutAccessGuard {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.isNotAuthenticated();
    }

    canActivateChild(): Observable<boolean | UrlTree> {
        return this.isNotAuthenticated();
    }

    private isNotAuthenticated(): Observable<boolean | UrlTree> {
        return this.authService
            .isAuthenticated()
            .pipe(map((isAuthenticated) => !isAuthenticated || this.router.createUrlTree([productAdIntelligencePath])));
    }
}
