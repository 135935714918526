<div fxLayout="row wrap" fxLayoutAlign="center center">
    <div
        *ngFor="let item of items; let i = index"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="item"
        [ngClass]="{ hovered: item.hovered }"
        (mouseenter)="hover(i, true)"
        (mouseleave)="hover(i, false)"
    >
        <div class="colored-circle-box s-10">
            <span [style.backgroundColor]="item.color"></span>
        </div>
        <span class="name ml-8">{{ item.name }}</span>
    </div>
</div>
