import { Directive, Input } from '@angular/core';

import { AnalyticsElementBaseDirective } from './analytics-element.base';

import { UXElementWithTextActionPayload } from '../../models';

@Directive({ selector: 'analyticsElementWithText' })
export abstract class AnalyticsElementWithTextBaseDirective<
    E extends HTMLElement = HTMLElement,
> extends AnalyticsElementBaseDirective<UXElementWithTextActionPayload, E> {
    @Input() analyticsName: string;

    protected getSpecificPayload(): Partial<UXElementWithTextActionPayload> {
        const elementName = this.analyticsName || this.getDefaultName();

        return { elementName };
    }

    private getDefaultName(): string {
        const formattedText = this.host.textContent
            .replaceAll(/(\r\n|\r|\n)/g, '')
            .replaceAll(/[^a-z0-9\d\s.,!?]/gi, '')
            .trim();

        return formattedText;
    }
}
