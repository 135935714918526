import { Class } from '../core/class';
import { PERIOD_TYPE } from '../enums';
import { IPeriodInterval } from '../interfaces';

export enum CommonPeriodCode {
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_3_MONTHS = 'LAST_3_MONTHS',
    LAST_12_MONTHS = 'LAST_12_MONTHS',
    YEAR_TO_DATE = 'YEAR_TO_DATE',
}

export type PeriodCode = CommonPeriodCode | string;

export class Period extends Class {
    id: number | string;
    code: PeriodCode;
    type: PERIOD_TYPE;
    title: string;
    start: string;
    end: string;
    supportedIntervals: IPeriodInterval[];
    defaultInterval: IPeriodInterval;
    customRange?: boolean;
    restricted: boolean;
    disabled: boolean;

    constructor(data: Partial<Period>) {
        super();

        this.id = data.id;
        this.code = data.code;
        this.type = data.type;
        this.title = data.title;

        this.start = data.start;
        this.end = data.end;
        this.supportedIntervals = data.supportedIntervals;
        this.defaultInterval = data.defaultInterval;
        this.customRange = data.customRange;
        this.restricted = data.restricted;
        this.disabled = data.disabled;
    }
}
