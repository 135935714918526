import { ExportImageService } from '@shared/services';
import { GlobalExportPdfService } from '@shared/components/global-export-button/global-export-pdf.service';
import { FiltersService } from '@shared/services/filters/filters.service';
import { ReportSettingsService } from '@shared/services/report-settings.service';
import { ReportService } from '@main/core/report.service';
import { MainExportService } from '@main/services/export.service';
import { RecentsService } from '@main/services/recents.service';
import { CreativesApiService } from '@main/widgets/top-ads/creatives.api-service';
import { PublishersService } from '@main/services/publishers.service';
import { Context } from '@main/core/context';
import { ChannelsService } from '@main/services/channels.service';
import { NavigationService } from '@main/services/navigation.service';
import { CampaignsCommonService } from '@main/services/campaigns.common.service';
import { ReportDataAggregationService } from '@main/services/report-data-aggregation.service';
import { GlobalExportExcelService } from '@main/services/global-export-excel.service';
import { KeywordsApiService } from '../keywords/services/keywords.api-service';
import { CategoriesEntityService } from '../categories/services';
import { KeywordsEntityService } from '../keywords/services/keywords.entity.service';
import { CampaignEntityService } from '../campaign/services/campaign.entity.service';
import { AdvertisersPublishersEntityService } from './services/advertisers-publishers.entity.service';
import { ReportGlobalExportExcelService } from './services/report-global-export-excel.service';

export const moduleServices = [
    RecentsService,
    MainExportService,
    GlobalExportExcelService,
    AdvertisersPublishersEntityService,
    CategoriesEntityService,
    CreativesApiService,
    KeywordsEntityService,
    KeywordsApiService,
    CampaignEntityService,
    PublishersService,
    ExportImageService,
    GlobalExportPdfService,
    FiltersService,
    ReportSettingsService,
    ReportDataAggregationService,
    ChannelsService,
    ReportService,
    NavigationService,
    CampaignsCommonService,
    Context,
    {
        provide: GlobalExportExcelService,
        useClass: ReportGlobalExportExcelService,
    },
];
