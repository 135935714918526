import { FlexLayoutModule } from '@angular/flex-layout';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from 'app/app.component';
import { AppConfig } from './app.config';
import { AppRoutingModule } from './app.routes';
import { StorageService } from '@core/services/storage.service';
import { WINDOW_PROVIDERS } from '@shared/services/window.providers';
import { RoleService } from '@shared/services/role.service';
import { LayoutService, downloadServices } from '@shared/services';
import { MarkStateService } from '@main/components/mark-entity-button/services/mark-state.service';
import { MarkService } from '@main/components/mark-entity-button/services/mark.service';
import { MarkHelper } from '@main/components/mark-entity-button/mark.helper';
import { TopToolbarModule } from '@main/shared/top-toolbar/top-toolbar.module';
import { setAppInjector } from './util/injector';

import CustomUrlSerializer from './util/custom-url-serializer';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormControlsModule } from '@shared/form-controls/form-controls.module';
import { AnalyticsModule } from '@app/modules/analytics/analytics.module';
import { ReactiveFormsModule } from '@angular/forms';
import { interceptors } from '@core/interceptors';

import { pages } from './pages';
import { SharedTopNotificationBarModule } from './modules/top-notification/top-notification-bar.module';
import { GroupsService } from './app-main/services/groups.service';
import { LimitsService } from '@main/services/limits.service';
import { FeaturesConfig } from '@app/features.config';
import { FeatureToggleModule } from '@main/shared/feature-toggle/feature-toggle.module';
import { provideEffectsManager } from '@ngneat/effects-ng';
import { EntityCommonService } from '@main/services/entity-common.service';
import { IntegrationsModule } from '@app/integrations/integrations.module';
import { AnalysisPageConfigService } from './app-main/services/analysis-page-config.service';
import { GroupsApiService } from '@main/services/groups-api.service';

export const initializeApp = (appConfig: AppConfig, featuresConfig: FeaturesConfig) => () => {
    return Promise.all([featuresConfig.resolve(), appConfig.resolve()]);
};

@NgModule({
    declarations: [...pages, AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        SharedTopNotificationBarModule,
        IntegrationsModule,
        TopToolbarModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        FormControlsModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        MatProgressSpinnerModule,
        AnalyticsModule.forRoot({ tool: 'mixpanel' }),
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'once' }),
        FeatureToggleModule.forRoot(),
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy, // HashLocationStrategy
        },
        { provide: APP_BASE_HREF, useValue: '/' },
        WINDOW_PROVIDERS,
        AppConfig,
        FeaturesConfig,
        StorageService,
        RoleService,
        GroupsService,
        GroupsApiService,
        MarkStateService,
        MarkService,
        MarkHelper,
        LimitsService,
        HttpClientModule,
        StorageService,
        LayoutService,
        EntityCommonService,
        AnalysisPageConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig, FeaturesConfig],
            multi: true,
        },
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer,
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                showDelay: 300,
                disableTooltipInteractivity: true,
            },
        },
        ...downloadServices,
        ...interceptors,
        provideEffectsManager({ dispatchByDefault: false }),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(injector: Injector) {
        setAppInjector(injector);
    }
}
