<div fxFlex fxLayout="row" fxLayoutAlign="start center">
    <button
        class="u-button h-32 font-12"
        tabindex="-1"
        mat-button
        color="primary"
        matTooltip="Open table filters"
        matTooltipPosition="after"
        [matMenuTriggerFor]="filtersMenu"
        [disabled]="disabled"
        (click)="onFiltersButtonClick($event)"
    >
        <mat-icon class="s-12 mr-4" svgIcon="filter"></mat-icon>
        Filter
    </button>

    <!-- chips submenus -->
    <ng-template ngFor let-filter [ngForOf]="filters">
        <mat-menu #menuView [overlapTrigger]="false" class="grid-filter-chip-popup">
            <ng-template matMenuContent>
                <div (click)="$event.stopPropagation()" class="px-16">
                    <!-- div class="adc-h6m">{{ filter.title }}</div -->

                    <div class="p-0" [ngSwitch]="filter.type">
                        <ng-container *ngSwitchCase="FilterType.VALUE">
                            <ng-container
                                *ngTemplateOutlet="filterValueTpl; context: { $implicit: filter }"
                            ></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="FilterType.RANGE">
                            <ng-container
                                *ngTemplateOutlet="filterRangeTpl; context: { $implicit: filter }"
                            ></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="FilterType.PERCENT_RANGE">
                            <ng-container
                                *ngTemplateOutlet="filterPercentRangeTpl; context: { $implicit: filter }"
                            ></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="FilterType.DATES_RANGE">
                            <ng-container
                                *ngTemplateOutlet="filterDatesRangeTpl; context: { $implicit: filter }"
                            ></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="FilterType.MULTI_SELECTABLE_LIST">
                            <ng-container
                                *ngTemplateOutlet="filterMultiSelectableListTpl; context: { $implicit: filter }"
                            ></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="FilterType.SINGLE_SELECTABLE_LIST">
                            <ng-container
                                *ngTemplateOutlet="filterSingleSelectableListTpl; context: { $implicit: filter }"
                            ></ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
        </mat-menu>
    </ng-template>

    <!-- all filters chips -->
    <mat-chip-listbox #chipList *ngIf="menuViewReady">
        <ng-template ngFor let-filter let-i="index" [ngForOf]="filters">
            <mat-chip-option
                *ngIf="true"
                [ngClass]="{ 'filter-chip-active': filter.active }"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeFilter(filter)"
                class="filter-chip p-0 h-32"
            >
                <mat-icon *ngIf="removable" class="s-16" matChipRemove>close</mat-icon>
                <button
                    mat-button
                    [matMenuTriggerFor]="filter.menuView"
                    #menuTrigger="matMenuTrigger"
                    (menuOpened)="onFilterFormShow(filter)"
                    (menuClosed)="onFilterFormHide(filter)"
                    [innerHTML]="filter.title + ': ' + filter.textValue"
                ></button>
            </mat-chip-option>
        </ng-template>
    </mat-chip-listbox>

    <!-- filters dropdown -->
    <mat-menu
        #filtersMenu="matMenu"
        [overlapTrigger]="false"
        class="grid-filter-filters-list-dropdown u-dropdown medium"
    >
        <ng-template ngFor let-filter [ngForOf]="filters">
            <button mat-button (click)="showFilter(filter)">{{ filter.title }}</button>
        </ng-template>
    </mat-menu>
</div>

<!-- single value input form -->
<ng-template #filterValueTpl let-filter>
    <form [formGroup]="filter.form" class="pt-8 w-200">
        <div class="field-title">Contains</div>
        <mat-form-field class="w-100-p u-input-simple">
            <input matInput formControlName="value" />
        </mat-form-field>
    </form>
</ng-template>

<!-- range input form -->
<ng-template #filterRangeTpl let-filter>
    <form
        [formGroup]="filter.form"
        (keydown.tab)="filter.lastControlFocus ? null : $event.stopPropagation()"
        class="pt-8 w-200"
    >
        <div class="field-title">Greater than</div>
        <mat-form-field class="w-100-p u-input-simple">
            <input matInput formControlName="from" tabindex="1" (focus)="filter.lastControlFocus = false" />
        </mat-form-field>

        <div class="field-title">Less than</div>
        <mat-form-field class="w-100-p u-input-simple">
            <input matInput formControlName="to" tabindex="2" (focus)="filter.lastControlFocus = true" />
        </mat-form-field>
    </form>
</ng-template>

<!-- percent range input form -->
<ng-template #filterPercentRangeTpl let-filter>
    <form [formGroup]="filter.form" class="py-8 w-200 range-filter">
        <div class="range-text">Selected range: {{ formatValue(filter) }}</div>
        <nouislider
            [min]="0"
            [max]="100"
            [step]="1"
            [tooltips]="[true, true]"
            [formControl]="filter.form.controls.value"
        ></nouislider>
    </form>
</ng-template>

<!-- date range input form -->
<ng-template #filterDatesRangeTpl let-filter>
    <form [formGroup]="filter.form" class="pt-8 w-240">
        <div class="field-title">Choose a date</div>
        <mat-form-field class="w-100-p u-input-simple">
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [value]="filter._value.from" formControlName="from" placeholder="Start date" />
                <input matEndDate [value]="filter._value.to" formControlName="to" placeholder="End date" />
            </mat-date-range-input>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
        </mat-form-field>
    </form>
</ng-template>

<!-- multi selectable list "form" -->
<ng-template #filterMultiSelectableListTpl let-filter>
    <div *ngIf="filter.item.data" class="pt-8 multi-select-list-filter-items">
        <div *ngFor="let item of filter.item.data; let i = index" class="item">
            <mat-checkbox
                [checked]="filter._value[i]"
                (change)="onFilterChange(filter.id, { index: i, checked: $event.checked })"
            >
                <span [innerHTML]="item.title"></span>
            </mat-checkbox>
        </div>
    </div>
</ng-template>

<!-- single selectable list "form" -->
<ng-template #filterSingleSelectableListTpl let-filter>
    <div *ngIf="filter.item.data" class="pt-8 single-select-list-filter-items">
        <div *ngFor="let item of filter.item.data; let i = index" class="item">
            <button mat-menu-item fxLayoutAlign="space-between center" (click)="onFilterChange(filter.id, item)">
                <span [innerHTML]="item.title"></span>
            </button>
        </div>
    </div>
</ng-template>
