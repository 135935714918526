/* eslint-disable max-lines */
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { RequestService } from '@shared/services/request/request.service';
import { Page } from '@shared/models/request-params.model';
import { DatasetRequestParams } from '@shared/services/request/request.types';
import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '@main/enums';
import { IEntityItemId } from '@main/types';
import { GroupsApiService } from './groups-api.service';
import {
    ChannelFilter,
    InstancesResolverItem,
    ResponseResolveInstances,
    ResponseSearchInstances,
} from './entity-common.service.types';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { FeatureToggleService } from '@main/shared/feature-toggle';
import { IEntityItemGroupId, IEntityItemInstanceId, RouteIdsItem } from '../types/types';
import { ReportHelpers } from '../core/report.helpers';
import { EntityCategory, EntityGroup, EntityItem } from '../models';
import { EntityInstance, EntitySingle } from '../models/EntityItem';
import { KeywordsHelpers } from '../reports/keywords/keywords.helpers';
import { GroupsHelper } from '@main/services/groups.helper';
import { IGroupDto } from '@main/interfaces';

@Injectable()
export class EntityCommonService {
    private readonly instancesCache = new Map<IEntityItemId, EntitySingle>();
    private categoryEnabled = this.featureToggleService.isEnabled('category_report');

    private virtualGroupsCounter = 0;
    private readonly listSize = 10;

    constructor(
        private requestService: RequestService,
        private groupsApiService: GroupsApiService,
        private featureToggleService: FeatureToggleService,
    ) {}

    getInstancesResolver(items: InstancesResolverItem[]): Observable<EntityItem[]> {
        const resolvers = items.map(([entityType, ids]) =>
            this.resolveEntitiesByIds<EntityItem>(ids, entityType).pipe(catchError(() => of<EntityItem[]>([]))),
        );

        return resolvers.length ? forkJoin(resolvers).pipe(map((result) => result.flat())) : of([]);
    }

    resolveEntitiesByIds<T>(
        ids: RouteIdsItem[],
        entityType?: BUSINESS_ENTITY,
        channel: ChannelFilter = null,
    ): Observable<T[]> {
        const plainIds = ReportHelpers.plainRouteIds(ids);

        const singleInstancesIds = plainIds.filter((id) =>
            EntityHelpers.isSingleInstanceId(id),
        ) as IEntityItemInstanceId[];

        const groupsIds = plainIds.filter((id) => EntityHelpers.isGroupId(id)) as IEntityItemGroupId[];

        return this.resolveGroupsByIds(groupsIds).pipe(
            mergeMap((result) => {
                const groupsItems = result.flat();
                const groupsInstancesIds = this.gatherGroupsInstancesIds(groupsItems);
                const groupCategoryBenchmarkIds = EntityHelpers.uniqIds(
                    groupsItems.map((v) => v._dto.details.categoryId).filter(Boolean),
                );

                const allInstancesIds = EntityHelpers.uniqIds([singleInstancesIds, groupsInstancesIds].flat());

                return forkJoin([
                    this.resolveSingleEntitiesById(entityType, allInstancesIds, channel),

                    groupCategoryBenchmarkIds.length
                        ? this.resolveSingleEntitiesById(BUSINESS_ENTITY.Category, groupCategoryBenchmarkIds)
                        : of([]),
                ]).pipe(
                    map(([instancesEntities, categoryBenchmarkEntities]) =>
                        this.buildEntitiesTree(
                            entityType,
                            ids,
                            [...groupsItems, ...instancesEntities, ...categoryBenchmarkEntities],
                            true,
                        ),
                    ),
                );
            }),
        ) as unknown as Observable<T[]>;
    }

    resolveGroupsItems(groupsItems: EntityGroup[]): Observable<EntityItem[]> {
        if (!groupsItems.length) {
            return of([]);
        }

        const entityType = groupsItems[0].entityType;

        if (entityType === BUSINESS_ENTITY.Keyword) {
            groupsItems.forEach((group) => {
                group.items = KeywordsHelpers.createKeywordsEntities(group._dto.details.instances);
            });

            return of(groupsItems);
        }

        const groupsIds = groupsItems.map((v) => v.id);
        const groupsInstancesIds = this.gatherGroupsInstancesIds(groupsItems);
        const groupCategoryBenchmarkIds = EntityHelpers.uniqIds(
            groupsItems.map((v) => v._dto.details.categoryId).filter(Boolean),
        );

        const allInstancesIds = EntityHelpers.uniqIds(groupsInstancesIds.flat());

        return forkJoin([
            this.resolveSingleEntitiesById(entityType, allInstancesIds),
            groupCategoryBenchmarkIds.length
                ? this.resolveSingleEntitiesById(BUSINESS_ENTITY.Category, groupCategoryBenchmarkIds)
                : of([]),
        ]).pipe(
            map(([instancesEntities, categoryBenchmarkEntities]) =>
                this.buildEntitiesTree(entityType, groupsIds, [
                    ...groupsItems,
                    ...instancesEntities,
                    ...categoryBenchmarkEntities,
                ]),
            ),
        );
    }

    resolveSingleEntitiesById(
        entityType: BUSINESS_ENTITY,
        ids: IEntityItemId[],
        channel: ChannelFilter = null,
    ): Observable<EntitySingle[]> {
        const { singleKey, dataset, recentDataset } = EntityHelpers.getEntityInfo(entityType);

        const fetchIds = ids.filter(
            (id) =>
                !!channel ||
                ![...this.instancesCache.values()].find(
                    (item) => item.id === id && item.entityType !== BUSINESS_ENTITY.Brand,
                ),
        );

        const datasetParams: DatasetRequestParams = {
            datasetName: channel ? recentDataset : dataset,
            entityName: singleKey,
            entityItemsIds: fetchIds,
            labelArea: 'base_entity_service',
        };

        if (channel) {
            datasetParams.channel = channel;
            datasetParams.requestParams = {
                page: { size: this.listSize } as Page,
            };
        }

        const request = fetchIds.length
            ? this.requestService.dataset<ResponseResolveInstances>(datasetParams)
            : of<ResponseResolveInstances>(null);

        return request.pipe(
            map((result) => {
                if (result?.elements) {
                    result.elements.forEach((item) => {
                        const newItem = EntityHelpers.mapSingleEntity(entityType, item);

                        this.instancesCache.set(newItem.id, newItem);
                    });
                }

                return ids.map((id) => [...this.instancesCache.values()].find((v) => v.id === id)).filter(Boolean);
            }),
        );
    }

    resolveGroupsByIds(groupsIds: IEntityItemGroupId[]): Observable<EntityGroup[]> {
        if (!groupsIds.length) {
            return of([]);
        }

        const groupResolvers = groupsIds.map((id: string) =>
            this.groupsApiService.fetchGroup<IGroupDto>(id).pipe(
                switchMap((group) =>
                    GroupsHelper.resolveSingleUnsavedGroup(group, this.resolveSingleEntitiesById.bind(this)),
                ),
                map((group) => {
                    return GroupsHelper.deserializeGroup(group);
                }),
            ),
        );

        return forkJoin(groupResolvers);
    }

    searchSingleEntities(
        entityType: BUSINESS_ENTITY | BUSINESS_ENTITY[],
        q: string,
        size: number,
        channel: ChannelFilter = null,
    ): Observable<EntitySingle[]> {
        if (entityType) {
            return Array.isArray(entityType)
                ? this.searchSingleEntitiesByTypes(entityType, q, size, channel)
                : this.searchSingleEntitiesByType(entityType, q, size, channel);
        }

        // not sure !entityType case still actual

        const entitiesTypes = [BUSINESS_ENTITY.Advertiser, BUSINESS_ENTITY.Publisher];

        if (this.categoryEnabled) {
            entitiesTypes.push(BUSINESS_ENTITY.Category);
        }

        return this.searchSingleEntitiesByTypes(entitiesTypes, q, size, channel);
    }

    private searchSingleEntitiesByTypes(
        entityTypes: BUSINESS_ENTITY[],
        q: string,
        count = 100,
        channel: ChannelFilter = null,
    ): Observable<EntitySingle[]> {
        const list$ = entityTypes.map((entityType) => this.searchSingleEntitiesByType(entityType, q, count, channel));

        return forkJoin(list$).pipe(
            map((lists) =>
                lists.map((list) => EntityHelpers.sortInstancesByImpressions(<EntityInstance[]>list).slice(0, 20)),
            ), // @TODO: @FIXME: (TACTICAL FIX)
            map((result) => EntityHelpers.sortInstancesByImpressions(<EntityInstance[]>result.flat()).slice(0, count)),
        );
    }

    clearCache(): void {
        this.instancesCache.clear();
    }

    private searchSingleEntitiesByType(
        entityType: BUSINESS_ENTITY,
        q: string,
        size: number = 100,
        channel: ChannelFilter = null,
    ): Observable<EntitySingle[]> {
        const { dataset } = EntityHelpers.getEntityInfo(entityType);
        const params = channel ? { q, size, channel } : { q, size };

        const datasetParams: DatasetRequestParams = {
            datasetName: dataset,
            customParams: params,
            labelArea: 'base_entity_service',
        };

        const processResponse = (response: ResponseSearchInstances): EntitySingle[] => {
            const result = response?.elements?.map((item) => EntityHelpers.mapSingleEntity(entityType, item)) || [];

            result[Symbol.for('total')] = response?.count.total || 0;

            return result;
        };

        return this.requestService.dataset<ResponseSearchInstances>(datasetParams).pipe(
            catchError(() => of(null)),
            map((response) => processResponse(response)),
        );
    }

    private gatherGroupsInstancesIds(items: EntityGroup[]): IEntityItemId[] {
        const ids = items
            .map((v) => {
                const {
                    details: { instances: instancesIds = [], unsavedGroups = [] },
                    relations = [],
                } = v._dto;
                const subGroupsIds = relations.map((f) => f.details.instances).flat();
                const unsavedIds = unsavedGroups.map((g) => g.instances).flat();
                return [...subGroupsIds, ...instancesIds, ...unsavedIds];
            })
            .flat();

        return EntityHelpers.uniqIds(ids);
    }

    private buildEntitiesTree(
        entityType: BUSINESS_ENTITY,
        ids: RouteIdsItem[],
        resolvedEntities: EntityItem[],
        isRoot = false,
    ): EntityItem[] {
        if (isRoot) {
            this.virtualGroupsCounter = 0;
        }

        return ids
            .map((id) => {
                if (Array.isArray(id)) {
                    this.virtualGroupsCounter++;

                    const virtualGroupTitle = id[Symbol.for('name')];

                    const virtualGroupItems = this.buildEntitiesTree(entityType, id, resolvedEntities);

                    if (!virtualGroupItems.length) {
                        return;
                    }

                    return EntityHelpers.createVirtualGroup(
                        this.virtualGroupsCounter,
                        virtualGroupTitle,
                        virtualGroupItems,
                    );
                }

                const isGroup = EntityHelpers.isGroupId(id) || EntityHelpers.isVirtualGroupId(id);

                if (isGroup) {
                    const group = <EntityGroup>(
                        resolvedEntities.find((v) => v.id === id && v.type === ENTITY_ITEM_TYPE.GROUP)
                    );

                    if (!group) {
                        return;
                    }

                    group.items
                        .filter((v) => v instanceof EntityGroup)
                        .forEach((v: EntityGroup) => {
                            v.items = (
                                this.buildEntitiesTree(entityType, [v.id], [v, ...resolvedEntities])[0] as EntityGroup
                            ).items;
                            v.setResolved();
                        });

                    // @FIXME: tactical fix
                    if (GroupsHelper.hasUnsavedGroups(group._dto) && !group.isVirtual()) {
                        group._dto.details.unsavedGroups.forEach((unsavedGroup, index) => {
                            if (group.items.some((subgroup) => subgroup.title === unsavedGroup.name)) {
                                return;
                            }

                            group.items.push(
                                EntityHelpers.createVirtualGroup(
                                    index,
                                    unsavedGroup.name,
                                    resolvedEntities.filter((item) => unsavedGroup.instances.includes(+item.id)),
                                ),
                            );
                        });
                    }

                    if (!group.isResolved()) {
                        const { instances: instancesIds = [], categoryId } = group._dto.details;

                        group.items.push(...this.buildEntitiesTree(entityType, instancesIds, resolvedEntities));

                        if (categoryId) {
                            const categoryBenchmarkEntity = resolvedEntities.find(
                                (v) => v.id === categoryId,
                            ) as EntityCategory;
                            categoryBenchmarkEntity.setAsBenchmarkCategory();

                            group.items.push(categoryBenchmarkEntity);
                        }

                        group.setResolved();
                    }

                    return group;
                }

                const isSingleInstance = EntityHelpers.isSingleInstanceId(id);

                if (isSingleInstance) {
                    return resolvedEntities.find((v) => v.id === id && v.type === ENTITY_ITEM_TYPE.SINGLE);
                }
            })
            .filter((v) => !!v);
    }
}
