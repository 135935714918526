export enum WIDGET_DIMENSION {
    ADVERTISERS = 'ADVERTISERS',
    PUBLISHERS = 'PUBLISHERS',
    IMPRESSIONS = 'IMPRESSIONS',
    SPEND = 'SPEND',
    CREATIVES = 'CREATIVES',
}

export interface WidgetDimension {
    id: WIDGET_DIMENSION;
    title: string;
    fullTitle: string;
    colorText: string;
    isCurrency?: boolean;
}
