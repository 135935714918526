/* eslint-disable max-lines */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { range } from './month-picker.helpers.component';
import { isNil } from 'lodash';
import * as moment from 'moment';

interface MonthItem {
    index: number;
    monthName: string;
    monthYear: number;
    monthMoment: moment.Moment;
    startDate: string;
    endDate: string;
    selectionMiddle: boolean;
    selectionStart: boolean;
    selectionEnd: boolean;
    disabled: boolean;
    selected: boolean;
    partiallySelected: boolean;
}

export type MonthRange = Readonly<{
    fromMonthYear: MonthItem;
    toMonthYear: MonthItem;
}>;

@Component({
    selector: 'month-picker',
    templateUrl: 'month-picker.component.html',
    styleUrls: ['month-picker.component.scss'],
})
export class MonthPickerComponent implements OnInit, OnChanges {
    /* first shown date on the calendar */
    @Input() minDate: string;

    /* last shown date on the calendar */
    @Input() maxDate: string;

    /* selected period start */
    @Input() startDate: string;

    /* selected period end */
    @Input() endDate: string;

    /* months available for selection, default is 'all in range' */
    @Input() selectableMonthsDates: string[];

    /* allowed month count to select, default - no limit */
    @Input() maxSelectableMonthsCount: number;

    /* range selection change event */
    @Output() monthRangeSelected = new EventEmitter<MonthRange>();

    leftYearIndex: number;
    years: number[];
    monthDataFrame: MonthItem[][] = [];

    private monthsData: MonthItem[];
    private monthsNames: string[];
    private selectionRangeIndexStart: number;
    private selectionRangeIndexEnd: number;
    private monthsFramesIndexes: number[];
    private globalIndexOffset: number;
    private currentYear: number;

    private initialized: boolean;

    ngOnInit(): void {
        this.initYears();
        this.calcLeftYearIndex();
        this.initMonthNames();
        this.initViewSlices();
        this.initMonthsData();
        this.updateMonthsAvailability();
        this.initSelectionRangeIndexes();
        this.updateSlicedView();

        if (this.startDate && this.endDate) {
            this.applyDates();
        }

        this.initialized = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.initialized) {
            return;
        }

        if (changes.startDate?.currentValue || changes.endDate?.currentValue) {
            this.applyDates();
        }
    }

    onClick(month: MonthItem): void {
        if (month.disabled) {
            return;
        }

        if (!isNil(this.selectionRangeIndexStart) && !isNil(this.selectionRangeIndexEnd)) {
            this.resetSelection();
        }

        if (isNil(this.selectionRangeIndexStart)) {
            this.selectionRangeIndexStart = month.index;
        } else {
            this.selectionRangeIndexEnd = month.index;

            if (this.selectionRangeIndexStart > this.selectionRangeIndexEnd) {
                this.swapSelectionIndexes();
            }
        }

        this.updateSelection();
        this.updateMonthsAvailability();
        this.updateSlicedView();
        this.emitData();
    }

    incrementYear(): void {
        if (this.isYearSelectable(this.leftYearIndex + 1)) {
            this.leftYearIndex++;
            this.updateSlicedView();
        }
    }

    decrementYear(): void {
        if (this.isYearSelectable(this.leftYearIndex - 1)) {
            this.leftYearIndex--;
            this.updateSlicedView();
        }
    }

    isYearSelectable(yearIndex: number): boolean {
        return yearIndex >= 0 && yearIndex < this.years.length - 1;
    }

    private applyDates(): void {
        this.resetSelection();

        let partiallySelected = false;

        let startMonth = this.monthsData.find((v) => v.startDate === this.startDate);
        let endMonth = this.monthsData.find((v) => v.endDate === this.endDate);

        if (!startMonth) {
            startMonth = this.monthsData.find((v) => moment(this.startDate).isBetween(v.startDate, v.endDate));
            partiallySelected = !!startMonth;
        }

        if (!endMonth) {
            endMonth = this.monthsData.find((v) => moment(this.endDate).isBetween(v.startDate, v.endDate));
            partiallySelected = !!endMonth;
        }

        if (startMonth && endMonth) {
            if (partiallySelected) {
                startMonth.partiallySelected = true;
                endMonth.partiallySelected = true;
            }

            this.selectionRangeIndexStart = startMonth.index;
            this.selectionRangeIndexEnd = endMonth.index;
        }

        this.updateSelection();
        this.calcLeftYearIndex();
        this.updateSlicedView();
    }

    private updateSelection(): void {
        if (this.selectionRangeIndexStart === null && this.selectionRangeIndexEnd === null) {
            return;
        }

        const isRange =
            this.selectionRangeIndexStart !== null &&
            this.selectionRangeIndexEnd !== null &&
            this.selectionRangeIndexStart !== this.selectionRangeIndexEnd;

        this.monthsData.forEach((month) => {
            if (month.index > this.selectionRangeIndexStart && month.index < this.selectionRangeIndexEnd) {
                month.selectionMiddle = true;
                month.selected = true;
            }

            if (this.selectionRangeIndexStart === month.index) {
                if (isRange) {
                    month.selectionStart = true;
                }
                month.selected = true;
            }

            if (this.selectionRangeIndexEnd === month.index) {
                if (isRange) {
                    month.selectionEnd = true;
                }
                month.selected = true;
            }
        });
    }

    private resetSelection(): void {
        this.initSelectionRangeIndexes();

        this.monthsData.forEach((month) => {
            month.selected = false;
            month.partiallySelected = false;
            month.selectionMiddle = false;
            month.selectionStart = false;
            month.selectionEnd = false;
        });
    }

    private emitData(): void {
        const fromMonthYear = this.monthsData[this.selectionRangeIndexStart];
        const toMonthYear = this.monthsData[this.selectionRangeIndexEnd];

        this.monthRangeSelected.emit({
            fromMonthYear,
            toMonthYear: toMonthYear || fromMonthYear,
        });
    }

    private updateSlicedView(): void {
        this.globalIndexOffset = this.monthsFramesIndexes[this.leftYearIndex];

        // left calendar
        this.monthDataFrame[0] = this.monthsData.slice(this.globalIndexOffset, this.globalIndexOffset + 12);

        // right calendar
        this.monthDataFrame[1] = this.monthsData.slice(this.globalIndexOffset + 12, this.globalIndexOffset + 24);
    }

    private initSelectionRangeIndexes(): void {
        this.selectionRangeIndexStart = null;
        this.selectionRangeIndexEnd = null;
    }

    private initMonthsData(): void {
        this.monthsData = [];

        this.years.forEach((monthYear) => {
            this.monthsNames.forEach((monthName, monthIndex) => {
                const monthMoment = moment(new Date(monthYear, monthIndex));

                const startDate = monthMoment.clone().startOf('month').format('YYYY-MM-DD');
                const endDate = monthMoment.clone().endOf('month').format('YYYY-MM-DD');

                this.monthsData.push({
                    index: this.monthsData.length,
                    monthMoment,
                    monthName,
                    monthYear,
                    startDate,
                    endDate,
                    selectionMiddle: false,
                    selectionStart: false,
                    selectionEnd: false,
                    selected: false,
                    partiallySelected: false,
                    disabled: false,
                });
            });
        });
    }

    private updateMonthsAvailability(): void {
        const minDateMoment = moment(this.minDate);
        const maxDateMoment = moment(this.maxDate);

        const selectableMonthsMoments = this.selectableMonthsDates?.map((v) => moment(v));
        const selectableMonthsMaxCount = this.maxSelectableMonthsCount;

        this.monthsData.forEach((item, index) => {
            const { monthMoment } = item;

            const isMonthSelectable =
                !selectableMonthsMoments || !!selectableMonthsMoments.find((v) => v.isSame(monthMoment, 'month'));

            const isMonthOutOfMaxCountLimit =
                selectableMonthsMaxCount &&
                !isNil(this.selectionRangeIndexStart) &&
                isNil(this.selectionRangeIndexEnd) &&
                Math.abs(this.selectionRangeIndexStart - index) > selectableMonthsMaxCount - 1;

            item.disabled =
                !isMonthSelectable ||
                isMonthOutOfMaxCountLimit ||
                monthMoment.isBefore(minDateMoment) ||
                monthMoment.isAfter(maxDateMoment) ||
                monthMoment.isSame(new Date(), 'month');
        });
    }

    private initYears(): void {
        this.currentYear = moment().year();

        this.years = range(moment(this.minDate).year(), moment(this.maxDate).year());

        // add year if less than 2
        if (this.years.length === 1) {
            if (this.years[0] === this.currentYear) {
                this.years.unshift(this.years[0] - 1);
            } else {
                this.years.push(this.years[0] + 1);
            }
        }
    }

    private calcLeftYearIndex(): void {
        this.leftYearIndex = this.years.findIndex((year) => year === moment(this.startDate).year());

        if (this.leftYearIndex === -1) {
            this.leftYearIndex = this.years.findIndex((year) => year === this.currentYear - 1);
        }

        if (this.leftYearIndex === this.years.length - 1) {
            this.leftYearIndex--;
        }
    }

    private initMonthNames(): void {
        this.monthsNames = new Array(12).fill(0).map((_, i) => moment().month(i).format('MMM'));
    }

    private initViewSlices(): void {
        this.monthsFramesIndexes = [];

        this.years.forEach((year, index) => {
            this.monthsFramesIndexes.push(index * 12);
        });
    }

    private swapSelectionIndexes(): void {
        const c = this.selectionRangeIndexStart;
        this.selectionRangeIndexStart = this.selectionRangeIndexEnd;
        this.selectionRangeIndexEnd = c;
    }
}
