<ng-container *ngTemplateOutlet="calendatTpl; context: { $implicit: { yearInc: 0 } }"></ng-container>

<ng-container *ngTemplateOutlet="calendatTpl; context: { $implicit: { yearInc: 1 } }"></ng-container>

<ng-template #calendatTpl let-params>
    <div class="outer-card">
        <div class="top-panel">
            <button
                class="switch-year-button prev-year-button"
                (click)="decrementYear()"
                [ngClass]="{ disabled: !isYearSelectable(leftYearIndex - 1) }"
            >
                <mat-icon class="s-16">keyboard_arrow_left</mat-icon>
            </button>

            <span class="year-label">{{ years[leftYearIndex + params.yearInc] }}</span>

            <button
                class="switch-year-button next-year-button"
                (click)="incrementYear()"
                [ngClass]="{ disabled: !isYearSelectable(leftYearIndex + 1) }"
            >
                <mat-icon class="s-16">keyboard_arrow_right</mat-icon>
            </button>
        </div>

        <div class="content-panel">
            <div
                *ngFor="let month of monthDataFrame[params.yearInc]"
                (click)="onClick(month)"
                class="month-item-box"
                [ngClass]="{
                    selected: month.selected,
                    'partially-selected': month.partiallySelected,
                    'range-middle': month.selectionMiddle,
                    'range-start': month.selectionStart,
                    'range-end': month.selectionEnd,
                    disabled: month.disabled
                }"
            >
                <div class="month-item-blank-placeholder"></div>
                <div class="month-item">
                    {{ month.monthName }}
                </div>
            </div>
        </div>
    </div>
</ng-template>
