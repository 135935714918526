import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minval',
})
export class MinValPipe implements PipeTransform {
    private min = '<0.1';

    transform(value: unknown): string {
        if (typeof value === 'string' && isNaN(parseFloat(value))) {
            return '';
        }

        if (typeof value === 'number') {
            if (!isFinite(value)) {
                return '';
            }

            if (value < 0.1) {
                return this.min;
            }
        }

        if (value == null) {
            return 'N/A';
        }

        return value.toString();
    }
}
