import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonHelpers } from '@shared/helpers/common.helpers';
import { OptionItem, Item, ItemId, ToggledItem } from './button-group.types';

@Component({
    selector: 'button-group',
    templateUrl: 'button-group.component.html',
    styleUrls: ['button-group.component.scss'],
})
export class ButtonGroupComponent implements OnChanges {
    @Input() options: OptionItem[] = [];
    @Input() available: ItemId[] = [];
    @Input() selected: ItemId[];
    @Input() disabled: boolean;
    @Input() canUnselectLast = false;
    @Input() iconSize = 18;
    @Input() styleConf: Record<string, string | number>;

    @Output() selectedChange = new EventEmitter<ItemId[]>();
    @Output() change = new EventEmitter<ItemId[]>();
    @Output() toggle = new EventEmitter<ToggledItem>();

    items: Item[] = [];
    _selected: ItemId[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options?.currentValue) {
            this.items = this.mapOptionItems(this.options);
        }

        if (changes.selected?.currentValue && !!this.options?.length) {
            const selected = this.mapSelectedItems();

            if (!CommonHelpers.equilObjects(selected, this._selected)) {
                this._selected = this.mapSelectedItems();
            }
        }

        this.updateState();
    }

    click(item: Item): void {
        if (!this.canUnselectLast && this._selected.length === 1 && this._selected[0] === item.id) {
            return;
        }

        this.updateState();

        const selected = !!this._selected.find((id) => item.id === id);
        const toggledItem: ToggledItem = { ...item, selected };

        setTimeout(() => {
            this.selectedChange.emit(this._selected);
            this.change.emit(this._selected);
            this.toggle.emit(toggledItem);
        });
    }

    private updateState(): void {
        this.items.forEach((item) => {
            item.display = this.available.includes(item.id);
            item.disabled = false;
            item.tooltip = this._selected.includes(item.id) ? `Hide ${item.tooltipTitle}` : `Show ${item.tooltipTitle}`;
        });

        if (!this.canUnselectLast && this._selected.length === 1) {
            const item = this.items.find((v) => v.id === this._selected[0]);

            item.disabled = true;
            item.tooltip = 'At least one of the filters is required';
        }
    }

    private mapOptionItems(options: OptionItem[]): Item[] {
        return options.map((item) => ({
            id: item.id,
            tooltipTitle: item.tooltipTitle,
            icon: item.icon,
            tooltip: null,
            display: null,
            disabled: null,
        }));
    }

    private mapSelectedItems(): ItemId[] {
        const ids = this.selected ? [...this.selected] : [...this.available];

        return this.options.filter((v) => ids.includes(v.id)).map((v) => v.id);
    }
}
