import { INTERVAL_TYPE } from '@main/enums';

export enum LIMIT_TYPE {
    EXPORT = 'EXPORT',
    VIEW = 'VIEW',
}

export enum ACTION_BUTTONS {
    BACK_TO_REPORT = 'BACK_TO_REPORT',
    CONTACT_ADMIN = 'CONTACT_ADMIN',
}

export interface MessageScheme {
    mailSubject: string;
    headerImage: string;
    title: string;
    subscript: string;
    actionButtons?: ACTION_BUTTONS[];
}

export enum LIMIT_AREA {
    WIDGET = 'WIDGET',
    GLOBAL = 'GLOBAL',
    CREATIVES = 'CREATIVES',
}

export type LimitsConfig = {
    [key in LIMIT_TYPE]: Partial<{
        [key1 in LIMIT_AREA]: Partial<{
            [key2 in INTERVAL_TYPE]: MessageScheme;
        }>;
    }>;
};

const EXPORT_IMAGE = 'assets/images/limits-page/export.svg';
const VIEWS_IMAGE = 'assets/images/limits-page/view.svg';
const SUBSCRIPT = 'Please contact your account administrator to increase this.';
export const LIMIT_WARNINGS_CONFIG: LimitsConfig = {
    [LIMIT_TYPE.EXPORT]: {
        [LIMIT_AREA.GLOBAL]: {
            [INTERVAL_TYPE.DAILY]: {
                mailSubject: 'My number of daily export reports available has been exceeded',
                headerImage: EXPORT_IMAGE,
                title: 'You have exceeded the number of daily export reports available.',
                subscript: SUBSCRIPT,
                actionButtons: [ACTION_BUTTONS.BACK_TO_REPORT, ACTION_BUTTONS.CONTACT_ADMIN],
            },
            [INTERVAL_TYPE.MONTHLY]: {
                mailSubject: 'My number of monthly export reports available has been exceeded',
                headerImage: EXPORT_IMAGE,
                title: 'You have exceeded the number of monthly export reports available.',
                subscript: SUBSCRIPT,
                actionButtons: [ACTION_BUTTONS.BACK_TO_REPORT, ACTION_BUTTONS.CONTACT_ADMIN],
            },
        },
        [LIMIT_AREA.CREATIVES]: {
            [INTERVAL_TYPE.DAILY]: {
                mailSubject: 'My number of daily widget export available has been exceeded',
                headerImage: EXPORT_IMAGE,
                title: 'You have exceeded the number of daily export widgets available.',
                subscript: SUBSCRIPT,
                actionButtons: [ACTION_BUTTONS.BACK_TO_REPORT, ACTION_BUTTONS.CONTACT_ADMIN],
            },
            [INTERVAL_TYPE.MONTHLY]: {
                mailSubject: 'My number of monthly widget export available has been exceeded',
                headerImage: EXPORT_IMAGE,
                title: 'You have exceeded the number of monthly export widgets available.',
                subscript: SUBSCRIPT,
                actionButtons: [ACTION_BUTTONS.BACK_TO_REPORT, ACTION_BUTTONS.CONTACT_ADMIN],
            },
        },
        [LIMIT_AREA.WIDGET]: {
            [INTERVAL_TYPE.DAILY]: {
                mailSubject: 'My number of daily widget export available has been exceeded',
                headerImage: EXPORT_IMAGE,
                title: 'You have exceeded the number of daily export widgets available.',
                subscript: SUBSCRIPT,
                actionButtons: [ACTION_BUTTONS.BACK_TO_REPORT, ACTION_BUTTONS.CONTACT_ADMIN],
            },
            [INTERVAL_TYPE.MONTHLY]: {
                mailSubject: 'My number of monthly widget export available has been exceeded',
                headerImage: EXPORT_IMAGE,
                title: 'You have exceeded the number of monthly export widgets available.',
                subscript: SUBSCRIPT,
                actionButtons: [ACTION_BUTTONS.BACK_TO_REPORT, ACTION_BUTTONS.CONTACT_ADMIN],
            },
        },
    },

    [LIMIT_TYPE.VIEW]: {
        [LIMIT_AREA.GLOBAL]: {
            [INTERVAL_TYPE.DAILY]: {
                mailSubject: 'My number of daily reports available has been exceeded',
                headerImage: VIEWS_IMAGE,
                title: 'You have exceeded the number of daily reports available.',
                subscript: SUBSCRIPT,
                actionButtons: [ACTION_BUTTONS.CONTACT_ADMIN],
            },
            [INTERVAL_TYPE.MONTHLY]: {
                mailSubject: 'My number of monthly reports available has been exceeded',
                headerImage: VIEWS_IMAGE,
                title: 'You have exceeded the number of monthly reports available.',
                subscript: SUBSCRIPT,
                actionButtons: [ACTION_BUTTONS.CONTACT_ADMIN],
            },
        },
    },
};
