export const svgIconsConfig = {
    html5: 'html5-icon.svg',
    banner: 'banner-icon.svg',
    video: 'video-icon.svg',
    export: 'export.svg',
    export2: 'export2.svg',
    edit: 'edit.svg',
    save: 'save.svg',
    html: 'html.svg',
    play: 'video.svg',
    imageT: 'image_t.svg',
    filter: 'filter.svg',
    vs: 'vs_icon.svg',
    amp: 'amp_icon.svg',
    filetypeTsv: 'filetype-tsv.svg',
    filetypeXls: 'filetype-xls.svg',
    filetypeJpg: 'filetype-jpg.svg',
    filetypeZip: 'filetype-zip.svg',
    filetypePdf: 'filetype-pdf.svg',
    eye: 'eye.svg',
    eye2: 'eye2.svg',
    diamond: 'diamond.svg',
    grid: 'grid.svg',
    chart: 'chart.svg',
    chartOnly: 'chart-only.svg',
    calendar: 'calendar.svg',
    dollar: 'dollar.svg',
    dollar2: 'dollar2.svg',
    both: 'both.svg',
    settings: 'settings.svg',
    settings2: 'settings2.svg',
    logout: 'logout.svg',
    search: 'search.svg',
    close: 'close.svg',
    channelAll: 'channel-all.svg',
    channelDisplay: 'channel-display.svg',
    channelVideo: 'channel-video.svg',
    channelSocial: 'channel-social.svg',
    channelMobileApp: 'channel-mobile-app.svg',
    channelDesktopDisplay: 'channel-desktop-display.svg',
    channelDesktopVideo: 'channel-desktop-video.svg',
    channelMobileWebDisplay: 'channel-mobile-web-display.svg',
    channelMobileWebVideo: 'channel-mobile-web-video.svg',
    channelDesktopDisplaySocial: 'channel-desktop-display-social.svg',
    channelDesktopVideoSocial: 'channel-desktop-video-social.svg',
    adFilterImage: 'ad-filter-image.svg',
    adFilterAnimatedBanner: 'ad-filter-animated-banner.svg',
    adFilterVideo: 'ad-filter-video.svg',
    adFilterSocial: 'ad-filter-social.svg',
    adFilterSocialCarousel: 'ad-filter-carousel.svg',
    adFilterSocialPromoted: 'ad-filter-promoted.svg',
    adFilterClose: 'ad-filter-close.svg',
    publisherFilterFacebook: 'publisher-filter-facebook.svg',
    publisherFilterTwitter: 'publisher-filter-twitter.svg',
    publisherFilterInstagram: 'publisher-filter-instagram.svg',
    publisherFilterTiktok: 'publisher-filter-tiktok.svg',
    publisherFilterPinterest: 'publisher-filter-pinterest.svg',
    publisherFilterLinkedin: 'publisher-filter-linkedin.svg',
    allDevices: 'all-devices.svg',
    mobileOsAndroid: 'mobileos-android.svg',
    mobileOsIos: 'mobileos-ios.svg',
    buttonExport: 'button-export.svg',
    checked: 'checked.svg',
    openLink: 'open-link.svg',
    link: 'link.svg',
    share: 'share.svg',
    dropdown: 'dropdown.svg',
    info: 'info.svg',
    infoOutline: 'info-outline.svg',
    plus: 'plus.svg',
    plusShape: 'plus-shape.svg',
    plusBadge: 'plus2.svg',
    star: 'star.svg',
    groupGeneric: 'group-generic.svg',
    groupComparison: 'group-comparison.svg',
    remove: 'remove.svg',
    siteDefault: 'site-default-ico.svg',
    articleDefault: 'article-default-ico.svg',
    campaign: 'campaign.svg',
    arrowRight: 'arrow-right.svg',
    arrowLeft: 'arrow-left.svg',
    smallArrow: 'small-arrow.svg',
    sort: 'sort.svg',
    warning: 'warning.svg',
    warningOutlined: 'warning-outlined.svg',
    laugh: 'laugh.svg',
    human: 'human.svg',
    alarm: 'alarm.svg',
    checkMark: 'check-mark.svg',
    hashtag: 'hashtag.svg',
    hashtagBlue: 'hashtagBlue.svg',
    bell: 'bell.svg',
    finance: 'finance.svg',
    category: 'category.svg',
    brand: 'brand.svg',
    masterBrand: 'master-brand.svg',
    threeDots: 'three-dots.svg',
    duplicate: 'duplicate.svg',
    trash: 'trash.svg',
    flipchart: 'flipchart.svg',
    dashboard: 'dashboard.svg',
    outlineWarning: 'outline-warning.svg',
    animBanner: 'anim-banner.svg',
    carousel: 'carousel.svg',
    desktop: 'desktop.svg',
    devices: 'devices.svg',
    image: 'image.svg',
    award: 'award.svg',
    error: 'error.svg',
    success: 'done.svg',
    sync: 'sync.svg',
    dragIndicator: 'drag-indicator.svg',
    notifications: 'notifications.svg',
    notificationsOutline: 'notifications-outline.svg',

    bookmark: 'redesign/bookmark.svg',
    bookmarkActive: 'redesign/bookmark-active.svg',
    bookmarkNotUsed: 'redesign/bookmark-not-used.svg',
    hierarchy: 'redesign/hierarchy.svg',
    plusThin: 'redesign/plus-thin.svg',
    enhancedBookmark: 'redesign/saved_items.svg',
};
