import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs';

import { ReportDeviationData } from '@main/core/report.types';
import { CommonHelpers } from '../helpers/common.helpers';

const dataDeviationAttrName = 'data-deviation';

@UntilDestroy()
@Directive({
    selector: '[showForDeviation]',
})
export class ShowForDeviationDirective implements OnInit, OnChanges {
    @Input() showForDeviation!: ReportDeviationData;

    computedDisplayProp: string;
    host = this.elementRef.nativeElement;

    constructor(private elementRef: ElementRef<HTMLElement>) {}

    ngOnInit() {
        const domChangeEvent$ = CommonHelpers.observeOnMutation(this.elementRef.nativeElement, {
            childList: true,
            subtree: true,
        });

        domChangeEvent$.pipe(untilDestroyed(this), debounceTime(100)).subscribe(() => {
            this.apply();
        });
    }

    ngOnChanges() {
        this.apply();
    }

    private apply(): void {
        if (!this.showForDeviation) {
            return;
        }

        const { nativeElement } = this.elementRef;
        const nestedContainers = nativeElement?.querySelectorAll(`[${dataDeviationAttrName}]`);

        const elements = nestedContainers?.length ? <HTMLElement[]>Array.from(nestedContainers) : [];

        if (nativeElement?.hasAttribute(dataDeviationAttrName)) {
            elements.unshift(nativeElement);
        }

        elements.forEach((el) => {
            const type = el.getAttribute(dataDeviationAttrName);

            if (this.showForDeviation[type]) {
                this.show(el);
            } else {
                this.hide(el);
            }
        });
    }

    private show(ct: HTMLElement): void {
        const oldDisplayProp = ct.getAttribute('data-deviation-old-display-prop');

        if (oldDisplayProp) {
            ct.style.display = oldDisplayProp;
        }
    }

    private hide(ct: HTMLElement): void {
        ct.setAttribute('data-deviation-old-display-prop', getComputedStyle(ct).display);
        ct.style.display = 'none';
    }
}
