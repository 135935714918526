import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { options as configOptions } from './pie-chart.options';
import { GenericChart } from './generic-chart';
import { chartColors } from '@shared/colors/data/palette/charts-colors.data';

@UntilDestroy()
export class PieChart extends GenericChart {
    presetsConfig = configOptions;

    private presets: string[];

    applyData(options: Highcharts.Options, data: Highcharts.Point[]): Highcharts.Options {
        (options.series[0] as Highcharts.SeriesPieOptions).data = data;
        (options.series[1] as Highcharts.SeriesPieOptions).data = data;

        return options;
    }

    applyCustomHandlers(options: Highcharts.Options, presets: string[]): Highcharts.Options {
        const self = this;

        this.presets = presets;

        const handlersOptions = {
            chart: {
                events: {
                    load(event: Event): void {
                        self.onLoad(this, event);
                    },
                },
            },

            plotOptions: {
                series: {
                    point: {
                        events: {
                            mouseOver(): void {
                                self.onSliceMouseOver(this);
                            },
                        },
                    },
                    events: {
                        mouseOut(): void {
                            self.onSliceMouseOut(this);
                        },
                    },
                },
            },
        };

        return _.merge(options, handlersOptions);
    }

    applyValuesColors(seriesData: Highcharts.SeriesOptionsType[]): Highcharts.SeriesOptionsType[] {
        return seriesData.map((item: any) => ({
            ...item,
            data: item.data?.map((v, index) => ({
                ...v,
                color: v.color || chartColors[index],
            })),
        }));
    }

    onLegendItemHover(index: number, hovered: boolean): void {
        const chart = this.chartInstance;
        const chartSerie = chart.series[1];

        if (this.options.customLegend) {
            chartSerie.points.forEach((point) => {
                point.setState(hovered && 'inactive');
            });

            chartSerie.points[index].setState(hovered && 'hover');
        }

        const data = hovered && chartSerie.data[index];

        this.setTooltip(chart, data);
    }

    private onLoad(chart: Highcharts.Chart, loadEvent: Event): void {
        this.bindLegendEvents(chart, loadEvent);

        if (this.presets.includes('big')) {
            this.setTooltip(chart);
        }
    }

    private onSliceMouseOver(event): void {
        if (!this.options.customLegend) {
            this.clearLegendSelection(event.series.chart);

            event.legendItem.element.firstElementChild.classList.add('hover');
        }

        this.pointHover$.next([event.index, true]);

        this.setTooltip(event.series.chart, event);
    }

    private onSliceMouseOut(event: Highcharts.Series): void {
        if (!this.options.customLegend) {
            this.clearLegendSelection(event.chart);
        }

        this.pointHover$.next([event.index, false]);

        this.setTooltip(event.chart);
    }

    private bindLegendEvents(_chart: Highcharts.Chart, loadEvent): void {
        const self = this;
        const legendItems = loadEvent.target.container.getElementsByClassName('piechart-legend-item');

        Array.from(legendItems).forEach((el: HTMLElement, i: number) => {
            el.addEventListener('mouseover', () => {
                self.legendHover$.next([i, true]);
            });

            el.addEventListener('mouseout', () => {
                self.legendHover$.next([i, false]);
            });
        });

        this.legendHover$.pipe(untilDestroyed(this)).subscribe(([index, hovered]) => {
            this.onLegendItemHover(index, hovered);
        });
    }

    private clearLegendSelection(chart: Highcharts.Chart): void {
        const legendItems = chart.container.getElementsByClassName('piechart-legend-item');

        Array.from(legendItems).forEach((el: HTMLElement) => {
            el.classList.remove('hover');
        });
    }

    private setTooltip(chart: Highcharts.Chart, data?): void {
        let html = '';

        if (data) {
            const valuePrecision = data.percentage < 1 ? 2 : 0;
            const value = data.percentage.toFixed(valuePrecision);

            html += `<div class="piechart-tooltip ${this.presets.join(' ')}">`;

            if (this.presets.includes('big')) {
                html += `<span class="title">${data.name}<span>`;
            }

            html += `<span class="value">${value}%<span>`;

            html += '</div>';
        }

        chart.setTitle({ text: html });
    }
}
