import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { viewReportTokenName } from '@app/config';
import { AdcError } from '@shared/models/error.model';
import { ViewReportService } from '@main/services/view-report.service';
import { INTERVAL_TYPE } from '@main/enums';
import { ADC_ERROR_CODE_ENUM } from '@app/shared/enums';

@Injectable()
export class ViewReportTokenInterceptor implements HttpInterceptor {
    constructor(private viewReportService: ViewReportService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const token = this.viewReportService.viewToken;

        if (request.headers.get(viewReportTokenName) === '') {
            if (token) {
                request = this.getRequestWithToken(request, token);
            } else {
                throw new AdcError(ADC_ERROR_CODE_ENUM.GENERIC_ERROR, 'Report view token is not defined');
            }
        }
        /*
        // Mock expired token for country 'us' or walmart.com instance
        if (
            request.url &&
            request.url.indexOf('security/tokens/report') !== -1 &&
            (request.body.instances[0] === 19428820 || request.body.params.country === 'us')
        ) {
            const error = new HttpErrorResponse({
                status: 429,
                statusText: 'Expired token',
                error: '{"attributes":{"interval":"DAILY"}}',
            });
            this.handle429(request, next, error);
            return throwError(() => error);
        }
*/
        return next.handle(request).pipe(
            catchError((error: Error, _caught: Observable<HttpEvent<unknown>>) => {
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 400:
                            return this.handle400Error(error, request, next);
                        case 429:
                            this.handle429(request, next, error);
                            break;
                        default:
                            return throwError(error);
                    }
                }

                return throwError(() => error);
            }),
        );
    }

    private handle429(_request: HttpRequest<unknown>, _next: HttpHandler, error: HttpErrorResponse): void {
        let parsedError;

        try {
            parsedError = JSON.parse(error.error);
        } catch {}

        if (parsedError?.attributes) {
            const interval = parsedError?.attributes?.interval as INTERVAL_TYPE;
            this.viewReportService.showViewLimitMessage(interval);
        }
    }

    private handle400Error(
        error: HttpErrorResponse,
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<unknown> {
        const errorBody = request.responseType === 'json' ? error.error : JSON.parse(error.error);

        if (!this.isTokenRequest(request)) {
            return throwError(() => error);
        }

        if (/GROUP should have at most [0-9]+ entities\./.test(errorBody.message)) {
            return throwError(() => error);
        }

        return this.viewReportService
            .refreshToken()
            .pipe(switchMap((newToken: string) => next.handle(this.getRequestWithToken(request, newToken))));
    }

    private getRequestWithToken(request: HttpRequest<any>, token: string): HttpRequest<unknown> {
        return request.clone({
            setHeaders: {
                [viewReportTokenName]: token,
            },
        });
    }

    private isTokenRequest(request: HttpRequest<any>): boolean {
        return request.url && request.url.indexOf('security/tokens/report') !== -1;
    }
}
