import { Directive } from '@angular/core';

import { ANALYTICS_AREA } from '../../data';
import { UXArea } from '../../models';

import { AnalyticsAreaBase } from './analytics-area.base';

@Directive({
    selector: '[analyticsWidget]',
    providers: [
        {
            provide: ANALYTICS_AREA,
            useExisting: AnalyticsWidgetDirective,
        },
    ],
})
export class AnalyticsWidgetDirective extends AnalyticsAreaBase {
    type: UXArea = 'widget';
}
