import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { EntityCategory, EntityGroup, EntityItem } from '@main/models';
import { BUSINESS_ENTITY } from '@main/enums';
import { NavigationService } from '@main/services/navigation.service';
import { FormatHelpers } from '@main/helpers/format.helpers';

@Component({
    selector: 'entity-icon-name',
    templateUrl: './entity-icon-name.component.html',
    styleUrls: ['./entity-icon-name.component.scss'],
})
export class EntityIconNameComponent implements OnInit, OnChanges {
    @Input() item: EntityItem;
    @Input() titleAsHtml = false;
    @Input() showTitlePrefix = true;
    @Input() showTitleSuffix = true;
    @Input() useAsSvg = false;
    @Input() clickable = false;
    @Input() navigatable = false;
    @Input() removable = false;
    @Input() disabled = false;
    @Input() tooltip: string;
    @Input() defaultIcon: string;
    @Input() matIconSize: number;
    @Input() matIconColor: string;
    @Input() iconCls: string;
    @Input() customClass: string;
    @Input() search: string;

    @Output() remove = new EventEmitter<EntityItem>();
    @Output() click = new EventEmitter<EntityItem>();

    title: string;
    iconUrl: string;
    _tooltip: string;

    get isGroup(): boolean {
        return this.item?.isGroup();
    }

    constructor(private navigationService: NavigationService) {}

    ngOnInit(): void {
        this.title = FormatHelpers.formatFullTitle(
            this.item,
            this.titleAsHtml,
            this.showTitlePrefix,
            this.showTitleSuffix,
        );
        this.iconUrl = this.item.icon;
    }

    ngOnChanges({ item, tooltip, disabled, navigatable }: SimpleChanges): void {
        if (item || tooltip || disabled || navigatable) {
            this._tooltip = this.getTooltip();
        }
    }

    onClick(): void {
        if (this.navigatable) {
            // postpone a bit to allow outer click handlers do the job
            setTimeout(() => {
                this.navigationService.navigateToEntities([this.item]);
            });
            return;
        }

        if (this.clickable) {
            this.click.emit(this.item);
        }
    }

    private getTooltip(): string {
        if (this.disabled) {
            return null;
        }

        if (this.tooltip) {
            return this.tooltip;
        }

        if (this.navigatable) {
            return 'Go to "' + this.item.title + '" report';
        }

        if (this.isGroup) {
            return (this.item as EntityGroup).items.map((v) => v.fullTitle).join('\n');
        }

        return this.item.entityType === BUSINESS_ENTITY.Category
            ? this.getCategoryTooltip(this.item as EntityCategory)
            : this.item.title;
    }

    private getCategoryTooltip(entity: EntityCategory): string {
        const { title: subcategory } = entity;

        if (!entity.parent) return subcategory;

        const { title: category } = entity.parent;

        return `${category} > ${subcategory}`;
    }
}
