import { AppInjector } from '@app/util/injector';
import { AdcCurrencyPipe } from '@shared/pipes/currency.pipe';
import { ShortPipe } from '@shared/pipes/short.pipe';
import { Currency, WidgetMetric } from '@shared/models';
import { AdcPercentPipe } from '@shared/pipes/percent.pipe';
import { EntityBase } from '../models/EntityBase';

export interface FormatImpSpendValueOptions {
    value: ImpSpendDataValue;
    viewType: WidgetMetric;
    precision: number;
    percentPrecision: number;
    smallPrecision: number;
    currency: Currency;
    currencyShortFormat: boolean;
    splitLine: boolean;
    asHtml: boolean;
    divider: string;
    roundSmall: boolean;
    hideVerySmallValue: boolean;
}

interface ImpSpendDataValue {
    total: number;
    average?: number;
    share: number;
}

export class FormatHelpers {
    static formatImpSpendValue(options: Partial<FormatImpSpendValueOptions>): string {
        const precision = options.precision !== undefined ? options.precision : 1;
        const percentPrecision = options.percentPrecision !== undefined ? options.percentPrecision : precision;
        const smallPrecision = options.smallPrecision !== undefined ? options.precision : 2;
        const currencyShortFormat = options.currencyShortFormat !== undefined ? options.currencyShortFormat : true;
        const splitLine = !!options.splitLine;
        const asHtml = !!options.asHtml || splitLine;
        const divider = options?.divider || '/';

        const showCount = [WidgetMetric.Number, WidgetMetric.Both].includes(options.viewType);
        const showPercent = [WidgetMetric.Percentage, WidgetMetric.Both].includes(options.viewType);

        let result = '';

        if (showCount) {
            let value = '';

            if (options.currency) {
                const pipe = AppInjector.get(AdcCurrencyPipe);

                value = pipe.transform(
                    options.value?.total ?? options.value?.average,
                    options.currency,
                    precision,
                    currencyShortFormat,
                    '0',
                );
            } else {
                const pipe = AppInjector.get(ShortPipe);
                const tempValue = options.value?.total ?? options.value?.average;

                if (options.hideVerySmallValue && tempValue && tempValue < 1) value = '< 1';
                else value = pipe.transform(tempValue, precision, !!options.roundSmall, '0');
            }

            result += asHtml ? `<span class="count">${value}</span>` : value;
        }

        if (showPercent) {
            result += asHtml ? '<span class="percent nowrap">' : '';

            if (options.viewType === WidgetMetric.Both) {
                result += splitLine ? '<br />' : ` ${divider} `;
            }

            const pipe = AppInjector.get(AdcPercentPipe);

            const value = pipe.transform(options.value?.share, percentPrecision, smallPrecision);

            result += asHtml ? `${value}</span>` : value;
        }

        return result;
    }

    static formatFullTitle(entity: EntityBase, html = false, prefix = true, suffix = true): string {
        const { title, titlePrefix, titleSuffix } = entity;
        return html
            ? `${prefix ? titlePrefix.html : ''}${title}${suffix ? titleSuffix.html : ''}`
            : `${prefix ? titlePrefix.text : ''}${title}${suffix ? titleSuffix.text : ''}`;
    }
}
