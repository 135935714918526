import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PeriodInterval } from '@shared/models/period-interval';
import { DatasetRequestParams, RequestService } from '@shared/services';
import { IPeriod } from '@shared/interfaces';
import { Period } from '@shared/models';
import { IElementsResponse } from '@main/interfaces';
import { EntityHelpers } from '@main/helpers/entity.helpers';

@Injectable()
export class PeriodsApiService {
    constructor(private requestService: RequestService) {}

    fetchPeriodIntervals(period: Period): Observable<PeriodInterval[]> {
        const params: DatasetRequestParams = {
            datasetName: 'periods',
            customParams: {
                period: EntityHelpers.serializePeriod(period),
            },
        };

        return this.requestService.dataset<IElementsResponse<IPeriod>>(params).pipe(
            map((res) => res?.elements && res.elements[0]),
            map((res) => res && this.normalizeIntervalPeriods(res)),
        );
    }

    private normalizeIntervalPeriods(data: IPeriod): PeriodInterval[] {
        return (
            data.supportedIntervals.map((v) => {
                const isDefault = v.code === data.defaultInterval?.code;

                return new PeriodInterval(v, isDefault);
            }) || []
        );
    }
}
