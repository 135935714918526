import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';

import { DatasetParamsValue } from '@shared/services/request/request.types';
import { GRID_FILTER_TYPE } from '@shared/enums';

export interface Page {
    number: number;
    size: number;
}

export interface SearchItem {
    field: string;
    value: DatasetParamsValue;
}

export interface Filter {
    id: string;
    type: GRID_FILTER_TYPE;
    value: any;
}

export interface SortItem {
    field: string;
    direction: SortDirection;
}

export interface FormatsParams {
    date?: string;
    impressions?: string;
    share?: string;
}

export interface IRequestParams {
    page?: Page;
    search?: SearchItem;
    filters?: Filter[];
    sort?: SortItem;
    formats?: FormatsParams;
    q?: string;
}

const defaultPageSize = 10;

export class RequestParams implements IRequestParams {
    page?: Page;
    search?: SearchItem;
    filters?: Filter[] = [];
    sort?: SortItem;
    formats?: FormatsParams;

    constructor(initialParams: Partial<IRequestParams> = {}) {
        this.reset();

        Object.assign(this, initialParams);
    }

    reset(): void {
        this.resetPage();
        this.resetFilters();

        this.resetSearch();
        this.resetSort();
        this.resetFormats();
    }

    resetPage(size = defaultPageSize): void {
        this.setPage(0, size);
    }

    setPage(number = 0, size = defaultPageSize): void {
        this.page = {
            number,
            size,
        };
    }

    resetFilters(): void {
        this.setFilters([]);
    }

    setFilters(items: Filter[]): void {
        this.filters = items;
    }

    setFilter(item: Filter): void {
        const filterIndex = this.filters.findIndex((v) => v.id === item.id);

        if (filterIndex !== -1) {
            this.filters[filterIndex] = item;
        } else {
            this.filters.push(item);
        }
    }

    resetFormats(): void {
        this.setFormats(null);
    }

    setFormats(formats: FormatsParams): void {
        this.formats = formats;
    }

    resetSort(): void {
        this.setSort(null);
    }

    setSort(item: SortItem): void {
        this.sort = item;
    }

    setPagingFromPager(event: PageEvent): void {
        this.page.number = event.pageIndex;
        this.page.size = event.pageSize;
    }

    resetSearch(): void {
        this.search = null;
    }

    setSearch(field: string, value: DatasetParamsValue): void {
        this.search = {
            field,
            value,
        };
    }
}
