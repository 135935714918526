import {
    Directive,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { LayoutService } from '@shared/services';
import { BehaviorSubject, combineLatest, filter, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[scrollToWidget]',
})
export class AdcScrollToWidgetDirective implements OnInit, OnChanges {
    @Input() scrollToWidget: string;
    @Input() scrollToWidgetReady = false;
    @Input() scrollToWidgetElementCls: string;
    @Input() matTooltip: string;
    @Input() scrollToWidgetReady$: Subject<boolean> = new BehaviorSubject<boolean>(true);

    @Output() matTooltipChange = new EventEmitter<string>();

    private readonly layoutService = inject(LayoutService);
    private readonly elementRef = inject(ElementRef);
    private readonly renderer = inject(Renderer2);
    private readonly hostElement = this.elementRef.nativeElement;

    private click$ = new Subject<boolean>();
    private scrollToElement: HTMLElement;

    ngOnInit() {
        fromEvent(this.elementRef.nativeElement, 'click')
            .pipe(untilDestroyed(this))
            .subscribe(() => this.click$.next(true));

        combineLatest([this.click$, this.scrollToWidgetReady$])
            .pipe(
                filter(([click, ready]) => click && ready),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.click$.next(false);
                this.scroll();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.scrollToWidget) {
            this.init();
        }
    }

    private init(): void {
        if (this.scrollToWidget) {
            this.scrollToElement = document.querySelector(this.scrollToWidget) as HTMLElement;

            if (this.scrollToElement) {
                this.renderer.setStyle(this.hostElement, 'cursor', 'pointer');

                if (this.scrollToWidgetElementCls) {
                    this.renderer.addClass(this.hostElement, this.scrollToWidgetElementCls);
                }
            } else {
                if (this.matTooltip) {
                    this.matTooltipChange.emit('');
                }
            }
        }
    }

    scroll() {
        if (this.scrollToElement) {
            this.layoutService.scrollToElement(this.scrollToWidget);
        }
    }
}
