import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, AnimationBuilder, style } from '@angular/animations';

import { LayoutHelpers } from '@shared/helpers/layout.helpers';
import { ScrollToHelpers } from '../helpers/scroll-to';
import { AppConfig } from '@app/app.config';

@Injectable()
export class LayoutService {
    private renderer: Renderer2;

    private scrollToElementHandler: (ctName: string | HTMLElement) => void;

    constructor(
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
        private animationBuilder: AnimationBuilder,
        private appConfig: AppConfig,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);

        if (this.appConfig.appInfo.fullHeightLayout) {
            this.setFullLayoutMode(true);
        }
    }

    setContainersCustomHeight(height: number, minHeight: number): void {
        LayoutHelpers.addCss(`.adc-dialog:not(.content-height) { max-height: ${height}px }`);
        LayoutHelpers.addCss(`.dialog-popup-v2:not(.content-height) { max-height: ${height}px }`);

        LayoutHelpers.addCss(`app-sidebar { max-height: ${height}px }`);
        LayoutHelpers.addCss(`.content-container { min-height: ${minHeight}px !important; }`);
    }

    addBodyClass(className: string): void {
        this.renderer.addClass(this.document.body, className);
    }

    setFullLayoutMode(enable: boolean): void {
        if (enable) {
            this.renderer.addClass(this.document.body, 'full-height-layout');
        } else {
            this.renderer.removeClass(this.document.body, 'full-height-layout');
        }
    }

    setScrollToElementHandler(handler: (ctName: string) => void): void {
        this.scrollToElementHandler = handler;
    }

    scrollToElement(targetEl: string | HTMLElement): void {
        if (this.scrollToElementHandler) {
            this.scrollToElementHandler(targetEl);
            return;
        }

        const contentBoxEl = document.getElementById('viewport-container');

        const hasScroll = contentBoxEl.scrollHeight > contentBoxEl.clientHeight;

        ScrollToHelpers.scrollToElement(targetEl, hasScroll && contentBoxEl);
    }

    hideSplashScreen(): void {
        const splashScreenElem = this.document.body.querySelector('#splash-screen');
        const player = this.animationBuilder
            .build([
                style({ opacity: '1' }),
                animate(
                    '400ms ease',
                    style({
                        opacity: '0',
                        zIndex: '-10',
                    }),
                ),
            ])
            .create(splashScreenElem);

        player.play();
    }
}
