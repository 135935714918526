import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { startPagePath } from '@app/config';

@Component({
    selector: 'bad-request-page',
    templateUrl: './bad-request-page.component.html',
    styles: [
        `
            p {
                text-align: center;
            }
        `,
    ],
})
export class BadRequestPageComponent {
    constructor(private router: Router) {}

    navigateToStartPage(): void {
        this.router.navigate([startPagePath]);
    }
}
