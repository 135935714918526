<button class="menu-trigger" mat-button (click)="menuTrigger?.openMenu()" *ngIf="!isDynamic && !viewType">
    <div fxLayout="row" fxLayoutAlign="center center">
        <div class="b-avatar">
            <img *ngIf="userAvatarUrl; else nameAbbr" [src]="userAvatarUrl" class="image" />

            <ng-template #nameAbbr>
                <span class="name-abbr">{{ userNameAbbr }}</span>
            </ng-template>
        </div>
        <mat-icon class="s-12 mx-4" svgIcon="dropdown"></mat-icon>
    </div>
</button>

<div
    *ngIf="!viewType"
    class="positioned-menu-trigger"
    [class]="menuType"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="userMenu"
></div>

<ng-container *ngIf="viewType">
    <ng-container *ngTemplateOutlet="BtnFace"></ng-container>
</ng-container>

<mat-menu
    #userMenu="matMenu"
    [overlapTrigger]="false"
    xPosition="{{ isDynamic ? 'after' : 'before' }}"
    class="global-account-menu-dropdown {{ !isDynamic ? 'p-8' : '' }}"
>
    <ng-container *ngIf="!isDynamic">
        <div class="menu-header">
            <ng-container *ngTemplateOutlet="fullSizeBtnFace"></ng-container>
        </div>
    </ng-container>

    <div class="divider" *ngIf="!isDynamic"></div>

    <button mat-menu-item (click)="openUserSettings()" fxLayout="row">
        <mat-icon class="s-16" matButtonIcon svgIcon="settings"></mat-icon>
        <span fxFlex>User settings</span>
    </button>

    <button *ngIf="showEmailNotifications" mat-menu-item (click)="openEmailNotificationsSettings()">
        <mat-icon class="s-16" matButtonIcon svgIcon="alarm"></mat-icon>
        <span fxFlex>Email notifications</span>
    </button>

    <button *ngIf="showAccountSettings" mat-menu-item (click)="openAMU()" aria-label="Account Management Button">
        <mat-icon class="s-16" svgIcon="settings2"></mat-icon>
        <span fxFlex>Account settings</span>
    </button>

    <button *ngIf="showSubscriptionPlans" mat-menu-item (click)="openSubscriptionPlans()">
        <mat-icon class="s-16" svgIcon="finance"></mat-icon>
        <span fxFlex>Subscription plans</span>
    </button>

    <button *ngIf="showMarkModeToggle" mat-menu-item>
        <mat-slide-toggle
            [checked]="markModeEnabled"
            (change)="onMarkModeStateChanged($event)"
            (click)="onSwitchMarkModeStateClicked($event)"
        >
            <span>Skip mode</span>
        </mat-slide-toggle>
    </button>

    <button *ngIf="showLogout" mat-menu-item (click)="onLogoutClick()" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="s-16" svgIcon="logout"></mat-icon>
        <span fxFlex>Logout</span>
    </button>
</mat-menu>

<router-outlet name="toolbar"></router-outlet>

<ng-template #BtnFace>
    <div class="button-face">
        <div class="b-avatar short" *ngIf="viewType === 'short'">
            <img *ngIf="userAvatarUrl; else nameAbbr" [src]="userAvatarUrl" class="image" />

            <ng-template #nameAbbr>
                <span class="name-abbr">{{ userNameAbbr }}</span>
            </ng-template>
        </div>

        <ng-container *ngIf="viewType === 'full'">
            <ng-container *ngTemplateOutlet="fullSizeBtnFace"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #fullSizeBtnFace>
    <div fxLayout="row" fxLayoutAlign="start center" class="header">
        <div class="b-avatar">
            <img *ngIf="userAvatarUrl; else nameAbbr" [src]="userAvatarUrl" class="image" />

            <ng-template #nameAbbr>
                <span class="name-abbr">{{ userNameAbbr }}</span>
            </ng-template>
        </div>
        <div>
            <span class="account-name">{{ userName }}</span>
            <span class="account-role">{{ userSubtitle }}</span>
        </div>
    </div>
</ng-template>
