import { GRID_FILTER_TYPE } from '@shared/enums';
import { Filter, FormatsParams, Page, SearchItem, SortItem } from '@shared/models/request-params.model';
import { BUSINESS_ENTITY } from '@main/enums';
import {
    DatasetCustomParams,
    DatasetCustomParamsComplexValue,
    DatasetRequestFiltersMapping,
    DatasetRequestOptions,
    DatasetRequestParams,
} from './request.types';

import * as moment from 'moment';
import { isArray, isObject } from 'lodash';

interface SerializedParams {
    [param: string]: string | number;
}

export class RequestDatasetsHelpers {
    static serialize(params: DatasetRequestParams): { params: SerializedParams; query: string; formats: string } {
        const {
            datasetName,
            entityName,
            entityItemsIds,
            entitiesIdsPairs = {},
            customParams,
            requestParams,
            options,
            channel = null,
        } = params;

        const serializedParams: SerializedParams = {};

        if (entityName && entityItemsIds) {
            entitiesIdsPairs[entityName] = entityItemsIds;
        }

        if (entitiesIdsPairs) {
            // prepend query with some parameter to avoid adBlocker rule match
            if (entitiesIdsPairs.advertiser && !options?.export) {
                serializedParams._ = '_';
            }

            Object.entries(entitiesIdsPairs).forEach(([key, value]) => {
                serializedParams[key] = Array.isArray(value) ? value.join(',') : value;
            });
        }

        if (channel) {
            Object.assign(serializedParams, { channel });
        }

        if (customParams) {
            Object.assign(serializedParams, this.genericTransformParams(customParams));
        }

        if (requestParams) {
            if (requestParams.page) {
                Object.assign(serializedParams, this.serializePagingParams(requestParams.page));
            }

            if (requestParams.sort) {
                Object.assign(
                    serializedParams,
                    this.serializeSortingParams(requestParams.sort, options?.fieldsMapping),
                );
            }

            if (requestParams.search) {
                Object.assign(
                    serializedParams,
                    this.serializeSearchingParams(requestParams.search, options?.fieldsMapping),
                );
            }

            if (requestParams.filters) {
                Object.assign(
                    serializedParams,
                    this.serializeFiltersParams(requestParams.filters, requestParams.formats, options?.fieldsMapping),
                );
            }
        }

        const query =
            `/${datasetName}?` +
            Object.entries(serializedParams)
                .map(([key, val]) => `${key}=${val}`)
                .join('&');

        const formats = requestParams?.formats ? this.serializeFormats(requestParams.formats) : undefined;

        return {
            params: serializedParams,
            query,
            formats,
        };
    }

    static formatRequestLabel(entityType: BUSINESS_ENTITY, widgetName: string): string {
        return `entity_type=${entityType || null};widget_name=${widgetName}`;
    }

    private static serializePagingParams(page: Page): DatasetCustomParams {
        const result: DatasetCustomParams = {};

        const pageNumber = page?.number;
        const pageSize = page?.size;

        if (pageNumber !== undefined) {
            result.page = pageNumber;
        }

        if (pageSize !== undefined) {
            result.size = pageSize;
        }

        return result;
    }

    private static serializeSortingParams(sort: SortItem, fieldMapping: DatasetRequestOptions): DatasetCustomParams {
        const sortDirection = sort.direction || 'desc';

        const mappedField =
            fieldMapping && Object.entries(fieldMapping).find(([, sortFieldName]) => sortFieldName === sort.field);

        const transformedFieldName = mappedField ? mappedField[0] : sort.field;
        const sortQuery = `${transformedFieldName}-${sortDirection}`;

        return {
            sort: sortQuery,
        };
    }

    private static serializeSearchingParams(
        search: SearchItem,
        fieldMapping: DatasetRequestOptions,
    ): DatasetCustomParams {
        const mappedField =
            fieldMapping && Object.entries(fieldMapping).find(([, fieldName]) => fieldName === search.field);

        const transformedFieldName = mappedField ? mappedField[0] : search.field;

        const params = search.value
            ? {
                  [transformedFieldName]: search.value,
              }
            : {};

        return this.genericTransformParams(params);
    }

    private static serializeFiltersParams(
        filters: Filter[],
        formats: FormatsParams,
        fieldsMapping?: DatasetRequestFiltersMapping,
    ): DatasetCustomParams {
        const result: DatasetCustomParams = {};

        filters.forEach((filter) => {
            if (!filter.value) {
                return;
            }

            const mappedField =
                fieldsMapping &&
                Object.entries(fieldsMapping).find(
                    ([, filterMappedValue]) =>
                        filterMappedValue === filter.id || filterMappedValue?.filterField === filter.id,
                );

            const fieldSerializer = mappedField && mappedField[1].serializer;

            const filterValue = fieldSerializer ? fieldSerializer(filter.value) : filter.value;

            let filterQuery;

            switch (filter.type) {
                case GRID_FILTER_TYPE.VALUE:
                    filterQuery = {
                        operation: 'contains',
                        item: filterValue,
                    };
                    break;

                case GRID_FILTER_TYPE.RANGE:
                /* falls through, same as PERCENT_RANGE */

                case GRID_FILTER_TYPE.PERCENT_RANGE:
                    if (filterValue.from && filterValue.to) {
                        filterQuery = {
                            operation: 'between',
                            items: [filterValue.from, filterValue.to],
                        };
                    } else {
                        if (filterValue.from) {
                            filterQuery = {
                                operation: 'gt',
                                item: filterValue.from,
                            };
                        }
                        if (filterValue.to) {
                            filterQuery = {
                                operation: 'lt',
                                item: filterValue.to,
                            };
                        }
                    }
                    break;

                case GRID_FILTER_TYPE.DATES_RANGE: {
                    const dateFormat = formats?.date?.toLocaleUpperCase() || 'DD/MMM/YYYY';

                    const valueFrom = filterValue.from ? moment(filterValue.from).format(dateFormat) : undefined;
                    const valueTo = filterValue.to ? moment(filterValue.to).format(dateFormat) : undefined;

                    if (valueFrom && valueTo) {
                        filterQuery = {
                            operation: 'between',
                            items: [valueFrom, valueTo],
                        };
                    } else {
                        if (valueFrom) {
                            filterQuery = {
                                operation: 'gt',
                                item: valueFrom,
                            };
                        }
                        if (valueTo) {
                            filterQuery = {
                                operation: 'lt',
                                item: valueTo,
                            };
                        }
                    }
                    break;
                }
                case GRID_FILTER_TYPE.SINGLE_SELECTABLE_LIST:
                    filterQuery = {
                        operation: 'in',
                        items: Array.isArray(filterValue) ? filterValue : [filterValue],
                    };
                    break;
            }

            if (filterQuery) {
                const requestField = mappedField ? mappedField[0] : filter.id;
                result[requestField] = filterQuery;
            }
        });

        return this.genericTransformParams(result);
    }

    private static genericTransformParams(params: DatasetCustomParams): DatasetCustomParams {
        const result = {};

        Object.entries(params).forEach(([key, value]) => {
            if (isObject(value)) {
                if (Array.isArray(value)) {
                    result[key] = value.join(',');
                    return;
                }

                value = <DatasetCustomParamsComplexValue>value;

                switch (value.operation) {
                    case 'in':
                        result[key] = `in(${isArray(value.items) ? value.items.join(',') : value.items})`;
                        break;

                    case 'contains':
                        result[key] = `contains(${value.item})`;
                        break;

                    case 'lt':
                        result[key] = `le(${value.item})`;
                        break;

                    case 'gt':
                        result[key] = `ge(${value.item})`;
                        break;

                    case 'between':
                        result[key] = `between(${isArray(value.items) ? value.items.join(',') : value.items})`;
                        break;

                    default:
                        throw new Error('Error serializing dataset params, unknown operation: ' + value.operation);
                }

                return;
            }

            result[key] = value;
        });

        return result;
    }

    private static serializeFormats(formats: FormatsParams): string {
        const keyValues = Object.entries(formats);

        return keyValues.length ? keyValues.map((v) => v.join('=')).join(';') : undefined;
    }
}
