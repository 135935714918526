import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoggedInAccessGuard, ProductGuard } from '@core/guards';
import { FEATURE_NAME_ENUM } from '@shared/enums';

import { productAdIntelligencePath, productBrandPath } from './config';
import { JwtLoginGuard } from './core/guards/jwt-login.guard';
import { AppPageComponent } from './pages';
import { commonPagesRoutes } from './pages/routes';
import { integrationsPagesRoutes } from './integrations/integrations.routes';

const routes: Routes = [
    {
        path: '',
        canActivate: [JwtLoginGuard],
        children: [
            {
                path: '',
                component: AppPageComponent,
                canActivateChild: [LoggedInAccessGuard],
                children: [
                    {
                        path: productAdIntelligencePath,
                        data: {
                            product: FEATURE_NAME_ENUM.PRODUCT_DIGITAL_AD_INTELLIGENCE,
                        },
                        canActivate: [ProductGuard],
                        loadChildren: () => import('./app-main/app-main.module').then((m) => m.AppMainModule),
                    },
                    {
                        path: productBrandPath,
                        data: {
                            product: FEATURE_NAME_ENUM.PRODUCT_BRAND_REPUTATION,
                        },
                        canActivate: [ProductGuard],
                        loadChildren: () => import('./app-brand/app-brand.module').then((m) => m.AppBrandModule),
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: productAdIntelligencePath,
                    },
                ],
            },
            {
                path: 'unsubscribe',
                loadChildren: () => import('./unsubscribe/unsubscribe.module').then((m) => m.UnsubscribeModule),
            },
            ...integrationsPagesRoutes,
            ...commonPagesRoutes,
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
