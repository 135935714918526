import { inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { CurrencyService, DatasetRequestParams, RequestService } from '@shared/services';
import { FiltersValue } from '@shared/services/filters/filters.types';
import { Currency } from '@shared/models';
import { BUSINESS_ENTITY, WIDGET } from '@main/enums';
import { EntityItem } from '@main/models';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { AdTypeItem } from '@main/services/ad-types.service.types';
import { ReportService } from '@main/core/report.service';
import { SerializeEntitiesIdsOptions } from '@main/helpers/entity.helpers.types';
import { BenchmarkResponseByEntities, RestResponseByEntities } from '@main/models/channel';
import { WidgetsApiService } from './widgets.api-service';
import { CreativesApiService } from '../../top-ads/creatives.api-service';
import { GetCreativesListParams } from '../../top-ads/creatives.service.types';
import { CreativesItem } from '../../top-ads/creatives.model';
import { CreativesHelpers } from '../../top-ads/creatives.helpers';

@Injectable()
export abstract class WidgetsService {
    protected readonly reportService = inject(ReportService);
    protected readonly currencyService = inject(CurrencyService);
    protected readonly widgetsApiService = inject(WidgetsApiService);
    protected readonly creativesApiService = inject(CreativesApiService);
    protected readonly requestService = inject(RequestService);

    getAdTypes(
        entityType: BUSINESS_ENTITY,
        entities: EntityItem[],
        filters: FiltersValue,
        currency: Currency,
        labelArea: WIDGET,
    ): Observable<AdTypeItem[]> {
        const { categoryBenchmark } = this.reportService;

        return this.widgetsApiService
            .getAdTypes(entityType, entities, filters, currency, categoryBenchmark, labelArea)
            .pipe(first(), shareReplay(1));
    }

    getTopAdsList(params: GetCreativesListParams): Observable<CreativesItem[]> {
        const {
            entityType,
            entities,
            filters,
            adTypeFilters,
            publishersFilterIds,
            pageIndex,
            pageSize,
            sortBy,
            showAdvertiser,
            entitiesGlobalParentheses = true,
        } = params;
        const { categoryBenchmark } = this.reportService;

        let datasetName = 'creatives';

        if (categoryBenchmark && entities.length > 1) {
            datasetName = 'benchmark-creatives';
        }

        const entitiesIdsPairs = this.getTopAdsEntitiesIds(
            { globalParentheses: entitiesGlobalParentheses, plain: true },
            entities,
        );
        const currency = this.currencyService.currency$.value;

        return this.creativesApiService
            .getCreativesList({
                entityType,
                datasetName,
                entitiesIdsPairs,
                pageNumber: pageIndex,
                pageSize,
                filters,
                adTypeFilters,
                publishersFilterIds,
                sortBy,
                currency,
            })
            .pipe(
                switchMap((creativesResponse) => {
                    const creativesElements = creativesResponse?.creatives?.elements;

                    const advertisers$ = showAdvertiser
                        ? this.creativesApiService.fetchCreativesAdvertisers(
                              entityType,
                              filters,
                              entitiesIdsPairs,
                              creativesElements,
                          )
                        : of(null);

                    return advertisers$.pipe(
                        map((advertisersResponse) => ({ creativesResponse, advertisersResponse })),
                    );
                }),
                map(({ creativesResponse, advertisersResponse }) => {
                    if (!creativesResponse) {
                        return;
                    }

                    const totalCreativesCount = creativesResponse?.creatives?.count?.total || 0;
                    const result = CreativesHelpers.deserializeCreativesList(creativesResponse, advertisersResponse);

                    result[Symbol.for('total')] = totalCreativesCount;

                    return result;
                }),
            );
    }

    getTopAdsEntitiesIds(
        options?: Partial<SerializeEntitiesIdsOptions>,
        entities = this.reportService.rootEntity.items,
    ): Record<string, string> {
        return EntityHelpers.serializeEntitiesIdsPairs(entities, options);
    }

    getChannelsComparisonData(
        entityType: BUSINESS_ENTITY,
        entities: EntityItem[],
        viewEntityType: BUSINESS_ENTITY,
        globalShare: boolean,
        categoryBenchmark: boolean,
        labelArea: WIDGET,
        filters: FiltersValue,
    ): Observable<any> {
        const { categoryBenchmarkId } = this.reportService;

        const { dataset: viewEntityDataset } = EntityHelpers.getEntityInfo(viewEntityType);

        const [regularEntityItems, benchmarkEntityItem] = EntityHelpers.getGroupedBenchmarkItems(
            entities,
            categoryBenchmarkId,
        );

        const customParams = EntityHelpers.serializeCommonFilters(
            filters.channel,
            filters.period,
            filters.country,
            filters.device,
            filters.taxonomyTopic,
            this.currencyService.currency$.value,
        );

        if (globalShare) {
            customParams.shareMod = 'GLOBAL_ENTITIES_CHANNEL_SHARE';
        }

        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(regularEntityItems);

        const commonDatasetParams: DatasetRequestParams = {
            datasetName: 'channels',
            entitiesIdsPairs,
            useViewToken: true,
            customParams,
            labelEntityType: entityType,
            labelArea,
        };

        const baseDatasetParams = cloneDeep(commonDatasetParams);
        const creativesDatasetParams = cloneDeep(commonDatasetParams);

        baseDatasetParams.customParams.fetch = `${viewEntityDataset}.count`;
        creativesDatasetParams.customParams.fetch = 'creatives.count';

        let benchmarkStreams: [Observable<any>, Observable<any>] = [of(null), of(null)];

        if (categoryBenchmark) {
            const benchmarkIdsPairs = EntityHelpers.serializeEntitiesIdsPairs([benchmarkEntityItem]);

            const benchmarkDatasetParams: DatasetRequestParams = {
                datasetName: 'benchmark-channels',
                entitiesIdsPairs: benchmarkIdsPairs,
                useViewToken: true,
                customParams,
                labelEntityType: BUSINESS_ENTITY.Category,
                labelArea,
            };

            const benchmarkBaseParams = cloneDeep(benchmarkDatasetParams);
            const benchmarkCreativeParams = cloneDeep(benchmarkDatasetParams);

            benchmarkBaseParams.customParams.fetch = `${viewEntityDataset}.count`;
            benchmarkCreativeParams.customParams.fetch = 'creatives.count';

            benchmarkStreams = [
                this.requestService.dataset<RestResponseByEntities>(benchmarkBaseParams),
                this.requestService.dataset<RestResponseByEntities>(benchmarkCreativeParams),
            ];
        }

        const requests = [
            this.requestService.dataset<RestResponseByEntities>(baseDatasetParams).pipe(startWith(null)),
            this.requestService.dataset<RestResponseByEntities>(creativesDatasetParams).pipe(startWith(null)),
            ...benchmarkStreams,
        ];

        return combineLatest(requests).pipe(
            map(([baseResponse, creativesResponse, benchmarkBaseResponse, benchmarkCreativesResponse]) => ({
                baseResponse,
                creativesResponse,
                benchmarkBaseResponse,
                benchmarkCreativesResponse,
            })),
            shareReplay(),
        );
    }

    getChannelsBenchmarkComparisonData(
        entities: EntityItem[],
        filters: FiltersValue,
        currency: Currency,
        viewEntityType: BUSINESS_ENTITY,
    ): Observable<BenchmarkResponseByEntities> {
        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(entities);

        const { dataset: viewEntityDataset } = EntityHelpers.getEntityInfo(viewEntityType);

        const datasetParams: DatasetRequestParams = {
            datasetName: 'benchmark-sov',
            entitiesIdsPairs,
            useViewToken: true,
            customParams: EntityHelpers.serializeFiltersParams(filters, currency),
            labelEntityType: BUSINESS_ENTITY.Category,
            labelArea: WIDGET.COMPARISON_SOV,
        };

        datasetParams.customParams.fetch = `creatives.count,${viewEntityDataset}.count`;

        return this.requestService.dataset<BenchmarkResponseByEntities>(datasetParams);
    }
}
