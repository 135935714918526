import { Observable } from 'rxjs';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';
import { IAppConfig, IAppInfo } from '@shared/interfaces';
import { WINDOW } from '@shared/services/window.providers';
import { IAppConfigApiServer } from './shared/interfaces/IAppConfig';
import { WIDGET } from './app-main/enums';
import { CommonPeriodCode } from './shared/models';

const appInfoDefaults: IAppInfo = {
    id: undefined, // white-label id
    name: undefined,
    logo: undefined,
    logoSmall: undefined,
    logoSmallBw: undefined,
    logoSidebar: undefined,
    favIcon: undefined,
    alt: undefined,
    description: undefined,
    keywords: undefined,
    pdfExport: {
        logo1: undefined,
        logo2: undefined,
    },
    supportEmail: 'support@adclarity.com',
    csvExport: {
        decimalDelimiter: '.',
        dateFormat: 'dd/MMM/yyyy',
    },
    footer: undefined,
    menuType: 'side',

    integration: false,
    integrationId: null,
    fullHeightLayout: false,

    showFooter: true,
    featuresEnabled: true,
    showDisabledExportButtonTooltip: true,
    notificationSubscription: true,

    accountMenu: {
        showSemrushSubscriptionPlans: false,
        showEmailNotifications: true,
        showLogout: true,
    },

    controlsToolbar: {
        defaultFilters: {
            country: null,
            period: CommonPeriodCode.LAST_3_MONTHS,
        },

        showChannelRestrictedTooltip: true,
    },

    widgets: {
        [WIDGET.TOP_ADS]: {
            canSelectAds: true,
        },
    },

    viewReportLimitsNotification: {
        showContacts: true,
    },

    override: {
        widgetsExportOptions: {},
        controlsRestrictionActions: {},
    },
};

@Injectable()
export class AppConfig {
    // environment config
    settings: IAppConfig;

    // white-label config
    appInfo: IAppInfo;

    private httpClient: HttpClient;
    private renderer: Renderer2;

    constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private titleService: Title,
        private meta: Meta,
        handler: HttpBackend,
        rendererFactory: RendererFactory2,
    ) {
        this.httpClient = new HttpClient(handler);
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    get getHostname(): string {
        return this.window.location.hostname;
    }

    get apiServer(): IAppConfigApiServer {
        return this.settings.apiServer;
    }

    get apiUrl(): string {
        const { protocol, apiAdress, version } = this.settings.apiServer;

        return protocol + apiAdress + version;
    }

    resolve(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.loadEnvConfig().subscribe(
                (response: IAppConfig) => {
                    this.settings = response;

                    this.loadWhiteLabelConfig(this.settings.whiteLabel).subscribe(
                        (data) => {
                            this.applyWhiteLabel(data);

                            resolve();
                        },
                        (error) => reject(error),
                    );
                },
                (error) => reject(error),
            );
        });
    }

    private loadEnvConfig(): Observable<IAppConfig> {
        const configFileName = this.getEnvConfigFileName();

        return this.fetchFile<IAppConfig>(configFileName);
    }

    private loadWhiteLabelConfig(id: string): Observable<IAppInfo> {
        const wlFileName = this.getWhiteLabelConfigFileName(id);

        return this.fetchFile<IAppInfo>(wlFileName);
    }

    private fetchFile<T>(fileName: string): Observable<T> {
        return this.httpClient.get<T>(fileName);
    }

    private applyWhiteLabel(data: IAppInfo): void {
        this.appInfo = Object.assign({}, appInfoDefaults, data);

        this.titleService.setTitle(data.name);
        this.meta.updateTag({ name: 'description', content: data.description });
        this.meta.updateTag({ name: 'keywords', content: data.keywords });

        /*      TBD: do we need the actual favicon for local dev?

        if (this.isLocalEnv) {
            const favIconTag = this.document.head.querySelector('[rel="icon"');
            this.renderer.setAttribute(favIconTag, 'href', data.favIcon);
        }
*/
        this.renderer.addClass(this.document.body, `wl-${data.id}`);
    }

    private getEnvConfigFileName(): string {
        return `../assets/config/config.${environment.name}.json`;
    }

    private getWhiteLabelConfigFileName(id: string): string {
        return `../assets/config/wl.${id}.json`;
    }
}
