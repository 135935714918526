import { SystemColor } from '../../models';

export const systemColors: Record<SystemColor, string> = {
    [SystemColor.Darkviolet]: '#9552e0',
    [SystemColor.Turquoise]: '#01cec9',
    [SystemColor.Mustard]: '#fdca6e',
    [SystemColor.Hotpink]: '#ff66cc',
    [SystemColor.Graphite]: '#3d4252',
    [SystemColor.LightGreen]: '#74c66c',
    [SystemColor.Coral]: '#d8705a',
    [SystemColor.Olive]: '#9f9560',
    [SystemColor.Deepskyblue]: '#47b4eb',
    [SystemColor.Crab]: '#ff8b94',
    [SystemColor.Royalblue]: '#4d7cfe',
};
