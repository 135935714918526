import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FullscreenViewService } from '@main/shared/widget-full-screen-view';

@Directive({ selector: '[showFullscreenBackButton]' })
export class ShowBackButtonDirective implements OnInit {
    constructor(
        private container: ViewContainerRef,
        private template: TemplateRef<unknown>,
        private service: FullscreenViewService,
    ) {}

    ngOnInit() {
        if (this.service.showBackButton) this.container.createEmbeddedView(this.template);
    }
}
