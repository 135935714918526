import { Directive, Input } from '@angular/core';

import { UXRadioActionPayload, UXElementType } from '../../models';
import { ANALYTICS_ELEMENT } from '../../data';

import { AnalyticsElementBaseDirective } from './analytics-element.base';

@Directive({
    selector: '[analyticsTextRadio]',
    providers: [
        {
            provide: ANALYTICS_ELEMENT,
            useExisting: AnalyticsRadioDirective,
        },
    ],
})
export class AnalyticsRadioDirective extends AnalyticsElementBaseDirective<UXRadioActionPayload, HTMLInputElement> {
    elementType = UXElementType.Radio;

    @Input() label: string;

    protected getSpecificPayload(): Partial<UXRadioActionPayload> {
        return {
            elementName: this.label,
            checked: this.host.checked,
        };
    }
}
