export const defaultFormatRule = '${symbol}${value}';

export const formatRules = {
    ARS: {
        full: '$${value} ${code}',
        short: '$${value}',
        headline: '${code}',
    },
    CLP: {
        full: '$${value} ${code}',
        short: '$${value}',
        headline: '${code}',
    },
    COP: {
        full: '$${value} ${code}',
        short: '$${value}',
        headline: '${code}',
    },
    SGD: {
        full: '$${value} ${code}',
        short: '$${value}',
        headline: '${code}',
    },
    ZAR: {
        full: 'R${value} ${code}',
        short: 'R${value}',
        headline: '${code}',
    },

    DKK: {
        full: '${value} kr ${code}',
        short: '${value} kr',
        headline: '${code}',
    },
    NOK: {
        full: '${value} kr ${code}',
        short: '${value} kr',
        headline: '${code}',
    },
    SEK: {
        full: '${value} kr ${code}',
        short: '${value} kr',
        headline: '${code}',
    },

    MYR: {
        full: 'RM ${value}',
    },
    IDR: {
        full: 'Rp ${value}',
    },

    CHF: {
        full: '${value} ${code}',
    },

    PHP: {
        full: '₱${value}',
    },
    RUB: {
        full: '₽${value}',
    },
    THB: {
        full: '฿${value}',
    },
};
