import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { EntityItem } from '@main/models';
import { BUSINESS_ENTITY } from '@main/enums';

@Component({
    selector: 'entity-card',
    templateUrl: './entity-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCardComponent {
    readonly BUSINESS_ENTITY = BUSINESS_ENTITY;

    @Input() item: EntityItem;
    @Input() removable: boolean;
    @Input() addable: boolean;
    @Input() clickable = false;
    @Input() animate = false;
    @Input() stripTitle = false;
    @Input() stripTitleCount = 30;
    @Input() addTooltip = '';
    @Input() removeTooltip = '';

    @Output() add = new EventEmitter<EntityItem>();
    @Output() click = new EventEmitter<EntityItem>();
    @Output() remove = new EventEmitter<EntityItem>();
}
