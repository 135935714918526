import { Component, Input } from '@angular/core';
import { AppConfig } from '@app/app.config';

@Component({
    selector: 'widget-error',
    templateUrl: './widget-error.component.html',
    styleUrls: ['./widget-error.component.scss'],
})
export class WidgetErrorComponent {
    @Input() title: string;

    get supportEmail(): string {
        return this.appConfig.appInfo.supportEmail;
    }

    constructor(private appConfig: AppConfig) {}
}
