import { NgModule } from '@angular/core';
import { IntegrationSemrushModule } from './semrush/semrush.module';
import { IntegrationUserGuidingModule } from './userguiding/userguiding.module';
import { IntegrationServiceBase } from './services/integration.service.base';
import { IntegrationWinmoModule } from './winmo/winmo.module';

@NgModule({
    imports: [IntegrationSemrushModule, IntegrationUserGuidingModule, IntegrationWinmoModule],
    exports: [IntegrationSemrushModule, IntegrationUserGuidingModule],
    providers: [IntegrationServiceBase],
})
export class IntegrationsModule {}
