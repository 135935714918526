<div
    class="icon"
    [ngClass]="{ inactive: (tooltip$ | async) === null }"
    [matTooltip]="tooltip$ | async"
    matTooltipPosition="above"
    analyticsHover
    analyticsImage
    analyticsBusiness="Tooltip Usage"
    [analyticsData]="{ 'Widget Name': analyticsWidget?.analyticsWidgetName }"
    [analyticsAddGlobalProps]="true"
>
    <mat-icon class="s-16" svgIcon="info"></mat-icon>
</div>
