import { ExportStatus } from '@main/services/export.service.types';
import { INTERVAL_TYPE } from '@main/enums';
import { LIMIT_AREA } from '@main/components/limits-notification/limits-notification.config';
import { FEATURE_NAME_ENUM } from '@shared/enums';

export class LimitsHelpers {
    static getLimitsArea(featureName: FEATURE_NAME_ENUM): LIMIT_AREA {
        const limitsMapping = {
            REPORT_EXCEL_EXPORT: LIMIT_AREA.GLOBAL,
            WIDGET_TSV_EXPORT: LIMIT_AREA.WIDGET,
            WIDGET_EXCEL_EXPORT: LIMIT_AREA.WIDGET,
            CREATIVES_EXPORT: LIMIT_AREA.CREATIVES,
        };

        return limitsMapping[featureName];
    }

    static getExpiredLimitInterval(status: ExportStatus): INTERVAL_TYPE {
        const intervalExceeded = LimitsHelpers.compareLimits(status.limits.downloadsCount, status.downloadsCount);
        const intervalPair = Object.entries(intervalExceeded).find(([, val]) => !!val);
        const [interval] = intervalPair || [];

        return (interval || null) as INTERVAL_TYPE;
    }

    static compareLimits(
        limits: Record<INTERVAL_TYPE, number>,
        status: Record<INTERVAL_TYPE, number>,
    ): Record<INTERVAL_TYPE, boolean> {
        return limits && status
            ? Object.keys(limits).reduce(
                  (acc, key) => {
                      return { ...acc, [key]: limits[key] <= status[key] };
                  },
                  { [INTERVAL_TYPE.DAILY]: false, [INTERVAL_TYPE.MONTHLY]: false } as Record<INTERVAL_TYPE, boolean>,
              )
            : <Record<INTERVAL_TYPE, boolean>>{};
    }
}
