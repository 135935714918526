import { Directive } from '@angular/core';

import { ANALYTICS_AREA } from '../../data';
import { UXArea } from '../../models';

import { AnalyticsAreaBase } from './analytics-area.base';

@Directive({
    selector: '[analyticsPopup]',
    providers: [
        {
            provide: ANALYTICS_AREA,
            useExisting: AnalyticsPopupDirective,
        },
    ],
})
export class AnalyticsPopupDirective extends AnalyticsAreaBase {
    type: UXArea = 'popup';
}
