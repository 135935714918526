import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';

import { productAdIntelligencePath } from '@app/config';
import { AppConfig } from '@app/app.config';
import { FiltersService } from '@shared/services/filters/filters.service';
import { CHANNEL } from '@main/enums';
import { ReportService } from '@main/core/report.service';
import { EventsService } from '@shared/services';

@Component({
    selector: 'blank-placeholder',
    templateUrl: './blank-placeholder.component.html',
    styleUrls: ['./blank-placeholder.component.scss'],
})
export class BlankPlaceholderComponent {
    entityTitle = '';
    allowedChannels$: Observable<string[]>;
    restrictedChannels$: Observable<string[]>;

    noChannelAvailable$: Observable<boolean>;
    noPeriodAvailable$: Observable<boolean>;
    dataUnavailable$: Observable<boolean>;

    get isIntegration(): boolean {
        return this.config.appInfo.integration;
    }

    constructor(
        private config: AppConfig,
        private router: Router,
        private filtersService: FiltersService,
        private reportService: ReportService,
        private eventService: EventsService,
    ) {
        this.noChannelAvailable$ = this.filtersService.channel$.pipe(map((v) => v === undefined));
        this.noPeriodAvailable$ = this.filtersService.period$.pipe(map((v) => v === undefined));
        this.dataUnavailable$ = combineLatest([this.noChannelAvailable$, this.noPeriodAvailable$]).pipe(
            map(([noChannelAvailable, noPeriodAvailable]) => noChannelAvailable || noPeriodAvailable),
        );

        this.entityTitle = this.reportService.rootEntity.getItemsTitles(true).join(', ');

        this.allowedChannels$ = this.filtersService.channels$.pipe(
            map((channels) => channels.filter((v) => !v.restricted && v.type !== CHANNEL.ALL).map((v) => v.title)),
        );

        this.restrictedChannels$ = this.filtersService.channels$.pipe(
            map((channels) => channels.filter((v) => v.restricted).map((v) => v.title)),
        );
    }

    onBtnUpgradeClick(): void {
        this.router.navigate([`/${productAdIntelligencePath}/pricing`]);
    }

    onBtnHomeClick(): void {
        this.eventService.resetHoldedEvents();
        this.router.navigate([`/${productAdIntelligencePath}/start`]);
    }
}
