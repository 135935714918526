import { Directive } from '@angular/core';

import { ANALYTICS_ELEMENT } from '../../data';
import { UXElementType } from '../../models';

import { AnalyticsElementWithTextBaseDirective } from '@app/modules/analytics/directives/elements/analytics-element-with-text.base';

@Directive({
    selector: '[analyticsElement]',
    providers: [
        {
            provide: ANALYTICS_ELEMENT,
            useExisting: AnalyticsElementDirective,
        },
    ],
})
export class AnalyticsElementDirective extends AnalyticsElementWithTextBaseDirective {
    protected elementType = UXElementType.Element;
}
