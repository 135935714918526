import { InjectionToken } from '@angular/core';

import { AnalyticsServiceClass, AnalyticsTool, AnalyticsToolService, AnalyticsWidgetComponent } from '../models';
import { AmplitudeService, MixpanelService } from '../services';

export const ANALYTICS_TOOL = new InjectionToken<AnalyticsToolService>('analytics_tool');

export const analyticsToolServicesMap: Record<AnalyticsTool, AnalyticsServiceClass> = {
    mixpanel: MixpanelService,
    amplitude: AmplitudeService,
};

export const getAnalyticsToolService = (tool: AnalyticsTool): AnalyticsServiceClass => analyticsToolServicesMap[tool];

export const ANALYTICS_AREA = new InjectionToken('analytics_area');
export const ANALYTICS_ELEMENT = new InjectionToken('analytics_element');
export const ANALYTICS_WIDGET = new InjectionToken<AnalyticsWidgetComponent>('analytics_container');
