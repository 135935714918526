import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { AuthService, AuthHttpError, AuthHttpErrorResponse } from '@shared/services';

@UntilDestroy()
@Component({
    selector: 'forgot-password-page',
    templateUrl: './forgot-password-page.component.html',
    styleUrls: ['../../pages.scss'],
})
export class ForgotPasswordPageComponent {
    form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
    });

    loading: boolean;
    sendEmailError: AuthHttpError;

    constructor(private router: Router, private formBuilder: UntypedFormBuilder, private authService: AuthService) {}

    sendEmail(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        const { email } = this.form.value;

        this.authService
            .remindPassword(email)
            .pipe(
                untilDestroyed(this),
                finalize(() => (this.loading = false)),
                catchError(({ error }: AuthHttpErrorResponse) => {
                    this.sendEmailError = error;

                    return throwError(error);
                }),
            )
            .subscribe(() => this.navigateToRecoverPasswordPage());
    }

    private navigateToRecoverPasswordPage(): void {
        const { email } = this.form.value;

        this.router.navigate(['recover-password'], { queryParams: { email } });
    }
}
