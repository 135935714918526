<app-base-page title="Log In">
    <div class="login">
        <form [formGroup]="form" (ngSubmit)="login()" novalidate>
            <input type="email" class="text-form-control block" placeholder="E-mail address" formControlName="email" />
            <app-validation-messages [control]="form.controls.email" fieldName="email"></app-validation-messages>

            <app-password-visibility>
                <input
                    #control
                    type="password"
                    class="text-form-control block"
                    placeholder="Password"
                    formControlName="password"
                />
            </app-password-visibility>
            <app-validation-messages [control]="form.controls.password" fieldName="password"></app-validation-messages>

            <mat-error *ngIf="loginError?.message" class="validation-message mt-10">
                <mat-icon>warning</mat-icon>
                <span>{{ loginError.message }}</span>
            </mat-error>

            <mat-checkbox class="check-form-control block" formControlName="rememberMe">
                <span>Remember my account</span>
            </mat-checkbox>

            <div class="actions">
                <button
                    mat-raised-button
                    type="submit"
                    color="accent"
                    class="submit-button u-button h-38 block"
                    [disabled]="loading"
                >
                    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent"> </mat-progress-bar>
                    Login
                </button>

                <a routerLink="/forgot-password" class="primary-link">Forgot password?</a>
            </div>
        </form>
    </div>
</app-base-page>
