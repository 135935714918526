<ng-container *ngFor="let entity of showedEntities; let i = index">
    <default-entity-card
        *ngIf="entity !== 'SEPARATOR'"
        class="multiline-entity-card"
        [item]="entity"
        [clickable]="false"
    >
    </default-entity-card>
    <ng-container *ngIf="operand && entity === 'SEPARATOR'">
        <span class="separator inline">{{ operand }}</span>
    </ng-container>
</ng-container>
<button
    fxFlex="none"
    *ngIf="groupedHiddenItems.length"
    class="other-entities"
    [ngClass]="{ 'animate-border': null }"
    [matMenuTriggerFor]="otherItemsPopup"
    (mouseenter)="othersPopup.openMenu()"
    (mouseleave)="onTriggerLeave$.next('leaves')"
    data-unit="other-items-button"
>
    <mat-icon svgIcon="threeDots" class="s-16 mr-8"></mat-icon> View all
</button>

<mat-menu
    class="multiline-entities-list-others-popup"
    #otherItemsPopup="matMenu"
    overlapTrigger="false"
    yPosition="below"
    [hasBackdrop]="!tooltipMode"
>
    <div
        class="multiline-entities-list-others-box"
        (mouseleave)="othersPopup.closeMenu()"
        (mouseenter)="onMenuEnters$.next('enters')"
        (click)="$event.stopPropagation()"
    >
        <div class="content">
            <ng-container *ngFor="let item of groupedHiddenItems; let i = index">
                <ng-container *ngIf="item === 'SEPARATOR' && operand">
                    <div class="separator row">
                        <span class="side-line"></span>
                        {{ operand }}
                        <span class="side-line"></span>
                    </div>
                </ng-container>
                <ng-container *ngIf="Array.isArray(item)">
                    <div class="items-row">
                        <default-entity-card
                            *ngFor="let subItem of item"
                            [item]="subItem"
                            [clickable]="false"
                            class="multiline-entity-card"
                        >
                        </default-entity-card>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</mat-menu>
