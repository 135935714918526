import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * Represents an event emitted when the scroll event occurs on a MatAutocomplete component.
 */
export interface IAutoCompleteScrollEvent {
    autoComplete: MatAutocomplete;
    scrollEvent: Event;
}

/**
 * Directive that enables scrolling functionality for MatAutocomplete component.
 * It emits the 'optionsScroll' event when the user scrolls to the bottom of the options list.
 */
@UntilDestroy()
@Directive({
    selector: 'mat-autocomplete[optionsScroll]',
    exportAs: 'mat-autocomplete[optionsScroll]',
})
export class MatAutocompleteOptionsScrollDirective implements OnDestroy {
    /**
     * The threshold percentage that determines when to emit the 'optionsScroll' event.
     * If not specified, the event will be emitted when the user scrolls to the bottom of the options list.
     */
    @Input() thresholdPercent = 0.8;

    /**
     * The threshold pixel that determines when to emit the 'optionsScroll' event.
     * If specified, the event will be emitted when the user scrolls to the bottom of the options list.
     */
    @Input() thresholdPixel = 1;

    /**
     * Event emitted when the user scrolls to the bottom of the options list.
     * The event payload contains the MatAutocomplete component and the scroll event.
     */
    @Output('optionsScroll') scroll = new EventEmitter<IAutoCompleteScrollEvent>();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened
            .pipe(
                tap(() => {
                    // When autocomplete raises opened, panel is not yet created (by Overlay).
                    // The panel will be available on the next tick.
                    // The panel will NOT open if there are no options to display.
                    setTimeout(() => {
                        // Remove listener just for safety, in case the close event is skipped.
                        this.removeScrollEventListener();
                        this.autoComplete.panel.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
                    }, 0);
                }),
                untilDestroyed(this),
            )
            .subscribe();

        this.autoComplete.closed
            .pipe(
                tap(() => this.removeScrollEventListener()),
                untilDestroyed(this),
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.removeScrollEventListener();
    }

    private onScroll(event: Event) {
        if (this.thresholdPercent === undefined) {
            this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
        } else {
            const scrollTop = Math.round((event.target as HTMLElement).scrollTop);
            const scrollHeight = (event.target as HTMLElement).scrollHeight;
            const elementHeight = (event.target as HTMLElement).clientHeight;
            const bottom = scrollHeight - (elementHeight + scrollTop);
            const atBottom =
                bottom === 0 ||
                bottom <= this.thresholdPixel ||
                (elementHeight + scrollTop) / scrollHeight >= this.thresholdPercent;
            if (atBottom) {
                this.scroll.next(null);
            }
        }
    }

    private removeScrollEventListener() {
        if (this.autoComplete?.panel) {
            this.autoComplete.panel.nativeElement.removeEventListener('scroll', this.onScroll);
        }
    }
}
