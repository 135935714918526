import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppConfig } from '@app/app.config';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
    @Input() title!: string;
    @Input() showActionButton = true;
    @Input() actionText = 'Refresh the app';

    @Output() onAction = new EventEmitter<void>();

    appInfo = this.config.appInfo;

    constructor(private config: AppConfig) {}
}
