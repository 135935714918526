<a
    *ngIf="navigatable"
    [attr.target]="linkNewPage ? '_blank' : null"
    rel="noreferrer"
    [matTooltip]="_tooltip"
    matTooltipClass="tooltip-multiline"
    matTooltipPosition="above"
    [href]="linkUrl"
    class="item"
>
    <fav-icon
        *ngIf="needShowIcon"
        [src]="iconUrl"
        [defaultIcon]="defaultIcon"
        [removable]="removable"
        [useAsSvg]="useAsSvg"
        [matIconSize]="matIconSize"
        [matIconColor]="matIconColor"
        [ngClass]="iconCls"
        (remove)="remove.emit()"
    ></fav-icon>

    <span [innerHtml]="title" class="title"></span>
</a>

<div
    *ngIf="!navigatable"
    [matTooltip]="_tooltip"
    matTooltipClass="tooltip-multiline"
    matTooltipPosition="above"
    (click)="onClick()"
    class="item"
>
    <fav-icon
        *ngIf="needShowIcon"
        [src]="iconUrl"
        [defaultIcon]="defaultIcon"
        [removable]="removable"
        [matIconSize]="matIconSize"
        [useAsSvg]="useAsSvg"
        [matIconColor]="matIconColor"
        [ngClass]="iconCls"
        (remove)="remove.emit()"
    ></fav-icon>

    <span [innerHtml]="title" class="title"></span>
</div>
