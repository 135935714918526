<button
    mat-button
    mat-flat-button
    type="button"
    color="accent"
    class="u-button btn-back h-32"
    data-unit="back-button"
    (click)="goBack()"
>
    &larr;&nbsp;&nbsp; Back
</button>
