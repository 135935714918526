import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RequestService } from '@shared/services/request/request.service';
import { RequestParams } from '@shared/models/request-params.model';
import { DatasetRequestParams } from '@shared/services/request/request.types';
import { FiltersValue } from '@shared/services/filters/filters.types';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { ReportConfig } from '@shared/interfaces/ReportConfig';
import { Currency } from '@shared/models';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { IEntityItemId } from '@main/types';
import { BUSINESS_ENTITY, WIDGET } from '@main/enums';
import {
    CreativesResponseBaseElement,
    GetCreativesAdvertisersListResponse,
    GetCreativesListResponse,
} from './creatives.service.types';
import { UI_AD_TYPE_FILTER } from './components/creatives-filters/ad-types-filter.types';
import { SORT_BY_TYPE } from './creatives.types';
import { CreativesHelpers } from './creatives.helpers';

interface getCreativesListParams {
    entityType: BUSINESS_ENTITY;
    datasetName: string;
    entitiesIdsPairs: Record<string, string>;
    pageNumber: number;
    pageSize: number;
    filters: FiltersValue;
    adTypeFilters: UI_AD_TYPE_FILTER[];
    publishersFilterIds: number[];
    sortBy: SORT_BY_TYPE;
    currency: Currency;
    categoryBenchmarkId?: IEntityItemId;
}

@Injectable()
export class CreativesApiService {
    constructor(private requestService: RequestService, @Inject(REPORT_CONFIG) protected reportConfig: ReportConfig) {}

    getCreativesList({
        entityType,
        datasetName,
        entitiesIdsPairs,
        pageNumber,
        pageSize,
        filters,
        adTypeFilters,
        publishersFilterIds,
        sortBy,
        currency,
    }: getCreativesListParams): Observable<GetCreativesListResponse> {
        const requestParams = new RequestParams();

        requestParams.setPage(pageNumber, pageSize);
        requestParams.setSort(CreativesHelpers.serializeSortBy(sortBy));

        const datasetParams: DatasetRequestParams = {
            datasetName,
            customParams: EntityHelpers.serializeFiltersParams(filters, currency),
            entitiesIdsPairs,
            requestParams,
            labelEntityType: entityType,
            labelArea: WIDGET.TOP_ADS,
            useViewToken: true,
        };

        const serializedAdTypeFilters = CreativesHelpers.serializeAdTypeFilters(adTypeFilters);

        if (serializedAdTypeFilters) {
            datasetParams.customParams.type = {
                operation: 'in',
                items: serializedAdTypeFilters,
            };
        }

        if (publishersFilterIds) {
            const serializedPublisherFilters = CreativesHelpers.serializePublisherTypeFilters(publishersFilterIds);

            if (serializedPublisherFilters) {
                datasetParams.customParams.publisher = serializedPublisherFilters;
            }
        }

        return this.requestService.dataset<GetCreativesListResponse>(datasetParams);
    }

    fetchCreativesAdvertisers(
        entityType: BUSINESS_ENTITY,
        filters: FiltersValue,
        entitiesIdsPairs: Record<string, string>,
        creativesItems: CreativesResponseBaseElement[],
    ): Observable<GetCreativesAdvertisersListResponse> {
        const creativesIds = creativesItems.map((v) => v.id);

        if (!creativesIds) {
            return of(null);
        }

        const datasetParams: DatasetRequestParams = {
            datasetName: 'creative-advertisers',
            entitiesIdsPairs,
            useViewToken: true,
            customParams: {
                ...EntityHelpers.serializeFiltersParams(filters),
                creative: creativesIds,
            },
            labelEntityType: entityType,
            labelArea: WIDGET.TOP_ADS,
        };

        return this.requestService.dataset<GetCreativesAdvertisersListResponse>(datasetParams);
    }
}
