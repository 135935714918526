<mat-progress-spinner
    *ngIf="checkingTokenValidity$ | async; else content"
    mode="indeterminate"
    diameter="40"
    color="gray"
    style="display: inline-block"
></mat-progress-spinner>

<ng-template #content>
    <app-base-page title="Enter new password">
        <p class="mb-30">Setup new password for you account and confirm</p>

        <form
            *ngIf="isTokenValid$ | async; else invalidTokenMessage"
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
            novalidate
        >
            <app-password-visibility>
                <input
                    #control
                    type="password"
                    class="text-form-control block"
                    placeholder="Enter password"
                    formControlName="password"
                />
            </app-password-visibility>
            <app-validation-messages [control]="form.controls.password" fieldName="password"></app-validation-messages>

            <app-password-visibility>
                <input
                    #control
                    type="password"
                    class="text-form-control block"
                    placeholder="Confirm password"
                    formControlName="confirmPassword"
                />
            </app-password-visibility>
            <app-validation-messages
                [control]="form.controls.confirmPassword"
                fieldName="confirmPassword"
            ></app-validation-messages>

            <div class="actions">
                <button
                    mat-raised-button
                    type="submit"
                    color="accent"
                    class="submit-button u-button h-38 block"
                    [disabled]="submissionProcessing$ | async"
                >
                    <mat-progress-bar *ngIf="submissionProcessing$ | async" mode="indeterminate" color="accent">
                    </mat-progress-bar>
                    Submit
                </button>
            </div>

            <mat-error *ngIf="passwordErrorMessage$ | async as message" class="mt-10">
                {{ message }}
            </mat-error>
        </form>
    </app-base-page>
</ng-template>

<ng-template #invalidTokenMessage>
    <mat-error>Token is invalid</mat-error>

    <div class="actions">
        <button
            routerLink="/login"
            mat-raised-button
            type="submit"
            color="accent"
            class="submit-button u-button h-38 block"
        >
            Go to login
        </button>
    </div>
</ng-template>
