import { Directive } from '@angular/core';

import { ANALYTICS_AREA } from '../../data';
import { UXArea } from '../../models';

import { AnalyticsAreaBase } from './analytics-area.base';

@Directive({
    selector: '[analyticsTopToolbar]',
    providers: [
        {
            provide: ANALYTICS_AREA,
            useExisting: AnalyticsTopToolbarDirective,
        },
    ],
})
export class AnalyticsTopToolbarDirective extends AnalyticsAreaBase {
    type: UXArea = 'top-toolbar';
}
