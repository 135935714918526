import { Injectable } from '@angular/core';

import { EventsService } from '@shared/services/events/events.service';
import { EventsEnum } from '@shared/services/events/events.enum';
import { MARK_ENTITY_ID, MARK_ENTITY_TYPE, MarkView, MarkViews } from '@main/components/mark-entity-button/mark.types';
import { MarkService } from '@main/components/mark-entity-button/services/mark.service';
import { MarkStatuses } from '@main/components/mark-entity-button/services/mark.service.types';
import { BUSINESS_ENTITY } from '@main/enums';
import { IContext } from '@main/interfaces';
import { Context } from '@main/core/context';

@Injectable()
export class MarkHelper {
    constructor(private eventsService: EventsService, private markService: MarkService) {}

    listenMarkStateChanged(context: IContext | Context): void {
        this.eventsService.on(EventsEnum.MARK_MODE_DISABLE, () => (context.settings.markModeEnabled = false));
        this.eventsService.on(EventsEnum.MARK_MODE_ENABLE, () => (context.settings.markModeEnabled = true));
    }

    mapToMarkViews(entityType: MARK_ENTITY_TYPE, ids: MARK_ENTITY_ID[]): MarkViews {
        const marks: MarkViews = {};
        ids.forEach((v) => {
            const mark: MarkView = {
                id: v,
                loading: true,
                status: undefined,
                type: entityType,
            };
            marks[v] = mark;
        });

        return marks;
    }

    loadMarkStatuses(marks: MarkViews): void {
        const ids = Object.values(marks).map((v) => v.id);
        if (ids.length === 0) {
            return;
        }
        const id = ids[0];
        const entityType = marks[id].type;

        this.markService.getMarkStatuses(entityType, ids).subscribe((res: MarkStatuses) => {
            res.items.forEach((item) => {
                marks[item.id].status = item.status;
                marks[item.id].loading = false;
            });
        });
    }

    mapBusinessEntity(businessEntity: BUSINESS_ENTITY): MARK_ENTITY_TYPE {
        const mapper = {
            [BUSINESS_ENTITY.Publisher]: MARK_ENTITY_TYPE.PUBLISHER,
            [BUSINESS_ENTITY.Advertiser]: MARK_ENTITY_TYPE.ADVERTISER,
        };

        return mapper[businessEntity];
    }
}
