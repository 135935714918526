import { Injectable } from '@angular/core';

import { GetPageInfoResult } from '@app/integrations/services/viewport.service.types';
import { ViewportServiceBase } from '@app/integrations/services/viewport.base-service';
import { SMSDK } from './semrush.service.types';

@Injectable()
export class ViewportService extends ViewportServiceBase {
    minAppHeight = 600;

    SDK: SMSDK;

    setSDK(SDK: SMSDK): void {
        this.SDK = SDK;
    }

    scrollTo(top: number): void {
        this.SDK.client('scrollTo', { top, behavior: 'smooth' });
    }

    resizeWindow(height: number, _windth?: number): void {
        this.SDK.client('resizeWindow', { height });
    }

    getPageInfo(): Promise<GetPageInfoResult> {
        return this.SDK.client('getPageInfo');
    }
}
