import { Directive } from '@angular/core';

import { UXElementType } from '../../models';
import { ANALYTICS_ELEMENT } from '../../data';

import { AnalyticsElementWithTextBaseDirective } from './analytics-element-with-text.base';

@Directive({
    selector: '[analyticsList]',
    providers: [
        {
            provide: ANALYTICS_ELEMENT,
            useExisting: AnalyticsListDirective,
        },
    ],
})
export class AnalyticsListDirective extends AnalyticsElementWithTextBaseDirective {
    elementType = UXElementType.List;
}
