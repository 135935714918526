import { Component, Input, Output, EventEmitter, OnInit, ViewChild, inject, ChangeDetectorRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';

import { PERIOD_TYPE } from '@shared/enums';
import { PeriodHelpers } from '@shared/helpers/period.helpers';
import { CommonPeriodCode, Period } from '@shared/models';
import { MonthRange } from '../month-picker/month-picker.component';

@Component({
    selector: 'period-selector',
    templateUrl: './period-selector.component.html',
    styleUrls: ['./period-selector.component.scss'],
})
export class PeriodSelectorComponent implements OnInit {
    @Input() value: Period;
    @Input({ required: true }) periods: Period[];
    @Input() periodMaxMonths: number;
    @Input() disabled = false;

    @Output() select = new EventEmitter<Period>();

    @ViewChild(MatMenuTrigger, { static: false })
    trigger: MatMenuTrigger;

    predefinedItems: Period[];
    selectedPeriod: Period = {} as Period;

    calendarMinDate: string;
    calendarMaxDate: string;
    calendarStartDate: string;
    calendarEndDate: string;
    selectableMonthsDates: string[];

    get isDisabled(): boolean {
        return !this.value || this.value?.disabled || this.disabled;
    }

    private predefinedPeriodsCodes = [
        CommonPeriodCode.LAST_7_DAYS,
        CommonPeriodCode.LAST_30_DAYS,
        CommonPeriodCode.LAST_3_MONTHS,
        CommonPeriodCode.LAST_12_MONTHS,
        CommonPeriodCode.YEAR_TO_DATE,
    ];

    private readonly cdr = inject(ChangeDetectorRef);

    ngOnInit(): void {
        this.predefinedItems = this.periods.filter((v) =>
            this.predefinedPeriodsCodes.includes(<CommonPeriodCode>v.code),
        );

        this.calculateAvailableRange();
        this.calculateAvailableMonths();
    }

    onPopupOpened(): void {
        this.selectedPeriod = this.value;

        this.calendarStartDate = this.value.start;
        this.calendarEndDate = this.value.end;
    }

    onSelectPredefined(period, event): void {
        if (!this.isPeriodSelectable(period)) {
            return;
        }

        this.selectedPeriod = period;

        this.calendarStartDate = period.start;
        this.calendarEndDate = period.end;
        this.cdr.detectChanges();

        if (event) {
            event.stopPropagation();
        }
    }

    onCalendarSelect(e: MonthRange): void {
        const startDate = e.fromMonthYear.startDate;
        const endDate = e.toMonthYear.endDate;

        const definedPeriod = this.periods.find((v) => v.start === startDate && v.end === endDate);

        if (definedPeriod) {
            this.selectedPeriod = definedPeriod;
        } else {
            const startMonth = this.periods.find((v) => v.start === startDate && v.type === PERIOD_TYPE.MONTHLY);
            const endMonth = this.periods.find((v) => v.end === endDate && v.type === PERIOD_TYPE.MONTHLY);

            this.selectedPeriod = PeriodHelpers.composePeriodFromStartEndMonths(startMonth, endMonth);
        }
    }

    onBtnApplyClick(): void {
        this.value = this.selectedPeriod;
        this.select.emit(this.selectedPeriod);
        this.trigger.closeMenu();
    }

    private calculateAvailableRange(): void {
        const months = this.periods.filter((v) => !v.disabled && !v.restricted);

        const sorted = months.sort((a, b) => (moment(a.start).isAfter(b.start) ? 1 : -1));

        if (sorted.length) {
            this.calendarMinDate = sorted[0].start;
            this.calendarMaxDate = sorted[sorted.length - 1].end;
        }
    }

    private calculateAvailableMonths(): void {
        const months = this.periods.filter((v) => !v.disabled && !v.restricted && v.type === PERIOD_TYPE.MONTHLY);

        this.selectableMonthsDates = months.map((v) => v.start);
    }

    private isPeriodSelectable(item): boolean {
        return !item.disabled && !item.restricted;
    }
}
