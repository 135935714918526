import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { youTubePublisherId } from '@app/config';
import { BUSINESS_ENTITY, PUBLISHER_TYPE } from '@main/enums';
import { EntityPublisher } from '@main/models';
import { NavigationService } from '@main/services/navigation.service';
import { ReportService } from '@main/core/report.service';

@Injectable()
export class CampaignsCommonService {
    constructor(
        private router: Router,
        private reportService: ReportService,
        private navigationService: NavigationService,
    ) {}

    canNavigateBackToReport(route: ActivatedRoute): boolean {
        const reportId = route.snapshot?.parent?.parent?.params?.id;

        return !!reportId;
    }

    navigateToCampaignReport(campaignId: number, route: ActivatedRoute): void {
        const navigationExtras: NavigationExtras = { relativeTo: route, replaceUrl: false };
        const { rootEntity } = this.reportService;
        const isSinglePublisher = rootEntity?.items.length === 1 && rootEntity.entityType === BUSINESS_ENTITY.Publisher;

        if (isSinglePublisher) {
            const publisherEntity = rootEntity.items[0] as EntityPublisher;
            const publisherId =
                publisherEntity.publisherTypeCode === PUBLISHER_TYPE.YOUTUBE_CHANNEL
                    ? youTubePublisherId
                    : publisherEntity.id;

            navigationExtras.queryParams = { publisherId };
        }

        this.router.navigate(['campaign', campaignId], navigationExtras);
    }

    goBackToReport(): void {
        // XXX: check why campaign history state is duplicated
        this.navigationService.goBack(2);
    }
}
