import { NgModule } from '@angular/core';

import { FullscreenWidgetBackButtonComponent } from './components';
import {
    FullscreenGlobalExportDirective,
    FullscreenWidgetExportDirective,
    ShowForFullScreenModeDirective,
    ShowBackButtonDirective,
} from './directives';
import { ShowMorePipe } from './pipes';
import { FullscreenViewExportService } from './services';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

const components = [FullscreenWidgetBackButtonComponent];

const directives = [
    ShowForFullScreenModeDirective,
    FullscreenGlobalExportDirective,
    FullscreenWidgetExportDirective,
    ShowBackButtonDirective,
];

const pipes = [ShowMorePipe];

@NgModule({
    declarations: [...components, ...directives, ...pipes],
    exports: [...components, ...directives, ...pipes],
    imports: [CommonModule, MatButtonModule],
    providers: [FullscreenViewExportService],
})
export class WidgetFullScreenViewModule {}
