import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { filter, first } from 'rxjs';

import { svgIconsConfig } from './app.icons-config';
import { LayoutService } from './shared/services';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private layoutService: LayoutService,
        private router: Router,
    ) {
        this.registerIcons();

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                first(),
            )
            .subscribe(() => this.layoutService.hideSplashScreen());
    }

    private registerIcons(): void {
        let registry = this.matIconRegistry;

        Object.entries(svgIconsConfig).forEach(([name, fileName]) => {
            registry = registry.addSvgIcon(
                name,
                this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${fileName}`),
            );
        });
    }
}
