import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as html2pdf from 'html2pdf.js';

import { NOTIFICATION_TYPE } from '@app/modules/top-notification/top-notification-bar.types';
import { SharedTopNotificationBarService } from '@app/modules/top-notification/top-notification-bar.service';
import { ExportHelpers } from '@shared/helpers/export.helpers';
import { LayoutHelpers } from '@shared/helpers/layout.helpers';
import { CommonHelpers } from '@shared/helpers/common.helpers';
import { ReportConfig } from '@shared/interfaces/ReportConfig';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { ReportService } from '@main/core/report.service';

@Injectable()
export class GlobalExportPdfService {
    originContainer: HTMLElement;
    clonedContainer: HTMLElement;

    constructor(
        private reportService: ReportService,
        private notificationService: SharedTopNotificationBarService,
        @Inject(REPORT_CONFIG) private reportConfig: ReportConfig,
    ) {}

    export(): Observable<boolean> {
        this.originContainer = document.querySelector('#viewport-container');
        ExportHelpers.setSvgIconsIds(this.originContainer);
        this.clonedContainer = this.createClone(this.originContainer);

        const fileName = CommonHelpers.finalizeExportFileNameTpl(
            this.reportService.getGlobalExportFileNameTpl(),
            'pdf',
        );

        ExportHelpers.fixSvgIcons(this.originContainer, this.clonedContainer);
        ExportHelpers.uncacheImages(this.clonedContainer);

        const opts = {
            filename: fileName,
            margin: [0.5, 0.5],
            html2canvas: {
                useCORS: true,
                logging: false,
            },
            pagebreak: { mode: 'css' },

            // jsPDF: { unit: 'in', format: 'a2', orientation: 'p' }
            jsPDF: this.generateJsPDFOptions(),
        };

        return new Observable((observer) => {
            this.notificationService.add(NOTIFICATION_TYPE.COMMON_EXPORT, 'Generating export', {
                theme: 'blue',
                spinner: true,
                shared: true,
            });

            html2pdf()
                .from(this.clonedContainer)
                .set(opts)
                .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
                .save()
                .then(() => {
                    this.notificationService.removeByType(NOTIFICATION_TYPE.COMMON_EXPORT);

                    observer.next(true);
                    observer.complete();
                });
        });
    }

    private generateJsPDFOptions(): Record<string, unknown> {
        const width = 1200;
        const height = this.calcContentHeight();

        const format = height > width ? [width, height] : [height, width];
        const orientation = height > width ? 'portrait' : 'landscape';

        return {
            orientation,
            unit: 'pt',
            format,
        };
    }

    private createClone(origin): HTMLElement {
        const container = origin.cloneNode(true) as HTMLElement;

        container.classList.add('mode-export');

        return container;
    }

    private calcContentHeight(): number {
        // looking for highest scrollable container = content
        const heights = Array.from(this.originContainer.getElementsByClassName('container')).map((el) =>
            LayoutHelpers.getElementHeight(el),
        );

        const extraHeight = 160;

        // 0.75 = px to pt
        return (Math.max(...heights) + this.getHeaderSize() + extraHeight) * 0.75;
    }

    private getHeaderSize(): number {
        const headerCtName = this.reportConfig.pdfExportHeaderContainer || 'main-export-pdf-header';
        const header = document.querySelector(headerCtName) as HTMLElement;
        let headerHeight = 0;

        // getting size of visible header element
        if (header) {
            header.style.display = 'block';
            headerHeight = LayoutHelpers.getElementHeight(header);
            header.style.display = '';
        }

        return headerHeight || 25;
    }
}
