export enum PALETTE {
    Grey90 = '#a4a4af',
    Grey60 = '#6a6a78',
    Grey40 = '#9494a1',
    Grey30 = '#ccccd7',
    Grey20 = '#e3e3e9',
    Grey10 = '#f5f5fa',
    White = '#ffffff',

    GY_100 = '#fdca6e',
    GY_10 = '#fef9f0',

    PG_100 = '#ff7675',
    PG_10 = '#fff1f1',

    B_100 = '#4d7cfe',
    B_10 = '#edf1fe',

    REB_100 = '#01cec9',
    REB_10 = '#e5faf9',

    EF_100 = '#6c5ce7',
    EF_10 = '#f0eefc',

    ML_100 = '#00b894',
    ML_10 = '#e5f7f4',

    PI16_100 = '#e74393',
    PI16_10 = '#fcecf4',
    PI8_100 = '#fd7aa8',
    PI8_10 = '#fef1f6',

    OV_100 = '#a19bfe',
    OV_10 = '#f5f5fe',

    CY_100 = '#1aabc4',
    CY_10 = '#e8f6f9',
}
