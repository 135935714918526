import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { NavigationCancel, Router, UrlTree } from '@angular/router';

import { AuthService } from '@shared/services';

@Injectable({ providedIn: 'root' })
export class LoggedInAccessGuard {
    constructor(private router: Router, private authService: AuthService) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationCancel),
                map(({ url }: NavigationCancel) => url),
            )
            .subscribe((url) => (this.authService.redirectAfterLoginUrl = url));
    }

    canActivate(): Observable<boolean | UrlTree> {
        return this.isAuthenticated();
    }

    canActivateChild(): Observable<boolean | UrlTree> {
        return this.isAuthenticated();
    }

    private isAuthenticated(): Observable<boolean | UrlTree> {
        return this.authService
            .isAuthenticated()
            .pipe(map((isAuthenticated) => isAuthenticated || this.router.createUrlTree(['login'])));
    }
}
