import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { getValidationMessage } from '../../../helpers/validatiom-message.helpers';

@Component({
    selector: 'app-validation-messages',
    templateUrl: './validation-messages.component.html',
    styleUrls: ['./validation-messages.component.scss'],
})
export class ValidationMessagesComponent implements OnInit {
    @Input() fieldName!: string;
    @Input() control!: AbstractControl;

    validationMessages$: Observable<string[]>;

    ngOnInit() {
        this.validationMessages$ = this.control.valueChanges.pipe(
            startWith(this.control.value),
            map(() => this.getErrorMessages()),
        );
    }

    private getErrorMessages(): string[] {
        if (!this.control.errors) return [];

        const errorsMap = Object.entries(this.control.errors);

        return errorsMap.map(([key, value]) => getValidationMessage(this.fieldName, key, value));
    }
}
