import { Component } from '@angular/core';

import { FullscreenViewService } from '../../services';

@Component({
    selector: 'fullscreen-widget-back-button',
    templateUrl: './fullscreen-widget-back-button.component.html',
    styleUrls: ['./fullscreen-widget-back-button.component.scss'],
})
export class FullscreenWidgetBackButtonComponent {
    constructor(private service: FullscreenViewService) {}

    goBack(): void {
        this.service.goBackToReport();
    }
}
