import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FullscreenViewExportService, FullscreenViewService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({ selector: '[fullscreenGlobalExport]' })
export class FullscreenGlobalExportDirective implements OnInit {
    @Input('fullscreenGlobalExportExceptions') exceptions: unknown[] = [];

    constructor(
        private container: ViewContainerRef,
        private template: TemplateRef<unknown>,
        private fullscreenViewService: FullscreenViewService,
        private exportService: FullscreenViewExportService,
    ) {
        this.fullscreenViewService.isFullscreenView$.pipe(untilDestroyed(this)).subscribe(() => {
            this.update();
        });
    }

    ngOnInit() {
        this.update();
    }

    private update(): void {
        const { isFullscreenView } = this.fullscreenViewService;
        const hasExceptions = this.exceptions.some(Boolean);
        this.container.clear();

        if (isFullscreenView && !hasExceptions) {
            this.exportService.setContainer(this.container);
        } else {
            this.container.createEmbeddedView(this.template);
        }
    }
}
