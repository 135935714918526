import { CommonHelpers } from '@shared/helpers/common.helpers';
import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '../enums';
import { EntityBase } from './EntityBase';
import { IBrandDto } from '../interfaces/IBrandDto';
import { BRAND_TYPE } from '../enums/brand_type';
import { EntityCategory } from '@main/models/EntityCategory';

export class EntityBrand extends EntityBase<IBrandDto> {
    parent: Partial<EntityBrand>;
    master: Partial<EntityBrand>;
    brandType: {
        code: BRAND_TYPE;
        title: string;
    };
    posterUrl: string;
    impressionsCount: number;
    domain: string;
    homePageUrl: string;
    subBrands: EntityBrand[];
    banner: string;
    category: EntityCategory;

    constructor(dto?: Partial<IBrandDto>) {
        super({
            type: ENTITY_ITEM_TYPE.SINGLE,
            entityType: BUSINESS_ENTITY.Brand,
            dto,
        });

        if (dto) {
            this.id = dto.id;
            this.brandType = dto.type;
            this.posterUrl = dto.posterUrl;
            this.parent = dto.parent && new EntityBrand(dto.parent);
            this.master = dto.master && new EntityBrand(dto.master);
            this.icon = dto.favIconUrl || null;
            this.impressionsCount = dto.impressions?.total;
            this.domain = dto.domain || null;
            this.homePageUrl = dto.homePageUrl || null;
            this.setTitle(dto.name);
            this.subBrands = dto.brands?.elements?.map((brand) => new EntityBrand(brand)) || null;
            this.banner = dto.banner || null;
            this.category = dto.category && new EntityCategory(dto.category);
        }
    }

    copy(): EntityBrand {
        return CommonHelpers.copyProps(this, new EntityBrand());
    }
}
