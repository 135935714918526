export interface Notification {
    id: string;
    type: NOTIFICATION_TYPE;
    message: string;
    options: NotificationOptions;
    state: NotificationState;
}

export interface NotificationOptions {
    theme: string;
    spinner: boolean;
    closable: boolean;
    shared: boolean;
}

export interface NotificationState {
    hidden?: boolean;
    sharedCount?: number;
}

export enum NOTIFICATION_TYPE {
    GENERIC = 'GENERIC',
    MAINTENANCE = 'MAINTENANCE',
    COMMON_EXPORT = 'COMMON_EXPORT',
}
