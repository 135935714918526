import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'widget-no-data',
    templateUrl: './widget-no-data.component.html',
    styleUrls: ['./widget-no-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetNoDataComponent {
    @Input() message = 'No data to match your filters';
}
