import { Injectable } from '@angular/core';

import { ViewportServiceBase } from '@app/integrations/services/viewport.base-service';
import { GetPageInfoResult } from '@app/integrations/services/viewport.service.types';
import { WMSDK } from './winmo.service.types';

@Injectable()
export class ViewportService extends ViewportServiceBase {
    minAppHeight = 400;

    SDK: WMSDK;

    setSDK(SDK: WMSDK): void {
        this.SDK = SDK;
    }

    scrollTo(top: number): void {
        console.info('Sending SDK event: scrollTo', { top });

        this.SDK.client('scrollTo', { top });
    }

    resizeWindow(height: number, _windth?: number): void {
        console.info('Sending SDK event: resizeWindow', { height });

        this.SDK.client('resizeWindow', { height });
    }

    getPageInfo(): Promise<GetPageInfoResult> {
        return this.SDK.client('getPageInfo').then((info) => {
            // normalizing offsetTop to our value
            info.offsetTop = info.offsetTop + info.scrollTop;
            return info;
        });
    }
}
