import { Injectable } from '@angular/core';
import {
    LIMIT_AREA,
    LIMIT_TYPE,
    LIMIT_WARNINGS_CONFIG,
    MessageScheme,
} from '@main/components/limits-notification/limits-notification.config';
import { INTERVAL_TYPE } from '@main/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '@shared/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { SessionStorageHelper } from '@shared/helpers/session-storage.helper';

@UntilDestroy()
@Injectable()
export class LimitsService {
    showLimitPage$ = new BehaviorSubject<boolean>(false);
    currentPath$ = new BehaviorSubject<[LIMIT_TYPE, LIMIT_AREA, INTERVAL_TYPE]>([null, null, null]);
    constructor(private authService: AuthService) {}

    get limitType(): LIMIT_TYPE {
        const path = this.currentPath$.value;

        return LIMIT_TYPE[path[0]] || null;
    }

    get contactMail(): Observable<string> {
        return this.authService.accountManagerMail;
    }

    getPathKey(path: [LIMIT_TYPE, LIMIT_AREA, INTERVAL_TYPE] = null): string {
        return (path ? path : this.currentPath$.value)?.join('_');
    }

    showLimitMessage(path: [LIMIT_TYPE, LIMIT_AREA, INTERVAL_TYPE]) {
        const isAlreadyShown = SessionStorageHelper.get(this.getPathKey(path));

        if (isAlreadyShown) {
            return;
        }

        this.currentPath$.next(path);
        this.showLimitPage$.next(true);
    }

    safeLimitsNotificationRemove(): void {
        if (!this.showLimitPage$.value || (this.showLimitPage$.value && this.getPathKey()[0] === LIMIT_TYPE.VIEW)) {
            return;
        }

        this.onBackToReport();
    }

    onBackToReport(): void {
        SessionStorageHelper.set(this.getPathKey(), 1);
        this.showLimitPage$.next(false);
    }

    getCurrentLimitMessage(): MessageScheme {
        return _.get(LIMIT_WARNINGS_CONFIG, this.currentPath$.value);
    }
}
