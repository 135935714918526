import { Directive, Input } from '@angular/core';

import { AnalyticsService, BusinessActionPayload, GlobalBusinessProps, UXActionPayload } from '@app/modules/analytics';
import { ReportService } from '@main/core/report.service';

@Directive({
    selector: '[analyticsBusiness]',
})
export class AnalyticsBusinessDirective {
    @Input('analyticsBusiness') actionName!: string;
    @Input() analyticsData: BusinessActionPayload = {};
    @Input() analyticsAddGlobalProps = false;
    @Input() analyticsAddUXProps = false;
    @Input() analyticsPropFromElementName?: keyof BusinessActionPayload;

    constructor(private analyticsService: AnalyticsService, private reportService: ReportService) {}

    track(uxPayload: UXActionPayload): void {
        let payload: BusinessActionPayload = { ...this.analyticsData };

        if (this.analyticsAddGlobalProps) payload = { ...payload, ...this.getGlobalProps() };
        if (this.analyticsAddUXProps) payload = { ...payload, ...uxPayload };
        if (uxPayload.areaType === 'widget') payload['Widget Name'] = uxPayload.areaName;
        if (this.analyticsPropFromElementName) payload[this.analyticsPropFromElementName] = uxPayload.elementName;

        this.analyticsService.trackBusinessAction(this.actionName, payload);
    }

    private getGlobalProps(): GlobalBusinessProps {
        const props: GlobalBusinessProps = {
            'Entity Type': this.analyticsService.getAnalyticsEntityType(
                this.reportService.rootEntity,
                this.reportService.categoryBenchmark,
            ),
            'Report Type': this.analyticsService.getAnalyticsReportType(
                this.reportService.rootEntity,
                this.reportService.entities,
            ),
        };

        return props;
    }
}
