export type MARK_ENTITY_ID = string | number;

export enum MARK_ENTITY_TYPE {
    ADVERTISER = 'ADVERTISER',
    PUBLISHER = 'PUBLISHER',
    CAMPAIGN = 'CAMPAIGN',
    CREATIVE = 'CREATIVE',
}

export interface MarkView {
    id: MARK_ENTITY_ID;
    type: MARK_ENTITY_TYPE;
    loading: boolean;
    status: boolean;
}

export interface MarkViews {
    [key: string]: MarkView;
}
