import { SortItem } from '@shared/models/request-params.model';
import { AdTypeItem } from '@main/services/ad-types.service.types';
import { SpecialPublisherItem } from '@main/services/publishers.service';
import { BUSINESS_ENTITY, CHANNEL } from '@main/enums';
import { EntityAdvertiser, EntityItem } from '@main/models';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { AD_TYPE } from '@main/enums/ad_type';
import {
    AD_TYPE_FILTER_TITLE,
    BE_TO_UI_AD_TYPE_FILTER_MAPPING,
    CHANNELS_AD_TYPE_FILTER,
    UI_AD_TYPE_FILTER,
    UI_TO_BE_AD_TYPE_MAPPING,
} from './components/creatives-filters/ad-types-filter.types';
import { Filter } from './components/creatives-filters/creatives-filters.types';
import { socialPublishersDefinition } from './components/creatives-filters/social-publishers-filter-definition';
import { SORT_BY_TYPE, WidgetConfig } from './creatives.types';
import { GetCreativesAdvertisersListResponse, GetCreativesListResponse } from './creatives.service.types';
import { CreativesItem } from './creatives.model';

export class CreativesHelpers {
    static getTypeFiltersForChannel(channelType: CHANNEL): UI_AD_TYPE_FILTER[] {
        const filters = CHANNELS_AD_TYPE_FILTER[channelType] || [];

        if (!filters.length) {
            console.warn(`Unknown channel: ${channelType}`);
        }

        return filters;
    }

    static getAdTypeFiltersByCode(code: string): UI_AD_TYPE_FILTER {
        return BE_TO_UI_AD_TYPE_FILTER_MAPPING[code];
    }

    static getAdTypeFilterTitle(type: UI_AD_TYPE_FILTER): string {
        return AD_TYPE_FILTER_TITLE[type];
    }

    static getAdTypesAvailableFilters(
        adTypes: AdTypeItem[],
        filtersForChannel: UI_AD_TYPE_FILTER[],
    ): UI_AD_TYPE_FILTER[] {
        const adTypesFilters = [];

        adTypes.forEach((v) => {
            const items = this.getAdTypeFiltersByCode(v.entity && v.entity.code);

            if (items) {
                adTypesFilters.push(...items);
            }
        });

        return filtersForChannel.filter((v) => adTypesFilters.includes(v));
    }

    static getPublisherTypesAvailableFilters(publishers: SpecialPublisherItem[]): number[] {
        return publishers.map((v) => v.id);
    }

    static getPublisherTypesFilterDefaultSelection(publishers: SpecialPublisherItem[]): number[] {
        const allFilters = this.getPublisherTypesAvailableFilters(publishers);
        return [...allFilters];
    }

    static generatePublisherTypeFiltersDefinition(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        socialPublishersDefinition,
        publishers: SpecialPublisherItem[],
    ): Filter[] {
        return socialPublishersDefinition
            .map((v) => {
                const publisherItem = publishers.find((publisher) => publisher.name === v.name);

                if (!publisherItem) {
                    return;
                }

                return {
                    id: publisherItem.id,
                    tooltipTitle: v.tooltipTitle,
                    icon: v.icon,
                };
            })
            .filter((v) => !!v);
    }

    static showAdvertiser(items: EntityItem[], widgetConfig: WidgetConfig): boolean {
        return widgetConfig.showAdvertiser || (widgetConfig.showAdvertiserIfFewEntities && items?.length > 1);
    }

    static serializePublisherTypeFilters(filters: number[]): string {
        if (filters.length === socialPublishersDefinition.length) {
            return;
        }

        return filters.join(',');
    }

    static serializeAdTypeFilters(uiFilters: UI_AD_TYPE_FILTER[]): AD_TYPE[] {
        if (uiFilters.length === 0) {
            return;
        }

        // check if all filters selected
        if (Object.keys(UI_AD_TYPE_FILTER).length === uiFilters.length) {
            return;
        }

        return uiFilters.flatMap((item: UI_AD_TYPE_FILTER) => UI_TO_BE_AD_TYPE_MAPPING[item]);
    }

    static serializeSortBy(sortBy: SORT_BY_TYPE): SortItem {
        switch (sortBy) {
            case SORT_BY_TYPE.NEWEST:
                return {
                    field: 'detectionRange.first',
                    direction: 'desc',
                };

            case SORT_BY_TYPE.EXPENSES:
                return {
                    field: 'expenses',
                    direction: 'desc',
                };

            case SORT_BY_TYPE.IMPRESSIONS:
                return {
                    field: 'impressions',
                    direction: 'desc',
                };

            case SORT_BY_TYPE.DURATION:
                return {
                    field: 'detectionRange.duration',
                    direction: 'desc',
                };
        }
    }

    static deserializeCreativesList(
        creativesResponse: GetCreativesListResponse,
        advertisersResponse: GetCreativesAdvertisersListResponse,
    ): CreativesItem[] {
        return creativesResponse?.creatives?.elements?.map((v) => {
            const creative = new CreativesItem(v);

            const advertiser = advertisersResponse?.creatives.elements.find((n) => n.id === v.id);

            if (advertiser) {
                const advertiserEntity = advertiser.brand
                    ? EntityHelpers.mapSingleEntity<EntityAdvertiser>(BUSINESS_ENTITY.Brand, advertiser.brand)
                    : EntityHelpers.mapSingleEntity<EntityAdvertiser>(
                          BUSINESS_ENTITY.Advertiser,
                          advertiser.advertiser,
                      );

                creative.applyAdvertiser(advertiserEntity);
            }

            return creative;
        });
    }
}
