import { NgModule } from '@angular/core';

import { SharedAdBlockCheckModule } from './adblock-check/adblock-check.module';

@NgModule({
    imports: [SharedAdBlockCheckModule],
    declarations: [],
    providers: [],
    exports: [SharedAdBlockCheckModule],
})
export class SharedModulesModule {}
