import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CurrencyService } from '@shared/services';

import { TabItem } from './tabs-bar.types';

@Component({
    selector: 'tabs-bar',
    templateUrl: './tabs-bar.component.html',
    styleUrls: ['./tabs-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsBarComponent {
    @Input() items: TabItem[];
    @Input() selected: TabItem;

    @Output() change = new EventEmitter<TabItem>();
    @Output() selectedChange = new EventEmitter<TabItem>();

    currency$ = this.currencyService.currency$;

    constructor(private currencyService: CurrencyService) {}

    onSelect(item: TabItem): void {
        this.change.emit(item);
        this.selectedChange.emit(item);
    }
}
