import { Injectable } from '@angular/core';
import { AccountService } from '@shared/services/account.service';
import { IRole } from '@shared/interfaces';
import { EventsService } from '@shared/services/events/events.service';
import { EventsEnum } from '@shared/services/events/events.enum';
import { AuthService } from '@shared/services/auth/auth.service';
import { ROLE_NAME_ENUM } from '@shared/enums';

@Injectable()
export class RoleService {
    private roles: ROLE_NAME_ENUM[] = [];

    constructor(
        private accountService: AccountService,
        private eventsService: EventsService,
        private authService: AuthService,
    ) {
        this.refreshRoles();

        this.eventsService.on(EventsEnum.USER_LOGGED_IN, this.refreshRoles.bind(this), 'roleService');
        this.eventsService.on(EventsEnum.USER_LOGGED_OUT, () => (this.roles = []), 'roleService');
    }

    getRolesList(): IRole[] {
        return [
            { id: ROLE_NAME_ENUM.ADMIN, priority: 1, name: 'BIS admin' },
            { id: ROLE_NAME_ENUM.RESELLER_ADMIN, priority: 2, name: 'Reseller admin' },
            { id: ROLE_NAME_ENUM.CUSTOMER_ADMIN, priority: 3, name: 'Account admin' },
            { id: ROLE_NAME_ENUM.USER, priority: 4, name: 'Standard user' },
        ];
    }

    refreshRoles(): void {
        this.authService.isAuthenticated().subscribe((isAuthenticated) => {
            if (isAuthenticated) {
                this.accountService.$currentUser.subscribe(() => {
                    this.roles = this.accountService.roles;
                });
            }
        });
    }

    hasRole(roleName: string): boolean {
        return !!this.roles.find((role) => role === roleName);
    }

    hasAdminRole(): boolean {
        const adminRoles = [ROLE_NAME_ENUM.ADMIN, ROLE_NAME_ENUM.RESELLER_ADMIN, ROLE_NAME_ENUM.CUSTOMER_ADMIN];

        return !!this.roles.find((role) => adminRoles.includes(role));
    }

    hasMarkModeRole(): boolean {
        return this.hasRole(ROLE_NAME_ENUM.SKIPLIST_MANAGER);
    }

    getRoleItemById(roleId: ROLE_NAME_ENUM): IRole {
        return this.getRolesList().find((v) => v.id === roleId);
    }

    getHighestRole(rolesIds: ROLE_NAME_ENUM[]): ROLE_NAME_ENUM {
        const roles = rolesIds.map((roleId) => this.getRoleItemById(roleId));

        const sorted = roles.sort((a, b) => a.priority - b.priority);

        return sorted.length ? sorted[0].id : undefined;
    }
}
