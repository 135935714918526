<app-base-page title="Reset password">
    <div class="reset-password">
        <form [formGroup]="form" (ngSubmit)="sendEmail()" novalidate>
            <input type="email" class="text-form-control block" placeholder="E-mail address" formControlName="email" />
            <app-validation-messages [control]="form.controls.email" fieldName="email"></app-validation-messages>

            <mat-error *ngIf="sendEmailError" class="d-block mt-10">{{ sendEmailError.message }}</mat-error>

            <div class="actions">
                <button
                    mat-raised-button
                    type="submit"
                    color="accent"
                    class="submit-button u-button h-38 block"
                    [disabled]="loading"
                >
                    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent"> </mat-progress-bar>
                    Send Recovery Link
                </button>

                <a [routerLink]="'/login'" class="primary-link">Go back to login</a>
            </div>
        </form>
    </div>
</app-base-page>
