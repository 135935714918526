import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppConfig } from '@app/app.config';
import { productAdIntelligencePath } from '@app/config';
import { WinmoService } from '../services/winmo.service';
import { IntegrationsService } from '@app/integrations/services/integrations.service';

@Component({
    selector: 'layout-winmo-preview',
    templateUrl: 'preview.component.html',
    styleUrls: ['./preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageWinmoPreviewComponent {
    readonly config = inject(AppConfig);
    readonly router = inject(Router);
    readonly winmoService = inject(WinmoService);
    readonly activatedRoute = inject(ActivatedRoute);
    readonly integrationsService = inject(IntegrationsService);

    get showFullReportBtn(): boolean {
        return this.winmoService.isSubscribed();
    }

    onViewReportBtnClick(): void {
        const path = this.activatedRoute.snapshot.firstChild.url.map((segment) => segment.path).join('/');

        this.integrationsService.getService<WinmoService>().openApp(`${productAdIntelligencePath}/${path}`);
    }
}
