import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { startPagePath } from '@app/config';

@Component({
    selector: 'not-found-page',
    templateUrl: './not-found-page.component.html',
})
export class NotFoundPageComponent {
    constructor(private router: Router) {}

    navigateToStartPage(): void {
        this.router.navigate([startPagePath]);
    }
}
