/* eslint-disable max-lines */
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
    Component,
    Input,
    Output,
    ViewChildren,
    QueryList,
    OnInit,
    ViewEncapsulation,
    EventEmitter,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { GRID_FILTER_TYPE as TYPE } from '@shared/enums';
import { ShortPipe } from '@shared/pipes/short.pipe';
import { CommonHelpers } from '@shared/helpers/common.helpers';
import { LayoutHelpers } from '@shared/helpers/layout.helpers';
import { Item, Filter } from './grid-filter.types';
import { Constants } from 'app/util/constants';

const DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: Constants.DATE_FMT_ALT,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'grid-filter',
    templateUrl: './grid-filter.component.html',
    styleUrls: ['./grid-filter.component.scss'],
    providers: [
        ShortPipe,
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class GridFilterComponent implements OnInit, OnChanges {
    @ViewChildren('menuView') menuView: QueryList<MatMenu>;
    @ViewChildren('menuTrigger') menuTrigger: QueryList<MatMenuTrigger>;

    @Input() items: Item[];
    @Input() removable = true;
    @Input() disabled: boolean;

    @Output() change = new EventEmitter<any>();

    menuViewReady = false;

    FilterType = TYPE;

    filters: Filter[];

    constructor(private shortPipe: ShortPipe) {}

    ngOnInit(): void {
        this.initFilters();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            this.initFilters();
        }
    }

    formatValue(filter: Filter): string {
        let text = '';

        switch (filter.type) {
            case TYPE.VALUE:
                return filter.value;

            case TYPE.RANGE:
                if (filter.value.from) {
                    text += `>${this.shortPipe.transform(filter.value.from || 0, 1)}`;
                }

                if (filter.value.from && filter.value.to) {
                    text += ` & `;
                }

                if (filter.value.to) {
                    text = text + `<${this.shortPipe.transform(filter.value.to || 0, 1)}`;
                }

                return text;

            case TYPE.PERCENT_RANGE:
                return `${filter.value.from}% - ${filter.value.to}%`;

            case TYPE.DATES_RANGE: {
                const textFrom = moment(filter.value.from).format(Constants.DATE_FMT_ALT);
                const textTo = moment(filter.value.to).format(Constants.DATE_FMT_ALT);

                return `${textFrom} - ${textTo}`;
            }
            case TYPE.MULTI_SELECTABLE_LIST: {
                const selected = this.getMultiSelectableListFilterSelected(filter);

                if (!selected || !selected.length) {
                    return 'None';
                }

                if (selected.length === filter.item.data.length) {
                    return 'All';
                }

                text = selected[0].title;

                if (selected.length > 1) {
                    text += ` and <b>${selected.length - 1}</b> more`;
                }

                return text;
            }
            case TYPE.SINGLE_SELECTABLE_LIST: {
                const dataItem = filter.item.data.find((v) => v.id === filter.value);

                return dataItem?.title || '';
            }
        }
    }

    showFilter(filter: Filter): void {
        const menuTrigger = this.getMenuTrigger(filter);

        filter.active = true;

        menuTrigger.openMenu();
    }

    removeFilter(filter: Filter): void {
        filter.active = false;

        this.resetFilter(filter);
        this.emitChanges();
    }

    onFilterFormShow(filter: Filter): void {
        switch (filter.type) {
            case TYPE.VALUE:
                CommonHelpers.focusFormControl(filter.form.get('value'));
                break;

            case TYPE.RANGE:
                CommonHelpers.focusFormControl(filter.form.get('from'));
                break;
        }
    }

    onFilterFormHide(filter: Filter): void {
        // manually trigger daterange filter on default selected dates
        if (filter.type === TYPE.DATES_RANGE && !filter.changed) {
            this.onFilterChange(filter.id, filter._value);
        }
    }

    onFiltersButtonClick(buttonEvent: Event): void {
        LayoutHelpers.fixTopScrollForMatMenuButton(buttonEvent);
    }

    private initFilters(): void {
        this.menuViewReady = false;

        this.filters = this.items.map((item, i) => ({
            item,
            index: i,
            id: item.id,
            title: item.title,
            type: item.type,
            menuView: undefined,
            form: this.createForm(item),
            value: undefined,
            textValue: '',
            active: false,
            changed: false,
        }));

        this.filters.forEach((filter) => {
            this.resetFilter(filter);
        });

        setTimeout(() => {
            this.menuView.forEach((v, i) => {
                this.filters[i].menuView = v;
            });

            this.menuViewReady = true;
        });
    }

    private createForm(item: Item): UntypedFormGroup {
        let form: UntypedFormGroup;

        switch (item.type) {
            case TYPE.VALUE:
            case TYPE.PERCENT_RANGE:
                form = new UntypedFormGroup({
                    value: new UntypedFormControl(),
                });
                break;

            case TYPE.DATES_RANGE:
            case TYPE.RANGE:
                form = new UntypedFormGroup({
                    from: new UntypedFormControl(),
                    to: new UntypedFormControl(),
                });
                break;

            case TYPE.MULTI_SELECTABLE_LIST:
                return;

            case TYPE.SINGLE_SELECTABLE_LIST:
                return;

            default:
                throw new Error('Unknown filter type');
        }

        form.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((res) => this.onFilterChange(item.id, res));

        return form;
    }

    private onFilterChange(id: string, result: any): void {
        const filter = this.getFilterById(id);

        filter.changed = true;

        switch (filter.type) {
            case TYPE.VALUE:
                filter.value = result.value;
                break;

            case TYPE.RANGE:
                filter.value = {
                    from: result.from ? parseInt(result.from, 10) : undefined,
                    to: result.to ? parseInt(result.to, 10) : undefined,
                };
                break;

            case TYPE.PERCENT_RANGE:
                filter.value = {
                    from: result.value[0],
                    to: result.value[1],
                };
                break;

            case TYPE.DATES_RANGE: {
                const { from, to } = result;

                if (!to) return;

                filter.value = {
                    from: new Date(from).valueOf(),
                    to: new Date(to).valueOf(),
                };
                break;
            }
            case TYPE.MULTI_SELECTABLE_LIST:
                filter._value[result.index] = result.checked;
                filter.value = this.getMultiSelectableListFilterSelected(filter);
                break;

            case TYPE.SINGLE_SELECTABLE_LIST: {
                filter.value = result.id;

                const menuTrigger = this.getMenuTrigger(filter);
                menuTrigger.closeMenu();
                break;
            }
        }

        filter.textValue = this.formatValue(filter);

        this.emitChanges();
    }

    private resetFilter(filter: Filter): void {
        switch (filter.type) {
            case TYPE.VALUE:
                filter.value = '';
                filter.form.get('value').setValue(filter.value, { emitEvent: false });
                break;

            case TYPE.RANGE:
                filter.value = {
                    from: undefined,
                    to: undefined,
                };
                filter.form.get('from').setValue(filter.value.from, { emitEvent: false });
                filter.form.get('to').setValue(filter.value.to, { emitEvent: false });
                break;

            case TYPE.PERCENT_RANGE:
                filter.value = {
                    from: 0,
                    to: 100,
                };
                filter.form.get('value').setValue([filter.value.from, filter.value.to], { emitEvent: false });
                break;

            case TYPE.DATES_RANGE:
                filter.value = {
                    from: new Date().getTime(),
                    to: new Date().getTime(),
                };
                // datepicker control value
                filter._value = {
                    from: moment(),
                    to: moment(),
                };
                filter.form.get('from').setValue(filter._value.from, { emitEvent: false });
                filter.form.get('to').setValue(filter._value.to, { emitEvent: false });
                break;

            case TYPE.MULTI_SELECTABLE_LIST:
                filter._value = filter.item.data.map(() => true);
                filter.value = filter.value = this.getMultiSelectableListFilterSelected(filter);
                break;

            case TYPE.SINGLE_SELECTABLE_LIST:
                filter.value = undefined;
                break;
        }

        filter.textValue = this.formatValue(filter);
        filter.changed = false;
    }

    private emitChanges(): void {
        this.items.forEach((item) => {
            const filter = this.getFilterById(item.id);

            item.value = filter.active ? filter.value : undefined;
        });

        this.change.emit(this.items.filter((v) => v.value));
    }

    private getFilterById(id: string): Filter {
        return this.filters.find((v) => v.id === id);
    }

    private getMultiSelectableListFilterSelected(filter: Filter): any {
        return filter.item.data.filter((v, i) => filter._value[i]);
    }

    private getMenuTrigger(filter: Filter): MatMenuTrigger {
        return this.menuTrigger.find((v, i) => i === filter.index);
    }
}
