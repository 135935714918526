import { BUSINESS_ENTITY } from '@main/enums';

export interface WMSDK {
    init: () => Promise<void>;
    client: (method: string, params?: any) => Promise<any>;
}

export type WinmoAuthTokenData = Readonly<{
    id: number;
    exp: number;
    email: string;
    firstName: string;
    lastName: string;
    country: string;
    allowedCountries: string[];
    subscribed: boolean;
    viewMode: WinmoViewMode;
    entities: string;
    path: string;
}>;

export type WinmoParams = Readonly<{
    names: string[];
    entityType: BUSINESS_ENTITY;
}>;

export enum WinmoViewMode {
    SIDEBAR = 'SIDEBAR',
    SEARCH = 'SEARCH',
}
