import { CommonHelpers } from '@app/shared/helpers/common.helpers';
import { ENTITY_ITEM_TYPE, GROUP_TYPE } from '../enums';
import { IEntityBase, IEntityGroup, IGroupDto } from '../interfaces';
import { IEntityItemGroupId, IEntityItemId } from '../types';
import { EntityBase } from './EntityBase';
import { EntityItem } from './EntityItem';

export class EntityGroup extends EntityBase<IGroupDto> {
    id: IEntityItemGroupId;
    groupType: GROUP_TYPE;
    items: EntityItem[];
    titleGenerated: boolean;
    virtual: boolean;
    resolved: boolean;

    accessLogId: string;

    constructor(params?: Partial<IEntityBase & IEntityGroup>) {
        super(params);

        if (params) {
            const { groupType, items, resolved, virtual, titleGenerated } = params;

            this.type = ENTITY_ITEM_TYPE.GROUP;
            this.groupType = groupType;
            this.items = items;
            this.resolved = !!resolved;
            this.virtual = !!virtual;
            this.titleGenerated = !!titleGenerated;

            this.accessLogId = params.accessLogId;
        }
    }

    isRegular(): boolean {
        return this.groupType === GROUP_TYPE.REGULAR;
    }

    isComparison(): boolean {
        return this.groupType === GROUP_TYPE.COMPARISON || this.isCategoryBenchmarkComparison();
    }

    isCategoryBenchmarkComparison(): boolean {
        return this.groupType === GROUP_TYPE.COMPARISON_CATEGORY_BENCHMARK;
    }

    isVirtual(): boolean {
        return this.virtual;
    }

    getItemsTitles(html?: boolean): string[] {
        return this.items.map((v) => (html ? v.fullTitleHtml : v.fullTitle));
    }

    getItemsIds(): IEntityItemId[] {
        return this.items.map((v) => v.id);
    }

    isResolved(): boolean {
        return this.resolved;
    }

    setResolved(): void {
        this.resolved = true;
    }

    setVirtual(value: boolean): void {
        this.virtual = value;
    }

    hasGroup(): boolean {
        return !!this.items.find((v) => v.isGroup());
    }

    copy(): EntityGroup {
        return CommonHelpers.copyProps({ ...this, items: this.items.slice() }, new EntityGroup());
    }
}
