import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { DatasetRequestParams, RequestService } from '@shared/services';
import { EntitySingle } from '@main/models';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { BUSINESS_ENTITY } from '@main/enums';
import { SearchEntitiesResponse } from './winmo.api-service.types';

@Injectable({ providedIn: 'root' })
export class WinmoApiService {
    constructor(private requestService: RequestService) {}

    searchEntities(names: string[], country: string): Observable<EntitySingle[]> {
        const q = names.join(',');

        const datasetParams: DatasetRequestParams = {
            datasetName: 'brands-and-advertisers',
            customParams: { q, country },
            labelEntityType: null,
            labelArea: 'winmo_service',
        };

        const processResponse = (response: SearchEntitiesResponse): EntitySingle[] => {
            const advertisers =
                response.advertisers?.elements?.map((item) =>
                    EntityHelpers.mapSingleEntity(BUSINESS_ENTITY.Advertiser, item),
                ) || [];

            const brands =
                response.brands?.elements?.map((item) => EntityHelpers.mapSingleEntity(BUSINESS_ENTITY.Brand, item)) ||
                [];

            return [...advertisers, ...brands];
        };

        return this.requestService.dataset<SearchEntitiesResponse>(datasetParams).pipe(
            catchError(() => of(null)),
            map((response) => processResponse(response)),
        );
    }
}
