import { Component, Input } from '@angular/core';
import { AppConfig } from '@app/app.config';

@Component({
    selector: 'app-base-page',
    templateUrl: './base-page.component.html',
    styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent {
    @Input() title!: string;
    @Input() icon: string;

    logoIconUrl = this.config.appInfo.logoSmall;

    constructor(private config: AppConfig) {}
}
