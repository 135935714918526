import { Inject, Injectable, inject } from '@angular/core';

import { WINDOW } from '@shared/services/window.providers';
import { AccountService } from '@shared/services/account.service';
import { IUser } from '@shared/interfaces';
import { AppConfig } from '@app/app.config';
import { SemrushService } from '../semrush/services/semrush.service';
import { PACKAGE } from '../semrush/semrush.types';

interface WindowUG extends Window {
    userGuiding: any;
}

const companyNameWlMap = {
    adcheck: 'AdCheck',
    adclarity: 'AdClarity',
    ibope: '',
    infoadex: 'InfoAdex',
    km: 'Kantar Media',
    nielsen: 'Nielsen',
    semrush: '',
};

@Injectable()
export class UserGuidingService {
    private packageName = {
        [PACKAGE.ALL]: 'All Channels',
        [PACKAGE.DISPLAY]: 'Display',
        [PACKAGE.SOCIAL_VIDEO]: 'Social & Video',
    };

    private initialized = false;

    private readonly semrushService = inject(SemrushService);

    constructor(
        private accountService: AccountService,
        private config: AppConfig,
        @Inject(WINDOW) private window: WindowUG,
    ) {
        if (this.initialized) {
            return;
        }

        this.initialized = true;

        this.accountService.$currentUser.subscribe((user) => {
            this.init(user);
        });
    }

    private init(user: IUser): void {
        const userGuiding = this.window.userGuiding;

        if (!userGuiding) {
            return;
        }

        try {
            userGuiding.identify(user.id, {
                name: this.getNameParam(user),
                domain: this.getDomainParam(),
                company: this.getCompanyParam(),
                package: this.getPackageParam(),
            });
        } catch (err) {
            console.warn(err);
        }
    }

    private getNameParam(user: IUser): string {
        return `${user.firstName} ${user.lastName}`;
    }

    private getDomainParam(): string {
        const urlParsed = new URL(this.window.location.href);

        return urlParsed.hostname;
    }

    private getCompanyParam(): string {
        const wlName = this.config.appInfo.id;

        return companyNameWlMap[wlName] || companyNameWlMap.adclarity;
    }

    private getPackageParam(): string {
        const wlName = this.config.appInfo.id;

        if (wlName.toLowerCase() !== 'semrush') {
            return 'others';
        }

        const semrushPackages = this.semrushService.getActivePackages();
        const packageText =
            semrushPackages.length === 1 ? this.packageName[semrushPackages[0]] : this.packageName[PACKAGE.ALL];

        return `SEMRush ${packageText}`;
    }
}
