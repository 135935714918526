import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'winmo-unsubscribed-page',
    templateUrl: './unsubscribed-page.component.html',
    styleUrls: ['./unsubscribed-page.component.scss'],
})
export class WinmoUnsubscribedPageComponent implements OnInit {
    message: Record<string, string>;

    ngOnInit() {
        this.message = {
            headerImage: 'assets/images/winmo/unsubscribed.svg',
            title: 'Upgrade to explore',
            subscript:
                'Your account does not have permission to view this page. Please contact Winmo for further assistance.',
        };
    }
}
