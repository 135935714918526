import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { RadioButtonItem } from './radiobutton-group.types';
import { chartColors } from '@shared/colors';

@Component({
    selector: 'radiobutton-group',
    templateUrl: './radiobutton-group.component.html',
    styleUrls: ['./radiobutton-group.component.scss'],
})
export class RadioButtonGroupComponent {
    @Input() items: RadioButtonItem[] = [];
    @Input() selected: RadioButtonItem;
    @Input() disabled: boolean;
    @Input() interactive = true;
    @Input() itemTemplate: TemplateRef<any>;

    @Output() change = new EventEmitter<RadioButtonItem>();
    @Output() selectedChange = new EventEmitter<RadioButtonItem>();

    onItemClick(item: RadioButtonItem): void {
        if (this.disabled || !this.interactive || item.disabled) {
            return;
        }

        this.selectedChange.emit(item);
        this.change.emit(item);
    }

    getColor(item: RadioButtonItem, index: number): string {
        return item.color || chartColors[index];
    }
}
