import { Directive, ElementRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import InlineEdit from './inline-edit.js';

export enum VALIDATION_ERROR_CODE {
    PATTERN_ERROR = 'PATTERN_ERROR',
    CUSTOM_ERROR = 'CUSTOM_ERROR',
}

@Directive({
    selector: '[inlineEdit]',
})
export class InlineEditDirective implements OnInit {
    @Input() editablePattern: string;
    @Input() editableValidateFn: (value: string) => boolean;
    @Input() onChange: (...any) => void;

    @Output() editableChange = new EventEmitter<string>();
    @Output() editableValidationError = new EventEmitter<VALIDATION_ERROR_CODE[]>();

    private inlineEdit: InlineEdit;

    constructor(private element: ElementRef) {}

    public ngOnInit(): void {
        this.inlineEdit = new InlineEdit(this.element.nativeElement, {
            onChange: (newValue, oldValue) => {
                this.editableChange.emit(newValue);

                if (this.onChange) {
                    this.onChange(newValue, oldValue, this.element);
                }
            },
            onValidate: this.validate.bind(this),
        });
    }

    private validate(value: string, _oldValue: string): boolean {
        const patternOk = !this.editablePattern || value.match(new RegExp(this.editablePattern, 'ui')) !== null;

        const validateFnOk = !this.editableValidateFn || this.editableValidateFn(value);

        const valid = patternOk && validateFnOk;

        if (!valid) {
            const errors = [];

            if (!patternOk) {
                errors.push(VALIDATION_ERROR_CODE.PATTERN_ERROR);
            }

            if (!validateFnOk) {
                errors.push(VALIDATION_ERROR_CODE.CUSTOM_ERROR);
            }

            this.editableValidationError.emit(errors);
        }

        return valid;
    }
}
