import { Directive, OnInit, TemplateRef, ViewContainerRef, Input, EmbeddedViewRef } from '@angular/core';
import { NgIfContext } from '@angular/common';
import { IUserFeatures } from '@shared/interfaces';
import { AccountService } from '@shared/services/account.service';

@Directive({
    selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
    private permissions = [];
    private features: IUserFeatures;
    private _thenTemplateRef: TemplateRef<NgIfContext> | null = null;
    private _elseTemplateRef: TemplateRef<NgIfContext> | null = null;
    private _thenViewRef: EmbeddedViewRef<NgIfContext> | null = null;
    private _elseViewRef: EmbeddedViewRef<NgIfContext> | null = null;

    constructor(private _viewContainer: ViewContainerRef, private accountService: AccountService) {}

    ngOnInit(): void {
        this.accountService.$features.subscribe((features) => {
            this._update(features);
        });
    }

    @Input()
    set hasPermission(val) {
        this.permissions = val;
        this._updateView();
    }

    @Input()
    set hasPermissionThen(templateRef: TemplateRef<NgIfContext> | null) {
        this.assertTemplate('ngIfThen', templateRef);
        this._thenTemplateRef = templateRef;
        this._thenViewRef = null;
        this._updateView();
    }

    @Input()
    set hasPermissionElse(templateRef: TemplateRef<NgIfContext> | null) {
        this.assertTemplate('ngIfThen', templateRef);
        this._elseTemplateRef = templateRef;
        this._elseViewRef = null;
        this._updateView();
    }

    private _update(features: IUserFeatures): void {
        this.features = features;

        this._updateView();
    }

    private _updateView(): void {
        if (this.checkPermission()) {
            if (!this._thenViewRef) {
                this._viewContainer.clear();
                this._elseViewRef = null;
                if (this._thenTemplateRef) {
                    this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef); // , this._context
                }
            }
        } else {
            if (!this._elseViewRef) {
                this._viewContainer.clear();
                this._thenViewRef = null;
                if (this._elseTemplateRef) {
                    this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef); // , this._context
                }
            }
        }
    }

    private checkPermission(): boolean {
        if (!this.features) {
            return true;
        }

        let hasPermission = false;

        if (this.features) {
            for (const checkPermission of this.permissions) {
                if (!!this.features[checkPermission]) {
                    hasPermission = true;
                }
            }
        }

        return hasPermission;
    }

    private assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
        const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);

        if (!isTemplateRefOrNull) {
            throw new Error(`${property} must be a TemplateRef.`);
        }
    }
}
