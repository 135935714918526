import { UntilDestroy } from '@ngneat/until-destroy';

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FullscreenViewExportService, FullscreenViewService } from '@main/shared/widget-full-screen-view';

@UntilDestroy()
@Directive({ selector: '[fullscreenWidgetExport]' })
export class FullscreenWidgetExportDirective implements OnInit {
    @Input('fullscreenWidgetExportExceptions') exceptions: unknown[] = [];

    constructor(
        private container: ViewContainerRef,
        private template: TemplateRef<unknown>,
        private fullscreenViewService: FullscreenViewService,
        private exportService: FullscreenViewExportService,
    ) {}

    ngOnInit() {
        const { isFullscreenView } = this.fullscreenViewService;
        const hasExceptions = this.exceptions.some(Boolean);

        if (isFullscreenView && !hasExceptions) {
            // Schedule in next tick to avoid ExpressionChangedAfterItHasBeenCheckedError in dev mode
            queueMicrotask(() => this.exportService.showWidgetExport(this.template));
        } else {
            this.container.createEmbeddedView(this.template);
        }
    }
}
