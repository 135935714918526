import { AdcCurrencyPipe } from './currency.pipe';
import { BigNumberPipe } from './big-number/big-number.pipe';
import { CurrencyHeadlinePipe } from './currency-headline.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { MinImpressionsPipe } from './min-impressions.pipe';
import { MinValPipe } from './minval.pipe';
import { AdcPercentPipe } from './percent.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { ShortPipe } from './short.pipe';
import { TimerPipe } from './timer.pipe';
import { SearchHighlightPipe } from './search-highlight.pipe';
import { RankPipe } from '@shared/pipes/rank.pipe';
import { DateAgoPipe } from '@shared/pipes/date-ago/date-ago.pipe';

export const pipes = [
    AdcCurrencyPipe,
    BigNumberPipe,
    CurrencyHeadlinePipe,
    DateFormatPipe,
    MinImpressionsPipe,
    MinValPipe,
    AdcPercentPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    SearchHighlightPipe,
    ShortPipe,
    TimerPipe,
    RankPipe,
    DateAgoPipe,
];

export const pipeProviders = [ShortPipe, AdcCurrencyPipe, AdcPercentPipe, CurrencyHeadlinePipe];
