import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class IntegrationServiceBase {
    loadSdk(): void {
        throw new Error('Method not implemented.');
    }

    getToken(): Observable<string> {
        throw new Error('Method not implemented.');
    }

    setToken(_token: string): void {
        throw new Error('Method not implemented.');
    }

    getIntegrationRedirectPage(): Observable<string> {
        throw new Error('Method not implemented.');
    }
}
