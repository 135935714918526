import { Directive, ElementRef, EventEmitter, Inject, Output } from '@angular/core';

import { ANALYTICS_ELEMENT } from '../../data';
import { UXActionPayload, UXActionType } from '../../models';

import { AnalyticsElementBaseDirective } from '../elements';

@Directive({ selector: 'analyticsActionBase' })
export abstract class AnalyticsActionBaseDirective {
    protected host = this.elementRef.nativeElement;

    @Output() analyticsTracked = new EventEmitter<void>();

    constructor(
        @Inject(ANALYTICS_ELEMENT) private element: AnalyticsElementBaseDirective<UXActionPayload>,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        if (!element) throw new Error('Analytics action can be used only in conjunction with analytics element');
    }

    protected abstract handleEvent(): void;

    protected track(actionType: UXActionType): void {
        this.element.track(actionType);
        this.analyticsTracked.next();
    }
}
