import { coerceArray } from '@angular/cdk/coercion';
import { inject, Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { from, mergeMap, Observable, of, ReplaySubject, throwError } from 'rxjs';

import { AppConfig } from '@app/app.config';
import { environment } from 'environments/environment';
import { IntegrationServiceBase } from '@app/integrations/services/integration.service.base';
import { productAdIntelligencePath } from '@app/config';
import { AuthHelpers, AuthTokenData, RequestService, SemrushProduct } from '@shared/services';
import { WINDOW } from '@shared/services/window.providers';
import { CommonHelpers } from '@shared/helpers/common.helpers';
import { PACKAGE } from '../semrush.types';
import { PricesConfigResponse, SMSDK } from './semrush.service.types';
import { ViewportService } from './viewport.service';

@Injectable({ providedIn: 'root' })
export class SemrushService implements IntegrationServiceBase {
    private readonly requestService = inject(RequestService);
    private readonly viewportService = inject(ViewportService);
    private readonly window = inject(WINDOW);
    private readonly config = inject(AppConfig);

    private readonly SDK$ = new ReplaySubject<SMSDK>(1);

    private sdkInjected = false;

    private tokenData: AuthTokenData;
    private activePackageIds: string[] = [];

    get isMainProductTrialAvailable(): boolean {
        return !!this.tokenData?.is_main_product_trial_available;
    }

    get productTrialsAvailable(): string[] {
        return this.tokenData?.product_trials_available || [];
    }

    get activeProducts(): SemrushProduct[] {
        return this.tokenData?.active_products || [];
    }

    getToken(): Observable<string> {
        if (!this.sdkInjected) {
            return throwError(() => new Error('SDK not loaded'));
        }

        return this.SDK$.pipe(mergeMap((SM) => from(SM.client('getAccessToken'))));
    }

    setToken(token: string): void {
        this.tokenData = AuthHelpers.decodeToken(token);
        this.activePackageIds = this.getPackagesIds();
    }

    getIntegrationRedirectPage(): Observable<string> {
        const url = this.hasActivePackages() ? this.tokenData.url : [productAdIntelligencePath, 'pricing'].join('/');

        return of(url);
    }

    getPrices(): Observable<PricesConfigResponse> {
        return this.requestService.pureRequest('GET', 'assets/config/semrush-prices.json');
    }

    addPackages(names: PACKAGE | PACKAGE[]): void {
        const productIds = coerceArray(names).map((name) => this.getSemrushIdForPackage(name));

        this.SDK$.subscribe((SM) => {
            SM.client('requestInAppPurchase', productIds);
        });
    }

    hasActivePackages(): boolean {
        return this.getPackagesIds().length > 0;
    }

    getActivePackages(): PACKAGE[] {
        const configPackages = Object.keys(environment.semrushPackagesIds) as PACKAGE[];

        return configPackages.filter((packageName) => this.isPackageActive(packageName));
    }

    isPackageActive(packageName: PACKAGE): boolean {
        const packageId = this.getSemrushIdForPackage(packageName);

        return this.activePackageIds.includes(packageId);
    }

    isAllPackageActive(): boolean {
        return (
            this.isPackageActive(PACKAGE.ALL) ||
            (this.isPackageActive(PACKAGE.DISPLAY) && this.isPackageActive(PACKAGE.SOCIAL_VIDEO))
        );
    }

    isTrialAvailable(packageName: PACKAGE): boolean {
        const packageId = this.getSemrushIdForPackage(packageName);

        return this.productTrialsAvailable.includes(packageId);
    }

    isTrialExpired(packageName: PACKAGE): boolean {
        return !this.isTrialAvailable(packageName) && !this.isPackageActive(packageName);
    }

    loadSdk(): void {
        if (this.sdkInjected) {
            return;
        }

        this.sdkInjected = true;

        this.SDK$.subscribe((SDK) => {
            this.initHistoryChangeHandlers(SDK);

            if (this.config.appInfo.fullHeightLayout) {
                this.viewportService.setSDK(SDK);
                this.viewportService.init();
            }
        });

        (this.window as any).onSmSdkLoad = () => {
            const SDK = (this.window as any).SM as SMSDK;

            if (isNil(SDK)) {
                return;
            }

            SDK.init().then(() => {
                this.SDK$.next(SDK);
            });
        };

        CommonHelpers.injectScript(environment.semrushSdkPath);
    }

    private initHistoryChangeHandlers(SDK: SMSDK): void {
        const currentUrl = new URL(window.location.href);

        SDK.client('replaceUrl', currentUrl.pathname + currentUrl.search);

        CommonHelpers.decorateHistoryChangeHandlers((method, url) => SDK.client(method, url));
    }

    private getPackagesIds(): string[] {
        return this.activeProducts.map((activeProduct) => activeProduct.id);
    }

    private getSemrushIdForPackage(packageName: PACKAGE): string {
        return environment.semrushPackagesIds[packageName];
    }
}
