import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Device } from '@main/models';
import { IconsHelpers } from '@main/helpers/icons.helpers';

@Component({
    selector: 'device-icon',
    templateUrl: './device-icon.component.html',
    styleUrls: ['./device-icon.component.scss'],
})
export class DeviceIconComponent implements OnInit, OnChanges {
    @Input() device: Device;
    @Input() customIcon: string;
    @Input() size = 16;

    icon: string;
    tooltipText: string;

    ngOnInit(): void {
        this.update();
    }

    ngOnChanges(): void {
        this.update();
    }

    private update(): void {
        if (this.device) {
            this.icon = this.customIcon || IconsHelpers.getDeviceIconNameByCode(this.device.code);
            this.tooltipText = this.device.title;
        }
    }
}
