<div class="ssc" [ngSwitch]="true">
    <div *ngSwitchDefault data-unit="skeleton-default">
        <div class="ssc-head-line mb"></div>
        <div class="ssc-head-line mb"></div>
        <div class="ssc-head-line"></div>
    </div>

    <div *ngSwitchCase="type | multiCase : [WIDGET.ESTIMATED_EXPENDITURE]" data-unit="skeleton-1">
        <div class="flex">
            <div class="w-50-p mts">
                <div class="ssc-line w-40-p mbs"></div>
                <div class="ssc-head-line w-40-p mbs"></div>
                <div class="ssc-line w-30-p"></div>
            </div>
            <div class="w-50-p mts">
                <div class="ssc-line w-40-p ml mbs"></div>
                <div class="ssc-head-line w-40-p ml mbs"></div>
                <div class="ssc-line w-30-p ml"></div>
            </div>
        </div>
    </div>

    <div
        *ngSwitchCase="
            type | multiCase : [WIDGET.AD_TYPES_DISTRIBUTION, WIDGET.TRANSACTION_METHODS, WIDGET.AD_PLACEMENT]
        "
        data-unit="skeleton-2"
    >
        <div class="flex justify-between align-center mts">
            <div class="ssc-circle ml" style="width: 95px; height: 95px"></div>
            <div class="w-60-p ml">
                <div class="ssc-line w-80-p mls mb"></div>
                <div class="ssc-line w-80-p mls mb"></div>
                <div class="ssc-line w-80-p mls"></div>
            </div>
        </div>
    </div>

    <div
        *ngSwitchCase="type | multiCase : [WIDGET.EXPENDITURE_TREND, WIDGET.COMPARISON_EXPENDITURE_TREND]"
        data-unit="skeleton-3"
    >
        <div class="ssc-head-line mb"></div>
        <div class="ssc-square" style="height: 215px"></div>
        <div class="ssc-head-line mt"></div>
    </div>

    <div *ngSwitchCase="type | multiCase : [WIDGET.COMPARISON_SOV]" data-unit="skeleton-4">
        <div class="ssc-head-line mb"></div>
        <div class="ssc-square" style="height: 155px"></div>
    </div>

    <div
        *ngSwitchCase="
            type
                | multiCase
                    : [
                          WIDGET.EXPENDITURE_BREAKDOWN,
                          WIDGET.TOP_ADVERTISERS_PUBLISHERS,
                          WIDGET.TRANSACTION_METHODS_DETAILS,
                          WIDGET.TOP_APPS,
                          WIDGET.YOUTUBE_CHANNELS,
                          WIDGET.CATEGORY_CHANNEL_SOV_BREAKDOWN
                      ]
        "
        data-unit="skeleton-5"
    >
        <div *ngIf="!isFullscreenView" class="flex justify-between align-center">
            <div class="w-100-p" style="padding-right: 110px">
                <div class="ssc-line table-row-60 mb"></div>
                <div class="ssc-line table-row-60 mb"></div>
                <div class="ssc-line table-row-60 mb"></div>
                <div class="ssc-line table-row-60"></div>
            </div>
            <div class="flex align-center" style="width: 390px; height: 240px">
                <div class="ssc-circle" style="width: 177px; height: 177px"></div>
            </div>
        </div>
        <ng-container *ngIf="isFullscreenView">
            <div class="ssc-line table-row mbs"></div>
            <div class="ssc-line table-row mbs"></div>
            <div class="ssc-line table-row mbs"></div>
            <div class="ssc-line table-row mbs"></div>
            <div class="ssc-line table-row"></div>
        </ng-container>
    </div>

    <div *ngSwitchCase="type | multiCase : [WIDGET.TOP_ADS]" data-unit="skeleton-6">
        <div class="flex justify-between align-start" style="gap: 24px">
            <ng-container *ngTemplateOutlet="topAdsCard"></ng-container>
            <ng-container *ngTemplateOutlet="topAdsCard"></ng-container>
            <ng-container *ngTemplateOutlet="topAdsCard"></ng-container>
            <ng-container *ngTemplateOutlet="topAdsCard"></ng-container>
        </div>

        <ng-template #topAdsCard>
            <div class="ssc-card ssc-wrapper w-30-p">
                <div class="ssc-square mb" style="height: 230px"></div>
                <div class="flex justify-between align-center">
                    <div class="ssc-line w-20-p"></div>
                    <div class="ssc-line w-20-p"></div>
                    <div class="ssc-line w-20-p"></div>
                    <div class="ssc-line w-20-p"></div>
                </div>
            </div>
        </ng-template>
    </div>

    <div
        *ngSwitchCase="type | multiCase : [WIDGET.CATEGORY_PUBLISHERS, WIDGET.CATEGORY_MARKET_LEADERS]"
        data-unit="skeleton-7"
    >
        <div class="ssc-line table-row mbs"></div>
        <div class="ssc-line table-row"></div>
    </div>

    <div
        *ngSwitchCase="
            type
                | multiCase
                    : [
                          WIDGET.COMPARISON_EXPENDITURE_BREAKDOWN,
                          WIDGET.COMPARISON_AD_BUYING_METHODS,
                          WIDGET.COMPARISON_ADVERTISERS_PUBLISHERS,
                          WIDGET.TOP_CAMPAIGNS
                      ]
        "
        data-unit="skeleton-8"
    >
        <div class="ssc-line table-row mbs"></div>
        <div class="ssc-line table-row mbs"></div>
        <div class="ssc-line table-row mbs"></div>
        <div class="ssc-line table-row mbs"></div>
        <div class="ssc-line table-row"></div>
    </div>

    <div *ngSwitchCase="type | multiCase : [WIDGET.CAMPAIGN_HEADER]" data-unit="skeleton-9">
        <div class="flex justify-between w-50-p">
            <div class="w-25-p mr">
                <div class="ssc-line w-80-p mbs"></div>
                <div class="ssc-head-line"></div>
            </div>
            <div class="w-25-p mr">
                <div class="ssc-line w-80-p mbs"></div>
                <div class="ssc-head-line"></div>
            </div>
            <div class="w-25-p mr">
                <div class="ssc-line w-80-p mbs"></div>
                <div class="ssc-head-line"></div>
            </div>
            <div class="w-25-p">
                <div class="ssc-line w-80-p mbs"></div>
                <div class="ssc-head-line"></div>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="type | multiCase : [WIDGET.BRAND_HEADER]" data-unit="skeleton-10">
        <div class="flex align-center mts">
            <div class="flex align-center">
                <div class="ssc-square" style="width: 40px; height: 40px; margin-right: 24px"></div>
            </div>
            <div class="w-100-p">
                <div class="ssc-head-line w-20-p mbs"></div>
                <div class="ssc-line w-40-p"></div>
            </div>
        </div>
        <br />
        <div class="flex align-center mt">
            <div class="ssc-head-line w-10-p mr"></div>
            <div class="ssc-head-line w-10-p mr"></div>
            <div class="ssc-head-line w-10-p"></div>
        </div>
    </div>
</div>
