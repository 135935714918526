import { chartColors } from '@shared/colors';

import { IconsHelpers } from '@main/helpers/icons.helpers';

import { axisTitleColor, labelsColor, lineColor } from './charts-defaults';

export const options = {
    generic: {
        chart: {
            type: 'column',
            height: 370,
            marginRight: 0,
            marginBottom: 110,
        },
        colors: chartColors,
        credits: { enabled: false },
        legend: {
            enabled: true,
            floating: true,
            useHTML: true,
            align: 'left',
            verticalAlign: 'top',
            padding: 0,
            marginBottom: 0,
            itemDistance: 5,
            itemStyle: {
                fontSize: '6px',
                fontWeight: 'normal',
            },
            itemWidth: 166,
            y: 295,
            labelFormatter(): string {
                return '<div style="margin-bottom: 5px; font-size: 11px; line-height: 12px;">' + this.name + '</div>';
            },
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                events: {
                    legendItemClick: () => false,
                },
            },
        },
        subtitle: { text: '' },
        title: {
            text: '',
        },
        tooltip: {
            useHTML: true,
            headerFormat: '<b>{point.key}</b><br/><table width="100%">',
            pointFormatter(): string {
                const valuePrecision = this.percentage > 0 && this.percentage < 1 ? 1 : 0;
                const value = this.percentage.toFixed(valuePrecision);

                return `
                    <tr>
                        <td style="color: ${this.color}">${this.series.name}:&nbsp;</td>
                        <td style="text-align: right"><b> ${value}%</b></td>
                    </tr>
                `;
            },
            footerFormat: '</table>',
            shared: true,
        },
        xAxis: {
            lineColor,
            tickWidth: 0,
            labels: {
                autoRotation: false,
                style: {
                    color: labelsColor,
                },
            },
            // categories: [...]
        },
        yAxis: {
            title: {
                // text: ''
                style: {
                    color: axisTitleColor,
                },
                margin: 0,
                x: -15,
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                },
            },
            labels: {
                style: {
                    color: labelsColor,
                },
            },
            gridLineColor: lineColor,
        },
        // series: []
    },

    small: {},

    stacking: {
        plotOptions: {
            column: {
                stacking: 'percent',
            },
        },
    },

    percents: {
        yAxis: {
            min: 0,
            max: 100,
            labels: {
                formatter(): string {
                    return this.value + '%';
                },
            },
        },
    },

    fixedColumnWidthAndIcons: {
        chart: {
            events: {
                render() {
                    const gap = 1;

                    const chart = this;
                    const axis = chart.xAxis[0];
                    const categoriesLength = axis.max - axis.min + axis.minPointOffset * 2;
                    const categoryWidth = axis.width / categoriesLength;
                    const pointsInCategoryLength = axis.series.length;

                    const maxPointWidth = Math.max(...axis.series.map((v) => v.data[0].pointWidth));

                    const calc = (maxWidth: number) => {
                        let grpPadding;

                        do {
                            grpPadding = (categoryWidth - pointsInCategoryLength * (maxWidth + gap)) / 2;
                            maxWidth -= 2;
                        } while (grpPadding < 5);

                        maxWidth += 2;

                        return {
                            pointWidth: maxWidth,
                            groupPadding: grpPadding,
                        };
                    };

                    const { pointWidth, groupPadding } = calc(maxPointWidth);

                    if (!chart.runningUpdate) {
                        chart.runningUpdate = true;

                        const ticks = this.xAxis[0].ticks;

                        fixLegend(ticks);

                        chart.update({
                            plotOptions: {
                                column: {
                                    groupPadding: groupPadding / categoryWidth,
                                    pointPadding: gap / categoryWidth,
                                    pointWidth,
                                },
                            },
                        });

                        chart.runningUpdate = false;
                    }
                },
            },
        },
    },

    customTooltip: {
        tooltip: {
            formatter: () => {},
            className: 'adc-charts-custom-tooltip',
            padding: 0,
            borderWidth: 0,
            shadow: false,
        },
    },

    highlightGroupOnHover: {
        plotOptions: {
            series: {
                point: {
                    events: {
                        mouseOver() {
                            const categoryIndex = this.category;
                            const series = this.series.chart.series;

                            series.forEach((serie) => {
                                serie._opacity = serie.opacity;
                                serie.opacity = 1;

                                serie.points.forEach((v, index) => {
                                    v._opacity = v.opacity;

                                    v.update(
                                        {
                                            opacity: index === categoryIndex ? 1 : 0.2,
                                        },
                                        true,
                                        false,
                                        false,
                                    );
                                });
                            });
                        },
                        mouseOut() {
                            try {
                                const series = this.series.chart.series;
                                series.forEach((serie) => {
                                    serie.opacity = serie._opacity;

                                    serie.points.forEach((v) => {
                                        v.update(
                                            {
                                                opacity: v._opacity,
                                            },
                                            true,
                                            false,
                                            false,
                                        );
                                    });
                                });
                            } catch {}
                        },
                    },
                },
            },
        },
    },
};

const fixLegend = (ticks: Highcharts.Tick[]): void => {
    /* set default icon for broken icons */
    Object.values(ticks).forEach((v: Highcharts.Tick) => {
        const el = v.label?.element;
        const img = el?.querySelector('.icon img') as HTMLImageElement;

        if (img) {
            img.onerror = () => {
                img.src = `${IconsHelpers.getEntityIconImagePath()}`;
            };
        }
    });
};
