<ng-container *ngIf="!isGroup">
    <labeled-icon
        [iconUrl]="iconUrl"
        [iconCls]="iconCls"
        [defaultIcon]="defaultIcon"
        [matIconSize]="matIconSize"
        [useAsSvg]="useAsSvg"
        [matIconColor]="matIconColor"
        [clickable]="clickable || navigatable"
        [removable]="removable"
        [title]="title | searchHighlight : search"
        [disabled]="disabled"
        [tooltip]="_tooltip"
        [cls]="customClass"
        (click)="onClick()"
        (remove)="remove.emit(item)"
    >
    </labeled-icon>
</ng-container>

<ng-container *ngIf="isGroup">
    <labeled-icon
        [iconUrl]="iconUrl"
        [matIconSize]="20"
        [matIconColor]="matIconColor"
        [useAsSvg]="useAsSvg"
        [clickable]="clickable || navigatable"
        [removable]="removable"
        [title]="title"
        [disabled]="disabled"
        [tooltip]="_tooltip"
        [cls]="customClass"
        (click)="onClick()"
        (remove)="remove.emit(item)"
    >
    </labeled-icon>
</ng-container>
