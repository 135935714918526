<div fxLayout="row wrap" fxLayoutAlign="start center" class="box">
    <div
        *ngFor="let item of items; let index = index"
        fxLayoutAlign="start center"
        class="item"
        [ngClass]="{ selected: item === selected, disabled: disabled || item.disabled, interactive: interactive }"
        (click)="onItemClick(item)"
    >
        <div class="colored-circle-box s-10">
            <span [style.backgroundColor]="getColor(item, index)"></span>
        </div>

        <ng-container *ngIf="!itemTemplate">
            <div class="title">{{ item.title }}</div>
        </ng-container>

        <ng-container *ngIf="!!itemTemplate">
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
        </ng-container>
    </div>
</div>
