import { CommonHelpers } from '@shared/helpers/common.helpers';
import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '../enums';
import { IAdvertiserDto } from '../interfaces';
import { EntityBase } from './EntityBase';

export class EntityAdvertiser extends EntityBase<IAdvertiserDto> {
    impressionsCount: number;

    constructor(dto?: Partial<IAdvertiserDto>) {
        super({
            type: ENTITY_ITEM_TYPE.SINGLE,
            entityType: BUSINESS_ENTITY.Advertiser,
            dto,
        });

        if (dto) {
            this.id = dto.id;
            this.icon = dto.favIconUrl;
            this.impressionsCount = dto.impressions?.total;

            this.setTitle(dto.name);
        }
    }

    copy(): EntityAdvertiser {
        return CommonHelpers.copyProps(this, new EntityAdvertiser());
    }
}
