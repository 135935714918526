<button
    #menuTrigger="matMenuTrigger"
    mat-button
    color="primary"
    [disabled]="isDisabled"
    data-unit="period-button"
    [matMenuTriggerFor]="popupMenu"
    [class.active]="menuTrigger.menuOpen"
    class="u-dropdown-button"
    (keydown.esc)="menuTrigger.closeMenu()"
    (menuOpened)="onPopupOpened()"
>
    <mat-icon class="s-12 mr-2" svgIcon="calendar"></mat-icon>
    <span>{{ !value ? 'N/A' : value.title }}</span>
    <mat-icon class="arrow" iconPositionEnd>keyboard_arrow_down</mat-icon>
</button>

<mat-menu
    #popupMenu="matMenu"
    [overlapTrigger]="false"
    xPosition="after"
    data-unit="period-menu"
    class="select-period-dropdown"
    (click)="$event.stopPropagation()"
>
    <div fxLayout="row" fxLayoutAlign="start" class="content" (click)="$event.stopPropagation()">
        <div class="row-predefined">
            <ng-container *ngFor="let item of predefinedItems">
                <button
                    mat-button
                    fxLayout="row"
                    fxLayoutAlign="start"
                    class="h3 pl-25"
                    data-unit="predefined-period-button"
                    (click)="onSelectPredefined(item, $event)"
                    [disabled]="item.disabled"
                    [ngClass]="{ selected: item.id === selectedPeriod.id, disabled: !isPeriodSelectable(item) }"
                >
                    {{ item.title }}
                </button>
            </ng-container>
        </div>
        <div class="row-calendar" fxFlex>
            <month-picker
                [minDate]="calendarMinDate"
                [maxDate]="calendarMaxDate"
                [startDate]="calendarStartDate"
                [endDate]="calendarEndDate"
                [selectableMonthsDates]="selectableMonthsDates"
                [maxSelectableMonthsCount]="periodMaxMonths"
                (monthRangeSelected)="onCalendarSelect($event)"
            >
            </month-picker>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="footer" (click)="$event.stopPropagation()">
        <button
            mat-raised-button
            type="button"
            color="accent"
            data-unit="apply-button"
            class="ml-16 btn-apply"
            (click)="onBtnApplyClick()"
        >
            <span>Apply</span>
        </button>
    </div>
</mat-menu>
