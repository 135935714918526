import moment from 'moment';
import { PERIOD_TYPE } from '../enums';
import { Period } from '../models';

export class PeriodHelpers {
    static serializePeriodDate(value: string): string {
        return value.replace(/-/g, '');
    }

    static deserializePeriodDate(value: string): string {
        return value.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }

    static isCustomPeriod(value: string | number): boolean {
        return value.toString().indexOf('-') !== -1;
    }

    static getPeriodByPersistedValue(periods: Period[], value: string | number): Period {
        if (!this.isCustomPeriod(value)) {
            return periods.find((v) => v.id === value || v.id === Number(value));
        }

        /* composing custom range period */
        const [startDate, endDate] = value
            .toString()
            .split('-')
            .map((v) => this.deserializePeriodDate(v));

        const startMonth = periods.find((v) => v.start === startDate && v.type === PERIOD_TYPE.MONTHLY);
        const endMonth = periods.find((v) => v.end === endDate && v.type === PERIOD_TYPE.MONTHLY);

        if (startMonth && endMonth) {
            return this.composePeriodFromStartEndMonths(startMonth, endMonth);
        }
    }

    static composePeriodFromStartEndMonths(startMonth: Period, endMonth: Period): Period {
        return new Period({
            id: `${this.serializePeriodDate(startMonth.start)}-${this.serializePeriodDate(endMonth.end)}`,
            customRange: true,
            type: PERIOD_TYPE.RANGE,
            title: `${startMonth.title} - ${endMonth.title}`,
            start: startMonth.start,
            end: endMonth.end,
            supportedIntervals: startMonth.supportedIntervals,
            defaultInterval: startMonth.defaultInterval,
        });
    }

    static calculateWholeRangePeriod(periods: Period[]): Period {
        const availablesMonths = periods.filter((v) => v.type === PERIOD_TYPE.MONTHLY && !v.disabled && !v.restricted);

        availablesMonths.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());

        const startMonth = availablesMonths[0];
        const endMonth = availablesMonths[availablesMonths.length - 1];

        return startMonth === endMonth ? startMonth : this.composePeriodFromStartEndMonths(startMonth, endMonth);
    }
}
